/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { motion, useAnimationControls } from 'framer-motion';

import cn from '../../../utils/cn';

import styles from './CompletionStep.module.scss';
import dashboardStyle from '../../templates/CompletionDashboard/CompletionDashboard.module.scss';

import CheckList from '../../molecules/CheckList/CheckList';
import ProgressBar from '../../atoms/ProgressBar/ProgressBar';
import Picto from '../../atoms/Picto/Picto';

const CompletionStep = ({
  title,
  progression,
  total,
  items,
  toggleLabel,
  className,
  width,
  subtitle,
  disabled,
  waiting,
  waitingLabel,
  onExpand,
  windowWidth,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpand = () => {
    setExpanded(!expanded);
    onExpand(!expanded);
  };

  const controls = useAnimationControls();
  const MOBILE = windowWidth <= 1024;

  useEffect(() => {
    if (expanded) controls.start({ width: '100%' });
    else controls.start({ width });
  }, [width, expanded]);

  useEffect(() => {
    if (expanded) handleExpand();
  }, [MOBILE]);

  return (
    <motion.div
      className={cn([
        className,
        styles.completionStep,
        expanded && styles.expanded,
        expanded && dashboardStyle.expanded,
        disabled || waiting ? styles.disabled : null,
        waiting ? styles.waiting : null,
      ])}
      initial={{ width }}
      animate={controls}
      transition={{
        type: 'inertia',
        duration: 0.33,
      }}
    >
      <div className={styles.topBlock}>

        <div
          className={cn([
            styles.header,
            !MOBILE ? styles.fade : null,
          ])}
        >

          <div>
            {subtitle
            && (expanded || waiting)
            && (
              <span className={styles.subtitle}>
                {waiting
                && (
                  <Picto
                    icon="info-circle"
                    color="var(--color-secondary)"
                    width={16}
                  />
                )}
                {subtitle}
              </span>
            )}
            <h6 className={styles.title}>{title}</h6>
          </div>

          <ProgressBar
            value={progression}
            total={total}
            size={waiting ? 'medium' : 'small'}
            hidden={expanded}
          />

        </div>

        {!waiting ? (
          <CheckList
            options={items}
            details={expanded}
            windowWidth={windowWidth}
          />
        ) : (
          <p className={styles.waitingLabel}>
            {waitingLabel}
          </p>
        )}

      </div>

      {!disabled
      && !waiting
      && (
        <div className={styles.toggle} onClick={handleExpand}>

          <span className={styles.label}>{toggleLabel}</span>

          <Picto
            icon={expanded ? 'minus' : 'plus'}
            color="var(--color-secondary)"
            width={16}
          />

        </div>
      )}

    </motion.div>
  );
};

CompletionStep.propTypes = {
  title: PropTypes.string.isRequired,
  progression: PropTypes.number.isRequired,
  total: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.shape()),
  toggleLabel: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  subtitle: PropTypes.string,
  disabled: PropTypes.bool,
  waiting: PropTypes.bool,
  waitingLabel: PropTypes.string,
  onExpand: PropTypes.func,
  windowWidth: PropTypes.number,
};

CompletionStep.defaultProps = {
  className: null,
  width: 275,
  disabled: false,
  waiting: false,
  onExpand: () => {},
  total: 100,
  items: [],
  toggleLabel: '',
  subtitle: '',
  waitingLabel: '',
  windowWidth: 1920,
};

export default CompletionStep;
