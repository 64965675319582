/* eslint-disable max-len */
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import {
  UnlockerLoader,
  Picto,
} from 'ui-library-unlocker';
// Hooks
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';

import styles from './AdminKPI.module.scss';
import { getKpi } from '../../../services/stats';

function AdminKPI() {
  const { t } = useTranslation();
  const formatLegend = useCallback((value) => <span>{t(`dashboard.adminKPI.table.columns.${value}`)}</span>, [t]);
  const formatTooltip = useCallback((value, name) => [`${value}`, t(`dashboard.adminKPI.table.columns.${name}`)], [t]);

  const {
    data: statsData,
    isFetching: statsFetching,
  } = useQuery({
    queryKey: ['stats-kpi'],
    queryFn: async () => {
      const stats = await getKpi();
      return {
        enrolment: [
          {
            name: t('dashboard.adminKPI.table.columns.agencies'),
            enroled: stats.data.numberOfOnboardedAgencies,
            waiting: stats.data.numberOfAgencies - stats.data.numberOfOnboardedAgencies,
            amt: stats.data.numberOfAgencies,
          },
          {
            name: t('dashboard.adminKPI.table.columns.pmo'),
            enroled: stats.data.numberOfOnboardedCompanies,
            waiting: stats.data.numberOfCompanies - stats.data.numberOfOnboardedCompanies,
            amt: stats.data.numberOfCompanies,
          },
          {
            name: t('dashboard.adminKPI.table.columns.pph'),
            enroled: stats.data.numberOfOnboardedLessors,
            waiting: stats.data.numberOfLessor - stats.data.numberOfOnboardedLessors,
            amt: stats.data.numberOfLessor,
          },
        ],
        lease: [
          {
            name: t('dashboard.adminKPI.table.columns.leases'),
            signed: stats.data.numberOfSignedLease,
            pending: stats.data.numberOfPendingLease,
            amt: stats.data.numberOfSignedLease + stats.data.numberOfPendingLease,
          },
        ],
        properties: [
          {
            name: t('dashboard.adminKPI.table.columns.properties'),
            leased: stats.data.numberOfLeasedProperties,
            available: stats.data.numberOfAvailableProperties,
            amt: stats.data.numberOfAvailableProperties + stats.data.numberOfLeasedProperties,
          },
        ],
        users: [
          {
            name: t('dashboard.adminKPI.table.columns.users'),
            tenant: stats.data.numberOfTenants,
            lessor: stats.data.numberOfLessor,
            amt: stats.data.numberOfTenants + stats.data.numberOfLessor,
          },
        ],
      };
    },
    keepPreviousData: true,
  });

  return (
    <div>
      <div className={styles.heading}>
        <div className={styles.header}>
          <h2>
            <Picto width={25} icon="chart" color="var(--color-secondary)" className="m-r-5 p-r-2" />
            {t('dashboard.adminKPI.title')}
          </h2>
        </div>
      </div>
      {statsFetching ? (
        <UnlockerLoader size={300} />
      ) : (
        <div className={styles.statsContainer}>
          <ResponsiveContainer width="100%" height={300} className={styles.statBlock}>
            <h3>
              {t('dashboard.adminKPI.enrolment')}
            </h3>
            <BarChart
              data={statsData.enrolment}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip formatter={formatTooltip} />
              <Legend
                formatter={formatLegend}
                verticalAlign="bottom"
                height={40}
              />
              <Bar dataKey="enroled" stackId="a" fill="var(--color-tertiary-600)" />
              <Bar dataKey="waiting" stackId="a" fill="var(--color-secondary-600)" />
            </BarChart>
          </ResponsiveContainer>
          <ResponsiveContainer className={styles.statBlock} width="100%" height={300}>
            <h3>
              {t('dashboard.adminKPI.lease')}
            </h3>
            <BarChart
              width={500}
              height={300}
              data={statsData.lease}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip formatter={formatTooltip} />
              <Legend
                formatter={formatLegend}
                verticalAlign="bottom"
                height={40}
              />
              <Bar dataKey="signed" stackId="a" fill="var(--color-tertiary-600)" />
              <Bar dataKey="pending" stackId="a" fill="var(--color-secondary-600)" />
            </BarChart>
          </ResponsiveContainer>

          <ResponsiveContainer width="100%" height={300} className={styles.statBlock}>

            <h3>
              {t('dashboard.adminKPI.properties')}
            </h3>
            <BarChart
              width={500}
              height={300}
              data={statsData.properties}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip formatter={formatTooltip} />
              <Legend
                formatter={formatLegend}
                verticalAlign="bottom"
                height={40}
              />
              <Bar dataKey="leased" stackId="a" fill="var(--color-tertiary-600)" />
              <Bar dataKey="available" stackId="a" fill="var(--color-secondary-600)" />
            </BarChart>
          </ResponsiveContainer>

          <ResponsiveContainer width="100%" height={300} className={styles.statBlock}>
            <h3>
              {t('dashboard.adminKPI.users')}
            </h3>
            <BarChart width={500} height={300} data={statsData.users}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip formatter={formatTooltip} />
              <Legend
                formatter={formatLegend}
                verticalAlign="bottom"
                height={40}
              />
              <Bar dataKey="tenant" fill="var(--color-tertiary-600)" />
              <Bar dataKey="lessor" fill="var(--color-secondary-600)" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>

  );
}

export default AdminKPI;
