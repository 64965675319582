import { useMemo } from 'react';
import { doesUserHaveRole } from '../utils/user';

import { useAppContext } from '../store/context';

import { ROLES } from '../utils/constants';

const useRoles = () => {
  const { context: { user, hasRealEstateAgency, roleSpace } } = useAppContext();

  const isUserRealEstateManager = useMemo(() => hasRealEstateAgency, [hasRealEstateAgency]);

  const isUserAdmin = useMemo(() => (user ? doesUserHaveRole(user['cognito:groups'], [
    ROLES.ROLE_ADMIN,
    ROLES.ROLE_SUPER_ADMIN,
  ]) : null), [user]);

  const userOriginallyLessor = useMemo(() => (user ? doesUserHaveRole(user['cognito:groups'], [
    ROLES.ROLE_LESSOR,
  ]) : null), [user]);

  const isUserLessor = useMemo(() => {
    if (roleSpace === 'lessor') return true;
    if (roleSpace === 'tenant') return false;
    return userOriginallyLessor;
  }, [userOriginallyLessor, roleSpace]);

  const isUserTenant = useMemo(() => {
    if (roleSpace === 'tenant') return true;
    if (roleSpace === 'lessor') return false;
    return (user
      ? !userOriginallyLessor && !isUserAdmin
      : null
    );
  }, [user, roleSpace, isUserAdmin, userOriginallyLessor]);

  return {
    isUserRealEstateManager,
    isUserAdmin,
    isUserLessor,
    isUserTenant,
    userOriginallyLessor,
  };
};

export default useRoles;
