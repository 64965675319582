import { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import TagManager from 'react-gtm-module';
import {
  utils,
  Tag,
} from 'ui-library-unlocker';

// Components
import Image from '../../../components/atoms/Image/Image';

// Utils
import { formatPrice, formatAddress } from '../../../utils/properties';
import { parseSanitized } from '../../../utils/html';

// Hooks
import { useAppContext } from '../../../store/context';

// Constants
import { PROPERTY_STATUS } from '../../../utils/constants';

// Styles
import styles from './PropertyCard.module.scss';

function PropertyCard({ item }) {
  const {
    pictures,
    rentExcludingCharges,
    charges,
    address,
    status,
    surface,
    numberOfRooms,
    numberOfBedrooms,
    name,
    description,
    _geoloc,
    objectID,
  } = item ?? {};

  const { t } = useTranslation();
  const { context: { searchMap, searchMapProperty }, dispatch } = useAppContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isTablet = useMediaQuery({ maxWidth: 1024 });

  const price = useMemo(() => {
    const total = (rentExcludingCharges || 0) + (charges || 0);
    return formatPrice(utils.centsToEuro(total));
  }, [rentExcludingCharges, charges]);

  const mainPicture = useMemo(() => {
    const main = pictures?.find((picture) => picture.main);
    return main ?? pictures?.[0];
  }, [pictures]);

  const imgSrc = useMemo(() => {
    try {
      return new URL(mainPicture?.url);
    } catch (e) {
      return null;
    }
  }, [mainPicture]);

  const handleHover = useCallback(() => {
    if (searchMap && _geoloc) {
      const zoom = searchMap.getZoom();
      if (zoom < 11) searchMap.setZoom(11);

      dispatch({
        type: 'SET_SEARCH_MAP_PROPERTY',
        payload: {
          id: objectID,
          type: 'hover',
        },
      });

      const center = searchMap.getCenter();
      if (
        center.lat().toFixed(7) === _geoloc.lat.toFixed(7)
        && center.lng().toFixed(7) === _geoloc.lng.toFixed(7)
      ) return;

      searchMap.panTo({
        lat: _geoloc.lat,
        lng: _geoloc.lng,
      });
    }
  }, [searchMap]);

  return (
    <div
      id={objectID}
      className={utils.cn([
        styles.propertyCard,
        status !== PROPERTY_STATUS.AVAILABLE ? styles.leased : null,
        searchMapProperty?.id === objectID ? styles.selected : null,
      ])}
      onMouseEnter={handleHover}
      onClick={() => {
        TagManager.dataLayer({
          dataLayer: {
            event: 'map_property_click',
            propertyTitle: name,
            propertyAlgoliaId: objectID,
          },
        });
        navigate({
          pathname: `${objectID}`,
          search: searchParams?.toString(),
        });
      }}
      role="button"
      tabIndex={0}
      onKeyDown={null}
    >
      <div className={styles.propertyCard__image}>
        <Image
          className={styles.propertyCard__image__img}
          src={imgSrc}
          alt="interieur"
        />
      </div>
      <div className={styles.propertyCard__content}>
        <div className={styles.propertyCard__content__header}>
          <div className={styles.propertyCard__content__header__price}>
            {status === PROPERTY_STATUS.AVAILABLE && (
              <>
                <span className={styles.price}>{price}</span>
                <small>{t('search.property.chargesIncluded')}</small>
              </>
            )}
          </div>
          <div className={styles.propertyCard__content__header__status}>
            <Tag
              variant={status === PROPERTY_STATUS.AVAILABLE ? 'secondary' : 'secondary-outline'}
              size={isTablet ? 'small' : 'medium'}
              icon={status === PROPERTY_STATUS.AVAILABLE ? 'house-2' : 'house-bulk'}
              label={t(`search.property.status.${status === PROPERTY_STATUS.AVAILABLE
                ? PROPERTY_STATUS.AVAILABLE
                : PROPERTY_STATUS.LEASED
              }`)}
            />
          </div>
        </div>
        <div className={styles.propertyCard__content__body__address}>
          <span className={styles.propertyName}>{name}</span>
          {formatAddress(address)}
        </div>
        <div className={styles.propertyCard__content__body__surface}>
          {surface}
          &nbsp;m²,&nbsp;
          {t('search.property.rooms', { count: numberOfRooms })}
          ,&nbsp;
          {t('search.property.bedrooms', { count: numberOfBedrooms })}
        </div>
        <div className={styles.propertyCard__content__body__description}>
          {parseSanitized(description)}
        </div>
      </div>
    </div>
  );
}

PropertyCard.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.oneOf([
      'unavailable',
      PROPERTY_STATUS.AVAILABLE,
      PROPERTY_STATUS.LEASED,
    ]),
    rentExcludingCharges: PropTypes.number,
    charges: PropTypes.number,
    address: PropTypes.shape({
      street: PropTypes.string,
      streetNumber: PropTypes.string,
      zipCode: PropTypes.string,
      city: PropTypes.string,
    }),
    surface: PropTypes.number,
    numberOfRooms: PropTypes.number,
    numberOfBedrooms: PropTypes.number,
    pictures: PropTypes.arrayOf(PropTypes.shape({})),
    name: PropTypes.string,
    description: PropTypes.string,
    _geoloc: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
    objectID: PropTypes.string,
  }).isRequired,
};

PropertyCard.defaultProps = {};

export default PropertyCard;
