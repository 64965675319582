/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';

import cn from '../../../utils/cn';

import styles from './RadioInput.module.scss';

const RadioInput = ({
  name,
  id,
  checked,
  onChange,
  disabled,
  className,
  label,
  value,
}) => {
  const triggerToggle = () => {
    if (!disabled) onChange(value);
  };

  return (
    <div className={cn([className, styles.wrgToggleWrapper])}>
      <div
        onClick={triggerToggle}
        className={cn([
          styles.wrgToggle,
          checked ? styles.wrgToggle__checked : null,
          disabled ? styles.wrgToggle__disabled : null,
        ])}
      >
        <div className={cn([
          styles.wrgToggleContainer,
          checked ? styles.wrgToggleContainerCheck : null,
        ])}
        />
        {checked && <div className={styles.wrgFilledCircle} />}
        <input
          type="radio"
          id={id}
          name={name}
          value={value}
          checked={checked}
          onChange={triggerToggle}
          className={styles.wrgToggleInput}
          disabled={disabled}
        />
      </div>
      {label && (
        <label
          htmlFor={id}
          className={styles.wrgToggleLabel}
        >
            {label}
        </label>
      )}
    </div>
  );
};

RadioInput.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
};

RadioInput.defaultProps = {
  checked: false,
  disabled: false,
  className: '',
  onChange: null,
  label: null,
};

export default RadioInput;
