/* eslint-disable no-underscore-dangle */
import axios from 'axios';

const Axios = axios.create({
  baseURL: `https://${process.env.REACT_APP_API_URL}`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 30000,
});

export default Axios;
