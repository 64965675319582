/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import MicroModal from 'micromodal';

import styles from './ImageModal.module.scss';

const ESCAPE_KEY = 'Escape';

function ImageModal({
  children, id, onClose, isOpen, className,
}) {
  useEffect(() => {
    try {
      if (isOpen === undefined) return () => {};
      if (isOpen) {
        MicroModal.show(id, {
          openClass: styles.isOpen,
          awaitOpenAnimation: true,
          awaitCloseAnimation: true,
        });
        document.onkeydown = (evt) => {
          evt = evt || window.event;
          if (evt.key === ESCAPE_KEY) {
            onClose(evt);
          }
        };
        return () => {
          document.onkeydown = null;
        };
      }
      MicroModal.close(id);
    } catch (error) {
      //
    }
    return () => {};
  }, [isOpen, id]);

  return (
    <div className={`${styles.micromodalSlide}`} id={id} aria-hidden="true">
      <div className={`${styles.modalOverlay}`} tabIndex="-1" onClick={onClose}>
        <div className={styles.modalClose} onClick={onClose} />
        <div
          onClick={(e) => e.stopPropagation()}
          className={`${className} ${styles.modalContainer}`}
          role="dialog"
          aria-modal="true"
          aria-labelledby={`${id}-title}`}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

ImageModal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  className: PropTypes.string,
};

ImageModal.defaultProps = {
  isOpen: undefined,
  className: '',
  children: null,
};

export default ImageModal;
