/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';

import cn from '../../../utils/cn';

// Component
import Picto from '../Picto/Picto';

import styles from './Thumbnail.module.scss';

// Original conditions if thumbnail is opened in a modal with its original size
// If file is specified, render its name on the right side of the thumbnail
const Thumbnail = ({
  width, height, src, file, fileNameHeader, alt, isDeletable, onClick, onDeleteClick,
}) => (
  <div className={cn([styles.thumbnailWrapper])}>
    <div
      onClick={onClick}
      style={{ width, height }}
      className={cn([styles.thumbnail])}
    >
      {src ? (
        <img
          src={src}
          alt={alt}
          className={cn([styles.thumbnailImage])}
        />
      ) : (
        <Picto
          width={50}
          icon="document"
          color="var(--color-primary)"
        />
      )}
      {isDeletable && (
      <Picto
        className={styles.deleteIcon}
        onClick={(e) => {
          e.stopPropagation();
          onDeleteClick({ src, file, alt });
        }}
        width={18}
        icon="close-circle"
        color="var(--color-secondary)"
      />
      )}
    </div>
    {file && (
    <div className={cn([styles.thumbnailFile])}>
      <p className="p-2-700">{fileNameHeader}</p>
      <p className="p-2-500">{file?.name || 'Unknown name'}</p>
    </div>
    )}
  </div>
);

/**
 * file: file object
*/
Thumbnail.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  src: PropTypes.string.isRequired,
  file: PropTypes.shape({
    name: PropTypes.string,
  }),
  fileNameHeader: PropTypes.string,
  alt: PropTypes.string.isRequired,
  isDeletable: PropTypes.bool,
  onClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
};

Thumbnail.defaultProps = {
  width: 50,
  height: 50,
  file: null,
  fileNameHeader: null,
  isDeletable: false,
  onClick: () => {},
  onDeleteClick: () => {},
};

export default Thumbnail;
