// Components
import {
  utils,
} from 'ui-library-unlocker';
import Gauges from './Gauges/Gauges';
import FeesTracking from './FeesTracking/FeesTracking';
import DoughnutKPI from './DoughnutKPI/DoughnutKPI';
// import MultiProgressKPI from './MultiProgressKPI/MultiProgressKPI';
import SimpleStats from './SimpleStats/SimpleStats';
import DashboardKPILists from './DashboardKPILists/DashboardKPILists';

// Hooks
import useRoles from '../../../hooks/useRoles';

// Styles
import styles from './DashboardKPI.module.scss';

function DashboardKPI() {
  const { isUserRealEstateManager } = useRoles();

  return (
    <div className={styles.content}>
      <aside className={utils.cn([styles.aside, styles.section])}>
        <Gauges />
        {isUserRealEstateManager && (
          <div className="m-t-30">
            <FeesTracking />
          </div>
        )}
        <div className="m-t-20">
          <DoughnutKPI />
        </div>
        {/* <div className="m-t-20">
          <MultiProgressKPI />
        </div> */}
      </aside>
      <div className={styles.rightCol}>
        <SimpleStats className={styles.section} />
        <DashboardKPILists className={styles.section} />
      </div>
    </div>
  );
}

export default DashboardKPI;
