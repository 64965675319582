import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation, useMatches } from 'react-router-dom';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';

// Components
import {
  Picto,
  utils,
  Table,
} from 'ui-library-unlocker';
import Tooltip from '../../../components/atoms/Tooltip/Tooltip';
import TableOptions from '../../../components/atoms/TableOptions/TableOptions';
import DataTable from '../../../components/organisms/DataTable/DataTable';

// Services
import { getLeaseRentReceipts } from '../../../services/lease';

// Utils
import { handleDocumentDownload } from '../../../utils/documents';

// Hooks
import useDocumentTitle from '../../../hooks/useDocumentTitle';

// Styles
import styles from './LeaseRentReceipts.module.scss';

function PaymentsList({
  data,
}) {
  const { payments } = data;
  const { t } = useTranslation();

  const columns = useMemo(() => ([
    {
      header: t('lease.rentReceipts.table.columns.dueDate'),
      accessorKey: 'dueDate',
      size: 150,
      cell: ({ row: { original: { dueDate } } }) => (
        <span>
          {dueDate ? format(new Date(dueDate), 'dd/MM/yyyy', { locale: fr }) : '--'}
        </span>
      ),
    },
    {
      header: t('lease.rentReceipts.table.columns.amount'),
      accessorKey: 'amount',
      size: 100,
      cell: ({ row: { original } }) => <span>{`${utils.centsToEuro(original.amount)} €`}</span>,
    },
    {
      header: t('lease.rentReceipts.table.columns.amountPaid'),
      accessorKey: 'amountPaid',
      size: 100,
      cell: ({ row: { original } }) => <span>{`${utils.centsToEuro(original.amountPaid)} €`}</span>,
    },
    {
      header: t('lease.rentReceipts.table.columns.paymentMethod'),
      accessorKey: 'paymentMethod',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { paymentMethod } } }) => (
        <span>
          {t(`global.paymentMethods.${paymentMethod}`)}
        </span>
      ),
    },
  ]), [t]);

  return (
    <div className={styles.paymentsList}>
      {payments?.length ? (
        <Table
          fullWidth
          columns={columns}
          data={payments}
          disableHover
        />
      ) : (
        <span className={styles.noPayments}>{t('lease.rentReceipts.noPayments')}</span>
      )}
    </div>
  );
}

PaymentsList.propTypes = {
  data: PropTypes.shape({
    payments: PropTypes.arrayOf(PropTypes.shape()),
  }),
};

PaymentsList.defaultProps = {
  data: {
    payments: [],
  },
};

function LeaseRentReceipts() {
  const { t } = useTranslation();
  const location = useLocation();
  useDocumentTitle(t('lease.tabs.rentReceipts.browserTitle'));

  const match = useMatches()?.find((m) => m.pathname === location.pathname);

  const [tooltipOpen, setTooltipOpen] = useState(null);

  const columns = useMemo(() => ([
    {
      header: t('lease.rentReceipts.table.columns.month'),
      accessorKey: 'month',
      size: 1000,
      enableSorting: false,
      cell: ({ row: { original: { year, month } } }) => (
        <div className={styles.tableName}>
          <span>{`${month}/${year}`}</span>
        </div>
      ),
    },
    {
      header: '',
      accessorKey: 'additionalOptions',
      size: 1,
      enableSorting: false,
      cell: ({ row: { original: { uid, documentUid } } }) => {
        const options = [
          {
            id: 'download',
            label: t('global.listOptions.download'),
            icon: <Picto icon="import" width={24} color="var(--color-primary)" />,
            onHoverIcon: <Picto icon="import" width={24} color="var(--color-white)" />,
            onClick: () => {
              handleDocumentDownload({ uid: documentUid }, t);
            },
          },
        ];
        return options?.filter((elem) => elem !== null).length === 0 ? null : (
          <div className={styles.seeMore}>
            <Picto
              id={`more-option-rent-receipts-${uid}`}
              icon="more"
              width={24}
              onClick={(e) => {
                e.stopPropagation();
                setTooltipOpen(tooltipOpen === uid ? null : uid);
              }}
              color="var(--color-secondary)"
            />
            <Tooltip
              isOpen={tooltipOpen === uid}
              anchorId={`more-option-rent-receipts-${uid}`}
              place="bottom"
              type="dark"
              effect="solid"
            >
              <TableOptions
                options={options}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ]), [t, tooltipOpen, setTooltipOpen]);

  return (
    <div
      className={styles.content}
      role="presentation"
      onKeyDown={null}
      onClick={() => setTooltipOpen(null)}
    >
      <DataTable
        columns={columns}
        searchHidden
        getData={(data) => getLeaseRentReceipts(match?.params?.id, data)}
        queryKey="lease-rent-receipts-list"
        openable
        itemsPerPage={12}
      >
        <PaymentsList />
      </DataTable>
    </div>
  );
}

LeaseRentReceipts.propTypes = {
  lease: PropTypes.shape({}),
};

LeaseRentReceipts.defaultProps = {
  lease: null,
};

export default LeaseRentReceipts;
