import {
  useCallback, useMemo, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { Buffer } from 'buffer';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';

// Components
import {
  utils,
} from 'ui-library-unlocker';
import Modal from '../Modal/Modal';
import Stepper from '../../organisms/Stepper/Stepper';

// Utils
import { hideModal } from '../../../utils/modal';

import styles from './EmailModal.module.scss';

function EmailModal({
  idModal,
  onClose,
  html,
  status,
}) {
  const { t } = useTranslation();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = useCallback(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClose = useCallback(() => {
    hideModal(idModal);
    onClose();
  }, [idModal]);

  const decodedHtml = useMemo(() => {
    if (html) {
      return Buffer.from(html, 'base64').toString('utf-8');
    }
    return '';
  }, [html]);

  const docWidth = useMemo(() => Math.min(windowWidth - 100, 600), [windowWidth]);

  const steps = useMemo(() => [
    {
      label: t(`dashboard.kpi.lists.emailsSent.status.${status === 'hard_bounce' ? 'hard_bounce' : 'sent'}`),
      error: status === 'hard_bounce',
    },
    {
      label: t('dashboard.kpi.lists.emailsSent.status.delivered'),
    },
    {
      label: t('dashboard.kpi.lists.emailsSent.status.open'),
    },
    {
      label: t('dashboard.kpi.lists.emailsSent.status.click'),
    },
  ], [t, status]);

  const activeStep = useMemo(() => {
    switch (status) {
      case 'hard_bounce':
        return 0;
      case 'sent':
        return 1;
      case 'delivered':
        return 2;
      case 'open':
        return 3;
      case 'click':
        return 4;
      default:
        return 0;
    }
  }, [status]);

  return (
    <Modal
      className={styles.modal}
      id={idModal}
      onClose={handleClose}
    >
      <div className={styles.modalContent}>
        <Stepper
          className={utils.cn([styles.stepper, 'm-t-15'])}
          steps={steps}
          activeStep={activeStep}
        />
        <iframe
          title="email"
          srcDoc={DOMPurify.sanitize(decodedHtml)}
          width={docWidth}
          height={window.innerHeight - 250}
          sandbox="allow-same-origin allow-popups"
        />
      </div>
    </Modal>
  );
}

EmailModal.propTypes = {
  idModal: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  html: PropTypes.string,
  status: PropTypes.string,
};

EmailModal.defaultProps = {
  onClose: () => {},
  html: '',
  status: '',
};

export default EmailModal;
