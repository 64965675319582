import * as yup from 'yup';
import { validatePhoneNumber } from '../validation';
import propertySchema from './propertySchema';

export const contactInfoInitialValues = {
  firstName: '',
  lastName: '',
  companyName: '',
  email: '',
  countryCode: null,
  phoneNumber: '',
};

export const contactInfoSchema = yup.object().shape({
  firstName: yup.string().matches(/^[^0-9]*$/, 'global.form.errors.invalid').required('global.form.errors.required'),
  lastName: yup.string().matches(/^[^0-9]*$/, 'global.form.errors.invalid').required('global.form.errors.required'),
  companyName: yup.string().nullable(),
  email: yup.string().email('global.form.errors.email').required('global.form.errors.required'),
  countryCode: yup.string().required('global.form.errors.required').nullable(),
  phoneNumber: validatePhoneNumber('phoneNumber', 'countryCode')
    .required('global.form.errors.required'),
});

export const propertyPostAdSchema = yup.object().shape({
  ...propertySchema.fields,
  ownerUid: yup.string().nullable(),
  managerUid: yup.string().nullable(),
});
