import { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { utils, Picto } from 'ui-library-unlocker';

// utils
import { showModal } from '../../../utils/modal';

// components
import MaskedField from '../../../components/atoms/MaskedField/MaskedField';

import styles from './IbanChoice.module.scss';

function IbanChoice({
  identity,
  selectedIdentityUID,
  handleFormChange,
  disabled: disabledProp,
  editModalId,
}) {
  const { t } = useTranslation();

  const disabled = useMemo(() => !identity.iban || disabledProp, [identity]);

  const custom = useMemo(() => identity.custom, [identity]);

  const handleClick = useCallback(() => {
    if (disabled) return;
    handleFormChange('selectedIdentity', identity);
  }, [handleFormChange, identity]);

  const handleEditPictoClick = useCallback((e) => {
    e.stopPropagation();
    showModal(editModalId);
  }, [editModalId]);

  return (
    <div
      className={utils.cn([
        styles.ibanChoice,
        selectedIdentityUID === identity.uid && styles.active,
        disabled && styles.disabled,
      ])}
      onClick={handleClick}
      onKeyDown={handleClick}
      role="button"
      tabIndex={0}
    >
      <span className="p-1-700 p-r-25">{identity.label}</span>
      <div>
        <small>{t('bankingIdentity.crud.form.lastName')}</small>
        <span className="p-1-500">{`${identity.ownerFirstName} ${identity.ownerLastName}`}</span>
      </div>
      <div>
        <small>IBAN</small>
        {!identity.isRedacted ? (
          <MaskedField
            value={identity.iban}
            formatValue={(val) => val.replace(/(.{4})/g, '$1 ')}
            className="p-1-500"
          />
        ) : (
          <span className="p-1-500">
            {identity.iban?.replace(/(.{4})/g, '$1 ')}
          </span>
        )}
      </div>
      {(custom && !disabled) && (
        <div
          className={styles.editPicto}
          onClick={handleEditPictoClick}
          role="button"
          tabIndex={0}
          onKeyDown={handleEditPictoClick}
        >
          <Picto
            icon="edit"
            width={20}
            color="var(--color-primary)"
          />
        </div>
      )}
    </div>
  );
}

IbanChoice.propTypes = {
  identity: PropTypes.shape({
    uid: PropTypes.string,
    ownerFirstName: PropTypes.string,
    ownerLastName: PropTypes.string,
    iban: PropTypes.string,
    label: PropTypes.string,
    isRedacted: PropTypes.bool,
    custom: PropTypes.bool,
  }),
  selectedIdentityUID: PropTypes.string,
  handleFormChange: PropTypes.func,
  disabled: PropTypes.bool,
  editModalId: PropTypes.string,
};

IbanChoice.defaultProps = {
  identity: {},
  selectedIdentityUID: '',
  handleFormChange: () => {},
  disabled: false,
  editModalId: '',
};

export default IbanChoice;
