/* eslint-disable func-names */
import * as yup from 'yup';

export const registerPaymentInitialValues = {
  leaseUID: '', // string
  payerUID: null, // string
  paymentType: 'rent', // string
  paymentMethod: null, // string

  // prefilled when selecting a property
  paymentDate: null, // date
  paidAmount: null, // int
};

const registerPaymentSchema = yup.object().shape({
  leaseUID: yup.string().required('global.form.errors.required'),
  payerUID: yup
    .string()
    .required('global.form.errors.required')
    .nullable(),
  paymentType: yup
    .string()
    .required('global.form.errors.required')
    .nullable(),
  paymentMethod: yup
    .string()
    .required('global.form.errors.required')
    .nullable(),

  // prefilled when selecting a lease
  paymentDate: yup
    .date()
    .nullable(),
  paidAmount: yup
    .number()
    .typeError('global.form.errors.number')
    .moreThan(-1, 'global.form.errors.negativeNumber')
    .required('global.form.errors.required'),
});

export default registerPaymentSchema;
