import api from './_client';

/**
 *
 * @param {number} page page number
 * @param {number} itemsPerPage number of items per page
 * @param {Object} filters number of items per page
 * @returns {import('axios').AxiosResponse<any>}
 */
export const getHousingBenefits = ({ page, itemsPerPage, filters = {} }) => {
  const apiFilters = {
    'filters[type][--in--]': filters?.type?.join?.(','),
    'filters[recurrent][--in--]': filters?.recurrent?.join?.(','),
    'filters[lease][uid][--in--]': filters?.leaseUid?.join?.(','),
    'filters[tenant][uid][--in--]': filters?.tenantUid?.join?.(','),
    [`orderBy[${filters?.orderBy?.property}]`]: filters.orderBy?.direction,
    q: filters.search?.[0],
  };
  return api.get('/payment/rent-distribution', {
    params: {
      page,
      itemsPerPage,
      ...apiFilters,
    },
  });
};

export const getHousingBenefit = (uid) => api.get(`/payment/rent-distribution/${uid}`);

export const createHousingBenefit = (data) => api.post('/payment/rent-distribution', data);

export const updateHousingBenefit = (uid, data) => api.patch(`/payment/rent-distribution/${uid}`, data);

export const deleteHousingBenefit = (uid) => api.delete(`/payment/rent-distribution/${uid}`);

export const importHousingBenefitDocument = (data) => api.post('/payment/housing-benefits/import', data);

export const validateHousingBenefitImport = (data) => api.post('/payment/housing-benefits/validate', data);
