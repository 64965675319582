import React from 'react';
import PropTypes from 'prop-types';

import RadioInput from '../../atoms/RadioInput/RadioInput';

import cn from '../../../utils/cn';

import styles from './RadioGroup.module.scss';

const RadioGroup = ({
  name,
  options,
  display,
  className,
  value,
  itemsPerRow,
  error,
  onChange,
  disabled,
  ...rest
}) => (
  <>
    <div
      className={cn([
        styles.radioGroup,
        display === 'horizontal'
          ? styles.radioGroupHorizontal
          : styles.radioGroupVertical,
        className,
      ])}
      style={{
        gridTemplateColumns: itemsPerRow ? `repeat(${itemsPerRow}, 1fr)` : null,
      }}
    >
      {options.map((option) => (
        <RadioInput
          key={option.value}
          id={`${name}-${option.value}`}
          name={name}
          label={option.label}
          value={option.value}
          onChange={() => onChange(option.value)}
          checked={value === option.value}
          disabled={disabled}
          {...rest}
        />
      ))}
    </div>
    {error && <div className={styles.error}>{error}</div>}
  </>
);

RadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  display: PropTypes.oneOf(['horizontal', 'vertical']),
  className: PropTypes.string,
  value: PropTypes.string,
  itemsPerRow: PropTypes.number,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

RadioGroup.defaultProps = {
  className: '',
  value: null,
  display: 'vertical',
  itemsPerRow: 4,
  error: null,
  disabled: false,
};

export default RadioGroup;
