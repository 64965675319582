/* eslint-disable func-names */
import * as yup from 'yup';
import { validatePhoneNumber } from '../validation';

export const contactAdInitialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  phoneCountryCode: null,
  message: '',
};

export const contactAdSchema = yup.object().shape({
  firstName: yup.string().matches(/^[^0-9]*$/, 'global.form.errors.invalid').required('global.form.errors.required'),
  lastName: yup.string().matches(/^[^0-9]*$/, 'global.form.errors.invalid').required('global.form.errors.required'),
  email: yup.string().email('global.form.errors.email').required('global.form.errors.required'),
  phoneCountryCode: yup.string().required('global.form.errors.required').nullable(),
  phoneNumber: validatePhoneNumber('phoneNumber', 'phoneCountryCode')
    .required('global.form.errors.required'),
  message: yup.string().required('global.form.errors.required'),
});
