/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link/* , useNavigate */ } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import {
  Picto, Tag, Button, utils,
} from 'ui-library-unlocker';

// Components
import Tooltip from '../../components/atoms/Tooltip/Tooltip';
import TableOptions from '../../components/atoms/TableOptions/TableOptions';
import ConfirmationModal from '../../components/organisms/ConfirmationModal/ConfirmationModal';
import DataTable from '../../components/organisms/DataTable/DataTable';
import MaskedField from '../../components/atoms/MaskedField/MaskedField';
import CopyBtn from '../../components/atoms/CopyBtn/CopyBtn';
import Modal from '../../components/molecules/Modal/Modal';
import BankingIdentityForm from '../../components/organisms/forms/BankingIdentityForm/BankingIdentityForm';

// Utils
import { showModal, hideModal } from '../../utils/modal';
import { useAppContext } from '../../store/context';
import { handleDocumentDownload } from '../../utils/documents';
import { formatAddress } from '../../utils/properties';

// Services
import {
  getBankingIdentities,
  getTenantBankingIdentities,
  deleteBankingIdentity,
} from '../../services/bankingIdentity';

import styles from './BankingIdentityList.module.scss';

const DELETE_CONFIRM_MODAL_ID = 'identity-delete-confirm';
const ADD_BANKING_IDENTITY_MODAL_ID = 'add-banking-identity-modal';

function BankingIdentityView({
  crudModal,
  delegatedTenantUID,
}) {
  const { t } = useTranslation();
  const { dispatch } = useAppContext();
  // const navigate = useNavigate();
  const addFormRef = useRef();

  const [tooltipOpen, setTooltipOpen] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    document.title = t('bankingIdentity.browserTitle');
  }, [t]);

  const deleteBankingIdentityMutation = useMutation({
    mutationFn: () => deleteBankingIdentity(selectedItem?.uid),
    onSuccess: () => {
      utils.toast.success(t('bankingIdentity.crud.deleteSuccess'));
      dispatch({ type: 'SET_NEED_DATA_TABLE_REFETCH', payload: 'bankingIdentity-list' });
    },
    onError: (err) => {
      switch (err?.response?.status) {
        case 409: {
          if (err?.response?.data?.message === 'PAYMENT_IDENTITY_IDENTITY_IN_USE_EXCEPTION') {
            utils.toast.error(t(`bankingIdentity.crud.formErrors.${err.response.data.message}`));
          } else {
            utils.toast.error(t('global.form.errors.generic'));
          }
          break;
        }
        default:
          utils.toast.error(t('global.form.errors.global'));
          break;
      }
    },
    onSettled: () => {
      resetSelected(DELETE_CONFIRM_MODAL_ID);
    },
  });

  const columns = useMemo(() => ([
    {
      header: t('bankingIdentity.table.columns.label'),
      accessorKey: 'label',
      size: 200,
    },
    {
      header: t('bankingIdentity.table.columns.iban'),
      accessorKey: 'iban',
      size: 200,
      cell: ({ row: { original: { iban } } }) => (
        <div className={styles.tableName}>
          <MaskedField
            value={iban}
            className={styles.maskedField}
            formatValue={(val) => val.replace(/(.{4})/g, '$1 ')}
          />
          <CopyBtn
            textToCopy={iban}
            htmlTitle={t('global.copy')}
            tooltipText={t('global.copied')}
            size={18}
          />
        </div>
      ),
    },
    {
      header: t('bankingIdentity.table.columns.isSigned'),
      accessorKey: 'isSigned',
      size: 200,
      cell: ({ row: { original: { isSigned } } }) => (
        <div>
          <Tag
            label={t(`bankingIdentity.isSigned.${isSigned}`)}
            size="medium"
            variant={isSigned ? 'secondary' : 'secondary-outline'}
          />
        </div>
      ),
    },
    {
      header: t('bankingIdentity.table.columns.address'),
      accessorKey: 'isSigned',
      size: 200,
      cell: ({
        row: {
          original: {
            streetNumber, street, zipCode, city,
          },
        },
      }) => (
        <div>
          {formatAddress({
            streetNumber, street, zipCode, city,
          })}
        </div>
      ),
    },
    {
      header: t('bankingIdentity.table.columns.countryCode'),
      accessorKey: 'countryCode',
      size: 100,
    },
    {
      header: t('bankingIdentity.table.columns.inUse'),
      accessorKey: 'inUse',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { inUse } } }) => (
        <div>
          <Tag
            label={t(`bankingIdentity.inUse.${inUse}`)}
            size="medium"
            variant={inUse ? 'secondary' : 'secondary-outline'}
          />
        </div>
      ),
    },
    {
      header: '',
      accessorKey: 'additionalOptions',
      size: 50,
      enableSorting: false,
      cell: ({ row }) => {
        const options = [
          // {
          //   id: 'see',
          //   label: t('global.listOptions.see'),
          //   icon: <Picto icon="eye" width={24} color="var(--color-primary)" />,
          //   onHoverIcon: <Picto icon="eye" width={24} color="var(--color-white)" />,
          //   onClick: (e) => {
          //     e.stopPropagation();
          //     setTooltipOpen(null);
          //     navigate(`/banking-identities/${row?.original?.uid}`);
          //   },
          // },
          !row?.original?.signedMandateUID ? null : {
            id: 'download',
            label: t('bankingIdentity.table.listOptions.downloadMandate'),
            icon: <Picto icon="import" width={24} color="var(--color-primary)" />,
            onHoverIcon: <Picto icon="import" width={24} color="var(--color-white)" />,
            onClick: () => {
              handleDocumentDownload({ uid: row?.original?.signedMandateUID }, t);
            },
          },
          row?.original?.inUse ? null : {
            id: 'delete',
            label: t('global.listOptions.delete'),
            icon: <Picto icon="trash" width={24} color="var(--color-primary)" />,
            onHoverIcon: <Picto icon="trash" width={24} color="var(--color-white)" />,
            onClick: () => {
              setSelectedItem(row?.original);
              showModal(DELETE_CONFIRM_MODAL_ID);
            },
          },
        ];
        return options?.filter((elem) => elem != null).length === 0 ? null : (
          <div className={styles.seeMore}>
            <Picto
              id={`more-option-bankingIdentity-${row?.original?.uid}`}
              icon="more"
              width={24}
              onClick={(e) => {
                e.stopPropagation();
                setTooltipOpen(tooltipOpen === row?.original?.uid ? null : row?.original?.uid);
              }}
              color="var(--color-secondary)"
            />
            <Tooltip
              isOpen={tooltipOpen === row?.original?.uid}
              anchorId={`more-option-bankingIdentity-${row?.original?.uid}`}
              place="bottom"
              type="dark"
              effect="solid"
            >
              <TableOptions
                options={options}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ]), [t, tooltipOpen, handleDocumentDownload]);

  const allFiltersOptions = useMemo(() => [
    {
      name: 'inUse',
      type: 'checkbox',
      title: t('bankingIdentity.table.columns.inUse'),
      options: [
        {
          value: 'true',
          label: t('bankingIdentity.inUse.true'),
        },
        {
          value: 'false',
          label: t('bankingIdentity.inUse.false'),
        },
      ],
    },
  ], []);

  const resetSelected = useCallback((modalId) => {
    setSelectedItem(null);
    hideModal(modalId);
  }, []);

  const headerBtn = useMemo(() => {
    if (crudModal) {
      return (
        <Button
          variant="primary"
          size="large"
          icon="plus"
          onClick={() => showModal(ADD_BANKING_IDENTITY_MODAL_ID)}
        >
          {t('bankingIdentity.add')}
        </Button>
      );
    }
    return (
      <Link to="/banking-identities/add">
        <Button
          variant="primary"
          size="large"
          icon="plus"
        >
          {t('bankingIdentity.add')}
        </Button>
      </Link>
    );
  }, [t, crudModal]);

  const handleModalClose = useCallback(() => {
    hideModal(ADD_BANKING_IDENTITY_MODAL_ID);
    setTimeout(() => {
      addFormRef.current.resetForm();
    });
  }, []);

  const handleModalCancel = useCallback((resetForm) => {
    hideModal(ADD_BANKING_IDENTITY_MODAL_ID);
    setTimeout(() => resetForm());
  }, []);

  const onAddSuccess = useCallback(() => {
    dispatch({ type: 'SET_NEED_DATA_TABLE_REFETCH', payload: 'bankingIdentity-list' });
  }, []);

  return (
    <div
      role="presentation"
      onClick={() => setTooltipOpen(null)}
    >
      {delegatedTenantUID ? (
        <div className="m-t-50">
          <h2>{t('bankingIdentity.title')}</h2>
        </div>
      ) : (
        <div className={styles.heading}>
          <h1>{t('bankingIdentity.title')}</h1>
        </div>
      )}
      <DataTable
        columns={columns}
        getData={delegatedTenantUID ? getTenantBankingIdentities : getBankingIdentities}
        params={{ tenantUID: delegatedTenantUID }}
        queryKey="bankingIdentity-list"
        allFiltersOptions={allFiltersOptions}
        headerBtn={headerBtn}
        searchHidden
      />
      <ConfirmationModal
        id={DELETE_CONFIRM_MODAL_ID}
        onSubmit={deleteBankingIdentityMutation.mutate}
        onCancel={() => resetSelected(DELETE_CONFIRM_MODAL_ID)}
      />
      {crudModal && (
        <Modal
          className={styles.modal}
          id={ADD_BANKING_IDENTITY_MODAL_ID}
          title={t('bankingIdentity.crud.addTitle')}
          onClose={handleModalClose}
        >
          <div className={styles.modalContent}>
            <BankingIdentityForm
              ref={addFormRef}
              isEditing={false}
              onCancel={handleModalCancel}
              onAddSuccess={onAddSuccess}
              delegatedTenantUID={delegatedTenantUID}
              noLoading
            />
          </div>
        </Modal>
      )}
    </div>
  );
}

BankingIdentityView.propTypes = {
  crudModal: PropTypes.bool,
  delegatedTenantUID: PropTypes.string,
};

BankingIdentityView.defaultProps = {
  crudModal: false,
  delegatedTenantUID: null,
};

export default BankingIdentityView;
