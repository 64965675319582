/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, {
  useCallback, useState, useMemo, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Picto, utils } from 'ui-library-unlocker';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import TagManager from 'react-gtm-module';

// Hooks
import { useAppContext } from '../../store/context';
import useRoles from '../../hooks/useRoles';

// Services
import { getConversations, getMessagesFromConversation } from '../../services/messages';

// Components
import NewMessageModal from './NewMessageModal';
import MessagesInbox from './MessagesInbox/MessagesInbox';

// Utils
import { showModal } from '../../utils/modal';

import styles from './Messages.module.scss';

function Messages() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { context: { emptyConversations } } = useAppContext();
  const { isUserAdmin, isUserRealEstateManager } = useRoles();

  const [page, setPage] = useState(1);

  const NEW_MESSAGE_WELCOME = 'newMessageWelcome';

  useEffect(() => {
    document.title = t('messages.browserTitle');
  }, [t]);

  const userType = useMemo(() => {
    if (isUserAdmin) return 'admin';
    if (isUserRealEstateManager) return 'manager';
    return 'owner';
  }, [isUserAdmin, isUserRealEstateManager]);

  // Used to check if the user has already created a conversation
  const {
    data: conversationsData,
    isFetching: conversationsFetching,
    refetch: refetchConversations,
  } = useQuery({
    queryKey: ['conversation-list', page],
    queryFn: () => getConversations({
      page,
      itemsPerPage: 100,
    }),
    keepPreviousData: true,
  });

  const handleConversationCreated = (conversationId) => {
    refetchConversations();
    navigate(`/messages/${conversationId}`);
  };

  const renderInboxWelcome = useCallback(() => (
    <>
      <div className={styles.welcome}>
        <h2 className={utils.cn(['m-t-40 m-b-4', styles.welcomeTitle])}>
          {t('messages.welcome')}
        </h2>
        <Picto width={166} icon="trace-5208" color="var(--color-tertiary)" />
        <p className="m-t-40 p-1-300">{t('messages.welcomeDescription1')}</p>
        <p className="m-t-30 p-1-300">{t('messages.welcomeDescription2')}</p>
        <Button
          icon="plus"
          iconSide="left"
          onClick={() => showModal(NEW_MESSAGE_WELCOME)}
          className="m-t-40"
          variant="primary"
          size="large"
        >
          {t('messages.newMessage')}
        </Button>
      </div>
      <img className={styles.mask} src="/images/appartment-1.png" alt="cover" />
    </>
  ), []);

  const renderInbox = useCallback(() => (
    <MessagesInbox conversations={conversationsData} conversationsFetching={conversationsFetching} />
  ), [conversationsData, conversationsFetching]);

  const renderContent = useCallback(() => {
    if (emptyConversations && !conversationsData?.data?.collection.length) return renderInboxWelcome();
    return renderInbox();
  }, [conversationsFetching, conversationsData, emptyConversations]);

  return (
    <>
      <div className={styles.heading}>
        <h1>{t('messages.title')}</h1>
        {emptyConversations && !conversationsData?.data?.collection.length ? null : (
          <Button
            icon="plus"
            iconSide="left"
            onClick={() => {
              TagManager.dataLayer({
                dataLayer: {
                  event: 'message_write',
                  userType,
                },
              });
              showModal(NEW_MESSAGE_WELCOME);
            }}
            variant="primary"
            size="large"
          >
            {t('messages.newMessage')}
          </Button>
        )}
      </div>
      <div className={utils.cn(['m-t-15', styles.content])}>
        {renderContent()}
      </div>
      <NewMessageModal modalId={NEW_MESSAGE_WELCOME} onConversationCreated={handleConversationCreated} />
    </>
  );
}

export default Messages;
