/* eslint-disable max-len */
import api from './_client';

/**
 *
 * @param {number} page page number
 * @param {number} itemsPerPage number of items per page
 * @param {Object} filters number of items per page
 * @returns {import('axios').AxiosResponse<any>}
 */
export const getNotifications = ({ page, itemsPerPage, filters = {} }) => {
  const apiFilters = {
    'filters[createdAt][--min--]': filters?.createdAtMin,
    'orderBy[createdAt]': 'DESC',
  };
  return api.get('/notification/in-app', {
    params: {
      page,
      itemsPerPage,
      ...apiFilters,
    },
  });
};

export const toggleHitNotification = (id) => api.put(`/notification/in-app/${id}/toggle`, {});

export const hitUntilNotification = (timestamp) => api.post('/notification/in-app/hit-until', {
  timestamp,
});

export const getLastUnreadNotification = () => {
  const apiFilters = {
    'filters[hit][--eq--]': false,
    'orderBy[createdAt]': 'DESC',
  };
  return api.get('/notification/in-app', {
    params: {
      page: 1,
      itemsPerPage: 1,
      ...apiFilters,
    },
  });
};
