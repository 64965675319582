import api from './_client';

/**
 *
 * @param {number} page page number
 * @param {number} itemsPerPage number of items per page
 * @param {Object} filters number of items per page
 * @returns {import('axios').AxiosResponse<any>}
 */
export const getBankingIdentities = ({ page, itemsPerPage, filters = {} }) => {
  const apiFilters = {
    'filters[inUse][--eq--]': filters?.inUse?.join?.(','),
    [`orderBy[${filters?.orderBy?.property}]`]: filters.orderBy?.direction,
    q: filters.search?.[0],
  };
  return api.get('/payment/banking-identities', {
    params: {
      page,
      itemsPerPage,
      ...apiFilters,
    },
  });
};

export const getBankingIdentity = (uid) => api.get(`/payment/banking-identities/${uid}`);

export const createBankingIdentity = (data) => api.post('/payment/banking-identities/add', data);

export const updateBankingIdentity = (uid, data) => api.patch(`/payment/banking-identities/${uid}`, data);

export const deleteBankingIdentity = (uid) => api.delete(`/payment/banking-identities/${uid}/delete`);

export const getAvailableBankingIdentities = (leaseUID) => api.get(
  `/payment/leases/${leaseUID}/payment/banking-identities/available`,
);

export const getTenantBankingIdentities = ({
  page, itemsPerPage, filters = {}, tenantUID,
}) => {
  const apiFilters = {
    'filters[inUse][--eq--]': filters?.inUse?.join?.(','),
    [`orderBy[${filters?.orderBy?.property}]`]: filters.orderBy?.direction,
    q: filters.search?.[0],
  };
  return api.get(`/payment/tenant/${tenantUID}/banking-identities`, {
    params: {
      page,
      itemsPerPage,
      ...apiFilters,
    },
  });
};

export const createTenantBankingIdentity = (tenantUID, data) => api.post(
  `/payment/tenant/${tenantUID}/banking-identities/add`,
  data,
);

export const updateTenantBankingIdentity = (tenantUID, uid, data) => api.patch(
  `/payment/tenant/${tenantUID}/banking-identities/${uid}`,
  data,
);

export const deleteTenantBankingIdentity = (tenantUID, uid) => api.delete(
  `/payment/tenant/${tenantUID}/banking-identities/${uid}/delete`,
);

export const getTenantAvailableBankingIdentities = (tenantUID, leaseUID) => api.get(
  `/payment/tenant/${tenantUID}/leases/${leaseUID}/payment/banking-identities/available`,
);
