import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { Picto, utils } from 'ui-library-unlocker';
import WithLogin from '../../components/templates/WithLogin/WithLogin';
import LoginForm from '../../components/organisms/forms/LoginForm/LoginForm';
import DiscoverLink from '../../components/atoms/DiscoverLink/DiscoverLink';

import { confirmSignUp } from '../../services/identity';

import { useAppContext } from '../../store/context';

import styles from './Login.module.scss';

function LoginView() {
  const { t } = useTranslation();
  const [search] = useSearchParams();
  const navigate = useNavigate();
  const codeSent = useRef(false);

  const { context: { accessToken } } = useAppContext();

  // Handle signup code verification
  useEffect(() => {
    const code = search.get('code');
    const username = search.get('username');

    const validateCode = async () => {
      codeSent.current = true;
      try {
        await confirmSignUp({
          code,
          username,
        });
        utils.toast.success(t('login.confirm.success'));
      } catch (error) {
        const { status, data } = error.response;

        switch (status) {
          case 400: {
            switch (data?.message) {
              case ('ERR_IDENTITY_ACCESS_FORM_ERROR_003'): {
                utils.toast.error(t('login.confirm.errors.ERR_IDENTITY_ACCESS_FORM_ERROR_003'));
                break;
              }
              case ('IDENTITY_ACCESS_CONFIRM_SIGNUP_EXPIRED_CODE'): {
                utils.toast.error(t('login.confirm.errors.IDENTITY_ACCESS_CONFIRM_SIGNUP_EXPIRED_CODE'));
                break;
              }
              default:
                break;
            }
            navigate('/register#resend-code');
            break;
          }
          case 401:
            utils.toast.error(t('login.confirm.errors.ERR_IDENTITY_CODE_MISMATCH'));
            break;
          default:
            break;
        }
      }
    };

    if (code && username && !codeSent.current) validateCode();
  }, []);

  useEffect(() => {
    document.title = t('login.browserTitle');
  }, [t]);

  const renderContentHeading = useCallback(() => (
    <div>
      <h1 className={styles.title}>
        {t('login.title')}
      </h1>
      <Picto
        width={154}
        color="var(--color-tertiary)"
        icon="trait-login-title"
      />
      <p className={styles.subTitle}>
        {t('login.subTitle')}
      </p>
    </div>
  ), []);

  const renderContentLeft = useCallback(() => (
    <div className={styles.contentLeft}>
      <div className={styles.imageBox}>
        <img className={styles.image} src="/images/jess.jpg" alt="cover" />
        <Picto
          className={styles.gribouillon}
          width={93}
          fill="var(--color-tertiary)"
          color="transparent"
          icon="gribouillon-6"
        />
        <Picto
          className={styles.dot}
          width={46}
          color="var(--color-secondary)"
          icon="point-moyen-900"
        />
      </div>
    </div>
  ), []);

  if (accessToken) {
    navigate('/');
    return null;
  }

  return (
    <WithLogin
      contentLeft={renderContentLeft()}
      contentHeading={renderContentHeading()}
    >
      <LoginForm />
      <div className="m-t-30 m-b-30 text-center">
        <DiscoverLink />
      </div>
    </WithLogin>
  );
}

export default LoginView;
