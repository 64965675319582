/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable consistent-return */
import React, {
  useEffect, useState, useMemo, forwardRef, useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';

import Picto from '../Picto/Picto';

import cn from '../../../utils/cn';

import useEllipsisVisible from '../../../hooks/useEllipsisVisible';

import styles from './TextInput.module.scss';

const TextInput = forwardRef(({
  id,
  name,
  type,
  label,
  value,
  full,
  disabled,
  className,
  inputClassName,
  error,
  valid,
  info,
  icon,
  iconColor,
  iconSide,
  iconSize,
  loading,
  isRequired,
  noInteractions,
  defaultValue,
  ...props
}, ref) => {
  const [viewPassword, setViewPassword] = useState(false);
  const { ref: inputRef, isEllipsisVisible } = useEllipsisVisible();

  useImperativeHandle(ref, () => inputRef.current);

  useEffect(() => {
    const event = inputRef?.current?.addEventListener('wheel', (e) => {
      inputRef.current.blur();
      e.preventDefault();
    }, { passive: false });
    return () => {
      inputRef?.current?.removeEventListener('wheel', event);
    };
  }, [inputRef?.current]);

  const HAS_ICON = error || valid || icon;

  const iconSideClassName = useMemo(() => {
    if (iconSide === 'left') {
      return styles.hasIconLeft;
    }
    return styles.hasIconRight;
  }, [iconSide]);

  const inputValue = useMemo(() => {
    if (defaultValue) return;
    if (value === 0) return '0';
    if (!value) return '';
    return value;
  }, [value, defaultValue]);

  return (
    <div className={cn([className])}>
      <div
        className={cn([
          styles.inputWrapper,
          full ? styles.full : null,
          disabled ? styles.disabled : null,
          loading ? styles.loading : null,
          noInteractions ? styles.noInteractions : null,
        ])}
        style={{
          '--iconSize': `${iconSize}px`,
        }}
      >
        <input
          id={id}
          name={name}
          ref={inputRef}
          type={viewPassword ? 'text' : type}
          placeholder={label}
          title={isEllipsisVisible ? value : null}
          value={inputValue}
          defaultValue={defaultValue || undefined}
          disabled={disabled}
          aria-invalid={error != null}
          className={cn([
            inputValue ? styles.hasValue : null,
            error ? styles.error : null,
            valid ? styles.valid : null,
            HAS_ICON ? styles.hasIcon : null,
            HAS_ICON ? iconSideClassName : null,
            iconSide === 'left' ? styles.hasIconLeft : null,
            inputClassName,
          ])}
          {...props}
        />
        {label && type !== 'hidden' ? (
          <label
            htmlFor={id}
            className={iconSide === 'left' ? styles.labelLeftIcon : null}
          >
            {label}
            {isRequired ? <span>*</span> : ''}
          </label>
        ) : null}

        {loading
          ? (
            <Picto
              className={styles.rightIcon}
              icon="loading"
              width={24}
              height={24}
              color="var(--color-secondary)"
            />
          )
          : (
            <>
              {type === 'password' ? (
                <button
                  type="button"
                  className={`${styles.viewPass} ${viewPassword ? styles.active : ''}`}
                  onClick={() => setViewPassword(!viewPassword)}
                >
                  <Picto
                    color="var(--color-secondary)"
                    icon={viewPassword ? 'eye-slash' : 'eye'}
                    width={24}
                    height={24}
                  />
                </button>
              ) : (
                <>
                  {error && <Picto color="var(--color-accent-red-500)" className={styles.rightIcon} icon="close-circle" width={24} height={24} />}
                  {valid && <Picto color="var(--color-secondary)" className={styles.rightIcon} icon="tick-circle" width={24} height={24} />}
                  {!error && !valid && icon && (
                    <Picto
                      color={iconColor || 'var(--color-secondary)'}
                      className={iconSide === 'left' ? styles.leftIcon : styles.rightIcon}
                      icon={icon}
                      width={iconSize}
                      height={iconSize}
                    />
                  )}
                </>
              )}
            </>
          )}
      </div>
      {error && typeof error === 'string' ? (
        <div className={cn([styles.context, styles.fieldError])}>
          {error}
        </div>
      ) : info && (
      <div className={cn([styles.context, styles.fieldInfo])}>
        {info}
      </div>
      )}
    </div>
  );
});

TextInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.oneOf(['text', 'email', 'password', 'number', 'hidden']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  full: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  valid: PropTypes.bool,
  info: PropTypes.string,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  iconSide: PropTypes.oneOf(['left', 'right']),
  iconSize: PropTypes.number,
  loading: PropTypes.bool,
  isRequired: PropTypes.bool,
  noInteractions: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

TextInput.defaultProps = {
  id: null,
  name: null,
  type: 'text',
  value: '',
  label: null,
  full: false,
  disabled: false,
  className: null,
  inputClassName: null,
  error: null,
  valid: null,
  info: null,
  icon: null,
  iconColor: null,
  iconSide: 'right',
  iconSize: 24,
  loading: false,
  isRequired: false,
  noInteractions: false,
  defaultValue: null,
};

export default TextInput;
