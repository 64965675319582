/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

// Components
import { useFormik } from 'formik';
import {
  Button,
  TextInput,
  utils,
} from 'ui-library-unlocker';
import Modal from '../../molecules/Modal/Modal';

// Utils
import { hideModal } from '../../../utils/modal';
import { editPasswordInitialValues, editPasswordSchema } from '../../../utils/forms/editPasswordSchema';
import { displayError, isFieldValid, errorFocusSubmit } from '../../../utils/forms/form';

// Services
import { editPasswordFromAdmin } from '../../../services/admin';

import styles from './EditPasswordModal.module.scss';

function EditPasswordModal({
  idModal,
  onClose,
  userUid,
}) {
  const { t } = useTranslation();

  const editPasswordMutation = useMutation({
    mutationFn: (data) => editPasswordFromAdmin({ ...data, username: userUid }),
    onSuccess: () => {
      handleClose();
      utils.toast.success(t('profile.personalInfo.form.editPassword.success'));
    },
    onError: () => {
      utils.toast.error(t('global.form.errors.global'));
    },
  });

  const formik = useFormik({
    initialValues: editPasswordInitialValues,
    validationSchema: editPasswordSchema,
    onSubmit: (values) => {
      editPasswordMutation.mutate(values);
    },
  });

  const handleClose = useCallback(() => {
    hideModal(idModal);
    setTimeout(() => formik.resetForm(), 1000);
    onClose();
  }, [formik, idModal]);

  return (
    <Modal
      className={styles.modal}
      id={idModal}
      title={t('profile.personalInfo.form.editPassword.title')}
      onClose={handleClose}
    >
      <div className={styles.modalContent}>
        <form className={styles.form} onSubmit={errorFocusSubmit(formik.handleSubmit)}>
          <TextInput
            type="password"
            id="password"
            name="password"
            className={styles.mediumInput}
            label={t('profile.personalInfo.form.editPassword.newPassword')}
            info={t('global.form.info.password')}
            error={displayError(t, formik, 'password')}
            valid={isFieldValid(formik, 'password')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
          />
          <TextInput
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            className={utils.cn([styles.mediumInput, 'm-t-25'])}
            label={t('profile.personalInfo.form.editPassword.confirmPassword')}
            error={displayError(t, formik, 'confirmPassword')}
            valid={isFieldValid(formik, 'confirmPassword')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirmPassword}
          />
          <div className={utils.cn(['m-t-40', styles.modalActions])}>
            <Button
              variant="secondary"
              size="large"
              className={styles.submitBtn}
              onClick={handleClose}
            >
              {t('global.cancel')}
            </Button>
            <Button
              type="submit"
              variant="primary"
              size="large"
              className={styles.submitBtn}
              loading={formik.isSubmitting}
            >
              {t('global.confirm')}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

EditPasswordModal.propTypes = {
  idModal: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  userUid: PropTypes.string,
};

EditPasswordModal.defaultProps = {
  onClose: () => {},
  userUid: '',
};

export default EditPasswordModal;
