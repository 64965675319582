import React from 'react';
import PropTypes from 'prop-types';

import Picto from '../Picto/Picto';

// Utils
import cn from '../../../utils/cn';

import styles from './Message.module.scss';

function Message({
  variant, content, icon, className,
}) {
  const iconProps = {
    error: { icon: 'alert-triangle', color: 'var(--color-primary)' },
    alert: { icon: 'alert-circle', color: 'var(--color-primary)' },
    success: { icon: 'tick-circle', color: 'var(--color-secondary)' },
    info: { icon: 'info-circle', color: 'var(--color-secondary)' },
  };

  return (
    <div className={cn([
      styles.message,
      styles[variant],
      className,
    ])}
    >
      <div className={styles.wrapper}>
        {icon && (
          <Picto
            width={24}
            height={24}
            className={styles.icon}
            color={iconProps[variant]?.color}
            icon={typeof icon === 'string' ? icon : iconProps[variant]?.icon}
          />
        )}
        <div className={styles.content}>{content}</div>
      </div>
    </div>
  );
}

Message.propTypes = {
  variant: PropTypes.oneOf(['error', 'alert', 'success', 'info']),
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  className: PropTypes.string,
};

Message.defaultProps = {
  variant: 'info',
  content: '',
  icon: true,
  className: '',
};

export default Message;
