/* eslint-disable react/jsx-no-useless-fragment */
import React, { useMemo } from 'react';
// import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { Link, useLocation } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';
import {
  utils,
  TextInput,
  Button,
  Message,
  SelectInput,
  PhoneInput,
} from 'ui-library-unlocker';

import { registerUserBeta, resendConfirm } from '../../../../services/identity';
import { betaRegisterInitialValues, betaRegisterSchema } from '../../../../utils/forms/registerSchema';
import resendCodeSchema, { resendCodeInitialValues } from '../../../../utils/forms/resendCodeSchema';

import { displayError, isFieldValid } from '../../../../utils/forms/form';

import styles from './RegisterForm.module.scss';

function BetaRegisterForm() {
  const { t } = useTranslation();
  const { hash } = useLocation();

  const leadTypeOptions = useMemo(() => [{
    value: 'owner',
    label: t('register.betaForm.leadTypes.owner'),
  }, {
    value: 'real_estate',
    label: t('register.betaForm.leadTypes.manager'),
  },
  ], [t]);

  const handleRegisterBeta = (data) => {
    const formData = new FormData();
    formData.append('formType', 'betaRegistration');

    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });

    return registerUserBeta(formData);
  };

  const mutation = useMutation({
    mutationFn: handleRegisterBeta,
    onError: (error) => {
      if (error?.response) {
        switch (error?.response?.status) {
          case 400: {
            utils.toast.error(t('global.form.errors.generic'));
            break;
          }
          case 406: {
            switch (error?.response?.data?.message) {
              case 'ERR_IDENTITY_PASSWORD_FORMAT': {
                formik.setFieldError('password', t(`register.form.errorResponses.${error?.response.data.message}`));
                break;
              }
              default:
                break;
            }
            break;
          }
          case 409: {
            switch (error?.response?.data?.message) {
              case 'ERR_IDENTITY_USERNAME_CONFLICT': {
                formik.setFieldError('email', t(`register.form.errorResponses.${error?.response.data.message}`));
                utils.toast.error(t(`register.form.errorResponses.${error?.response.data.message}`));
                break;
              }
              default:
                break;
            }
            break;
          }
          default:
            utils.toast.error(t('global.form.errors.generic'));
            break;
        }
      }
    },
  });

  const resendCodeMutation = useMutation({
    mutationFn: resendConfirm,
    onSuccess: (response) => {
      switch (response?.status) {
        case 204:
          utils.toast.success(t('register.resendCode.success'));
          break;
        case 400: {
          utils.toast.error(t('global.form.errors.generic'));
          break;
        }
        default:
          break;
      }
    },
  });

  const formik = useFormik({
    initialValues: betaRegisterInitialValues,
    validationSchema: betaRegisterSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: (values) => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'signup_beta',
        },
      });
      mutation.mutate(values);
    },
  });

  const resendCodeFormik = useFormik({
    initialValues: resendCodeInitialValues,
    validationSchema: resendCodeSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: (values) => resendCodeMutation.mutate({ email: values.username }),
  });
  const formType = hash === '#resend-code' ? 'resend-code' : 'register';

  return (
    <>
      {mutation?.isSuccess ? (
        <Message content={t('register.betaForm.success')} className="m-b-25" />
      ) : (
        <>
          {formType === 'register' && (
          <>
            <form onSubmit={formik.handleSubmit} className={styles.betaForm}>
              <TextInput
                type="text"
                id="firstName"
                name="firstName"
                autoComplete="firstName"
                label={t('register.betaForm.firstName')}
                error={displayError(t, formik, 'firstName')}
                valid={isFieldValid(formik, 'firstName')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
              />
              <TextInput
                type="text"
                id="lastName"
                name="lastName"
                className="m-t-25"
                autoComplete="lastName"
                label={t('register.betaForm.lastName')}
                error={displayError(t, formik, 'lastName')}
                valid={isFieldValid(formik, 'lastName')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
              />
              <SelectInput
                id="leadType"
                name="leadType"
                className="m-t-25"
                label={t('register.betaForm.youAre')}
                options={leadTypeOptions}
                error={displayError(t, formik, 'leadType')}
                valid={isFieldValid(formik, 'leadType', null, betaRegisterInitialValues?.leadType)}
                onChange={(value) => formik.setFieldValue('leadType', value.value)}
                onBlur={formik.handleBlur}
                value={leadTypeOptions.find((leadType) => leadType.value === formik.values.leadType)}
              />
              <TextInput
                type="text"
                id="email"
                name="email"
                className="m-t-25"
                autoComplete="email"
                label={t('register.betaForm.email')}
                error={displayError(t, formik, 'email')}
                valid={isFieldValid(formik, 'email')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              <TextInput
                type="number"
                id="numberOfProperty"
                name="numberOfProperty"
                className="m-t-25"
                autoComplete="numberOfProperty"
                min={0}
                label={t('register.betaForm.numberOfProperty')}
                error={displayError(t, formik, 'numberOfProperty')}
                valid={isFieldValid(formik, 'numberOfProperty')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.numberOfProperty}
              />
              <PhoneInput
                id="phoneNumber"
                name="phoneNumber"
                label={t('register.betaForm.phoneNumber')}
                className="m-t-25"
                error={displayError(t, formik, 'phoneNumber')}
                valid={isFieldValid(formik, 'phoneNumber', null, betaRegisterInitialValues?.phoneNumber)}
                value={{
                  countryCode: formik?.values?.phoneCountryCode,
                  phone: formik?.values?.phoneNumber,
                }}
                onBlur={formik.handleBlur}
                onChange={(value) => {
                  const { countryCode, phone } = value;
                  formik.setFieldValue('phoneCountryCode', countryCode);
                  formik.setFieldValue('phoneNumber', phone);
                }}
              />
              <Button
                type="submit"
                className="m-t-30 m-b-20 center-block"
                size="large"
                label={t('register.form.submit')}
                loading={mutation?.isLoading}
              />
            </form>
          </>
          )}
          {formType === 'resend-code' && (
          <>
            <p className={utils.cn([styles.youAre, 'm-b-20'])}>
              {t('register.resendCode.description')}
            </p>
            <form onSubmit={resendCodeFormik.handleSubmit}>
              <TextInput
                type="text"
                id="username"
                name="username"
                label={t('register.form.username')}
                error={displayError(t, resendCodeFormik, 'username')}
                valid={isFieldValid(resendCodeFormik, 'username')}
                onChange={resendCodeFormik.handleChange}
                onBlur={resendCodeFormik.handleBlur}
                value={resendCodeFormik.values.username}
              />
              <p className={styles.backToLogin}>
                <Link to="/login">
                  {t('register.resendCode.backToLogin')}
                </Link>
              </p>
              <Button
                type="submit"
                className="m-t-30 center-block"
                size="large"
                label={t('global.form.submit')}
              />
            </form>
          </>
          )}
        </>
      )}
    </>
  );
}

BetaRegisterForm.propTypes = {
};

export default BetaRegisterForm;
