/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';

import ReactPhoneInput, {
  parsePhoneNumber,
} from 'react-phone-number-input';

// Utils
import cn from '../../../utils/cn';
import { getCompletePhoneNumber } from '../../../utils/phone';

import styles from './PhoneInput.module.scss';

/**
 *
 * @param {object} value  { countryCode: string, phone: string }
 */
const PhoneInput = ({
  className,
  label,
  error,
  info,
  onChange,
  onBlur,
  value,
  placeholder,
  isRequired,
  valid,
  name,
  disabled,
  ...props
}) => {
  useEffect(() => {
    try {
      if (typeof value === 'string') {
        const parsed = parsePhoneNumber(value || '');
        onChange({
          countryCode: parsed.country,
          phone: parsed.nationalNumber,
        });
      }
    } catch (e) {
      //
    }
  }, [value]);

  const formattedValue = useMemo(() => getCompletePhoneNumber(value), [value]);

  // eslint-disable-next-line react/no-unstable-nested-components
  const Input = useMemo(() => forwardRef((p, ref) => (
    <input
      {...p}
      ref={ref}
      onBlur={onBlur}
    />
  )), [onBlur]);

  return (
    <div className={className}>
      <div className={cn([
        styles.inputWrapper,
        !value ? styles.formGroupEmpty : null,
        error ? styles.error : null,
        valid ? styles.valid : null,
        disabled ? styles.disabled : null,
      ])}
      >
        <ReactPhoneInput
          value={formattedValue}
          defaultCountry="FR"
          name={name}
          aria-invalid={error != null}
          onChange={(v) => {
            try {
              const parsed = parsePhoneNumber(v || '');
              onChange(typeof v === 'undefined' ? {
                countryCode: null,
                phone: null,
              } : {
                countryCode: parsed.country,
                phone: parsed.nationalNumber,
              });
            } catch (e) {
              onChange({
                countryCode: null,
                phone: null,
              });
            }
          }}
          inputComponent={Input}
          placeholder={placeholder}
          disabled={disabled}
          {...props}
        />
        {label ? (
          <label htmlFor={props.name}>
            {label}
            {isRequired ? <span>*</span> : ''}
          </label>
        ) : null}
      </div>
      {(error && typeof error === 'string') ? (
        <div className={cn([styles.context, styles.fieldError])}>
          {error}
        </div>
      ) : info && (
      <div className={cn([styles.context, styles.fieldInfo])}>
        {info}
      </div>
      )}
    </div>
  );
};

PhoneInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  info: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  valid: PropTypes.bool,
  disabled: PropTypes.bool,
};

PhoneInput.defaultProps = {
  className: '',
  error: null,
  info: null,
  placeholder: '',
  onChange: () => {},
  onBlur: () => {},
  value: null,
  isRequired: false,
  valid: false,
  disabled: false,
};

export default PhoneInput;
