/* eslint-disable func-names */
import * as yup from 'yup';

export const leaseInitialValues = {
  name: '', // string
  type: '', // string
  usage: '', // string
  rent: null, // int
  charges: null, // int
  chargesRegularisationFrequency: 1, // int
  startDate: null, // date
  duration: null, // int
  rentPaymentDay: 5, // int
  rentAdjustmentFrequency: 1, // int
  depositAmount: null, // int
  additionalMarkings: '', // string

  // Only used for business leases
  paymentFrequency: 'monthly', // string
  activityPractised: '', // string
  rentSubjectToVat: false, // bool
  terminationFrequency: 9, // int

  // prefilled when selecting a property
  propertyUid: null, // string

  // prefilled when selecting a tenant, for now, only one tenant is selected [tenantUid]
  tenants: null, // array of uid strings

  // Only used for tense area leases
  lastRentAmount: null, // int
  lastRentDate: null, // date
  lastRentAdjustmentDate: null, // date

  html: null, // string
};

const leaseSchema = yup.object().shape({
  name: yup.string().required('global.form.errors.required'),
  type: yup.string().required('global.form.errors.required'),
  usage: yup.string().required('global.form.errors.required'),

  // prefilled when selecting a property
  rent: yup
    .number()
    .typeError('global.form.errors.number')
    .moreThan(-1, 'global.form.errors.negativeNumber')
    .required('global.form.errors.required'),
  charges: yup
    .number()
    .typeError('global.form.errors.number')
    .moreThan(-1, 'global.form.errors.negativeNumber')
    .required('global.form.errors.required'),
  chargesRegularisationFrequency: yup
    .number()
    .typeError('global.form.errors.number')
    .moreThan(-1, 'global.form.errors.negativeNumber')
    .required('global.form.errors.required'),
  startDate: yup.date().required('global.form.errors.required'),
  duration: yup
    .number()
    .typeError('global.form.errors.number')
    .when('type', {
      is: 'furnished',
      then: yup.number().oneOf([1], 'global.form.errors.invalid').required('global.form.errors.required'),
      otherwise: yup.number().when('type', {
        is: 'commercial',
        then: yup.number().oneOf([1, 3, 6, 9], 'global.form.errors.invalid').required('global.form.errors.required'),
        otherwise: yup.number().oneOf([1, 3, 6], 'global.form.errors.invalid').required('global.form.errors.required'),
      }),
    }),
  rentPaymentDay: yup
    .number()
    .typeError('global.form.errors.number')
    .min(1, 'lease.add.form.errors.rentPaymentDayInterval')
    .max(27, 'lease.add.form.errors.rentPaymentDayInterval')
    .moreThan(-1, 'global.form.errors.negativeNumber')
    .required('global.form.errors.required'),
  rentAdjustmentFrequency: yup
    .number()
    .typeError('global.form.errors.number')
    .moreThan(-1, 'global.form.errors.negativeNumber')
    .required('global.form.errors.required'),
  depositAmount: yup
    .number()
    .typeError('global.form.errors.number')
    .moreThan(-1, 'global.form.errors.negativeNumber')
    .required('global.form.errors.required'),
  additionalMarkings: yup.string().nullable(),
  paymentFrequency: yup
    .string()
    .required('global.form.errors.required')
    .nullable(),

  // Only used for business leases
  activityPractised: yup.string().when('type', {
    is: (value) => value === 'commercial',
    then: yup.string().required('global.form.errors.required'),
    otherwise: yup.string().nullable(),
  }),
  rentSubjectToVat: yup.boolean().required('global.form.errors.required'),
  terminationFrequency: yup
    .number()
    .typeError('global.form.errors.number')
    .moreThan(-1, 'global.form.errors.negativeNumber')
    .required('global.form.errors.required'),

  // prefilled when selecting a property
  propertyUid: yup.string().required('lease.add.form.errors.propertyUid'),

  // prefilled when selecting a tenant, for now, only one tenant is selected [tenantUid]
  // array of uid strings
  tenants: yup.array().of(yup.string()).required('lease.add.form.errors.tenants'),

  // Only used for tense area leases
  lastRentAmount: yup
    .string()
    .test('at least one filled', 'At least one field must be filled', function (value) {
      const { lastRentDate, lastRentAdjustmentDate } = this.parent;

      if ((lastRentDate || lastRentAdjustmentDate) && !value) {
        return this.createError({
          path: 'lastRentAmount',
          message: 'lease.add.form.errors.tenseAllFilled',
        });
      }

      if (typeof value === 'string' && Number.isNaN(Number(value))) {
        return this.createError({
          path: 'lastRentAmount',
          message: 'global.form.errors.number',
        });
      }

      return true;
    })
    .nullable(),
  lastRentDate: yup
    .date()
    .test('at least one filled', 'At least one field must be filled', function (value) {
      const { lastRentAmount, lastRentAdjustmentDate } = this.parent;

      if ((lastRentAmount || lastRentAdjustmentDate) && !value) {
        return this.createError({
          path: 'lastRentDate',
          message: 'lease.add.form.errors.tenseAllFilled',
        });
      }
      return true;
    })
    .nullable(),
  lastRentAdjustmentDate: yup
    .date()
    .test('at least one filled', 'At least one field must be filled', function (value) {
      const { lastRentDate, lastRentAmount } = this.parent;

      if ((lastRentDate || lastRentAmount) && !value) {
        return this.createError({
          path: 'lastRentAdjustmentDate',
          message: 'lease.add.form.errors.tenseAllFilled',
        });
      }
      return true;
    })
    .nullable(),
  html: yup.string().nullable(),
});

export default leaseSchema;
