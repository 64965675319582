import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Components
import {
  Button,
} from 'ui-library-unlocker';
import EditPasswordModal from '../EditPasswordModal/EditPasswordModal';

// Utils
import { showModal } from '../../../utils/modal';

// Constants
const PASSWORD_CHANGE_MODAL_ID = 'passwordChangeModal';

function EditPwdFromAdmin({
  className,
  userUid,
}) {
  const { t } = useTranslation();

  const handleButtonClick = useCallback(() => {
    showModal(PASSWORD_CHANGE_MODAL_ID);
  }, []);

  return (
    <>
      <Button
        type="button"
        variant="secondary"
        className={className}
        onClick={handleButtonClick}
        label={t('profile.personalInfo.form.editPassword.button')}
        size="medium"
      />
      <EditPasswordModal
        idModal={PASSWORD_CHANGE_MODAL_ID}
        userUid={userUid}
      />
    </>
  );
}

EditPwdFromAdmin.propTypes = {
  className: PropTypes.string,
  userUid: PropTypes.string,
};

EditPwdFromAdmin.defaultProps = {
  className: '',
  userUid: '',
};

export default EditPwdFromAdmin;
