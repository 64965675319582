/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useState, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';
import { Message, Picto } from 'ui-library-unlocker';

import { useAppContext } from '../../../store/context';

// Components
import DocumentList from '../../../components/organisms/DocumentList/DocumentList';

// Services
import { getLeaseDocuments } from '../../../services/lease';
import { handleDocumentDownload } from '../../../utils/documents';

import styles from './LeaseDocuments.module.scss';

function LeaseDocuments() {
  const { t } = useTranslation();
  const { context: { user, uiBuilders } } = useAppContext();

  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  const match = useMatch('/lease/:id');

  const {
    data: documentListData,
    isFetching: documentListFetching,
    isFetched: documentListFetched,
  } = useQuery({
    queryKey: ['lease-documents', user, page, itemsPerPage],
    queryFn: async () => getLeaseDocuments(match?.params?.id, {
      page,
      itemsPerPage,
    }),
    enabled: !!user,
    keepPreviousData: true,
  });

  const columns = useMemo(() => ([
    {
      header: t('global.documents.columns.headers.category'),
      accessorKey: 'type',
      size: 500,
      enableSorting: false,
      cell: ({ row: { original } }) => {
        if (!uiBuilders) return null;
        const documentType = uiBuilders['/lease/ui'].documentTypes?.[original?.type];
        return (
          <div className={styles.documentTypeValue}>{documentType}</div>
        );
      },
    },
    {
      header: t('global.documents.columns.headers.name'),
      accessorKey: 'name',
      size: 500,
      enableSorting: false,
    },
    {
      header: '',
      accessorKey: 'download',
      size: 10,
      enableSorting: false,
      cell: ({ row: { original } }) => (
        <div>
          <Picto
            className={styles.downloadIcon}
            icon="import"
            width={20}
            color="var(--color-secondary)"
            onClick={() => handleDocumentDownload(original, t)}
          />
        </div>
      ),
    },
  ]), [t, uiBuilders]);

  const fileList = useMemo(() => {
    if (uiBuilders) {
      const documentType = uiBuilders['/lease/ui'].documentTypes;
      return Object.keys(documentType);
    }
    return [];
  }, [uiBuilders]);

  return (
    <div className="m-t-50">
      {documentListFetched && !documentListData?.data?.collection?.length ? (
        <Message
          type="info"
          content={t('global.form.errors.global')}
        />
      ) : (
        <DocumentList
          className="m-t-25"
          fileList={fileList}
          columns={columns}
          documentListData={documentListData}
          pagination={{
            page,
            itemsPerPage,
            setPage,
            setItemsPerPage,
          }}
          isDocumentListFetching={documentListFetching}
          onFileListElementDelete={null}
        />
      )}
    </div>
  );
}

LeaseDocuments.propTypes = {
};

export default LeaseDocuments;
