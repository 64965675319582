/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Picto, Button, utils } from 'ui-library-unlocker';

// Hooks
import useProfileCompletion from '../../hooks/useProfileCompletion';

import styles from './ProfileNeedCompletion.module.scss';

function Highlight({ children }) {
  return <span className={styles.highlight}>{children}</span>;
}

function ProfileNeedCompletion({ title, heading }) {
  const { t } = useTranslation();
  const {
    isUserCompletionLevel1,
    isUserCompletionLevel2,
  } = useProfileCompletion();

  useEffect(() => {
    document.title = t('completeProfile.browserTitle');
  }, [t]);

  return (
    <div>
      <div className={styles.heading}>
        <h1>{title}</h1>
      </div>
      <div className={styles.content}>
        <h2 className={utils.cn(['m-t-40 m-b-4', styles.welcomeTitle])}>
          {heading}
        </h2>
        <Picto width={166} icon="trace-5208" color="var(--color-tertiary)" />
        {isUserCompletionLevel1 && (
          <>
            <p className="m-t-40 p-1-300">
              <Trans
                i18nKey="completeProfile.subHeading"
                t={t}
                components={[<Highlight />]}
              />
            </p>
            <Link to="/profile#personal-info">
              <Button className="m-t-40" variant="primary" size="large">
                {t('completeProfile.cta')}
              </Button>
            </Link>
          </>
        )}
        {isUserCompletionLevel2 && (
        <p className="m-t-40 p-1-300">
          <Trans
            i18nKey="completeProfile.level2SubHeading"
            t={t}
            components={[<Highlight />]}
          />
        </p>
        )}
        <img className={styles.mask} src="/images/appartment-1.png" alt="cover" />
      </div>
    </div>
  );
}

ProfileNeedCompletion.propTypes = {
  title: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
};

export default ProfileNeedCompletion;
