import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

// Components
import {
  TextInput,
  SelectInput,
  WysiwygInput,
  ToggleInput,
  Message,
} from 'ui-library-unlocker';
import FormInfoRequired from '../../components/atoms/FormInfoRequired/FormInfoRequired';
import AddressInput from '../../components/molecules/AddressInput/AddressInput';

// Utils
import { displayError, isFieldValid, errorFocusSubmit } from '../../utils/forms/form';

import styles from './PostAd.module.scss';

function PropertyPostAd({
  formik,
  className,
  propertyBuilders,
}) {
  const { t } = useTranslation();

  const typeOptions = useMemo(() => {
    if (!propertyBuilders?.propertyTypes) return [];
    return Object.entries(propertyBuilders.propertyTypes).map(([key, type]) => ({
      value: key,
      label: type,
    }));
  }, [propertyBuilders]);

  const furnishedOptions = useMemo(() => [
    {
      label: t('property.crud.form.isFurnished'),
      value: true,
    },
    {
      label: t('property.crud.form.isUnfurnished'),
      value: false,
    },
  ], []);

  const chargesRepartitionOptions = [
    {
      label: t('property.crud.form.provision'),
      value: 'provision',
    },
    {
      label: t('property.crud.form.lumpSum'),
      value: 'lumpSum',
    },
  ];

  const isFormError = useMemo(() => !isEmpty(formik.errors), [formik.errors]);

  return (
    <form className={className} onSubmit={errorFocusSubmit(formik.handleSubmit)}>
      <h2 className="m-b-25 m-t-30">{t('postAd.property.title')}</h2>
      <FormInfoRequired />
      <TextInput
        type="text"
        id="name"
        name="name"
        className="m-t-25"
        label={t('property.crud.form.name')}
        error={displayError(t, formik, 'name')}
        valid={isFieldValid(formik, 'name')}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.name}
      />
      <SelectInput
        className="m-t-25"
        id="type"
        name="type"
        label={t('property.crud.form.type')}
        options={typeOptions}
        error={displayError(t, formik, 'type')}
        valid={isFieldValid(formik, 'type')}
        onChange={(value) => {
          formik.setFieldValue('type', value.value);
        }}
        onBlur={formik.handleBlur}
        value={typeOptions.find((type) => type.value === formik.values.type)}
      />
      <SelectInput
        className="m-t-25"
        id="furnished"
        name="furnished"
        label={t('property.crud.form.furnished')}
        options={furnishedOptions}
        error={displayError(t, formik, 'furnished')}
        valid={isFieldValid(formik, 'furnished', { inputType: 'boolean' })}
        onChange={(value) => {
          formik.setFieldValue('furnished', value.value);
        }}
        onBlur={formik.handleBlur}
        value={furnishedOptions.find((furnished) => furnished.value === formik.values.furnished)}
      />
      <AddressInput
        id="address"
        name="address"
        className="m-t-25"
        label={t('property.crud.form.address')}
        error={displayError(t, formik, 'address')}
        valid={isFieldValid(formik, 'address')}
        onAddressSelect={(value) => formik.setFieldValue('address', value)}
        value={formik.values.address || null}
        countryRestrictions={['fr', 'gp', 're', 'mq', 'gf', 'nc', 'yt', 'pf']}
      />

      <h2 className="m-b-25 m-t-30">
        {t('property.subtitles.generalInfo.description')}
      </h2>
      <TextInput
        min="0"
        type="number"
        id="constructionYear"
        name="constructionYear"
        className="m-t-25"
        label={t('property.crud.form.constructionYear')}
        error={displayError(t, formik, 'constructionYear')}
        valid={isFieldValid(formik, 'constructionYear')}
        onChange={(e) => {
          if (e.target.value === '') {
            return formik.setFieldValue('constructionYear', null);
          }
          return formik.handleChange(e);
        }}
        onBlur={formik.handleBlur}
        value={formik.values.constructionYear}
      />
      <TextInput
        type="text"
        id="tantiemes"
        name="tantiemes"
        className="m-t-25"
        label={t('property.crud.form.tantiemes')}
        error={displayError(t, formik, 'tantiemes')}
        valid={isFieldValid(formik, 'tantiemes')}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.tantiemes}
      />
      <div className={styles.inputCols}>
        <TextInput
          min="0"
          type="number"
          id="surface"
          name="surface"
          className="m-t-25"
          label={t('property.crud.form.surface')}
          error={displayError(t, formik, 'surface')}
          valid={isFieldValid(formik, 'surface')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.surface}
        />
        <TextInput
          min="0"
          type="number"
          id="numberOfRooms"
          name="numberOfRooms"
          className="m-t-25"
          label={t('property.crud.form.numberOfRooms')}
          error={displayError(t, formik, 'numberOfRooms')}
          valid={isFieldValid(formik, 'numberOfRooms')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.numberOfRooms}
        />
      </div>
      <div className={styles.inputCols}>
        <TextInput
          min="0"
          type="number"
          id="numberOfBathrooms"
          name="numberOfBathrooms"
          className="m-t-25"
          label={t('property.crud.form.numberOfBathrooms')}
          error={displayError(t, formik, 'numberOfBathrooms')}
          valid={isFieldValid(formik, 'numberOfBathrooms')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.numberOfBathrooms}
        />
        <TextInput
          min="0"
          type="number"
          id="numberOfBedrooms"
          name="numberOfBedrooms"
          className="m-t-25"
          label={t('property.crud.form.numberOfBedrooms')}
          error={displayError(t, formik, 'numberOfBedrooms')}
          valid={isFieldValid(formik, 'numberOfBedrooms')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.numberOfBedrooms}
        />
      </div>
      <WysiwygInput
        className="m-t-25"
        label={t('property.crud.form.description')}
        onChange={(value) => formik.setFieldValue('description', value)}
        error={displayError(t, formik, 'description', 'wysiwyg')}
        valid={isFieldValid(formik, 'description')}
        defaultValue={formik.values.description}
      />

      <h2 className="m-b-25 m-t-30">
        {t('property.subtitles.generalInfo.rentingInfo')}
      </h2>
      <TextInput
        min="0"
        step="0.01"
        type="number"
        id="rentExcludingCharges"
        name="rentExcludingCharges"
        className="m-t-25"
        label={t('property.crud.form.rentExcludingCharges')}
        error={displayError(t, formik, 'rentExcludingCharges')}
        valid={isFieldValid(formik, 'rentExcludingCharges')}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.rentExcludingCharges}
      />
      <TextInput
        min="0"
        step="0.01"
        type="number"
        id="rentSupplement"
        name="rentSupplement"
        className="m-t-25"
        label={t('property.crud.form.rentSupplement')}
        error={displayError(t, formik, 'rentSupplement')}
        valid={isFieldValid(formik, 'rentSupplement')}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.rentSupplement}
      />
      <TextInput
        min="0"
        step="0.01"
        type="number"
        id="charges"
        name="charges"
        className="m-t-25"
        label={t('property.crud.form.charges')}
        error={displayError(t, formik, 'charges')}
        valid={isFieldValid(formik, 'charges')}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.charges}
      />
      {!!formik.values.charges && parseFloat(formik.values.charges) > 0 && (
      <SelectInput
        id="chargesRepartition"
        name="chargesRepartition"
        options={chargesRepartitionOptions}
        label={t('property.crud.form.chargesRepartition')}
        className="m-t-25"
        error={displayError(t, formik, 'chargesRepartition')}
        valid={isFieldValid(formik, 'chargesRepartition')}
        onChange={(value) => formik.setFieldValue('chargesRepartition', value.value)}
        onBlur={formik.handleBlur}
        value={chargesRepartitionOptions.find((type) => type.value === formik.values.chargesRepartition)}
      />
      )}
      <ToggleInput
        id="heatingChargesIncluded"
        name="heatingChargesIncluded"
        className="m-t-25"
        label={t('property.crud.form.heatingChargesIncluded')}
        checked={!!formik.values.heatingChargesIncluded}
        onChange={(check) => formik.setFieldValue('heatingChargesIncluded', check)}
      />
      <ToggleInput
        id="coldWaterChargesIncluded"
        name="coldWaterChargesIncluded"
        className="m-t-25"
        label={t('property.crud.form.coldWaterChargesIncluded')}
        checked={!!formik.values.coldWaterChargesIncluded}
        onChange={(check) => formik.setFieldValue('coldWaterChargesIncluded', check)}
      />
      <ToggleInput
        id="hotWaterChargesIncluded"
        name="hotWaterChargesIncluded"
        className="m-t-25"
        label={t('property.crud.form.hotWaterChargesIncluded')}
        checked={!!formik.values.hotWaterChargesIncluded}
        onChange={(check) => formik.setFieldValue('hotWaterChargesIncluded', check)}
      />
      <WysiwygInput
        className="m-t-25"
        label={t('property.crud.form.otherChargesIncluded')}
        onChange={(value) => formik.setFieldValue('otherChargesIncluded', value)}
        error={displayError(t, formik, 'otherChargesIncluded', 'wysiwyg')}
        valid={isFieldValid(formik, 'otherChargesIncluded')}
        defaultValue={formik.values.otherChargesIncluded}
      />
      <TextInput
        type="text"
        id="deliveryPoint"
        name="deliveryPoint"
        className="m-t-25"
        label={t('property.crud.form.deliveryPoint')}
        error={displayError(t, formik, 'deliveryPoint')}
        valid={isFieldValid(formik, 'deliveryPoint')}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.deliveryPoint}
      />
      <TextInput
        type="text"
        id="taxNumber"
        name="taxNumber"
        className="m-t-25"
        label={t('property.crud.form.taxNumber')}
        error={displayError(t, formik, 'taxNumber')}
        valid={isFieldValid(formik, 'taxNumber')}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.taxNumber}
      />
      <TextInput
        type="text"
        id="cadastralRef"
        name="cadastralRef"
        className="m-t-25"
        label={t('property.crud.form.cadastralRef')}
        error={displayError(t, formik, 'cadastralRef')}
        valid={isFieldValid(formik, 'cadastralRef')}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.cadastralRef}
      />

      {isFormError
        ? <Message variant="error" className="m-t-30" content={t('global.form.errors.localGeneric')} />
        : null}
    </form>
  );
}

PropertyPostAd.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
      furnished: PropTypes.bool,
      address: PropTypes.shape({}),
      constructionYear: PropTypes.number,
      tantiemes: PropTypes.string,
      surface: PropTypes.number,
      numberOfRooms: PropTypes.number,
      numberOfBathrooms: PropTypes.number,
      numberOfBedrooms: PropTypes.number,
      description: PropTypes.string,
      rentExcludingCharges: PropTypes.number,
      rentSupplement: PropTypes.number,
      charges: PropTypes.number,
      chargesRepartition: PropTypes.string,
      heatingChargesIncluded: PropTypes.bool,
      coldWaterChargesIncluded: PropTypes.bool,
      hotWaterChargesIncluded: PropTypes.bool,
      otherChargesIncluded: PropTypes.string,
      deliveryPoint: PropTypes.string,
      taxNumber: PropTypes.string,
      cadastralRef: PropTypes.string,
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    errors: PropTypes.shape({}),
  }).isRequired,
  className: PropTypes.string,
  propertyBuilders: PropTypes.shape({
    propertyTypes: PropTypes.shape({}),
  }).isRequired,
};

PropertyPostAd.defaultProps = {
  className: '',
};

export default PropertyPostAd;
