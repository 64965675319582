import React, { useMemo } from 'react';

import { utils } from 'ui-library-unlocker';

import styles from './EnvBanner.module.scss';

function EnvBanner() {
  const variant = useMemo(() => {
    const env = process.env.REACT_APP_ENV;
    switch (env) {
      case 'staging':
        return {
          label: 'Staging',
          color: 'staging',
        };
      case 'preprod':
        return {
          label: 'Preprod',
          color: 'preprod',
        };
      case 'prod':
      case 'local':
        return null;
      default:
        return {
          label: 'Unknown',
          color: 'unknown',
        };
    }
  }, []);

  return variant ? (
    <div
      className={utils.cn([
        styles.envBanner,
        styles[variant.color],
      ])}
    >
      <span>
        {variant.label}
      </span>
    </div>
  ) : null;
}

export default EnvBanner;
