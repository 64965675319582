/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import {
  Link, useNavigate, useSearchParams,
} from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { utils, TextInput, Button } from 'ui-library-unlocker';

import { forgotUserPasswordConfirm } from '../../../../services/identity';
import forgotPasswordConfirmSchema,
{ forgotPasswordConfirmInitialValues } from '../../../../utils/forms/forgotPasswordConfirmSchema';
import { displayError, errorFocusSubmit, isFieldValid } from '../../../../utils/forms/form';

import styles from './ForgotPasswordForm.module.scss';

function ForgotPasswordForm({ label, action }) {
  const { t } = useTranslation();
  const [search] = useSearchParams();
  const navigate = useNavigate();

  const username = search.get('username');
  const code = search.get('code');

  const mutation = useMutation({
    mutationFn: forgotUserPasswordConfirm,
    onSuccess: ({ response, status }) => {
      const s = status || response?.status;
      switch (s) {
        case 204:
          utils.toast.success(t('forgotPassword.form.editSuccess'));
          navigate('/login');
          break;
        default:
          break;
      }
    },
    onError: (error) => {
      if (error?.response) {
        switch (error?.response?.status) {
          case 400: {
            switch (error?.response?.data?.message) {
              case 'IDENTITY_ACCESS_CONFIRM_FORGOT_PASSWORD_EXPIRED_CODE_EXCEPTION': {
                utils.toast.alert(t('forgotPassword.form.errorResponses.IDENTITY_ACCESS_CONFIRM_FORGOT_PASSWORD_EXPIRED_CODE_EXCEPTION'));
                break;
              }
              case 'IDENTITY_ACCESS_CONFIRM_FORGOT_PASSWORD_LIMIT_EXCEEDED_EXCEPTION': {
                utils.toast.alert(t('forgotPassword.form.errorResponses.IDENTITY_ACCESS_CONFIRM_FORGOT_PASSWORD_LIMIT_EXCEEDED_EXCEPTION'));
                break;
              }
              default:
                utils.toast.alert(t('globals.form.submit.generic'));
                break;
            }
            break;
          }
          default:
            break;
        }
      }
    },
  });

  const formik = useFormik({
    initialValues: { ...forgotPasswordConfirmInitialValues, username },
    validationSchema: forgotPasswordConfirmSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: (values) => mutation.mutate({
      confirmationCode: code,
      password: values.password,
      username,
    }),
  });

  return (
    <>
      <div className={utils.cn([styles.userSection, styles.tenant])}>
        <p className={styles.userTitle}>
          {label}
        </p>
        <p className={styles.userAction}>
          {action}
        </p>
      </div>
      <form className={styles.form} onSubmit={errorFocusSubmit(formik.handleSubmit)}>
        <TextInput
          type="text"
          id="username"
          name="username"
          label={t('forgotPassword.form.username')}
          error={displayError(t, formik, 'username')}
          valid={isFieldValid(formik, 'username')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disabled
          value={formik.values.username}
        />
        <TextInput
          type="password"
          id="password"
          name="password"
          className="m-t-25"
          label={t('login.form.password')}
          error={displayError(t, formik, 'password')}
          valid={isFieldValid(formik, 'password')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          autoComplete="new-password"
        />
        <p className={styles.forgotPassword}>
          <Link to="/login">
            {t('forgotPassword.backToLogin')}
          </Link>
        </p>
        <Button
          type="submit"
          className="m-t-30 center-block"
          size="large"
          label={t('forgotPassword.form.submit')}
        />
        <p className={styles.noAccount}>
          {t('login.noAccount')}
          <Link to="/register">
            {t('login.signUp')}
          </Link>
        </p>
      </form>
    </>
  );
}

ForgotPasswordForm.propTypes = {
  label: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
};

export default ForgotPasswordForm;
