/* eslint-disable max-len */
import * as yup from 'yup';
import { twoDecimalPlaces } from './form';

export const internalTransferInitialValues = {
  sourceAccount: null, // Compte source
  destinationAccount: null, // Compte destinataire
  amount: null, // Montant
};

export const internalTransferSchema = yup.object().shape({
  sourceAccount: yup.object()
    .required('global.form.errors.required')
    .nullable(),
  destinationAccount: yup.object()
    .required('global.form.errors.required')
    .nullable(),
  amount: yup.number()
    .typeError('global.form.errors.number')
    .moreThan(-1, 'global.form.errors.negativeNumber')
    .required('global.form.errors.required')
    .test(
      'is-decimal',
      'global.form.errors.twoDecimalPlaces',
      (value) => !value || twoDecimalPlaces(value),
    ),
});
