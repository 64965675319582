import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Picto, utils } from 'ui-library-unlocker';

import styles from './404.module.scss';

function NotFoundView() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = t('404.browserTitle');
  }, [t]);

  return (
    <div className={styles.notFound}>
      <div
        className={styles.cover}
      >
        <Picto
          className={utils.cn([styles.decoration, styles.topDeco])}
          icon="point-moyen-900"
        />
        <Picto
          className={utils.cn([styles.decoration, styles.bottomDeco])}
          icon="gribouillon-6"
        />
        <img
          className={styles.img}
          src="/images/404.png"
          alt="Personne assise dans un salon avec un ordinateur sur les genoux"
        />
      </div>
      <div className={styles.content}>
        <div className={styles.title}>
          <Picto
            className={styles.circle}
            icon="rond-1"
          />
          <h1>{t('404.title')}</h1>

        </div>
        <h2>{t('404.subTitle')}</h2>
        <p>{t('404.explanation')}</p>
        <Button
          label={t('404.goBack')}
          size="large"
          blockLevel
          className="m-b-20 m-t-20"
          onClick={() => navigate(-1)}
        />
        <Button
          onClick={() => navigate('/messages')}
          label={t('404.contactSupport')}
          variant="secondary"
          size="large"
          blockLevel
          iconSide="left"
          icon="mail"
        />
      </div>
    </div>
  );
}

export default NotFoundView;
