export const getAddressField = (place, fields, fieldType = 'long_name') => {
  const found = place.address_components?.find((address) => {
    const { types } = address;
    return types?.find((type) => {
      const foundField = fields.find((field) => field === type);
      return foundField;
    });
  })?.[fieldType];

  return found || null;
};
