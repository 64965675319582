import * as yup from 'yup';

export const forgotPasswordConfirmInitialValues = {
  username: '',
  password: '',
};

const forgotPasswordConfirmSchema = yup.object().shape({
  username: yup.string().email('global.form.errors.email').required('global.form.errors.required'),
  password: yup.string().required('global.form.errors.required'),
});

export default forgotPasswordConfirmSchema;
