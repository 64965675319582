import { useEffect, useRef, useState } from 'react';

const useEllipsisVisible = () => {
  const [isEllipsisVisible, setEllipsisVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current.offsetWidth < ref.current.scrollWidth) {
      setEllipsisVisible(true);
    } else {
      setEllipsisVisible(false);
    }
  }, [ref.current]);

  return { ref, isEllipsisVisible };
};

export default useEllipsisVisible;
