import { useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';

// Components
import {
  Message,
  utils,
  Picto,
  WithHeader,
  Button,
} from 'ui-library-unlocker';
import Footer from '../../components/templates/Footer/Footer';

// Services
import { publishPostAd } from '../../services/property';

import styles from './PostAd.module.scss';

function PostAdPublishView() {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const navigate = useNavigate();

  const [success, setSuccess] = useState(false);

  const {
    id: propertyUID,
    token,
  } = useParams();

  useEffect(() => {
    document.title = t('postAd.publish.browserTitle');
  }, [t]);

  const publishAdMutation = useMutation({
    mutationFn: publishPostAd,
    onSuccess: () => {
      setSuccess(true);
    },
    onError: () => {
      utils.toast.error(t('global.form.errors.global'));
    },
  });

  const renderNav = useCallback(() => (
    <nav className={styles.nav}>
      <ul className={styles.list}>
        <li className={styles.item}>
          <Button
            onClick={() => navigate('/post-ad')}
            label={t('postAd.headerBtn')}
            size={isMobile ? 'small' : 'medium'}
            variant="default-outline"
          />
        </li>
        <li className={styles.item}>
          <Button
            onClick={() => navigate('/login')}
            label={t('login.title')}
            size={isMobile ? 'small' : 'medium'}
          />
        </li>
      </ul>
    </nav>
  ), [isMobile, navigate, t]);

  const handleSubmit = useCallback(() => {
    publishAdMutation.mutate({
      propertyUID,
      token,
    });
  }, [publishAdMutation, propertyUID, token]);

  const handleRedirect = useCallback(() => {
    navigate(`/rechercher-des-biens/${propertyUID}`);
  }, [navigate, propertyUID]);

  const handleSignUp = useCallback(() => {
    navigate('/register');
  }, [navigate]);

  return (
    <WithHeader
      logoLink="/register"
      navContent={renderNav()}
      isContained={false}
      className={utils.cn([
        styles.postAdViewHeader,
        'p-l-20 p-r-20',
      ])}
      displayBurger={false}
    >
      <div className={styles.postAdView}>
        <div>
          <div className={styles.heading}>
            <h1 className={styles.title}>
              <span>{t('postAd.publish.title')}</span>
              <Picto height={30} icon="receipt-2" color="var(--color-primary)" />
            </h1>
            <Picto width={166} icon="trace-5208" color="var(--color-tertiary)" />
          </div>
          <div className={styles.content}>
            {success ? (
              <>
                <h2>{t('postAd.publish.success.title')}</h2>
                <Message
                  content={t('postAd.publish.success.message')}
                  variant="success"
                  className="m-t-20"
                />
                <p className="m-t-20">
                  <Trans i18nKey="postAd.publish.success.description" />
                </p>
                <Button
                  type="button"
                  className="m-t-30"
                  size="large"
                  label={t('postAd.publish.success.seeAd')}
                  onClick={handleRedirect}
                />
                <h2 className="m-t-30">{t('postAd.delete.subtitle')}</h2>
                <p className="m-t-20">
                  <Trans i18nKey="postAd.delete.description" />
                </p>
                <Button
                  type="button"
                  className="m-t-30"
                  size="large"
                  label={t('postAd.delete.signUp')}
                  onClick={handleSignUp}
                />
              </>
            ) : (
              <>
                <h2>{t('postAd.publish.subtitle')}</h2>
                <p className="m-t-20">{t('postAd.publish.description')}</p>
                <Button
                  type="button"
                  className="m-t-30"
                  size="large"
                  label={t('postAd.publish.submit')}
                  onClick={handleSubmit}
                  loading={publishAdMutation.isLoading}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <Footer className={styles.footer} />
    </WithHeader>
  );
}

PostAdPublishView.propTypes = {
};

PostAdPublishView.defaultProps = {
};

export default PostAdPublishView;
