import api from './_client';
import { getDateFilters } from '../utils/dates';

export const getInventories = ({ page, itemsPerPage, filters = {} }) => {
  const apiFilters = {
    'filters[uid][--in--]': filters.uid?.join?.(','),
    'filters[propertyUid][--in--]': filters.propertyUid?.join?.(','),
    'filters[lease][uid][--in--]': filters.leaseUid?.join?.(','),
    'filters[status][--in--]': filters.status?.join?.(','),
    'filters[status][--not-eq--]': filters.statusNot?.join?.(','),
    ...getDateFilters('date', filters),
    [`orderBy[${filters?.orderBy?.property}]`]: filters.orderBy?.direction,
    q: filters.search?.[0],
  };

  return api.get('/inventory/inventories', {
    params: {
      page,
      itemsPerPage,
      ...apiFilters,
    },
  });
};

export const createInventory = (propertyUid, data) => api.post(`/inventory/${propertyUid}/create`, data);

export const getInventory = (uid) => api.get(`/inventory/${uid}`);

export const updateInventory = (uid, data) => api.patch(`/inventory/${uid}`, {
  ...(data || {}),
  isDraft: true,
});

export const sendInventoryToSign = (uid, data) => api.patch(`/inventory/${uid}`, {
  ...(data || {}),
  isDraft: false,
});

export const deleteInventory = (uid) => api.delete(`/inventory/${uid}`);
