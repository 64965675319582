import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Table, Pagination, Picto, utils,
} from 'ui-library-unlocker';

// Components
import DeleteDocumentModal from '../../molecules/DeleteDocumentModal/DeleteDocumentModal';

// Styles
import styles from './DocumentList.module.scss';
import { showModal } from '../../../utils/modal';

function DocumentList({
  className,
  fileList,
  columns,
  documentListData,
  isReadOnly,
  addButton,
  onAddBtnClick,
  onFileListElementAddClick,
  onFileListElementDelete,
  pagination,
  isDocumentListFetching,
}) {
  const [documentToDelete, setDocumentToDelete] = useState(null);

  const data = useMemo(() => {
    const documentList = documentListData?.data?.collection || [];

    // Sort the document list by the order of the file list type
    if (fileList?.length && documentList) {
      const sortedDocumentList = fileList.reduce((acc, file) => {
        const documentListForType = documentList.filter((doc) => doc.type === file).map((doc) => ({
          ...doc,
          isTemplate: false,
        }));

        // Disabled template columns for now
        // if (!isReadOnly) {
        //   documentListForType.unshift({
        //     type: file,
        //     isTemplate: true,
        //   });
        // }

        return [...acc, ...documentListForType];
      }, []);
      return sortedDocumentList;
    }
    return documentList || [];
  }, [documentListData, fileList, isReadOnly]);

  const documentListColumns = useMemo(() => [
    ...columns,
    {
      header: '',
      accessorKey: 'options',
      size: 1,
      enableSorting: false,
      cell: ({ row: { original } }) => {
        if (isReadOnly) return null;
        const isTemplate = original?.isTemplate;
        const option = isTemplate ? {
          element: <Button
            size="small"
            onClick={() => onFileListElementAddClick(original)}
            variant="primary"
            icon="plus"
          />,
        } : {
          element: onFileListElementDelete ? (
            <Picto
              className={utils.cn(['m-r-5', styles.trashIcon])}
              icon="trash"
              onClick={() => {
                setDocumentToDelete(original);
                showModal('profile-document-delete');
              }}
              width={16}
              color="var(--color-secondary)"
            />
          ) : null,
        };

        return (
          <div style={{ float: 'right' }}>{option.element}</div>
        );
      },
    },
  ], [columns, onFileListElementAddClick, isReadOnly]);

  return (
    <div className={utils.cn([className])}>
      {addButton?.enabled && (
        <div className={styles.addBtnWrapper}>
          <Button
            className={styles.addBtn}
            onClick={onAddBtnClick}
            label={addButton.label}
            icon={addButton.icon || ''}
          />
        </div>
      )}
      <div className={styles.tableWrapper}>
        <Table
          fullWidth
          columns={documentListColumns}
          data={data}
          isLoading={isDocumentListFetching}
        />
        {pagination && documentListData !== null
          ? (
            <div className={styles.pagination}>
              <Pagination
                breakLine="..."
                totalCount={documentListData?.data?.totalNumberOfItems || 0}
                currentPage={pagination.page}
                onPageChange={(p) => pagination.setPage(p)}
                initialItemsPerPage={pagination.itemsPerPage}
                onItemsPerPageChange={(items) => pagination.setItemsPerPage(items)}
              />
            </div>
          ) : null}
      </div>
      <DeleteDocumentModal
        id="profile-document-delete"
        document={documentToDelete}
        onDocumentDelete={(doc) => {
          onFileListElementDelete(doc);
          setDocumentToDelete(null);
        }}
      />
    </div>
  );
}

DocumentList.propTypes = {
  className: PropTypes.string,
  fileList: PropTypes.arrayOf(PropTypes.string),
  columns: PropTypes.arrayOf(PropTypes.shape()),
  documentListData: PropTypes.shape(),
  isReadOnly: PropTypes.bool,
  addButton: PropTypes.shape(),
  onAddBtnClick: PropTypes.func,
  onFileListElementAddClick: PropTypes.func,
  onFileListElementDelete: PropTypes.func,
  pagination: PropTypes.shape({
    page: PropTypes.number,
    setPage: PropTypes.func,
    itemsPerPage: PropTypes.number,
    setItemsPerPage: PropTypes.func,
  }),
  isDocumentListFetching: PropTypes.bool,
};

DocumentList.defaultProps = {
  className: '',
  fileList: [],
  columns: [],
  documentListData: null,
  isReadOnly: false,
  addButton: {
    enabled: false,
    label: '',
    icon: '',
  },
  onFileListElementAddClick: () => { },
  onFileListElementDelete: () => { },
  onAddBtnClick: () => { },
  isDocumentListFetching: false,
  pagination: {
    page: 1,
    setPage: () => { },
    itemsPerPage: 10,
    setItemsPerPage: () => { },
  },
};

export default DocumentList;
