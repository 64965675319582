import { useCallback, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import cn from 'ui-library-unlocker/src/utils/cn';

// Components
import {
  WithHeader,
  Button,
  utils,
} from 'ui-library-unlocker';
import Footer from '../../components/templates/Footer/Footer';
import NewFeedbackModal from './NewFeedbackModal';

// Utils
import { showModal } from '../../utils/modal';

import styles from './Support.module.scss';

const feedbackModal = 'feedBackModal';

function SupportView() {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const navigate = useNavigate();

  useEffect(() => {
    document.title = t('support.browserTitle');
  }, [t]);

  const renderNav = useCallback(() => (
    <nav className={styles.nav}>
      <ul className={styles.list}>
        <li className={styles.item}>
          <Button
            onClick={() => navigate('/post-ad')}
            label={t('postAd.headerBtn')}
            size={isMobile ? 'small' : 'medium'}
            variant="default-outline"
          />
        </li>
        <li className={styles.item}>
          <Button
            onClick={() => navigate('/login')}
            label={t('login.title')}
            size={isMobile ? 'small' : 'medium'}
          />
        </li>
      </ul>
    </nav>
  ), [isMobile, navigate, t]);

  return (
    <WithHeader
      logoLink="/register"
      navContent={renderNav()}
      isContained={false}
      className={utils.cn([
        styles.searchPublicView,
        'p-l-20 p-r-20',
      ])}
      displayBurger={false}
    >

      <div className={styles.black}>
        <div className={styles.info}>
          <h1>{t('support.title')}</h1>
          <h2>{t('support.subTitle')}</h2>
        </div>
        <div className={styles.cat}>
          <div className={cn([styles.ear, styles.earLeft])} />
          <div className={cn([styles.ear, styles.earRight])} />
          <div className={styles.face}>
            <div className={cn([styles.eye, styles.eyeLeft])}>
              <div className={styles.eyePupil} />
            </div>
            <div className={cn([styles.eye, styles.eyeRight])}>
              <div className={styles.eyePupil} />
            </div>
            <div className={styles.muzzle} />
          </div>

        </div>
        <div className={styles.subInfo}>
          <p>{t('support.help')}</p>
          <p>{t('support.feedback')}</p>
          <p>
            <Button
              onClick={() => showModal(feedbackModal)}
              size="large"
              label="Signaler un problème"
            />
          </p>
        </div>
      </div>
      <NewFeedbackModal
        id={feedbackModal}
      />
      <Footer />
    </WithHeader>
  );
}

SupportView.propTypes = {
};

SupportView.defaultProps = {};

export default SupportView;
