import * as yup from 'yup';

export const loginInitialValues = {
  username: '',
  password: '',
};

const loginSchema = yup.object().shape({
  username: yup.string().email('global.form.errors.email').required('global.form.errors.required'),
  password: yup.string().required('global.form.errors.required'),
});

export default loginSchema;
