/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import { Button } from 'ui-library-unlocker';
import { useTranslation } from 'react-i18next';

import Modal from '../Modal/Modal';

import { hideModal } from '../../../utils/modal';

import styles from './ValidationChangeModal.module.scss';

function ValidationChangeModal({
  id,
  onAccept,
  submitLoading,
  entity,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      className={styles.modal}
      id={id}
      title={t('global.info')}
      onClose={() => {
        hideModal(id);
      }}
    >
      <div className={styles.modalContent}>
        <p className="p-1-500">
          {t(`${entity}.submitWarn`)}
        </p>
        <div className={styles.modalActions}>
          <Button
            variant="primary"
            size="large"
            className={styles.submitBtn}
            loading={submitLoading}
            onClick={() => {
              onAccept();
              hideModal(id);
            }}
          >
            {t('global.confirm')}
          </Button>
          <Button
            variant="secondary"
            size="large"
            className={styles.submitBtn}
            loading={submitLoading}
            onClick={() => {
              hideModal(id);
            }}
          >
            {t('global.cancel')}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

ValidationChangeModal.propTypes = {
  id: PropTypes.string.isRequired,
  onAccept: PropTypes.func.isRequired,
  submitLoading: PropTypes.bool.isRequired,
  entity: PropTypes.oneOf(['profile', 'company']).isRequired,
};

export default ValidationChangeModal;
