/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';

// Components
import {
  Picto,
  Tag,
  Button,
  utils,
} from 'ui-library-unlocker';
import Tooltip from '../../components/atoms/Tooltip/Tooltip';
import TableOptions from '../../components/atoms/TableOptions/TableOptions';
import TenantLeavePropertyModal from './TenantLeavePropertyModal';
import DataTable from '../../components/organisms/DataTable/DataTable';

// Hooks
import useRoles from '../../hooks/useRoles';
import { useAppContext } from '../../store/context';
import useDocumentTitle from '../../hooks/useDocumentTitle';

// Services
import { getTenants } from '../../services/tenant';
import { getTenantsFromAdmin } from '../../services/admin';

// Utils
import { showModal } from '../../utils/modal';
import { getPersonStatusVariant } from '../../utils/variants';

// Styles
import styles from './TenantList.module.scss';

// Constants
import { SEARCH_SCOPE_LIST } from '../../utils/constants';

const SEARCH_SCOPE = {
  [SEARCH_SCOPE_LIST.TENANTS]: 'uid',
  [SEARCH_SCOPE_LIST.PROPERTIES]: 'propertyUid',
};
const TENANT_LEAVE_PROPERTY_MODAL_ID = 'TENANT_LEAVE_PROPERTY_MODAL_ID';

function TenantListView() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isUserAdmin, isUserRealEstateManager } = useRoles();
  const { context: { user } } = useAppContext();

  const [tooltipOpen, setTooltipOpen] = useState(null);
  const [tenantLeaving, setTenantLeaving] = useState(null);

  useDocumentTitle(t('tenant.browserTitle'));

  const columns = useMemo(() => ([
    {
      header: t('tenant.table.columns.id'),
      accessorKey: 'unlockerId',
      size: 100,
    },
    {
      header: t('tenant.table.columns.createdAt'),
      accessorKey: 'createdAt',
      size: 100,
      cell: ({ row: { original: { createdAt } } }) => (
        <div>
          <span>{createdAt ? format(new Date(createdAt), 'dd MMM yyyy', { locale: fr }) : '-'}</span>
        </div>
      ),
    },
    {
      header: t('tenant.table.columns.name'),
      accessorKey: 'lastName',
      minSize: 200,
      cell: ({ row }) => (
        <div className={styles.name}>
          <span className={styles.firstName}>{row?.original?.firstName}</span>
          <span className={styles.lastName}>{row?.original?.lastName}</span>
        </div>
      ),
    },
    {
      header: t('tenant.table.columns.property'),
      accessorKey: 'propertyName',
      size: 200,
      cell: ({ row }) => (
        <div>
          {row?.original?.propertyName != null ? (
            <div className={styles.nameAddress}>
              <Link to={`/property/${row.original.propertyUid}`} className={styles.name}>
                <p className="p-2-700">
                  {row.original.propertyName}
                  <Picto icon="export-link" width={9} color="var(--color-secondary)" className={styles.externalLink} />
                </p>
              </Link>
              <div className={styles.address}>
                {row.original.propertyName}
              </div>
            </div>
          ) : '-'}
        </div>
      ),
    },
    {
      header: t('tenant.table.columns.price'),
      accessorKey: 'price',
      size: 100,
      enableSorting: false,
      cell: ({ row }) => (
        <div>
          <span>{row?.original?.balance != null ? `${utils.centsToEuro(row?.original?.balance)} €` : '-'}</span>
        </div>
      ),
    },
    {
      header: t('tenant.table.columns.status'),
      accessorKey: 'status',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { status, underPendingInvitation } } }) => (
        <div>
          <Tag
            label={t(`global.personStatus.${underPendingInvitation ? 'isPendingInvitation' : status}`)}
            size="medium"
            variant={getPersonStatusVariant(status, underPendingInvitation)}
          />
        </div>
      ),
    },
    {
      header: t('tenant.table.columns.delegated'),
      accessorKey: 'delegated',
      size: 100,
      enableSorting: false,
      cell: ({ row }) => {
        const userHasDelegation = (
          row?.original?.isDelegated
          && user?.username === row?.original?.delegatedTo
        );
        return (
          <div>
            <span>
              {userHasDelegation ? (
                <Tag
                  label={t('tenant.table.columns.delegated')}
                  size="medium"
                  variant="tertiary"
                />
              ) : ''}
            </span>
          </div>
        );
      },
    },
    {
      header: '',
      accessorKey: 'additionalOptions',
      size: 100,
      enableSorting: false,
      cell: ({ row }) => {
        const userHasDelegation = (
          row?.original?.isDelegated
          && user?.username === row?.original?.delegatedTo
        );
        return (
          <div className={styles.seeMore}>
            <Picto
              id={`more-option-tenant-${row?.original?.uid}`}
              icon="more"
              width={24}
              onClick={(e) => {
                e.stopPropagation();
                setTooltipOpen(tooltipOpen === row?.original?.uid ? null : row?.original?.uid);
              }}
              color="var(--color-secondary)"
            />
            <Tooltip
              isOpen={tooltipOpen === row?.original?.uid}
              anchorId={`more-option-tenant-${row?.original?.uid}`}
              place="bottom"
              type="dark"
              effect="solid"
            >
              <TableOptions
                options={[
                  {
                    id: userHasDelegation ? 'edit' : 'see',
                    label: t(`global.listOptions.${userHasDelegation ? 'edit' : 'see'}`),
                    icon: <Picto icon={userHasDelegation ? 'edit' : 'eye'} width={24} color="var(--color-primary)" />,
                    onHoverIcon: (
                      <Picto icon={userHasDelegation ? 'edit' : 'eye'} width={24} color="var(--color-white)" />
                    ),
                    onClick: (e) => {
                      e.stopPropagation();
                      setTooltipOpen(null);
                      navigate(`/tenant/${row?.original?.uid}`);
                    },
                  },
                  isUserRealEstateManager && row?.original?.propertyName != null ? {
                    id: 'terminate-lease',
                    label: t('global.listOptions.terminateLease'),
                    icon: <Picto icon="logout" width={24} color="var(--color-primary)" />,
                    onHoverIcon: <Picto icon="logout" width={24} color="var(--color-white)" />,
                    onClick: () => {
                      setTenantLeaving(row?.original);
                      showModal(TENANT_LEAVE_PROPERTY_MODAL_ID);
                    },
                  } : null,
                ]}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ]), [t, tooltipOpen, navigate, user, isUserRealEstateManager]);

  const allFiltersOptions = useMemo(() => [
    {
      name: 'status',
      type: 'checkbox',
      title: t('tenant.table.columns.status'),
      options: [
        {
          value: 'active',
          label: t('tenant.table.status.active'),
        },
        {
          value: 'inactive',
          label: t('tenant.table.status.inactive'),
        },
      ],
    },
  ], []);

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={null}
      onClick={() => setTooltipOpen(null)}
    >
      <div className={styles.heading}>
        <h1>{t('tenant.title')}</h1>
      </div>
      <DataTable
        columns={columns}
        searchLabel={t('tenant.search')}
        omniSearch
        omniSearchScope={SEARCH_SCOPE}
        getData={isUserAdmin ? getTenantsFromAdmin : getTenants}
        queryKey="tenant-list"
        allFiltersOptions={allFiltersOptions}
        headerBtn={(
          <Link to="/tenant/add">
            <Button
              variant="primary"
              size="large"
              icon="plus"
            >
              {t('tenant.add')}
            </Button>
          </Link>
        )}
      />
      <TenantLeavePropertyModal id={TENANT_LEAVE_PROPERTY_MODAL_ID} tenant={tenantLeaving} />
    </div>
  );
}

export default TenantListView;
