import { useCallback, useState } from 'react';

// Components
import {
  Button,
  Picto,
} from 'ui-library-unlocker';

// Styles
import styles from './InfoBanner.module.scss';

// Constants
const BANNER_ID = 'webinar'; // must change when message changes

function InfoBanner() {
  const [open, setOpen] = useState(localStorage.getItem('unlocker-banner-closed') !== BANNER_ID);

  const handleBtnClick = useCallback(() => {
    setOpen(false);
    // eslint-disable-next-line max-len
    window.open('https://events.teams.microsoft.com/event/b4ed300e-7dcc-4d38-9b89-cd5816b8820e@3b0527de-4764-4246-b501-5781f40da94c', '_blank');
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    localStorage.setItem('unlocker-banner-closed', BANNER_ID);
  }, []);

  if (!open) return null;

  return (
    <div className={styles.banner}>
      <div className={styles.container}>
        <p className={styles.title}>
          <Picto
            icon="calendar"
            width={24}
            className="m-r-5"
          />
          <b>Webinaire Unlocker !</b>
        </p>
        <div className={styles.content}>
          <span className="m-t-8 m-b-8">
            Venez découvrir les dernières nouveautés de Unlocker lors de notre webinaire le jeudi 12 décembre à 12h00.
            Inscription gratuite.
          </span>
          <Button
            label="S'inscrire maintenant"
            onClick={handleBtnClick}
            size="medium"
          />
          <Button
            label="Fermer"
            onClick={handleClose}
            size="medium"
            variant="secondary"
          />
        </div>
      </div>
    </div>
  );
}

export default InfoBanner;
