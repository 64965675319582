import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

// Components
import {
  Picto,
  utils,
} from 'ui-library-unlocker';

import styles from './Footer.module.scss';

function Footer({
  className,
}) {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  if (isMobile) return null;

  return (
    <footer className={utils.cn([styles.footer, className])}>
      <Link to="/register" className={styles.footerLogo}>
        <Picto icon="logo-horizontal" width={160} color="var(--color-black)" />
      </Link>

      <div className={styles.socialNetworks}>
        <a href="https://www.facebook.com/profile.php?id=100085865975036" target="_blank" rel="noopener noreferrer">
          <Picto icon="facebook-frame" width={20} color="var(--color-black)" />
        </a>
        <a href="https://fr.linkedin.com/company/unlockerapp" target="_blank" rel="noopener noreferrer">
          <Picto icon="linkedin-frame" width={24} color="var(--color-black)" />
        </a>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  className: PropTypes.string,
};

Footer.defaultProps = {
  className: '',
};

export default Footer;
