import api from './_client';
import { getDateFilters } from '../utils/dates';

export const getPaymentAccounts = ({ page, itemsPerPage, filters = {} }) => {
  const apiFilters = {
    'filters[uid][--in--]': filters.id?.join?.(','),
    'filters[enrolmentStatus][--in--]': filters.enrolmentStatus?.join?.(','),
    'filters[accountStatus][--in--]': filters.accountStatus?.join?.(','),
    'filters[user][--in--]': filters.userUid?.join?.(','),
    ...getDateFilters('createdAt', filters),
    [`orderBy[${filters?.orderBy?.property}]`]: filters.orderBy?.direction,
    q: filters.search?.[0],
  };

  return api.get('/payment/account', {
    params: {
      page,
      itemsPerPage,
      ...apiFilters,
    },
  });
};

export const getPaymentAccount = (uid) => api.get(`/payment/account/${uid}`);

export const submitPaymentAccountEnrolment = (uid) => api.post(`/payment/admin/enrolment/submit/${uid}`);
