/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { useSearchParams, useNavigate } from 'react-router-dom';
import {
  Picto,
  utils,
  Button,
} from 'ui-library-unlocker';
import WithLogin from '../../components/templates/WithLogin/WithLogin';

import { acceptInvitation } from '../../services/identity';

import { useAppContext } from '../../store/context';

import styles from './InvitationAccept.module.scss';

function InvitationAccept() {
  const { t } = useTranslation();
  const [search] = useSearchParams();
  const navigate = useNavigate();

  const { context: { accessToken } } = useAppContext();

  const code = search.get('code');
  const requester = search.get('requester');

  useEffect(() => {
    document.title = t('acceptInvitation.browserTitle');
  }, [t]);

  useEffect(() => {
    if (!code) navigate('/login');
  }, [code]);

  const acceptInvitationMutation = useMutation({
    mutationFn: acceptInvitation,
    onSuccess: ({ response, status }) => {
      const s = status || response?.status;
      switch (s) {
        case 204:
          utils.toast.success(t('acceptInvitation.success'));
          window.scrollTo({ top: 0, behavior: 'smooth' });

          if (accessToken) {
            navigate('/');
          } else {
            navigate('/login');
          }
          break;
        default:
          break;
      }
    },
    onError: (err) => {
      if (err?.response) {
        switch (err?.response?.status) {
          case 424: {
            utils.toast.error(t('acceptInvitation.errorProfilePending'));
            break;
          }
          case 400: {
            utils.toast.error(t('global.form.errors.generic'));
            break;
          }
          default:
            utils.toast.error(t('global.form.errors.generic'));
            break;
        }
      }
    },
  });

  const renderContentHeading = useCallback(() => (
    <div>
      <h1 className={styles.title}>
        {t('acceptInvitation.header')}
      </h1>
      <Picto
        width={154}
        color="var(--color-tertiary)"
        icon="trait-login-title"
      />
      <p className="m-t-30 t-h2-500">
        {t('global.invitedBy')}
        <a>{requester || t('global.invitedByUnknown')}</a>
      </p>
      <p className={styles.subTitle}>
        {t('acceptInvitation.subHeader')}
      </p>
    </div>
  ), []);

  const renderContentLeft = useCallback(() => (
    <div className={styles.contentLeft}>
      <div className={styles.imageBox}>
        <img className={styles.image} src="/images/jess.jpg" alt="cover" />
        <Picto
          className={styles.gribouillon}
          width={93}
          fill="var(--color-tertiary)"
          color="transparent"
          icon="gribouillon-6"
        />
        <Picto
          className={styles.dot}
          width={46}
          color="var(--color-secondary)"
          icon="point-moyen-900"
        />
      </div>
    </div>
  ), []);

  return (
    <WithLogin
      contentLeft={renderContentLeft()}
      contentHeading={renderContentHeading()}
    >
      <Button
        type="button"
        className="m-t-30"
        size="large"
        label={t('acceptInvitation.submit')}
        onClick={() => acceptInvitationMutation.mutate({
          token: code,
          password: 'empty',
        })}
      />
    </WithLogin>
  );
}

export default InvitationAccept;
