const getTenantsPercent = ({ tenantsCount, tenantsList = [] }) => {
  if (tenantsCount) {
    if (tenantsList?.some((el) => el.underPendingInvitation === false)) return 100;

    return 50;
  }

  return 0;
};

const formatTenantsCompletion = ({
  // prevPercentage,
  tenantsPercent,
  t,
  navigate,
  showModal,
  COMPLETION_LIST_WIDTH,
  index,
}) => ({
  id: 4,
  title: t('dashboard.completion.tenant.title'),
  progression: tenantsPercent,
  toggleLabel: t('dashboard.completion.details'),
  subtitle: t('dashboard.completion.tenant.subtitle', { count: index }),
  width: COMPLETION_LIST_WIDTH,
  // waiting: prevPercentage !== 100,
  disabled: tenantsPercent === 100,
  items: [
    {
      id: 1,
      label: t('dashboard.completion.tenant.step1.label'),
      checked: tenantsPercent > 0,
      progression: tenantsPercent > 0 ? 100 : 0,
      onClick: () => navigate('/tenant/add'),
    },
    {
      id: 2,
      label: t('dashboard.completion.tenant.step2.label'),
      checked: tenantsPercent > 50,
      progression: tenantsPercent > 50 ? 100 : 0,
      onClick: () => showModal('tenant-invited-wait-modal'),
    },
  ],
});

export {
  getTenantsPercent,
  formatTenantsCompletion,
};
