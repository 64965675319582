/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import Picto from '../../atoms/Picto/Picto';

import usePagination, { DOTS } from '../../../hooks/usePagination';

import style from './Pagination.module.scss';

const Pagination = ({
  breakLine,
  onPageChange,
  initialItemsPerPage,
  onItemsPerPageChange,
  totalCount,
  siblingCount,
  currentPage,
  className,
  theme,
  locale,
}) => {
  const [pageSize, setPageSize] = useState(initialItemsPerPage);
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const renderDescription = useCallback(() => {
    const currentPageMin = currentPage === 1 ? 1 : (currentPage - 1) * pageSize;
    const currentPageMax = currentPage * pageSize;
    const count = totalCount > currentPageMax ? totalCount : currentPageMax;

    if (locale === 'fr') {
      return `Résultats ${currentPageMin} - ${currentPageMax} sur ${count}`;
    }
    return `Results ${currentPageMin} - ${currentPageMax} of ${count}`;
  }, [currentPage, pageSize, totalCount, locale]);

  const lastPage = paginationRange[paginationRange.length - 1];

  return (
    <div className={style.paginationWrapper}>
      <ul
        data-testid="pagination"
        className={`
        ${style.paginationContainer} ${style[className]} ${style[theme]}
      `}
      >
        <li
          data-testid="paginationPrevious"
          className={`
        ${style.paginationNavigation}
          ${style.paginationItem}
          ${style.paginationLabel}
          ${currentPage === 1 && style.disabled}
        `}
          onClick={onPrevious}
        >
          <Picto color="var(--color-secondary)" transform="rotate(90)" width={20} icon="arrow-down" />
        </li>
        {paginationRange.map((pageNumber) => {
          if (pageNumber === DOTS) {
            return (
              <li
                className={`${style.paginationItem} ${style.dots}`}
                data-testid="paginationBreakLine"
                key="paginationBreakLine"
              >
                {breakLine}
              </li>
            );
          }

          return (
            <li
              data-testid={`pagination-${pageNumber}`}
              key={`pagination-${pageNumber}`}
              className={`
              ${style.paginationItem} ${
                pageNumber === currentPage && style.selected
              }
            `}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </li>
          );
        })}
        <li
          data-testid="paginationNext"
          className={`${style.paginationNavigation} ${style.paginationItem} ${style.paginationLabel} ${
            currentPage === lastPage && style.disabled
          }`}
          onClick={onNext}
        >
          <Picto color="var(--color-secondary)" transform="rotate(-90)" width={20} icon="arrow-down" />
        </li>
      </ul>
      <span className={style.description}>
        {renderDescription()}
      </span>
      <span className={style.pageSize}>
        <select
          defaultValue={initialItemsPerPage?.toString()}
          onChange={(e) => {
            onItemsPerPageChange(Number(e.target.value));
            setPageSize(Number(e.target.value));
          }}
          id="pagination-select"
        >
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
        <Picto
          className={pageSize > 99 ? style.pageSizeArrowLarge : style.pageSizeArrow}
          color="var(--color-secondary)"
          width={13}
          icon="arrow-down"
        />
      </span>
    </div>
  );
};

export default Pagination;

Pagination.propTypes = {
  breakLine: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onPageChange: PropTypes.func.isRequired,
  initialItemsPerPage: PropTypes.number,
  onItemsPerPageChange: PropTypes.func,
  totalCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  siblingCount: PropTypes.number,
  className: PropTypes.string,
  theme: PropTypes.oneOf(['primary', 'dark']),
  locale: PropTypes.oneOf(['fr', 'en']),
};

Pagination.defaultProps = {
  initialItemsPerPage: 20,
  onItemsPerPageChange: () => {},
  siblingCount: 1,
  className: '',
  breakLine: '',
  theme: 'primary',
  locale: 'fr',
};
