/* eslint-disable max-len */
import api from './_client';

export const exportOwner = (data) => api.post('/lease/export/owner/generate', data);

export const exportManager = (data) => api.post('/lease/export/manager/generate', data);

export const exportGLI = (data) => api.post('/payment/export/gli', {
  ownerOrManagerUid: data.ownerUID || data.managerUID,
  isManager: !!data.managerUID,
  year: data.startDate.getFullYear(),
  month: data.startDate.getMonth() + 1,
});
