import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Components
import InternalTransferForm from '../../components/organisms/forms/InternalTransferForm/InternalTransferForm';

// Styles
import styles from './InternalTransferEdit.module.scss';

function InternalTransferEdit({ isEditing }) {
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles.heading}>
        <div className={styles.title}>
          <h1 className="m-b-30">
            {isEditing ? t('internalTransfer.crud.editTitle') : t('internalTransfer.crud.addTitle')}
          </h1>
        </div>
      </div>
      <div className={styles.content}>
        <InternalTransferForm />
      </div>
    </div>
  );
}

InternalTransferEdit.propTypes = {
  isEditing: PropTypes.bool.isRequired,
};

export default InternalTransferEdit;
