import {
  useMemo,
  useEffect,
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import TagManager from 'react-gtm-module';
import {
  utils,
  Tag,
  Picto,
  Button,
  UnlockerLoader,
} from 'ui-library-unlocker';

// Components
import PhotoGallery from '../../../components/organisms/PhotoGallery/PhotoGallery';
import ConsumptionScore from '../../../components/atoms/ConsumptionScore/ConsumptionScore';
import Map from '../../../components/organisms/Map/Map';
import Modal from '../../../components/molecules/Modal/Modal';
import ContactAdModal from '../../../components/organisms/ContactAdModal/ContactAdModal';

// Utils
import { showModal, hideModal } from '../../../utils/modal';
import { formatPrice, formatAddress } from '../../../utils/properties';
import { parseSanitized } from '../../../utils/html';

// Hooks
import { useAppContext } from '../../../store/context';
import useAlgolia from '../../../hooks/useAlgolia';

// Constans
import { PROPERTY_STATUS } from '../../../utils/constants';

// Styles
import styles from './PropertyDetails.module.scss';

const CONTACT_AD_MODAL_ID = 'contact-ad-modal';

function Ticked({ label }) {
  return (
    <div className={styles.ticked}>
      <Picto
        icon="tick-circle"
        width={24}
        className={styles.ticked__icon}
      />
      <span>{label}</span>
    </div>
  );
}

Ticked.propTypes = {
  label: PropTypes.string.isRequired,
};

function PropertyDetails({
  property,
  className,
  listLoaded,
  objectID,
}) {
  const [item, setItem] = useState(property);
  const [algoliaError, setAlgoliaError] = useState(null);

  const {
    pictures,
    rentExcludingCharges,
    charges,
    address,
    status,
    surface,
    numberOfRooms,
    numberOfBedrooms,
    name,
    description,
    _geoloc,
    constructionYear,
    heatings = [],
    heatingChargesIncluded,
    coldWaterChargesIncluded,
    hotWaterChargesIncluded,
    dpeScore,
    gesScore,
    visibility,
  } = item ?? {};

  const { t } = useTranslation();
  const { context: { uiBuilders } } = useAppContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const algolia = useAlgolia();

  useEffect(() => setItem(property), [property]);

  const propertyMutation = useMutation(algolia.getObject, {
    onSuccess: (hit) => {
      setItem(hit);
    },
    onError: (error) => {
      setAlgoliaError(error);
    },
  });

  useEffect(() => {
    if (listLoaded && !property) {
      propertyMutation.mutate({ objectID });
    }
  }, [listLoaded, property]);

  const generatedHeatingTypes = useMemo(() => heatings.map((heating) => (
    <Ticked
      label={uiBuilders?.['/property/ui']?.propertyHeatings?.[heating]}
      key={heating}
    />
  )), [heatings, uiBuilders]);

  const generatedEquipments = useMemo(() => {
    const equipmentList = Object.keys(
      uiBuilders?.['/property/ui']?.propertyEquipments || {},
    ).filter((key) => item?.[key] === true);
    return equipmentList.map((equipment) => (
      <Ticked
        label={uiBuilders?.['/property/ui']?.propertyEquipments?.[equipment]}
        key={equipment}
      />
    ));
  }, [uiBuilders, item]);

  const totalRent = useMemo(() => {
    const total = (rentExcludingCharges || 0) + (charges || 0);
    return formatPrice(utils.centsToEuro(total));
  }, [rentExcludingCharges, charges]);

  const chargesIncluded = useMemo(() => {
    const included = [];
    if (heatingChargesIncluded) {
      included.push(t('search.propertyDetails.chargesIncluded.heatingChargesIncluded'));
    }
    if (coldWaterChargesIncluded) {
      included.push(t('search.propertyDetails.chargesIncluded.coldWaterChargesIncluded'));
    }
    if (hotWaterChargesIncluded) {
      included.push(t('search.propertyDetails.chargesIncluded.hotWaterChargesIncluded'));
    }
    return included.join(', ');
  }, [heatingChargesIncluded]);

  const gallery = useMemo(() => {
    const pics = [];
    const mainPicture = pictures?.find((picture) => picture.main);

    if (mainPicture) {
      pics.push(mainPicture);
      pictures?.forEach((picture) => {
        if (!picture.main) pics.push(picture);
      });
    } else {
      pictures?.forEach((picture, i) => {
        pics.push({
          ...picture,
          main: i === 0,
        });
      });
    }

    return pics.map((picture) => ({
      objectID: picture.objectID,
      url: picture.url,
      main: picture.main,
    }));
  }, [pictures]);

  const generateRandomCoordinates = useCallback((center = {}, radius = 1000) => {
    // Convertir le rayon de mètres à degrés
    const radiusInDegrees = radius / 111300;

    // Générer des coordonnées aléatoires dans le cercle
    const randomAngle = Math.random() * 2 * Math.PI;
    const randomRadius = Math.sqrt(Math.random()) * radiusInDegrees;

    // Calculer les nouvelles coordonnées du centre du cercle
    const newLat = center.lat + randomRadius * Math.cos(randomAngle);
    const newLng = center.lng + randomRadius * Math.sin(randomAngle);

    return { lat: newLat, lng: newLng };
  }, []);

  const markers = useMemo(() => ([
    {
      id: 1,
      position: status === PROPERTY_STATUS.AVAILABLE ? _geoloc : generateRandomCoordinates(_geoloc, 1000),
      content: (
        <div className={styles.marker}>
          <Picto
            icon="location"
            width={24}
            color="var(--color-white)"
          />
        </div>
      ),
    },
  ]), [_geoloc]);

  return (
    <div className={utils.cn([styles.propertyDetails, className])}>
      <div className={styles.propertyDetails__container}>
        <div className={styles.propertyDetails__subheader}>
          <button
            className={styles.goBack}
            type="button"
            onClick={() => navigate(
              location.key === 'default'
                ? {
                  pathname: location.pathname.split('/')[0],
                  search: searchParams?.toString(),
                }
                : -1,
            )}
          >
            <Picto
              icon="arrow-down"
              width={24}
              className={styles.backArrow}
            />
            <span>{t('global.goBack')}</span>
          </button>
        </div>
        {algoliaError && (
          <div className={styles.error}>
            <h1 className={utils.cn([styles.title, styles.titleError])}>
              <Picto icon="scribble-cross-medium" width="20" color="var(--color-tertiary)" />
              <span>{t('search.propertyDetails.notFound.title')}</span>
            </h1>
            <p className="m-t-20">{t('search.propertyDetails.notFound.description')}</p>
          </div>
        )}
        {!item && !algoliaError && (
          <div style={{ height: 300 }}>
            <UnlockerLoader size={300} />
          </div>
        )}
        {item && (
          <>
            <div className={styles.propertyDetails__header}>
              <div className={styles.propertyDetails__header__title}>
                <Tag
                  variant={status === PROPERTY_STATUS.AVAILABLE ? 'secondary' : 'secondary-outline'}
                  size="medium"
                  icon={status === PROPERTY_STATUS.AVAILABLE ? 'house-2' : 'house-bulk'}
                  label={t(`search.propertyDetails.status.${status === PROPERTY_STATUS.AVAILABLE
                    ? PROPERTY_STATUS.AVAILABLE
                    : PROPERTY_STATUS.LEASED
                  }`)}
                />
                <h1 className={styles.title}>{name}</h1>
              </div>
              <span className={styles.city}>
                {`${address?.city} (${address?.zipCode})`}
              </span>
              <span className={styles.mainInfos}>
                {constructionYear && `${t('search.propertyDetails.constructionYear', { year: constructionYear })}, `}
                {`
                  ${surface} m²,
                  ${t('search.propertyDetails.rooms', { count: numberOfRooms })},
                  ${t('search.propertyDetails.bedrooms', { count: numberOfBedrooms })}
                `}
              </span>
            </div>
            {gallery.length > 0 ? (
              <PhotoGallery
                className={styles.propertyDetails__gallery}
                pictures={gallery}
              />
            ) : (
              <p className={styles.propertyDetails__no_gallery}>
                <div className={styles.propertyDetails__no_gallery__icon}>
                  <Picto
                    icon="camera"
                    width={24}
                  />
                  <Picto
                    icon="scribble-cross-medium"
                    width={14}
                    className={styles.propertyDetails__no_gallery__scribble}
                    color="var(--color-secondary)"
                  />
                </div>
                {t('search.propertyDetails.noGallery')}
              </p>
            )}
            <div className={styles.propertyDetails__body}>
              <main className={styles.propertyDetails__body__main}>
                <div className={styles.propertyDetails__body__description}>
                  <h2>{t('search.propertyDetails.description')}</h2>
                  {description
                    ? parseSanitized(description)
                    : (<p>{t('search.propertyDetails.noData')}</p>)}
                </div>
                <div className={styles.propertyDetails__body__heatingType}>
                  <h2>{t('search.propertyDetails.heatingType')}</h2>
                  {generatedHeatingTypes.length ? (
                    <div
                      className={utils.cn([
                        styles.propertyDetails__body__heatingType__list,
                        generatedHeatingTypes.length > 5 ? styles.propertyDetails__body__list__horizontal : null,
                      ])}
                    >
                      {generatedHeatingTypes}
                    </div>
                  ) : (
                    <p>{t('search.propertyDetails.noData')}</p>
                  )}
                </div>
                <div className={styles.propertyDetails__body__equipments}>
                  <h2>{t('search.propertyDetails.equipments')}</h2>
                  {generatedEquipments.length ? (
                    <div
                      className={utils.cn([
                        styles.propertyDetails__body__equipments__list,
                        generatedEquipments.length > 5 ? styles.propertyDetails__body__list__horizontal : null,
                      ])}
                    >
                      {generatedEquipments}
                    </div>
                  ) : (
                    <p>{t('search.propertyDetails.noData')}</p>
                  )}
                </div>
                <div className={styles.propertyDetails__body__diagnostics}>
                  <h2>{t('search.propertyDetails.diagnostics.title')}</h2>
                  <div className={styles.propertyDetails__body__diagnostics__container}>
                    <div className={styles.propertyDetails__body__diagnostics__dpe}>
                      <h3>{t('search.propertyDetails.diagnostics.dpe.title')}</h3>
                      <p className={styles.propertyDetails__body__diagnostics__desc}>
                        {dpeScore
                          ? t('search.propertyDetails.diagnostics.dpe.energyConsumption', { value: dpeScore })
                          : t('search.propertyDetails.noData')}
                      </p>
                      {dpeScore && (
                        <ConsumptionScore score={dpeScore} type="dpe" />
                      )}
                    </div>
                    <div className={styles.propertyDetails__body__diagnostics__ges}>
                      <h3>{t('search.propertyDetails.diagnostics.ges.title')}</h3>
                      <p className={styles.propertyDetails__body__diagnostics__desc}>
                        {gesScore
                          ? t('search.propertyDetails.diagnostics.ges.energyConsumption', { value: gesScore })
                          : t('search.propertyDetails.noData')}
                      </p>
                      {gesScore && (
                        <ConsumptionScore score={gesScore} type="ges" />
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.propertyDetails__body__map}>
                  <h2>{t('search.propertyDetails.map.title')}</h2>
                  <p className={styles.propertyDetails__body__map__address}>
                    {formatAddress(address)}
                  </p>
                  {_geoloc && (
                    <Map
                      id="propertyDetailsMap"
                      center={_geoloc}
                      zoom={13}
                      className={styles.propertyDetails__body__map__map}
                      markers={status === PROPERTY_STATUS.AVAILABLE ? markers : []}
                      circles={status === PROPERTY_STATUS.AVAILABLE ? [] : markers}
                    />
                  )}
                </div>
              </main>
              {status === PROPERTY_STATUS.AVAILABLE && (
              <>
                <aside className={styles.propertyDetails__body__aside}>
                  <h2>{t('search.propertyDetails.rentDetails')}</h2>
                  <div className={styles.propertyDetails__body__aside__rent}>
                    <span>{t('search.propertyDetails.rent')}</span>
                    <span className={styles.totalRent}>{totalRent}</span>
                  </div>
                  <div className={styles.rentDetailsItem}>
                    <span>{t('search.propertyDetails.rentExcludingCharges')}</span>
                    <span>{formatPrice(utils.centsToEuro(rentExcludingCharges || 0))}</span>
                  </div>
                  <div>
                    <div className={styles.rentDetailsItem}>
                      <span>{t('search.propertyDetails.charges')}</span>
                      <span>{formatPrice(utils.centsToEuro(charges || 0))}</span>
                    </div>
                    <small className={styles.includedCharges}>
                      {t('search.propertyDetails.chargesIncluded.label', { charges: chargesIncluded })}
                    </small>
                  </div>
                  <hr />
                  <div>
                    <h2>{t('search.propertyDetails.candidate.title')}</h2>
                    <p className={styles.candidateDesc}>
                      {t('search.propertyDetails.candidate.description')}
                    </p>
                  </div>
                  <Button
                    label={t('search.propertyDetails.candidate.button')}
                    variant="primary"
                    size="large"
                    onClick={() => {
                      TagManager.dataLayer({
                        dataLayer: {
                          event: 'property_apply_click',
                          propertyTitle: name,
                          propertyAlgoliaId: objectID,
                        },
                      });
                      showModal(visibility === 'listing_visible'
                        ? CONTACT_AD_MODAL_ID
                        : 'candidate-to-property-modal');
                    }}
                  />
                </aside>
                <Modal
                  id="candidate-to-property-modal"
                  title={t('global.goToMobileApp')}
                  size="small"
                  onClose={() => hideModal('candidate-to-property-modal')}
                >
                  <div className={styles.userCtas}>
                    <a
                      className={styles.storePill}
                      href="https://apps.apple.com/us/app/unlocker/id1667221898"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="/images/cta-app-store.png"
                        alt="Download on the App Store"
                      />
                    </a>
                    <a
                      className={styles.storePill}
                      href="https://play.google.com/store/apps/details?id=com.unlockerapp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="m-l-20"
                        src="/images/cta-google-play.png"
                        alt="Get it on Google Play"
                      />
                    </a>
                  </div>
                </Modal>
                <ContactAdModal
                  idModal={CONTACT_AD_MODAL_ID}
                  onClose={() => hideModal('contact-ad-modal')}
                  propertyUID={objectID}
                />
              </>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

PropertyDetails.propTypes = {
  property: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.oneOf([
      'unavailable',
      PROPERTY_STATUS.AVAILABLE,
      PROPERTY_STATUS.LEASED,
    ]),
    rentExcludingCharges: PropTypes.number,
    charges: PropTypes.number,
    address: PropTypes.shape({
      street: PropTypes.string,
      streetNumber: PropTypes.string,
      zipCode: PropTypes.string,
      city: PropTypes.string,
    }),
    surface: PropTypes.number,
    numberOfRooms: PropTypes.number,
    numberOfBedrooms: PropTypes.number,
    pictures: PropTypes.arrayOf(PropTypes.shape({})),
    name: PropTypes.string,
    description: PropTypes.string,
    _geoloc: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
    objectID: PropTypes.string,
    constructionYear: PropTypes.number,
    heatings: PropTypes.arrayOf(PropTypes.string),
    heatingChargesIncluded: PropTypes.bool,
    coldWaterChargesIncluded: PropTypes.bool,
    hotWaterChargesIncluded: PropTypes.bool,
    dpeScore: PropTypes.number,
    gesScore: PropTypes.number,
  }),
  className: PropTypes.string,
  listLoaded: PropTypes.bool,
  objectID: PropTypes.string.isRequired,
};

PropertyDetails.defaultProps = {
  property: null,
  className: '',
  listLoaded: false,
};

export default PropertyDetails;
