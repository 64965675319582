/* eslint-disable func-names */
import * as yup from 'yup';

export const newFeedBackInitialValues = {
  name: null,
  email: null,
  description: null,
};

const newFeedBackSchema = yup.object().shape({
  name: yup.string().required('global.form.errors.required'),
  email: yup.string().required('global.form.errors.required'),
  description: yup.string().required('global.form.errors.required'),
});

export default newFeedBackSchema;
