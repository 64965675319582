import axios from 'axios';
import { PROPERTY_STATUS, PROPERTY_VISIBILITY } from './constants';

export const getStatusStyles = (status) => {
  switch (status) {
    case PROPERTY_STATUS.AVAILABLE:
      return {
        variant: 'secondary',
        icon: 'house-bulk',
      };
    case PROPERTY_STATUS.LEASED:
      return {
        variant: 'secondary-outline',
        icon: 'house-2',
      };
    case PROPERTY_STATUS.DRAFT:
      return {
        variant: 'tertiary',
        icon: 'house-2',
      };
    case PROPERTY_STATUS.DISABLED:
      return {
        variant: 'error',
        icon: 'house-2',
      };
    case PROPERTY_VISIBILITY.VISIBLE:
    case PROPERTY_VISIBILITY.LISTING_VISIBLE:
      return {
        variant: 'secondary',
        icon: 'eye',
      };
    case PROPERTY_VISIBILITY.HIDDEN:
    case PROPERTY_VISIBILITY.DEACTIVATED:
    case PROPERTY_VISIBILITY.LISTING_DELETED:
      return {
        variant: 'secondary-outline',
        icon: 'eye-slash',
      };
    case PROPERTY_VISIBILITY.WAITING_LISTING_VALIDATION:
      return {
        variant: 'tertiary',
        icon: 'timer',
      };
    case PROPERTY_VISIBILITY.WAITING_KYC:
    case 'waiting_kyc_owner':
    case 'waiting_kyc_manager':
      return {
        variant: 'error',
        icon: 'alert-triangle-bold',
      };
    default:
      return {
        variant: 'primary',
        icon: 'alert-circle-bold',
      };
  }
};

export const getIpAddress = () => axios.get('https://api64.ipify.org?format=json');

export const formatPrice = (price) => {
  const formatter = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
  });

  return formatter.format(price);
};

export const formatAddress = (address) => {
  if (!address) return 'Adresse indisponible';
  const {
    streetNumber,
    street,
    streetDetails,
    zipCode,
    city,
  } = address;

  return `${streetNumber ?? ''} ${street ?? ''} ${streetDetails ?? ''} ${zipCode ?? ''} ${city ?? ''}`;
};
