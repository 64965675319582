import * as yup from 'yup';

export const editPasswordInitialValues = {
  password: null,
  confirmPassword: null,
};

export const editPasswordSchema = yup.object().shape({
  password: yup.string()
    .required('global.form.errors.required')
    .min(8, 'global.form.errors.password')
    .test('isValidPassword', 'global.form.errors.password', (value) => {
      const hasUpperCase = /[A-Z]/.test(value);
      const hasNumber = /[0-9]/.test(value);
      const hasSpecialChar = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(value);

      const conditions = [hasUpperCase, hasNumber, hasSpecialChar];

      return conditions.find((c) => c !== true) === undefined;
    })
    .nullable(),
  confirmPassword: yup.string()
    .oneOf([yup.ref('password'), null], 'global.form.errors.passwordMatch')
    .required('global.form.errors.required')
    .nullable(),
});
