/* eslint-disable max-len */
import * as yup from 'yup';
import { twoDecimalPlaces } from './form';

import { HOUSING_BENEFITS_TYPES } from '../constants';

export const housingBenefitsInitialValues = {
  leaseUID: null, // Bail
  tenantUID: null, // Locataire
  name: '', // Libellé
  type: '', // Type
  amount: '', // Montant
  recurrent: false, // Récurrent
  date: null, // Date
  leaseRentWithCharges: 0, // Loyer avec charges
};

export const housingBenefitsSchema = yup.object().shape({
  leaseUID: yup.string().required('global.form.errors.required').nullable(),
  tenantUID: yup.string().required('global.form.errors.required').nullable(),
  name: yup.string().required('global.form.errors.required').nullable(),
  type: yup.string().oneOf(Object.values(HOUSING_BENEFITS_TYPES)).required('global.form.errors.required'),
  amount: yup
    .number()
    .typeError('global.form.errors.number')
    .moreThan(-1, 'global.form.errors.negativeNumber')
    .test({
      message: 'global.form.errors.maxValue',
      test: function test(value) {
        const { leaseRentWithCharges } = this.parent;
        return !leaseRentWithCharges || value <= leaseRentWithCharges;
      },
    })
    .required('global.form.errors.required')
    .test(
      'is-decimal',
      'global.form.errors.twoDecimalPlaces',
      (value) => !value || twoDecimalPlaces(value),
    ),
  recurrent: yup.boolean(),
  date: yup.date().required('global.form.errors.required').nullable(),
});

export const housingBenefitsImportInitialValues = {
  rows: [],
};

export const housingBenefitsImportSchema = yup.object().shape({
  rows: yup.array().of(
    yup.object().shape({
      tenant: yup.object().shape({}).nullable()
        .when('status', {
          is: (val) => val !== 'rejected',
          then: yup.object().required('global.form.errors.required').nullable(),
        }),
      lease: yup.string().nullable()
        .when('status', {
          is: (val) => val !== 'rejected',
          then: yup.string().required('global.form.errors.required').nullable(),
        }),
      status: yup.string().oneOf(['accepted', 'rejected', null]).required('global.form.errors.required').nullable(),
      amount: yup
        .number()
        .typeError('global.form.errors.number')
        .moreThan(-1, 'global.form.errors.negativeNumber')
        .test({
          message: 'global.form.errors.maxValue',
          test: function test(value) {
            const { leaseRentWithCharges } = this.parent;
            return !leaseRentWithCharges || value <= leaseRentWithCharges;
          },
        })
        .required('global.form.errors.required')
        .test(
          'is-decimal',
          'global.form.errors.twoDecimalPlaces',
          (value) => !value || twoDecimalPlaces(value),
        ),
      recurrent: yup.boolean().nullable(),
    }),
  ),
});
