/* eslint-disable max-len */
import * as yup from 'yup';

// import addressSchema from './addressSchema';

export const bankingIdentityInitialValues = {
  label: '', // Titre
  firstName: '', // Prénom
  lastName: '', // Nom
  iban: '', // IBAN
  address: null, // Adresse
};

export const bankingIdentitySchema = yup.object().shape({
  label: yup.string().required('global.form.errors.required'),
  firstName: yup.string().matches(/^[^0-9]*$/, 'global.form.errors.invalid').required('global.form.errors.required'),
  lastName: yup.string().matches(/^[^0-9]*$/, 'global.form.errors.invalid').required('global.form.errors.required'),
  iban: yup.string()
    .length(27, 'global.form.errors.iban')
    .test({
      message: 'global.form.errors.ibanFormat',
      test: function test(value) {
        return value?.length > 0 ? value.startsWith('FR') : true;
      },
    })
    .required('global.form.errors.required')
    .nullable(),
  // address: addressSchema.required('global.form.errors.required').nullable(),
  address: yup.object().shape({
    street: yup.string().required('global.form.errors.required'),
    streetNumber: yup.string().nullable(),
    zipCode: yup.string().required('global.form.errors.required'),
    city: yup.string().required('global.form.errors.required'),
  }).required('global.form.errors.required').nullable(),
});
