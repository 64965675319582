const getDocsPercent = (docs = {}) => {
  docs = Object.values(docs ?? {});
  const { length } = docs;
  if (length <= 0) return 0;
  const docsCompleted = docs.reduce((acc, value) => acc + (value ? 1 : 0), 0);
  const percent = Math.floor((docsCompleted / length) * 100);
  return percent;
};

const getOwnersPercent = ({ ownersCount, ownersList = [] }) => {
  if (ownersCount) {
    if (ownersList?.some((el) => el.underPendingInvitation === false)) return 100;
    return 50;
  }
  return 0;
};

const getCompanyPercent = ({
  companyInformations,
  documents,
  validated,
  isUserRealEstateManager,
  ownersPercent,
}) => {
  let percent = 0;
  if (companyInformations) percent += 30;
  const docsPercent = getDocsPercent(documents);
  if (docsPercent > 0) {
    percent += isUserRealEstateManager ? 15 : 25;
  }
  if (docsPercent === 100) {
    percent += isUserRealEstateManager ? 15 : 25;
  }
  if (validated) percent += 20;
  if (isUserRealEstateManager) {
    if (ownersPercent > 0) percent += 10;
    if (ownersPercent > 50) percent += 10;
  }
  return percent;
};

const formatCompaniesCompletion = ({
  // completionPercentage,
  companyPercent,
  ownersPercent,
  t,
  navigate,
  COMPLETION_LIST_WIDTH,
  isUserRealEstateManager,
  documents,
  validated,
}) => {
  const generateDocSteps = (docs = {}) => Object.entries(docs ?? {}).map(([key, value]) => ({
    id: key,
    label: t(`dashboard.completion.profile.step3.docTypes.${key}`),
    completion: t(`dashboard.completion.${value ? 'finished' : 'waiting'}`),
    completed: value,
  }));

  const generateItems = () => {
    const steps = [
      {
        id: 1,
        label: t('dashboard.completion.company.step1.label'),
        checked: companyPercent > 0,
        progression: companyPercent > 0 ? 100 : 0,
        onClick: () => navigate('/company/add'),
      },
      {
        id: 2,
        label: t('dashboard.completion.company.step2.label'),
        checked: documents && Object.values(documents).length > 0 && !!Object.values(documents).every((doc) => doc),
        progression: getDocsPercent(documents),
        onClick: () => navigate('/company'),
        steps: generateDocSteps(documents),
      },
      {
        id: 3,
        label: t('dashboard.completion.company.step3.label'),
        checked: !!validated,
        progression: validated ? 100 : 0,
        onClick: () => navigate('/company'),
      },
    ];
    if (isUserRealEstateManager) {
      steps.push({
        id: 4,
        label: t('dashboard.completion.company.step4.label'),
        checked: ownersPercent > 50,
        progression: ownersPercent > 50 ? 100 : 0,
        onClick: () => navigate('/owner/add'),
        steps: [
          {
            id: 1,
            label: t('dashboard.completion.company.step4.level1'),
            completion: t(`dashboard.completion.${ownersPercent > 0 ? 'finished' : 'waiting'}`),
            completed: ownersPercent > 0,
          },
          {
            id: 2,
            label: t('dashboard.completion.company.step4.level2'),
            completion: t(`dashboard.completion.${ownersPercent > 50 ? 'finished' : 'waiting'}`),
            completed: ownersPercent > 50,
          },
        ],
      });
    }
    return steps;
  };

  return ({
    id: 2,
    title: t('dashboard.completion.company.title'),
    progression: companyPercent,
    toggleLabel: t('dashboard.completion.details'),
    subtitle: t('dashboard.completion.company.subtitle', { count: 2 }),
    width: COMPLETION_LIST_WIDTH,
    // waiting: completionPercentage !== 100,
    disabled: companyPercent === 100,
    items: generateItems(),
  });
};

export {
  getCompanyPercent,
  formatCompaniesCompletion,
  getOwnersPercent,
};
