/* eslint-disable react/no-array-index-key */
/* eslint-disable no-return-assign */
import {
  useCallback, useMemo, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useOutletContext, useNavigate } from 'react-router-dom';

// Components
import {
  Button,
  SelectInput,
  TextAreaInput,
  utils,
} from 'ui-library-unlocker';
import QualityPicker from '../../../components/molecules/QualityPicker/QualityPicker';
import FurnitureList from './FurnitureList';
import EquipmentList from './EquipmentList';
import PhotoSection from './PhotoSection';
import ConfirmationModal from '../../../components/organisms/ConfirmationModal/ConfirmationModal';

// Hooks
import usePrevious from '../../../hooks/usePrevious';
import useScroll from '../../../hooks/useScroll';
import useResponsive from '../../../hooks/useResponsive';

// Utils
import { displayError } from '../../../utils/forms/form';
import { showModal, hideModal } from '../../../utils/modal';

// Constants
import { INVENTORY_ROOMS } from '../../../utils/constants';

// Styles
import styles from './InventoryRooms.module.scss';

const DELETE_CONFIRM_MODAL_ID = 'inventory-room-delete-confirm';

function Room({
  typeOptions,
  formikKey,
  data,
  sameTypeBeforeCount,
  isCollapsed,
  toggleCollapse,
  handleRemoveFromList,
  roomIndex,
}) {
  const { t } = useTranslation();
  const { formik, updateInventoryMutation, isReadOnly } = useOutletContext();

  const handleBlur = useCallback((e) => {
    formik.handleBlur(e);
    if (formik.values.uid) {
      updateInventoryMutation.mutate(formik.values);
    }
  }, [formik, updateInventoryMutation]);

  const autoSaveOnChange = useCallback((field, value) => {
    formik.setFieldValue(`${formikKey}.${field}`, value);
    let newEquipments = data.equipments;
    if (field === 'type') {
      const equipments = INVENTORY_ROOMS.find((room) => room.type === value)?.equipments || {};
      newEquipments = Object.keys(equipments).map((equipment) => ({
        type: equipment,
        score: data.equipments?.find((e) => e.type === equipment)?.score || null,
        isChecked: true,
      }));
      formik.setFieldValue(`${formikKey}.equipments`, newEquipments);
    }
    updateInventoryMutation.mutate({
      ...formik.values,
      rooms: formik.values.rooms?.map((room, index) => (index === roomIndex
        ? {
          ...room,
          [field]: value,
          equipments: newEquipments,
        }
        : room
      )),
    });
  }, [formik, formikKey, updateInventoryMutation, roomIndex, data.equipments]);

  return (
    <div
      id={formikKey.replace(/\./g, '-')}
      className={styles.room}
    >
      <div className={styles.heading}>
        <h2 className={styles.title}>
          {
            typeOptions.find((typeItem) => typeItem.value === data.type)?.label
            || t('inventory.crud.rooms.new')
          }
          {sameTypeBeforeCount > 0 ? ` (${sameTypeBeforeCount + 1})` : ''}
        </h2>
        {formik.values.rooms?.length > 1 && !isReadOnly && (
          <Button
            variant="danger"
            icon="trash"
            onClick={handleRemoveFromList}
          />
        )}
      </div>
      <SelectInput
        className="m-t-25"
        id={`${formikKey}.type`}
        name={`${formikKey}.type`}
        label={t('inventory.crud.rooms.type')}
        error={displayError(t, formik, `${formikKey}.type`, null, { nestedValue: true })}
        options={typeOptions}
        onChange={(value) => autoSaveOnChange('type', value.value)}
        onBlur={formik.handleBlur}
        value={typeOptions.find((typeItem) => typeItem.value === data.type) || null}
        disabled={isReadOnly}
      />
      <QualityPicker
        className={utils.cn([styles.score, 'm-t-25', 'input'])}
        value={data.score}
        onChange={(value) => autoSaveOnChange('score', value)}
        error={displayError(t, formik, `${formikKey}.score`, null, { nestedValue: true })}
        disabled={isReadOnly}
      />
      <TextAreaInput
        className="m-t-25"
        id={`${formikKey}.comment`}
        name={`${formikKey}.comment`}
        label={`${t('inventory.crud.rooms.comment')} ${t('global.form.optional')}`}
        onChange={(e) => formik.setFieldValue(`${formikKey}.comment`, e.target.value || null)}
        onBlur={handleBlur}
        value={data.comment}
        error={displayError(t, formik, `${formikKey}.comment`, null, { nestedValue: true })}
        disabled={isReadOnly}
      />
      <EquipmentList
        room={data}
        roomIndex={roomIndex}
        formikKey={`${formikKey}.equipments`}
      />
      <FurnitureList
        data={data.furniture}
        formikKey={`${formikKey}.furniture`}
        isCollapsed={isCollapsed}
        toggleCollapse={toggleCollapse}
        roomIndex={roomIndex}
      />
      <PhotoSection
        title={t(`inventory.crud.rooms.photos.title.${data.type || 'default'}`)}
        id={`${formikKey}.pictures`}
        data={data}
        formik={formik}
        formikKey={formikKey}
        updateInventoryMutation={updateInventoryMutation}
      />
    </div>
  );
}

Room.propTypes = {
  typeOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  formikKey: PropTypes.string.isRequired,
  data: PropTypes.shape({
    type: PropTypes.string,
    score: PropTypes.number,
    comment: PropTypes.string,
    equipments: PropTypes.arrayOf(PropTypes.shape({})),
    furniture: PropTypes.arrayOf(PropTypes.shape({})),
    pictures: PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        documentUid: PropTypes.string,
      }),
    ])),
  }).isRequired,
  sameTypeBeforeCount: PropTypes.number,
  isCollapsed: PropTypes.bool,
  toggleCollapse: PropTypes.func,
  handleRemoveFromList: PropTypes.func,
  roomIndex: PropTypes.number.isRequired,
};

Room.defaultProps = {
  typeOptions: [],
  sameTypeBeforeCount: 0,
  isCollapsed: false,
  toggleCollapse: () => {},
  handleRemoveFromList: () => {},
};

function InventoryRooms() {
  const { t } = useTranslation();
  const {
    formik,
    updateInventoryMutation,
    isReadOnly,
    collapsedFurniture,
    setCollapsedFurniture,
  } = useOutletContext();
  const navigate = useNavigate();
  const { scrollToElement } = useScroll();
  const previousRoomsCount = usePrevious(formik.values.rooms?.length);
  const { isMobile } = useResponsive();
  const [indexToDelete, setIndexToDelete] = useState(null);

  const typeOptions = useMemo(() => INVENTORY_ROOMS.map((room) => ({
    label: room.label,
    value: room.type,
  })), []);

  const handleAddRoom = useCallback(() => {
    const newRooms = [
      ...(formik.values.rooms || []),
      {
        type: 'undefined',
        score: null,
        comment: null,
        equipments: [],
        furniture: [],
      },
    ];
    formik.setFieldValue('rooms', newRooms);
    setCollapsedFurniture((prev) => [...prev, false]);
    updateInventoryMutation.mutate({
      ...formik.values,
      rooms: newRooms,
    });
  }, [formik, setCollapsedFurniture, updateInventoryMutation]);

  const handleDelete = useCallback((index) => {
    setIndexToDelete(index);
    showModal(DELETE_CONFIRM_MODAL_ID);
  }, []);

  const cancelDelete = useCallback(() => {
    setIndexToDelete(null);
    hideModal(DELETE_CONFIRM_MODAL_ID);
  }, []);

  const handleRemoveFromList = useCallback(() => {
    const newRooms = formik.values.rooms?.filter((_, index) => index !== indexToDelete);
    formik.setFieldValue('rooms', newRooms);
    setCollapsedFurniture((prev) => prev.filter((_, index) => index !== indexToDelete));
    updateInventoryMutation.mutate({
      ...formik.values,
      rooms: newRooms,
    });
    cancelDelete();
  }, [formik, setCollapsedFurniture, indexToDelete, cancelDelete, updateInventoryMutation]);

  const handleCollapse = useCallback((index) => {
    setCollapsedFurniture((prev) => prev.map((value, i) => (i === index ? !value : value)));
  }, [setCollapsedFurniture]);

  const renderRooms = useCallback(() => {
    if (formik.values.rooms) {
      return formik.values.rooms.map((room, index) => {
        const sameTypeBeforeCount = formik.values.rooms
          .slice(0, index)
          .filter((r) => r.type === room.type).length;
        return (
          <Room
            key={index}
            typeOptions={typeOptions}
            formikKey={`rooms.${index}`}
            roomIndex={index}
            data={room}
            sameTypeBeforeCount={sameTypeBeforeCount}
            isCollapsed={collapsedFurniture[index]}
            toggleCollapse={() => handleCollapse(index)}
            handleRemoveFromList={() => handleDelete(index)}
          />
        );
      });
    }
    return null;
  }, [formik.values.rooms, typeOptions, collapsedFurniture, handleCollapse, handleDelete]);

  useEffect(() => {
    const roomsInDOM = [];
    formik.values.rooms?.forEach((_, index) => {
      const id = `rooms-${index}`;
      const element = document.getElementById(id);
      if (element) {
        roomsInDOM.push({
          id,
          top: element.offsetTop,
          bottom: element.offsetTop + element.offsetHeight,
        });
      }
    });
    function handleScroll() {
      const inventoryContent = document.getElementById('inventory-edit');
      const { scrollTop, offsetHeight } = inventoryContent;
      roomsInDOM.forEach((room) => {
        const newHash = `#${room.id}`;
        const offset = offsetHeight / 2;
        if (
          scrollTop >= (room.top - offset)
          && scrollTop < (room.bottom - offset)
          && window.location.hash !== newHash
        ) {
          navigate(newHash);
        }
      });
    }
    document.getElementById('inventory-edit')?.addEventListener('scroll', handleScroll);
    return () => {
      document.getElementById('inventory-edit')?.removeEventListener('scroll', handleScroll);
    };
  }, [
    // if something changes in the rooms, it could change the div height (e.g. furniture)
    formik.values.rooms,
    navigate,
    collapsedFurniture,
  ]);

  useEffect(() => {
    if (formik.values.rooms?.length > previousRoomsCount) {
      scrollToElement(`rooms-${formik.values.rooms.length - 1}`, 'instant');
    }
  }, [formik.values.rooms?.length]);

  return (
    <form
      id="inventory-rooms"
      className={styles.form}
    >
      {renderRooms()}
      <div className={styles.addRoomBtn}>
        {!isReadOnly && (
          <Button
            variant="primary"
            onClick={handleAddRoom}
            label={t('inventory.crud.rooms.add')}
            icon="plus"
            iconSide="left"
            size={isMobile ? 'medium' : 'large'}
            disabled={!formik.values.propertyUid}
          />
        )}
      </div>
      <ConfirmationModal
        id={DELETE_CONFIRM_MODAL_ID}
        onSubmit={handleRemoveFromList}
        onCancel={cancelDelete}
      />
    </form>
  );
}

export default InventoryRooms;
