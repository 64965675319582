const getLotDocsCompletion = (hasPropertyTax, hasPropertyDeed) => {
  let docPercent = 0;
  if (hasPropertyTax && hasPropertyDeed) docPercent = 100;
  else if (hasPropertyTax || hasPropertyDeed) docPercent = 50;

  return {
    docPercent,
  };
};

const getLotCaractCompletion = (hasGES, hasDPE) => {
  let caractPercent = 0;
  if (hasGES && hasDPE) caractPercent = 100;
  else if (hasGES || hasDPE) caractPercent = 50;

  return {
    caractPercent,
  };
};

const getLotPercent = ({
  propertiesCount,
  hasPictures,
  hasPropertyDeed,
  hasPropertyTax,
  hasGES,
  hasDPE,
}) => {
  if (propertiesCount) {
    let percent = 25;

    if (hasPropertyDeed && hasPropertyTax && hasPictures && hasGES && hasDPE) return 100;

    if (hasGES) percent += 12;
    if (hasDPE) percent += 13;
    if (hasPropertyDeed) percent += 15;
    if (hasPropertyTax) percent += 15;
    if (hasPictures) percent += 15;

    return percent;
  }

  return 0;
};

const formatLotCompletion = ({
  // prevPercentage,
  lotPercent,
  t,
  navigate,
  COMPLETION_LIST_WIDTH,
  index,
  hasPropertyTax,
  hasPropertyDeed,
  hasPictures,
  hasGES,
  hasDPE,
}) => {
  const { docPercent } = getLotDocsCompletion(hasPropertyTax, hasPropertyDeed);
  const { caractPercent } = getLotCaractCompletion(hasGES, hasDPE);

  return ({
    id: 3,
    title: t('dashboard.completion.lot.title'),
    progression: lotPercent,
    toggleLabel: t('dashboard.completion.details'),
    subtitle: t('dashboard.completion.lot.subtitle', { count: index }),
    width: COMPLETION_LIST_WIDTH,
    // waiting: prevPercentage !== 100,
    disabled: lotPercent === 100,
    items: [
      {
        id: 1,
        label: t('dashboard.completion.lot.step1.label'),
        checked: lotPercent > 0,
        progression: lotPercent > 0 ? 100 : 0,
        onClick: () => navigate('/property/add'),
      },
      {
        id: 2,
        label: t('dashboard.completion.lot.step2.label'),
        checked: hasGES && hasDPE,
        progression: caractPercent,
        onClick: () => navigate('/property'),
        steps: [
          {
            id: 1,
            label: t('dashboard.completion.lot.step2.level1'),
            completion: t(`dashboard.completion.${hasGES ? 'finished' : 'waiting'}`),
            completed: hasGES,
          },
          {
            id: 2,
            label: t('dashboard.completion.lot.step2.level2'),
            completion: t(`dashboard.completion.${hasDPE ? 'finished' : 'waiting'}`),
            completed: hasDPE,
          },
        ],
      },
      {
        id: 3,
        label: t('dashboard.completion.lot.step3.label'),
        checked: hasPropertyTax && hasPropertyDeed,
        progression: docPercent,
        onClick: () => navigate('/property'),
        steps: [
          {
            id: 1,
            label: t('dashboard.completion.lot.step3.level1'),
            completion: t(`dashboard.completion.${hasPropertyTax ? 'finished' : 'waiting'}`),
            completed: hasPropertyTax,
          },
          {
            id: 2,
            label: t('dashboard.completion.lot.step3.level2'),
            completion: t(`dashboard.completion.${hasPropertyDeed ? 'finished' : 'waiting'}`),
            completed: hasPropertyDeed,
          },
        ],
      },
      {
        id: 4,
        label: t('dashboard.completion.lot.step4.label'),
        checked: hasPictures,
        progression: hasPictures ? 100 : 0,
        onClick: () => navigate('/property'),
      },
    ],
  });
};

export {
  getLotPercent,
  formatLotCompletion,
};
