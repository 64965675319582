export const COUNTRIES = {
  AF: 'Afghanistan',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctica',
  AG: 'Antigua and Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas (the)',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia (Plurinational State of)',
  BQ: 'Bonaire, Sint Eustatius and Saba',
  BA: 'Bosnia and Herzegovina',
  BW: 'Botswana',
  BV: 'Bouvet Island',
  BR: 'Brazil',
  IO: 'British Indian Ocean Territory (the)',
  BN: 'Brunei Darussalam',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  CV: 'Cabo Verde',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada',
  KY: 'Cayman Islands (the)',
  CF: 'Central African Republic (the)',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CX: 'Christmas Island',
  CC: 'Cocos (Keeling) Islands (the)',
  CO: 'Colombia',
  KM: 'Comoros (the)',
  CD: 'Congo (the Democratic Republic of the)',
  CG: 'Congo (the)',
  CK: 'Cook Islands (the)',
  CR: 'Costa Rica',
  HR: 'Croatia',
  CU: 'Cuba',
  CW: 'Curaçao',
  CY: 'Cyprus',
  CZ: 'Czechia',
  CI: "Côte d'Ivoire",
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic (the)',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  SZ: 'Eswatini',
  ET: 'Ethiopia',
  FK: 'Falkland Islands (the) [Malvinas]',
  FO: 'Faroe Islands (the)',
  FJ: 'Fiji',
  FI: 'Finland',
  FR: 'France',
  GF: 'French Guiana',
  PF: 'French Polynesia',
  TF: 'French Southern Territories (the)',
  GA: 'Gabon',
  GM: 'Gambia (the)',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HM: 'Heard Island and McDonald Islands',
  VA: 'Holy See (the)',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Iran (Islamic Republic of)',
  IQ: 'Iraq',
  IE: 'Ireland',
  IM: 'Isle of Man',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  JE: 'Jersey',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KP: "Korea (the Democratic People's Republic of)",
  KR: 'Korea (the Republic of)',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: "Lao People's Democratic Republic (the)",
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MO: 'Macao',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshall Islands (the)',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexico',
  FM: 'Micronesia (Federated States of)',
  MD: 'Moldova (the Republic of)',
  MC: 'Monaco',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Netherlands (the)',
  NC: 'New Caledonia',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger (the)',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Norfolk Island',
  MP: 'Northern Mariana Islands (the)',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestine, State of',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines (the)',
  PN: 'Pitcairn',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  MK: 'Republic of North Macedonia',
  RO: 'Romania',
  RU: 'Russian Federation (the)',
  RW: 'Rwanda',
  RE: 'Réunion',
  BL: 'Saint Barthélemy',
  SH: 'Saint Helena, Ascension and Tristan da Cunha',
  KN: 'Saint Kitts and Nevis',
  LC: 'Saint Lucia',
  MF: 'Saint Martin (French part)',
  PM: 'Saint Pierre and Miquelon',
  VC: 'Saint Vincent and the Grenadines',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Sao Tome and Principe',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SX: 'Sint Maarten (Dutch part)',
  SK: 'Slovakia',
  SI: 'Slovenia',
  SB: 'Solomon Islands',
  SO: 'Somalia',
  ZA: 'South Africa',
  GS: 'South Georgia and the South Sandwich Islands',
  SS: 'South Sudan',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SD: 'Sudan (the)',
  SR: 'Suriname',
  SJ: 'Svalbard and Jan Mayen',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'Syrian Arab Republic',
  TW: 'Taiwan',
  TJ: 'Tajikistan',
  TZ: 'Tanzania, United Republic of',
  TH: 'Thailand',
  TL: 'Timor-Leste',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad and Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  TC: 'Turks and Caicos Islands (the)',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates (the)',
  GB: 'United Kingdom of Great Britain and Northern Ireland (the)',
  UM: 'United States Minor Outlying Islands (the)',
  US: 'United States of America (the)',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VE: 'Venezuela (Bolivarian Republic of)',
  VN: 'Viet Nam',
  VG: 'Virgin Islands (British)',
  VI: 'Virgin Islands (U.S.)',
  WF: 'Wallis and Futuna',
  EH: 'Western Sahara',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
  AX: 'Åland Islands',
};

export const NATIONALITIES = {
  AF: 'Afghan',
  AL: 'Albanian',
  DZ: 'Algerian',
  AS: 'American Samoan',
  AD: 'Andorran',
  AO: 'Angolan',
  AI: 'Anguillan',
  AQ: 'Antarctic',
  AG: 'Antiguan and Barbudan',
  AR: 'Argentinian',
  AM: 'Armenian',
  AW: 'Aruban',
  AU: 'Australian',
  AT: 'Austrian',
  AZ: 'Azerbaijani',
  BS: 'Bahamian',
  BH: 'Bahraini',
  BD: 'Bangladeshi',
  BB: 'Barbadian',
  BY: 'Belarusian',
  BE: 'Belgian',
  BZ: 'Belizean',
  BJ: 'Beninese',
  BM: 'Bermudian',
  BT: 'Bhutanese',
  BO: 'Bolivian',
  BQ: 'Bonaire, Sint Eustatius and Saba',
  BA: 'Bosnian and Herzegovinian',
  BW: 'Motswana',
  BV: 'Bouvet Island',
  BR: 'Brazilian',
  IO: 'British Indian Ocean Territory',
  BN: 'Bruneian',
  BG: 'Bulgarian',
  BF: 'Burkinabé',
  BI: 'Burundian',
  CV: 'Cabo Verdean',
  KH: 'Cambodian',
  CM: 'Cameroonian',
  CA: 'Canadian',
  KY: 'Caymanian',
  CF: 'Central African',
  TD: 'Chadian',
  CL: 'Chilean',
  CN: 'Chinese',
  CX: 'Christmas Island',
  CC: 'Cocos (Keeling) Islands',
  CO: 'Colombian',
  KM: 'Comorian',
  CD: 'Congolese (Democratic Republic of the Congo)',
  CG: 'Congolese',
  CK: 'Cook Islands',
  CR: 'Costa Rican',
  HR: 'Croatian',
  CU: 'Cuban',
  CW: 'Curaçaoan',
  CY: 'Cypriot',
  CZ: 'Czech',
  CI: 'Ivorian',
  DK: 'Danish',
  DJ: 'Djiboutian',
  DM: 'Dominican',
  DO: 'Dominican',
  EC: 'Ecuadorian',
  EG: 'Egyptian',
  SV: 'Salvadoran',
  GQ: 'Equatorial Guinean',
  ER: 'Eritrean',
  EE: 'Estonian',
  SZ: 'Eswatini',
  ET: 'Ethiopian',
  FK: 'Falkland Islander',
  FO: 'Faroese',
  FJ: 'Fijian',
  FI: 'Finnish',
  FR: 'Française',
  GF: 'French Guianese',
  PF: 'French Polynesian',
  TF: 'French Southern Territories',
  GA: 'Gabonese',
  GM: 'Gambian',
  GE: 'Georgian',
  DE: 'German',
  GH: 'Ghanaian',
  GI: 'Gibraltar',
  GR: 'Greek',
  GL: 'Greenlandic',
  GD: 'Grenadian',
  GP: 'Guadeloupean',
  GU: 'Guamanian',
  GT: 'Guatemalan',
  GG: 'Guernsey',
  GN: 'Guinean',
  GW: 'Bissau-Guinean',
  GY: 'Guyanese',
  HT: 'Haitian',
  HM: 'Heard Island and McDonald Islands',
  VA: 'Vatican',
  HN: 'Honduran',
  HK: 'Hong Kong',
  HU: 'Hungarian',
  IS: 'Icelandic',
  IN: 'Indian',
  ID: 'Indonesian',
  IR: 'Iranian',
  IQ: 'Iraqi',
  IE: 'Irish',
  IM: 'Manx',
  IL: 'Israeli',
  IT: 'Italian',
  JM: 'Jamaican',
  JP: 'Japanese',
  JE: 'Jersey',
  JO: 'Jordanian',
  KZ: 'Kazakhstani',
  KE: 'Kenyan',
  KI: 'I-Kiribati',
  KP: 'North Korean',
  KR: 'South Korean',
  KW: 'Kuwaiti',
  KG: 'Kyrgyzstani',
  LA: 'Laotian',
  LV: 'Latvian',
  LB: 'Lebanese',
  LS: 'Basotho',
  LR: 'Liberian',
  LY: 'Libyan',
  LI: 'Liechtenstein',
  LT: 'Lithuanian',
  LU: 'Luxembourger',
  MO: 'Macanese',
  MG: 'Malagasy',
  MW: 'Malawian',
  MY: 'Malaysian',
  MV: 'Maldivian',
  ML: 'Malian',
  MT: 'Maltese',
  MH: 'Marshallese',
  MQ: 'Martinican',
  MR: 'Mauritanian',
  MU: 'Mauritian',
  YT: 'Mahoran',
  MX: 'Mexican',
  FM: 'Micronesian',
  MD: 'Moldovan',
  MC: 'Monegasque',
  MN: 'Mongolian',
  ME: 'Montenegrin',
  MS: 'Montserratian',
  MA: 'Moroccan',
  MZ: 'Mozambican',
  MM: 'Burmese',
  NA: 'Namibian',
  NR: 'Nauruan',
  NP: 'Nepali',
  NL: 'Dutch',
  NC: 'New Caledonian',
  NZ: 'New Zealand',
  NI: 'Nicaraguan',
  NE: 'Nigerien',
  NG: 'Nigerian',
  NU: 'Niuean',
  NF: 'Norfolk Island',
  MP: 'Northern Marianan',
  NO: 'Norwegian',
  OM: 'Omani',
  PK: 'Pakistani',
  PW: 'Palauan',
  PS: 'Palestinian',
  PA: 'Panamanian',
  PG: 'Papua New Guinean',
  PY: 'Paraguayan',
  PE: 'Peruvian',
  PH: 'Filipino',
  PN: 'Pitcairn Islander',
  PL: 'Polish',
  PT: 'Portuguese',
  PR: 'Puerto Rican',
  QA: 'Qatari',
  MK: 'Macedonian',
  RO: 'Romanian',
  RU: 'Russian',
  RW: 'Rwandan',
  RE: 'Réunionese',
  BL: 'Saint-Barthélemoise',
  SH: 'Saint Helenian',
  KN: 'Kittitian and Nevisian',
  LC: 'Saint Lucian',
  MF: 'Saint-Martinoise',
  PM: 'Saint Pierre and Miquelon',
  VC: 'Saint Vincentian',
  WS: 'Samoan',
  SM: 'Sammarinese',
  ST: 'São Toméan',
  SA: 'Saudi Arabian',
  SN: 'Senegalese',
  RS: 'Serbian',
  SC: 'Seychellois',
  SL: 'Sierra Leonean',
  SG: 'Singaporean',
  SX: 'Sint Maartener',
  SK: 'Slovak',
  SI: 'Slovenian',
  SB: 'Solomon Islander',
  SO: 'Somali',
  ZA: 'South African',
  GS: 'South Georgia and the South Sandwich Islands',
  SS: 'South Sudanese',
  ES: 'Spanish',
  LK: 'Sri Lankan',
  SD: 'Sudanese',
  SR: 'Surinamese',
  SJ: 'Svalbard and Jan Mayen',
  SE: 'Swedish',
  CH: 'Swiss',
  SY: 'Syrian',
  TW: 'Taiwanese',
  TJ: 'Tajikistani',
  TZ: 'Tanzanian',
  TH: 'Thai',
  TL: 'East Timorese',
  TG: 'Togolese',
  TK: 'Tokelauan',
  TO: 'Tongan',
  TT: 'Trinidadian or Tobagonian',
  TN: 'Tunisian',
  TR: 'Turkish',
  TM: 'Turkmen',
  TC: 'Turks and Caicos Islander',
  TV: 'Tuvaluan',
  UG: 'Ugandan',
  UA: 'Ukrainian',
  AE: 'Emirati',
  GB: 'British',
  UM: 'United States Minor Outlying Islands',
  US: 'American',
  UY: 'Uruguayan',
  UZ: 'Uzbekistani',
  VU: 'Ni-Vanuatu',
  VE: 'Venezuelan',
  VN: 'Vietnamese',
  VG: 'British Virgin Islander',
  VI: 'U.S. Virgin Islander',
  WF: 'Wallisian and Futunan',
  EH: 'Sahrawi',
  YE: 'Yemeni',
  ZM: 'Zambian',
  ZW: 'Zimbabwean',
  AX: 'Åland Islander',
};

export const DEPARTMENTS = [
  {
    nom: 'Ain',
    code: '01',
    codeRegion: '84',
  },
  {
    nom: 'Aisne',
    code: '02',
    codeRegion: '32',
  },
  {
    nom: 'Allier',
    code: '03',
    codeRegion: '84',
  },
  {
    nom: 'Alpes-de-Haute-Provence',
    code: '04',
    codeRegion: '93',
  },
  {
    nom: 'Hautes-Alpes',
    code: '05',
    codeRegion: '93',
  },
  {
    nom: 'Alpes-Maritimes',
    code: '06',
    codeRegion: '93',
  },
  {
    nom: 'Ardèche',
    code: '07',
    codeRegion: '84',
  },
  {
    nom: 'Ardennes',
    code: '08',
    codeRegion: '44',
  },
  {
    nom: 'Ariège',
    code: '09',
    codeRegion: '76',
  },
  {
    nom: 'Aube',
    code: '10',
    codeRegion: '44',
  },
  {
    nom: 'Aude',
    code: '11',
    codeRegion: '76',
  },
  {
    nom: 'Aveyron',
    code: '12',
    codeRegion: '76',
  },
  {
    nom: 'Bouches-du-Rhône',
    code: '13',
    codeRegion: '93',
  },
  {
    nom: 'Calvados',
    code: '14',
    codeRegion: '28',
  },
  {
    nom: 'Cantal',
    code: '15',
    codeRegion: '84',
  },
  {
    nom: 'Charente',
    code: '16',
    codeRegion: '75',
  },
  {
    nom: 'Charente-Maritime',
    code: '17',
    codeRegion: '75',
  },
  {
    nom: 'Cher',
    code: '18',
    codeRegion: '24',
  },
  {
    nom: 'Corrèze',
    code: '19',
    codeRegion: '75',
  },
  {
    nom: "Côte-d'Or",
    code: '21',
    codeRegion: '27',
  },
  {
    nom: "Côtes-d'Armor",
    code: '22',
    codeRegion: '53',
  },
  {
    nom: 'Creuse',
    code: '23',
    codeRegion: '75',
  },
  {
    nom: 'Dordogne',
    code: '24',
    codeRegion: '75',
  },
  {
    nom: 'Doubs',
    code: '25',
    codeRegion: '27',
  },
  {
    nom: 'Drôme',
    code: '26',
    codeRegion: '84',
  },
  {
    nom: 'Eure',
    code: '27',
    codeRegion: '28',
  },
  {
    nom: 'Eure-et-Loir',
    code: '28',
    codeRegion: '24',
  },
  {
    nom: 'Finistère',
    code: '29',
    codeRegion: '53',
  },
  {
    nom: 'Corse-du-Sud',
    code: '2A',
    codeRegion: '94',
  },
  {
    nom: 'Haute-Corse',
    code: '2B',
    codeRegion: '94',
  },
  {
    nom: 'Gard',
    code: '30',
    codeRegion: '76',
  },
  {
    nom: 'Haute-Garonne',
    code: '31',
    codeRegion: '76',
  },
  {
    nom: 'Gers',
    code: '32',
    codeRegion: '76',
  },
  {
    nom: 'Gironde',
    code: '33',
    codeRegion: '75',
  },
  {
    nom: 'Hérault',
    code: '34',
    codeRegion: '76',
  },
  {
    nom: 'Ille-et-Vilaine',
    code: '35',
    codeRegion: '53',
  },
  {
    nom: 'Indre',
    code: '36',
    codeRegion: '24',
  },
  {
    nom: 'Indre-et-Loire',
    code: '37',
    codeRegion: '24',
  },
  {
    nom: 'Isère',
    code: '38',
    codeRegion: '84',
  },
  {
    nom: 'Jura',
    code: '39',
    codeRegion: '27',
  },
  {
    nom: 'Landes',
    code: '40',
    codeRegion: '75',
  },
  {
    nom: 'Loir-et-Cher',
    code: '41',
    codeRegion: '24',
  },
  {
    nom: 'Loire',
    code: '42',
    codeRegion: '84',
  },
  {
    nom: 'Haute-Loire',
    code: '43',
    codeRegion: '84',
  },
  {
    nom: 'Loire-Atlantique',
    code: '44',
    codeRegion: '52',
  },
  {
    nom: 'Loiret',
    code: '45',
    codeRegion: '24',
  },
  {
    nom: 'Lot',
    code: '46',
    codeRegion: '76',
  },
  {
    nom: 'Lot-et-Garonne',
    code: '47',
    codeRegion: '75',
  },
  {
    nom: 'Lozère',
    code: '48',
    codeRegion: '76',
  },
  {
    nom: 'Maine-et-Loire',
    code: '49',
    codeRegion: '52',
  },
  {
    nom: 'Manche',
    code: '50',
    codeRegion: '28',
  },
  {
    nom: 'Marne',
    code: '51',
    codeRegion: '44',
  },
  {
    nom: 'Haute-Marne',
    code: '52',
    codeRegion: '44',
  },
  {
    nom: 'Mayenne',
    code: '53',
    codeRegion: '52',
  },
  {
    nom: 'Meurthe-et-Moselle',
    code: '54',
    codeRegion: '44',
  },
  {
    nom: 'Meuse',
    code: '55',
    codeRegion: '44',
  },
  {
    nom: 'Morbihan',
    code: '56',
    codeRegion: '53',
  },
  {
    nom: 'Moselle',
    code: '57',
    codeRegion: '44',
  },
  {
    nom: 'Nièvre',
    code: '58',
    codeRegion: '27',
  },
  {
    nom: 'Nord',
    code: '59',
    codeRegion: '32',
  },
  {
    nom: 'Oise',
    code: '60',
    codeRegion: '32',
  },
  {
    nom: 'Orne',
    code: '61',
    codeRegion: '28',
  },
  {
    nom: 'Pas-de-Calais',
    code: '62',
    codeRegion: '32',
  },
  {
    nom: 'Puy-de-Dôme',
    code: '63',
    codeRegion: '84',
  },
  {
    nom: 'Pyrénées-Atlantiques',
    code: '64',
    codeRegion: '75',
  },
  {
    nom: 'Hautes-Pyrénées',
    code: '65',
    codeRegion: '76',
  },
  {
    nom: 'Pyrénées-Orientales',
    code: '66',
    codeRegion: '76',
  },
  {
    nom: 'Bas-Rhin',
    code: '67',
    codeRegion: '44',
  },
  {
    nom: 'Haut-Rhin',
    code: '68',
    codeRegion: '44',
  },
  {
    nom: 'Rhône',
    code: '69',
    codeRegion: '84',
  },
  {
    nom: 'Haute-Saône',
    code: '70',
    codeRegion: '27',
  },
  {
    nom: 'Saône-et-Loire',
    code: '71',
    codeRegion: '27',
  },
  {
    nom: 'Sarthe',
    code: '72',
    codeRegion: '52',
  },
  {
    nom: 'Savoie',
    code: '73',
    codeRegion: '84',
  },
  {
    nom: 'Haute-Savoie',
    code: '74',
    codeRegion: '84',
  },
  {
    nom: 'Paris',
    code: '75',
    codeRegion: '11',
  },
  {
    nom: 'Seine-Maritime',
    code: '76',
    codeRegion: '28',
  },
  {
    nom: 'Seine-et-Marne',
    code: '77',
    codeRegion: '11',
  },
  {
    nom: 'Yvelines',
    code: '78',
    codeRegion: '11',
  },
  {
    nom: 'Deux-Sèvres',
    code: '79',
    codeRegion: '75',
  },
  {
    nom: 'Somme',
    code: '80',
    codeRegion: '32',
  },
  {
    nom: 'Tarn',
    code: '81',
    codeRegion: '76',
  },
  {
    nom: 'Tarn-et-Garonne',
    code: '82',
    codeRegion: '76',
  },
  {
    nom: 'Var',
    code: '83',
    codeRegion: '93',
  },
  {
    nom: 'Vaucluse',
    code: '84',
    codeRegion: '93',
  },
  {
    nom: 'Vendée',
    code: '85',
    codeRegion: '52',
  },
  {
    nom: 'Vienne',
    code: '86',
    codeRegion: '75',
  },
  {
    nom: 'Haute-Vienne',
    code: '87',
    codeRegion: '75',
  },
  {
    nom: 'Vosges',
    code: '88',
    codeRegion: '44',
  },
  {
    nom: 'Yonne',
    code: '89',
    codeRegion: '27',
  },
  {
    nom: 'Territoire de Belfort',
    code: '90',
    codeRegion: '27',
  },
  {
    nom: 'Essonne',
    code: '91',
    codeRegion: '11',
  },
  {
    nom: 'Hauts-de-Seine',
    code: '92',
    codeRegion: '11',
  },
  {
    nom: 'Seine-Saint-Denis',
    code: '93',
    codeRegion: '11',
  },
  {
    nom: 'Val-de-Marne',
    code: '94',
    codeRegion: '11',
  },
  {
    nom: "Val-d'Oise",
    code: '95',
    codeRegion: '11',
  },
  {
    nom: 'Guadeloupe',
    code: '971',
    codeRegion: '01',
  },
  {
    nom: 'Martinique',
    code: '972',
    codeRegion: '02',
  },
  {
    nom: 'Guyane',
    code: '973',
    codeRegion: '03',
  },
  {
    nom: 'La Réunion',
    code: '974',
    codeRegion: '04',
  },
  {
    nom: 'Saint-Pierre-et-Miquelon',
    code: '975',
    codeRegion: '05',
  },
  {
    nom: 'Mayotte',
    code: '976',
    codeRegion: '06',
  },
  {
    nom: 'Afrique et Terres Australes Françaises',
    code: '984',
    codeRegion: '07',
  },
  {
    nom: 'Wallis-et-Futuna',
    code: '986',
    codeRegion: '08',
  },
  {
    nom: 'Polynésie Française',
    code: '987',
    codeRegion: '09',
  },
  {
    nom: 'Nouvelle Calédonie',
    code: '988',
    codeRegion: '10',
  },
];

export const globalDepartments = [

];
