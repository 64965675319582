import * as yup from 'yup';

export const emailLoginInfoInitialValues = {
  email: '',
};

export const passwordLoginInfoInitialValues = {
  previousPassword: '',
  proposedPassword: '',
};

export const emailLoginInfoSchema = yup.object().shape({
  email: yup.string().email('global.form.errors.email').required('global.form.errors.required'),
});

export const passwordLoginInfoSchema = yup.object().shape({
  previousPassword: yup.string().required('global.form.errors.required'),
  proposedPassword: yup.string().required('global.form.errors.required'),
});
