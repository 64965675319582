import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';

// Components
import {
  utils,
} from 'ui-library-unlocker';
import PercentKPI from '../../../../components/molecules/PercentKPI/PercentKPI';

// Services
import {
  getCurrentMonthFees,
  getCurrentMonthUnlockerFees,
  getRentBalance,
} from '../../../../services/stats';

// Hooks
import useRoles from '../../../../hooks/useRoles';

// Styles
import styles from '../DashboardKPI.module.scss';

function Gauges() {
  const { t } = useTranslation();
  const { isUserRealEstateManager } = useRoles();

  const formatLabel = useCallback((value) => `${utils.centsToEuro(value).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} €`, []);

  const {
    data: currentMonthFees,
  } = useQuery({
    queryKey: ['month-lifetime-fees'],
    queryFn: getCurrentMonthFees,
    keepPreviousData: false,
    enabled: isUserRealEstateManager,
  });

  const {
    data: currentMonthUnlockerFees,
  } = useQuery({
    queryKey: ['current-month-unlocker-fees'],
    queryFn: getCurrentMonthUnlockerFees,
    keepPreviousData: false,
  });

  const {
    data: rentBalance,
  } = useQuery({
    queryKey: ['rent-balance'],
    queryFn: getRentBalance,
    keepPreviousData: false,
  });

  const currentMonthFeesData = useMemo(() => currentMonthFees?.data, [currentMonthFees]);
  const currentMonthFeesValue = useMemo(() => currentMonthFeesData?.currentMonthActualFees, [currentMonthFeesData]);
  const currentMonthFeesTotal = useMemo(() => (
    currentMonthFeesData?.currentMonthExpectedFees || 0
  ), [currentMonthFeesData]);
  const currentMonthProgressPercent = useMemo(() => (
    currentMonthFeesData?.monthlyProgressPercentage
  ), [currentMonthFeesData]);

  const currentMonthUnlockerFeesData = useMemo(() => currentMonthUnlockerFees?.data, [currentMonthUnlockerFees]);
  const currentMonthUnlockerFeesValue = useMemo(() => (
    currentMonthUnlockerFeesData?.currentMonthActualFees
  ), [currentMonthUnlockerFeesData]);
  const currentMonthUnlockerFeesTotal = useMemo(() => (
    currentMonthUnlockerFeesData?.currentMonthExpectedFees || 0
  ), [currentMonthUnlockerFeesData, currentMonthUnlockerFeesValue]);
  const currentMonthUnlockerProgressPercent = useMemo(() => (
    currentMonthUnlockerFeesData?.monthlyProgressPercentage
  ), [currentMonthUnlockerFeesData]);

  const rentBalanceData = useMemo(() => rentBalance?.data, [rentBalance]);
  const rentBalanceValue = useMemo(() => rentBalanceData?.totalAmountPaid, [rentBalanceData]);
  const rentBalanceTotal = useMemo(() => rentBalanceData?.totalAmountDue, [rentBalanceData]);
  const rentBalanceProgressPercent = useMemo(() => rentBalanceData?.yearlyProgressPercentage, [rentBalanceData]);

  return (
    <div className={styles.gauges}>
      {isUserRealEstateManager && (
        <PercentKPI
          value={currentMonthFeesValue}
          total={currentMonthFeesTotal}
          progressPercent={currentMonthProgressPercent}
          size={80}
          formatLabel={formatLabel}
          title={t('dashboard.kpi.percentGauges.fees')}
          color="var(--color-secondary)"
          subtext={t('dashboard.kpi.percentGauges.feesSubtext')}
          isLoading={!currentMonthFeesData}
        />
      )}
      <PercentKPI
        value={currentMonthUnlockerFeesValue}
        total={currentMonthUnlockerFeesTotal}
        progressPercent={currentMonthUnlockerProgressPercent}
        size={80}
        formatLabel={formatLabel}
        title={t('dashboard.kpi.percentGauges.unlockerFees')}
        color="var(--color-tertiary)"
        subtext={t('dashboard.kpi.percentGauges.unlockerFeesSubtext')}
        isLoading={!currentMonthUnlockerFeesData}
      />
      <PercentKPI
        total={rentBalanceTotal}
        value={rentBalanceValue}
        progressPercent={rentBalanceProgressPercent}
        size={80}
        formatLabel={formatLabel}
        title={t('dashboard.kpi.percentGauges.rentBalance')}
        color="var(--color-accent-green)"
        restColor="var(--color-accent-red)"
        showRestPercent
        subtext={t('dashboard.kpi.percentGauges.rentBalanceSubtext')}
        isLoading={!rentBalanceData}
        showProgressPercent={false}
      />
    </div>
  );
}

export default Gauges;
