import React, { useMemo, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components
import {
  utils,
  Picto,
} from 'ui-library-unlocker';
import PaymentsList from './PaymentsList/PaymentsList';
import PaymentsHistory from './PaymentsHistory/PaymentsHistory';

// Styles
import styles from './Payments.module.scss';

function Payments() {
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case '/payments/history':
        document.title = t('payment.tabs.history.browserTitle');
        break;
      default:
        document.title = t('payment.tabs.incoming.browserTitle');
        break;
    }
  }, [location, t]);

  const tabs = useMemo(() => [
    {
      label: t('payment.tabs.incoming.tabTitle'),
      title: t('payment.tabs.incoming.title'),
      hash: '/payments',
      activePicto: <Picto color="var(--color-secondary)" width="100%" icon="trace-3512" />,
      component: (
        <PaymentsList />
      ),
    },
    {
      label: t('payment.tabs.history.tabTitle'),
      title: t('payment.tabs.history.title'),
      hash: '/payments/history',
      activePicto: <Picto color="var(--color-secondary)" width="100%" icon="trace-3512" />,
      component: (
        <PaymentsHistory />
      ),
    },
  ], [t, location]);

  const currentTab = useMemo(() => {
    // const hash = location.hash.replace('#', '');
    const hash = location.pathname;
    const tab = tabs.find((cur) => cur.hash === hash);
    return tab || tabs[0];
  }, [tabs, location]);

  return (
    <div>
      <div className={styles.heading}>
        <h1>{t('payment.title')}</h1>
        <ul className={styles.tabList}>
          {tabs.map((tab) => {
            const isActive = tab.hash === currentTab.hash;

            return (
              <li className={utils.cn([styles.tabItem, isActive ? styles.active : null])} key={tab.hash}>
                <Link to={`${tab.hash}`}>{tab.label}</Link>
                {isActive && tab.activePicto}
              </li>
            );
          })}
        </ul>
      </div>
      <div className={styles.content}>
        {currentTab.component}
      </div>
    </div>
  );
}

export default Payments;
