import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Components
import {
  utils,
} from 'ui-library-unlocker';

// Styles
import styles from './QualityPicker.module.scss';

function QualityPicker({
  className,
  options,
  value,
  onChange,
  error,
  disabled,
}) {
  const { t } = useTranslation();

  const qualityOptions = useMemo(() => {
    if (options) return options;
    return [
      {
        label: t('global.form.score.new'),
        value: 1,
      },
      {
        label: t('global.form.score.good'),
        value: 2,
      },
      {
        label: t('global.form.score.used'),
        value: 3,
      },
      {
        label: t('global.form.score.poor'),
        value: 4,
      },
    ];
  }, [options]);

  const renderOptions = useCallback(() => qualityOptions.map((option) => (
    <button
      key={option.value}
      className={utils.cn([
        styles.option,
        value === option.value && styles.active,
      ])}
      onClick={() => (!disabled ? onChange?.(option.value) : null)}
      type="button"
    >
      {option.label}
    </button>
  )), [qualityOptions, value, onChange, disabled]);

  return (
    <div>
      <div
        className={utils.cn([styles.container, className])}
        aria-invalid={error !== null}
      >
        {renderOptions()}
      </div>
      {error && (
        <small className={styles.error}>{t('global.form.errors.required')}</small>
      )}
    </div>
  );
}

QualityPicker.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  })),
  value: PropTypes.number,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
};

QualityPicker.defaultProps = {
  className: '',
  options: null,
  value: null,
  onChange: null,
  error: false,
  disabled: false,
};

export default QualityPicker;
