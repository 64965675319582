import { useMediaQuery } from 'react-responsive';

const useResponsive = () => {
  const isMobileXs = useMediaQuery({ maxWidth: 320 });
  const isMobileS = useMediaQuery({ maxWidth: 375 });
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isTablet = useMediaQuery({ maxWidth: 1024 });
  const isLaptopXs = useMediaQuery({ maxWidth: 1220 });
  const isLaptopS = useMediaQuery({ maxWidth: 1390 });
  const isLaptop = useMediaQuery({ maxWidth: 1440 });
  const isDesktop = useMediaQuery({ maxWidth: 1920 });

  return {
    isMobileXs,
    isMobileS,
    isMobile,
    isTablet,
    isLaptopXs,
    isLaptopS,
    isLaptop,
    isDesktop,
  };
};

export default useResponsive;
