/* eslint-disable react/prop-types */
import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  $getRoot,
  $insertNodes,
  $createParagraphNode,
} from 'lexical';
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { ListItemNode, ListNode } from '@lexical/list';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { TRANSFORMERS } from '@lexical/markdown';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

import ToolbarPlugin from './plugins/ToolbarPlugin';
import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin';

import cn from '../../../utils/cn';

import Theme from './theme/theme';

import styles from './WysiwygInput.module.scss';

function Placeholder({ placeholder }) {
  return <div className="editor-placeholder">{placeholder}</div>;
}

function EditableHandler({ disabled }) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor.setEditable(!disabled);
  }, [disabled]);

  return null;
}

const editorConfig = {
  // The editor theme
  theme: Theme,
  // Handling of errors during update
  onError(error) {
    throw error;
  },
  // Any custom nodes go here
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode,
  ],
};

export default function WysiwygInput({
  label, onChange, defaultValue, className, error, valid, info, disabled,
}) {
  const onEditorChange = useCallback((_, editor) => {
    editor.update(() => {
      const raw = $generateHtmlFromNodes(editor, null);
      if (typeof onChange === 'function') onChange(raw/* ?.replace(/<b>|<\/b>|<br>/g, '') */);
    });
  }, []);

  return (
    <div className={cn([className || null])}>
      <LexicalComposer initialConfig={{
        ...editorConfig,
        editable: !disabled,
        editorState: (editor) => {
          editor.update(() => {
          // eslint-disable-next-line no-undef
            const parser = new DOMParser();
            const dom = parser.parseFromString(defaultValue || '', 'text/html');
            const nodes = $generateNodesFromDOM(editor, dom);

            const paragraph = $createParagraphNode();
            $getRoot().append(paragraph);

            // Select the root
            $getRoot().select();
            $insertNodes(nodes);
          });
        },
      }}
      >
        <div className={cn([
          'editor-container',
          error ? styles.error : null,
          valid ? styles.valid : null,
          disabled ? 'editor-disabled' : null,
        ])}
        >
          {!disabled && (
            <ToolbarPlugin label={label} />
          )}
          <OnChangePlugin onChange={onEditorChange} ignoreSelectionChange />
          <div className="editor-inner">
            <RichTextPlugin
              contentEditable={<ContentEditable className="editor-input" />}
              placeholder={<Placeholder placeholder={label} />}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <HistoryPlugin />
            <ListPlugin />
            <LinkPlugin />
            <ListMaxIndentLevelPlugin maxDepth={7} />
            <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
            <EditableHandler disabled={disabled} />
          </div>
        </div>
      </LexicalComposer>
      {error && typeof error === 'string' ? (
        <div className={cn([styles.context, styles.fieldError])}>
          {error}
        </div>
      ) : info && (
      <div className={cn([styles.context, styles.fieldInfo])}>
        {info}
      </div>
      )}
    </div>
  );
}

WysiwygInput.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  valid: PropTypes.bool,
  info: PropTypes.string,
  disabled: PropTypes.bool,
};

WysiwygInput.defaultProps = {
  onChange: null,
  defaultValue: null,
  className: null,
  error: null,
  valid: null,
  info: null,
  disabled: false,
};
