import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import LoginInfoForm from '../../components/organisms/forms/LoginInfoForm/LoginInfoForm';

import styles from './LoginInfo.module.scss';

function LoginInfo() {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('loginInfo.browserTitle');
  }, [t]);

  return (
    <div>
      <div className={styles.heading}>
        <h1>{t('loginInfo.title')}</h1>
      </div>
      <div className={styles.content}>
        <LoginInfoForm />
      </div>
    </div>
  );
}

export default LoginInfo;
