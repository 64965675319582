import { useEffect } from 'react';

const useInactivity = (callbackNoActivity, callbackActivityResumed, delay = 60000) => {
  useEffect(() => {
    let inactivityTimeout;
    let inactive = false;

    const resetTimeout = () => {
      if (inactive) {
        callbackActivityResumed?.();
      }
      inactive = false;
      clearTimeout(inactivityTimeout);
      inactivityTimeout = setTimeout(() => {
        inactive = true;
        callbackNoActivity?.();
      }, delay);
    };

    // Écoutez les événements qui indiquent l'activité de l'utilisateur
    window.addEventListener('mousemove', resetTimeout);
    window.addEventListener('keydown', resetTimeout);
    window.addEventListener('scroll', resetTimeout);

    // Réinitialisez le délai d'inactivité lorsque le composant est monté
    resetTimeout();

    return () => {
      // Nettoyez lorsque le composant est démonté
      window.removeEventListener('mousemove', resetTimeout);
      window.removeEventListener('keydown', resetTimeout);
      window.removeEventListener('scroll', resetTimeout);
      clearTimeout(inactivityTimeout);
    };
  }, []);
};

export default useInactivity;
