import { useState, useEffect } from 'react';

function useHover(ref) {
  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    const node = ref?.current;

    if (node) {
      const handleMouseEnter = () => setIsHovering(true);
      const handleMouseLeave = () => setIsHovering(false);

      node.addEventListener('mouseenter', handleMouseEnter);
      node.addEventListener('mouseleave', handleMouseLeave);

      return () => {
        node.removeEventListener('mouseenter', handleMouseEnter);
        node.removeEventListener('mouseleave', handleMouseLeave);
      };
    }

    return () => {};
  }, [ref]); // Re-run if ref changes

  return isHovering;
}

export default useHover;
