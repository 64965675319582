/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import Picto from '../Picto/Picto';

import cn from '../../../utils/cn';

import styles from './Tag.module.scss';

// onClose display a close icon if specified
const Tag = ({
  label,
  size,
  icon,
  iconSide,
  onClose,
  showCloseIcon,
  variant,
  className,
  wrap,
}) => {
  const getSizeClassName = () => {
    switch (size) {
      case 'small':
        return {
          tag: styles.small,
          icon: 11,
          closeIcon: 10,
        };
      case 'medium':
        return {
          tag: styles.medium,
          icon: 13,
          closeIcon: 11,
        };
      default:
        return {
          tag: styles.large,
          icon: 16,
          closeIcon: 13,
        };
    }
  };

  const getVariantClassName = () => {
    switch (variant) {
      case 'primary':
        return {
          tag: styles.primary,
          icon: 'var(--color-white)',
        };
      case 'secondary':
        return {
          tag: styles.secondary,
          icon: 'var(--color-white)',
        };
      case 'secondary-outline':
        return {
          tag: styles.secondaryOutline,
          icon: 'var(--color-secondary)',
        };
      case 'tertiary':
        return {
          tag: styles.tertiary,
          icon: 'var(--color-primary)',
        };
      case 'error':
        return {
          tag: styles.error,
          icon: 'var(--color-primary)',
        };
      case 'transparent':
        return {
          tag: styles.transparent,
          icon: 'var(--color-secondary)',
        };
      case 'white':
        return {
          tag: styles.white,
          icon: 'var(--color-secondary)',
        };
      case 'success':
        return {
          tag: styles.success,
          icon: 'var(--color-primary)',
        };
      default:
        return {
          tag: styles.secondary,
          icon: 'var(--color-white)',
        };
    }
  };

  return (
    <div className={cn([
      className,
      styles.tag,
      wrap ? styles.wrap : null,
      getSizeClassName().tag,
      getVariantClassName().tag,
    ])}
    >
      <div className={cn([styles.content, (iconSide === 'left' || showCloseIcon) ? styles.left : styles.right])}>
        {icon && (
        <div className={styles.icon}>
          <Picto
            color={getVariantClassName().icon}
            width={getSizeClassName().icon}
            icon={icon}
          />
        </div>
        )}
        <div className={styles.label}>
          {label}
        </div>
        {showCloseIcon ? (
          <div onClick={onClose} className={cn([styles.close, 'm-l-5'])}>
            <Picto
              color={getVariantClassName().icon}
              width={getSizeClassName().closeIcon}
              icon="close"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  icon: PropTypes.node,
  iconSide: PropTypes.oneOf(['left', 'right']),
  onClose: PropTypes.oneOfType([PropTypes.func]),
  showCloseIcon: PropTypes.bool,
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'secondary-outline',
    'tertiary',
    'error',
    'transparent',
    'success',
    'white',
  ]),
  className: PropTypes.string,
  wrap: PropTypes.bool,
};

Tag.defaultProps = {
  size: 'medium',
  icon: null,
  iconSide: 'left',
  onClose: null,
  showCloseIcon: false,
  variant: 'secondary',
  className: null,
  wrap: false,
};

export default Tag;
