import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { Picto, utils } from 'ui-library-unlocker';

import styles from './SideModal.module.scss';

function SideModal({
  open, onClose, title, children,
}) {
  return createPortal(
    <div>
      <div
        className={utils.cn([styles.overlay, open ? styles.overlayOpen : null])}
        tabIndex={-1}
        role="button"
        onClick={onClose}
        onKeyDown={onClose}
        aria-label="Close Modal"
      />
      <div className={utils.cn([styles.modal, open ? styles.modalOpen : null])}>
        {open ? (
          <div className={styles.content}>
            <div className={styles.header}>
              <h2>
                {title}
                <Picto className={styles.trace} color="var(--color-secondary)" width="100%" icon="trace-3472" />
              </h2>
              <button className={styles.close} aria-label="Close modal" onClick={onClose} type="button" />
            </div>
            {children}
          </div>
        ) : null}
      </div>
    </div>,
    document.querySelector('body'),
  );
}

SideModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default SideModal;
