/* eslint-disable max-len */
import { useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Picto, utils } from 'ui-library-unlocker';

import styles from './ComingFeature.module.scss';

function ComingFeature() {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('comingFeature.browserTitle');
  }, [t]);

  return (
    <div>
      <div className={styles.heading}>
        <h1>{t('comingFeature.title')}</h1>
      </div>
      <div className={styles.content}>
        <h2 className={utils.cn(['m-t-40 m-b-4'])}>
          {t('comingFeature.subtitle')}
        </h2>
        <Picto width={166} icon="trace-5208" color="var(--color-tertiary)" />
        <p className="m-t-40 p-1-700">{t('comingFeature.listHeading')}</p>
        <ul className={utils.cn(['m-t-20', styles.features])}>
          <li><Trans>{t('comingFeature.listElem0')}</Trans></li>
          <li>{t('comingFeature.listElem1')}</li>
          <li>{t('comingFeature.listElem2')}</li>
          <li>{t('comingFeature.listElem3')}</li>
          <li>{t('comingFeature.listElem4')}</li>
        </ul>
        <p className="m-t-20 p-1-700">{t('comingFeature.conclusion')}</p>
        <p className="m-t-20 p-1-500">{t('comingFeature.fromTeam')}</p>
        <img className={styles.mask} src="/images/appartment-1.png" alt="cover" />
      </div>
    </div>
  );
}

export default ComingFeature;
