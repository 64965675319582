import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Picto } from 'ui-library-unlocker';
import WithLogin from '../../components/templates/WithLogin/WithLogin';
import ForgotPasswordForm from '../../components/organisms/forms/ForgotPasswordForm/ForgotPasswordForm';
import DiscoverLink from '../../components/atoms/DiscoverLink/DiscoverLink';

import styles from './ForgotPassword.module.scss';

function ForgotPasswordView() {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('forgotPassword.browserTitle');
  }, [t]);

  const renderContentHeading = useCallback(() => (
    <div>
      <h1 className={styles.title}>
        {t('forgotPassword.title')}
      </h1>
    </div>
  ), []);

  const renderContentLeft = useCallback(() => (
    <div className={styles.contentLeft}>
      <div className={styles.imageBox}>
        <img className={styles.image} src="/images/jess.jpg" alt="cover" />
        <Picto
          className={styles.gribouillon}
          width={93}
          fill="var(--color-tertiary)"
          color="transparent"
          icon="gribouillon-6"
        />
        <Picto
          className={styles.dot}
          width={46}
          color="var(--color-secondary)"
          icon="point-moyen-900"
        />
      </div>
    </div>
  ), []);

  return (
    <WithLogin
      contentLeft={renderContentLeft()}
      contentHeading={renderContentHeading()}
    >
      <ForgotPasswordForm action={t('forgotPassword.subtitle')} />
      <div className="m-t-30 m-b-30 text-center">
        <DiscoverLink />
      </div>
    </WithLogin>
  );
}

export default ForgotPasswordView;
