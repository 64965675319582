/* eslint-disable import/prefer-default-export */
import { AsYouType, getCountryCallingCode } from 'libphonenumber-js';

/**
 *
 * @param {object} phoneNumber { countryCode: string, phone: string }
 */
export const getCompletePhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return null;
  const { countryCode, phone } = phoneNumber;

  try {
    if (typeof value === 'string') return phoneNumber;
    const formatter = new AsYouType(countryCode);
    const countryCallingCode = getCountryCallingCode(countryCode);
    let fullNumber = '';

    for (let i = 0; i < phone.length; i += 1) {
      fullNumber = formatter.input(phone.charAt(i));
    }

    fullNumber = `+${countryCallingCode} ${fullNumber}`;
    return fullNumber;
  } catch (e) {
    return null;
  }
};
