import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  Button, DateInput, SelectInput, TextAreaInput, TextInput, utils,
} from 'ui-library-unlocker';
import { t } from 'i18next';

import { useMutation } from '@tanstack/react-query';

import { useMemo } from 'react';
import { useAppContext } from '../../store/context';

import { hideModal, showModal } from '../../utils/modal';

import { terminateLease } from '../../services/lease';

import Modal from '../../components/molecules/Modal/Modal';
import ConfirmationModal from '../../components/organisms/ConfirmationModal/ConfirmationModal';
import { displayError } from '../../utils/forms/form';

function TenantLeavePropertyModal({
  id,
  lease,
  refetch,
}) {
  const { context: { uiBuilders } } = useAppContext();
  const confirmationId = 'confirmation-modal-leave';

  const leaseTerminateMutation = useMutation(terminateLease, {
    onSuccess: () => {
      utils.toast.success(t('tenant.leaving.formSuccess'));
      refetch();
      hideModal(id);
    },
    onError: (error) => {
      if (error.response.status === 404) {
        utils.toast.error(t('tenant.leaving.form404Error'));
      } else {
        utils.toast.error(t('global.form.errors.global'));
      }
    },
  });
  const handleTriggerConfirmation = () => {
    hideModal(id);
    showModal(confirmationId);
  };

  const handleCancelConfirmation = () => {
    hideModal(confirmationId);
    showModal(id);
  };

  const {
    values, handleChange, handleSubmit, touched, errors, setFieldValue,
  } = useFormik({
    initialValues: {
      terminationDate: new Date(),
      reason: null,
      comment: '',
    },
    validationSchema: Yup.object().shape({
      terminationDate: Yup.date().required('global.form.errors.required'),
      reason: Yup.string().required('global.form.errors.required').nullable(),
      comment: Yup.string(),
    }),
    onSubmit: () => {
      handleTriggerConfirmation();
    },
  });

  const confirmSubmit = () => {
    hideModal(confirmationId);
    showModal(id);
    leaseTerminateMutation.mutate({
      leaseUid: lease?.uid,
      data: {
        ...values,
        comment: values?.comment || null,
      },
    });
  };

  // eslint-disable-next-line max-len
  const reasons = useMemo(() => Object.entries(uiBuilders?.['/lease/ui']?.leaseTerminationReasons || {})?.map(([key, value]) => ({
    label: value,
    value: key,
  })), [uiBuilders]);

  return createPortal(
    <Modal
      id={id}
      title={t('tenant.leaving.title')}
      onClose={() => {
        hideModal(id);
      }}
    >
      <form onSubmit={handleSubmit} className="text-left">
        <TextInput label={t('tenant.leaving.property')} value={lease?.propertyName} className="m-b-40" disabled />
        <DateInput
          label={t('tenant.leaving.date')}
          value={values.terminationDate}
          onChange={(date) => setFieldValue('terminationDate', date)}
          name="terminationDate"
          error={displayError(t, { errors, touched }, 'terminationDate')}
        />
        <SelectInput
          label={t('tenant.leaving.reason')}
          className="m-t-10"
          name="reason"
          value={reasons?.find((reason) => reason?.value === values?.reason)}
          onChange={(elem) => setFieldValue('reason', elem?.value)}
          options={reasons}
          error={displayError(t, { errors, touched }, 'reason')}
        />
        <TextAreaInput
          className="m-t-10"
          label={t('tenant.leaving.comment')}
          value={values?.comment}
          onChange={handleChange}
          name="comment"
          error={displayError(t, { errors, touched }, 'comment')}
        />
        <Button
          label={t('tenant.leaving.submit')}
          className="m-t-20"
          size="large"
          blockLevel
          type="submit"
          loading={leaseTerminateMutation.isLoading}
        />
        {createPortal(
          <ConfirmationModal
            id={confirmationId}
            onSubmit={confirmSubmit}
            onCancel={handleCancelConfirmation}
          />,
          document.querySelector('body'),
        )}
      </form>
    </Modal>,
    document.querySelector('body'),
  );
}

TenantLeavePropertyModal.propTypes = {
  id: PropTypes.string.isRequired,
};

TenantLeavePropertyModal.defaultProps = {
  lease: null,
  refetch: () => {},
};

export default TenantLeavePropertyModal;
