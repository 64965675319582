/* eslint-disable react/no-array-index-key */
import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Components
import {
  Button,
} from 'ui-library-unlocker';
import AddDocumentModal from '../AddDocumentModal/AddDocumentModal';
import ImageList from '../ImageList/ImageList';

// Utils
import { showModal } from '../../../utils/modal';

// Styles
// import styles from './ImageUpload.module.scss';

const UPLOAD_IMAGE_MODAL_ID = 'upload-image-modal';

function ImageUpload({
  picture,
  className,
  entityUID,
  entityType,
  refetchPicture,
  fileType,
}) {
  const { t } = useTranslation();

  const handleImportBtn = useCallback(() => {
    showModal(UPLOAD_IMAGE_MODAL_ID);
  }, []);

  const list = useMemo(() => {
    if (picture) {
      return [{
        uid: picture.uid,
        url: picture.thumbnails?.find((thumb) => thumb.format === 'property_thumbnail')?.url,
        lightboxUrl: picture.thumbnails?.find((thumb) => thumb.format === 'property_full_screen')?.url,
      }];
    }
    return [];
  }, [picture]);

  return (
    <>
      {picture ? (
        <ImageList
          className={className}
          images={list}
          entityUID={entityUID}
          entityType={entityType}
          refetchImages={refetchPicture}
        />
      ) : (
        <Button
          variant="secondary"
          label={t('global.importFile')}
          size="medium"
          icon="import"
          onClick={handleImportBtn}
        />
      )}
      <AddDocumentModal
        modalId={UPLOAD_IMAGE_MODAL_ID}
        entityType={entityType}
        entityUID={entityUID}
        refetchDocumentList={refetchPicture}
        fileType={fileType}
        fileFormat="image"
        documentType={`${entityType}Picture`}
      />
    </>
  );
}

ImageUpload.propTypes = {
  picture: PropTypes.shape({
    thumbnails: PropTypes.arrayOf(PropTypes.shape({
      format: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })),
    uid: PropTypes.string.isRequired,
  }),
  className: PropTypes.string,
  entityUID: PropTypes.string.isRequired,
  entityType: PropTypes.oneOf(['company', 'person']).isRequired,
  refetchPicture: PropTypes.func.isRequired,
  fileType: PropTypes.string.isRequired,
};

ImageUpload.defaultProps = {
  picture: null,
  className: '',
};

export default ImageUpload;
