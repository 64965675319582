import { useMemo } from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';

// Components
import {
  utils,
  Picto,
} from 'ui-library-unlocker';
import PercentCircle from '../../atoms/KPI/PercentCircle/PercentCircle';

// Styles
import styles from './PercentKPI.module.scss';

function ProgressPercent({
  value,
}) {
  const positive = useMemo(() => value >= 0, [value]);

  return (
    <div className={utils.cn([
      styles.progressPercent,
      positive ? styles.positive : styles.negative,
    ])}
    >
      <Picto
        className={styles.icon}
        color={
          positive
            ? 'var(--color-accent-green)'
            : 'var(--color-accent-red)'
        }
        width={15}
        icon="arrow-down-bold"
      />
      <span>
        {Math.abs(value)}
        {' '}
        %
      </span>
    </div>
  );
}

ProgressPercent.propTypes = {
  value: PropTypes.number,
};

ProgressPercent.defaultProps = {
  value: 0,
};

function PercentKPI({
  total,
  value,
  size,
  color,
  className,
  title,
  formatLabel,
  progressPercent,
  showProgressPercent,
  restColor,
  showRestPercent,
  subtext,
  isLoading,
}) {
  const shouldShowPercent = useMemo(() => !!(
    (showRestPercent || showProgressPercent)
    && progressPercent
  ), [showRestPercent, showProgressPercent, progressPercent]);

  if (isLoading) {
    return (
      <div className={utils.cn([styles.container, className])}>
        <Skeleton circle width={size} height={size} />
        <div className={styles.legend}>
          <Skeleton height={13} width={50} />
          <Skeleton height={18} width={75} />
        </div>
      </div>
    );
  }

  return (
    <div className={utils.cn([styles.container, className])}>
      <PercentCircle
        total={total}
        value={value}
        size={size}
        color={color}
        restColor={restColor}
        showRestPercent={showRestPercent}
        subtext={subtext}
      />
      <div className={styles.legend}>
        <span className={styles.title}>{title}</span>
        <span className={styles.label}>{formatLabel?.(value) || value}</span>
        {shouldShowPercent && (
          <ProgressPercent value={progressPercent} />
        )}
      </div>
    </div>
  );
}

PercentKPI.propTypes = {
  total: PropTypes.number,
  value: PropTypes.number,
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  formatLabel: PropTypes.func,
  progressPercent: PropTypes.number,
  showProgressPercent: PropTypes.bool,
  restColor: PropTypes.string,
  showRestPercent: PropTypes.bool,
  subtext: PropTypes.string,
  isLoading: PropTypes.bool,
};

PercentKPI.defaultProps = {
  total: 100,
  value: 0,
  size: 200,
  color: 'var(--color-secondary)',
  className: '',
  title: '',
  formatLabel: (value) => value,
  progressPercent: 0,
  showProgressPercent: true,
  restColor: '',
  showRestPercent: false,
  subtext: '',
  isLoading: false,
};

export default PercentKPI;
