import * as yup from 'yup';

export const documentInitialValues = {
  customName: '',
  filename: '',
  type: null,
  userUid: null,
  metadata: null,
};

export const getDocumentSchema = ({ documentType }) => yup.object().shape({
  customName: yup.string().max(50, 'Filename too long').required('global.form.errors.required'),
  filename: yup.string(),
  type: yup.string().nullable().oneOf(documentType, 'global.form.errors.required'),
  userUid: yup.string().required('global.form.errors.required'),
  metadata: yup.object().shape(),
});
