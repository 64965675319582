import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import fr from 'date-fns/locale/fr';
import Skeleton from 'react-loading-skeleton';

// Components
import {
  utils,
} from 'ui-library-unlocker';
import LineGraph from '../../../../components/atoms/KPI/LineGraph/LineGraph';

// Services
import { getYearlyTrackedFees } from '../../../../services/stats';

// Styles
import styles from './FeesTracking.module.scss';

function ChartSkeleton() {
  return (
    <Skeleton height={200} />
  );
}

function FeesTracking() {
  const { t } = useTranslation();

  const {
    data: trackedFees,
    isLoading,
  } = useQuery({
    queryKey: ['tracked-fees'],
    queryFn: getYearlyTrackedFees,
    keepPreviousData: false,
  });

  const feesData = useMemo(() => trackedFees?.data?.yearFees || [], [trackedFees]);

  const chartData = useMemo(() => {
    let thisMonth = new Date().getMonth();
    const reversed = [...feesData].reverse();
    const result = [];
    reversed.forEach((value) => {
      result.push({
        month: fr.localize.month(thisMonth, { width: 'abbreviated' }),
        value,
      });
      if (thisMonth === 0) {
        thisMonth = 11;
      } else {
        thisMonth -= 1;
      }
    });
    return result.reverse();
  }, [feesData]);

  const formatTooltip = useCallback((value, name) => [
    `${utils.centsToEuro(value)}€`,
    t(`dashboard.kpi.feesTracking.legend.${name}`),
  ], [t]);

  const formatYAxis = useCallback((value) => {
    const euros = utils.centsToEuro(value);
    return euros.split('.')[0]; // Remove cents after dot
  }, [t]);

  return (
    <div className="m-t-40">
      <h3 className="m-b-20 m-l-20">{t('dashboard.kpi.feesTracking.title')}</h3>
      <div className={styles.container}>
        {isLoading ? (
          <ChartSkeleton />
        ) : (
          <LineGraph
            data={chartData}
            width="100%"
            height={200}
            xAxisDataKey="month"
            lines={[
              {
                dataKey: 'value',
                color: 'var(--color-secondary)',
                type: 'monotone',
              },
            ]}
            margin={{
              top: 10,
              right: 10,
              left: -10,
              bottom: 5,
            }}
            tooltip
            formatTooltip={formatTooltip}
            formatYAxis={formatYAxis}
          />
        )}
      </div>
    </div>
  );
}

export default FeesTracking;
