/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';

import cn from '../../../utils/cn';

import styles from './CheckList.module.scss';

import CheckedItem from '../../atoms/CheckedItem/CheckedItem';
import Tag from '../../atoms/Tag/Tag';

const CheckList = forwardRef(({
  options,
  className,
  details,
  windowWidth,
}, ref) => {
  const findCurrent = () => options?.find((el) => !el.checked);

  const current = findCurrent()?.id;

  const MOBILE = windowWidth <= 1024;

  return (
    <div
      className={cn([
        className,
        styles.checkList,
        details ? styles.details : null,
        !MOBILE ? styles.fade : null,
      ])}
      ref={ref}
    >

      {options && options.map((el) => (
        <div key={el.id} className={styles.listItem}>

          <CheckedItem
            {...el}
            details={details}
            current={el.id === current}
            progressBarSize={windowWidth <= 1440 ? 'small' : 'medium'}
            windowWidth={windowWidth}
          />

          <AnimatePresence mode={MOBILE ? 'sync' : 'popLayout'}>
            {details && el.steps?.map((s) => (
              <motion.div
                key={s.id}
                className={styles.step}
                initial={{
                  height: MOBILE ? 0 : 25,
                  marginTop: MOBILE ? 0 : 5,
                  opacity: 0,
                }}
                animate={{
                  height: MOBILE ? 'auto' : 25,
                  marginTop: 5,
                  opacity: 1,
                }}
                exit={{
                  height: MOBILE ? 0 : 25,
                  marginTop: MOBILE ? 0 : 5,
                  opacity: 0,
                }}
                transition={{
                  duration: 0.33,
                }}
              >
                {s.completion && (
                  <Tag
                    variant={s.completed ? 'secondary-outline' : 'tertiary'}
                    icon={s.completed ? 'tick-circle' : 'timer'}
                    label={s.completion}
                    size="small"
                    className={styles.tag}
                  />
                )}
                <span className={styles.stepLabel}>{s.label}</span>
              </motion.div>
            ))}
          </AnimatePresence>

        </div>
      ))}

    </div>
  );
});

CheckList.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  className: PropTypes.string,
  details: PropTypes.bool,
  windowWidth: PropTypes.number,
};

CheckList.defaultProps = {
  className: null,
  details: false,
  windowWidth: 1920,
};

export default CheckList;
