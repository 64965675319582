/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useMatch } from 'react-router-dom';

import BankingIdentityForm from '../../components/organisms/forms/BankingIdentityForm/BankingIdentityForm';

// Services
import { getBankingIdentity } from '../../services/bankingIdentity';

import styles from './BankingIdentityEdit.module.scss';

function BankingIdentityEdit({ isEditing }) {
  const { t } = useTranslation();

  const match = useMatch('/banking-identities/:id');

  const bankingIdentityQuery = useQuery({
    queryKey: ['bankingIdentity-id', isEditing],
    queryFn: () => {
      if (isEditing && match?.params?.id !== 'add') {
        return getBankingIdentity(match?.params?.id);
      }
      return null;
    },
    cacheTime: 0,
  });

  return (
    <div>
      <div className={styles.heading}>
        <div className={styles.title}>
          <h1>{isEditing ? t('bankingIdentity.crud.editTitle') : t('bankingIdentity.crud.addTitle')}</h1>
        </div>
      </div>
      <div className={styles.content}>
        <BankingIdentityForm isEditing={isEditing} bankingIdentityQuery={bankingIdentityQuery} />
      </div>
    </div>
  );
}

BankingIdentityEdit.propTypes = {
  isEditing: PropTypes.bool.isRequired,
};

export default BankingIdentityEdit;
