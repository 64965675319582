/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
import { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

// Components
import {
  Button,
  DateInput,
  utils,
  SelectInput,
} from 'ui-library-unlocker';
import Modal from '../Modal/Modal';

// Services
import { exportOwner, exportManager, exportGLI } from '../../../services/export';

// Utils
import { displayError, isFieldValid, errorFocusSubmit } from '../../../utils/forms/form';
import { hideModal } from '../../../utils/modal';
import { exportSchema, exportInitialValues as initialValues } from '../../../utils/forms/exportSchema';

// Styles
import styles from './ExportModal.module.scss';

// Constants
import { EXPORT_TYPES } from '../../../utils/constants';

function ExportModal({
  idModal,
  onClose,
  entity,
}) {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues,
    validationSchema: exportSchema,
    onSubmit: (values) => {
      exportMutation.mutate({
        ...values,
        [entityUidKey]: entity?.uid,
      });
    },
  });

  const exportMethod = useMemo(() => {
    if (formik.values.type === EXPORT_TYPES.GLI) {
      return exportGLI;
    }
    if (entity?.isRealEstateAgency) {
      return exportManager;
    }
    return exportOwner;
  }, [entity?.isRealEstateAgency, formik.values.type]);

  const exportMutation = useMutation({
    mutationFn: (data) => exportMethod(data),
    onSuccess: () => {
      handleClose();
      utils.toast.info(t('export.success'));
    },
    onError: () => {
      utils.toast.error(t('global.form.errors.global'));
    },
  });

  const entityUidKey = useMemo(() => {
    switch (entity?.type) {
      case 'owner':
        return 'ownerUID';
      case 'company':
        if (entity.isRealEstateAgency) {
          return 'managerUID';
        }
        return 'ownerUID'; // temporary
      default:
        return '';
    }
  }, [entity?.type]);

  useEffect(() => {
    if (formik.values.startDate > formik.values.endDate) {
      formik.setFieldValue('endDate', new Date(
        formik.values.startDate.getFullYear() + 1,
        formik.values.startDate.getMonth(),
        formik.values.startDate.getDate(),
      ));
    }
  }, [formik.values.startDate, formik.values.endDate]);

  const handleClose = useCallback(() => {
    hideModal(idModal);
    setTimeout(() => formik.resetForm(), 1000);
    onClose();
  }, [formik, idModal]);

  const typeOptions = useMemo(() => Object.values(EXPORT_TYPES).map((type) => ({
    value: type,
    label: t(`export.type.${type}`),
  })), [t]);

  return (
    <Modal
      className={styles.modal}
      id={idModal}
      title={t('export.title', { exportName: entity?.name })}
      onClose={handleClose}
    >
      <div className={styles.modalContent}>
        <form className={styles.form} onSubmit={errorFocusSubmit(formik.handleSubmit)}>
          <SelectInput
            id="exportType"
            className="m-b-30"
            name="exportType"
            label={t('export.typeLabel')}
            options={typeOptions}
            onChange={(option) => formik.setFieldValue('type', option.value)}
            value={typeOptions.find((item) => item.value === formik.values.type)}
          />
          <DateInput
            id="startDate"
            name="startDate"
            className="m-b-30"
            label={formik.values.type === EXPORT_TYPES.GLI ? t('export.month') : t('export.startDate')}
            value={formik.values.startDate}
            onChange={(date) => formik.setFieldValue('startDate', date)}
            onBlur={formik.handleBlur}
            error={displayError(t, formik, 'startDate')}
            valid={isFieldValid(formik, 'startDate', null, initialValues?.startDate)}
            full
            monthYearPicker={formik.values.type === EXPORT_TYPES.GLI}
            portalId={styles.datepickerMenu}
          />
          {formik.values.type !== EXPORT_TYPES.GLI && (
            <DateInput
              id="endDate"
              name="endDate"
              label={t('export.endDate')}
              value={formik.values.endDate}
              onChange={(date) => formik.setFieldValue('endDate', date)}
              onBlur={formik.handleBlur}
              error={displayError(t, formik, 'endDate')}
              valid={isFieldValid(formik, 'endDate', null, initialValues?.endDate)}
              full
              minDate={formik.values.startDate}
              portalId={styles.datepickerMenu}
            />
          )}
          <div className={utils.cn(['m-t-40', styles.modalActions])}>
            <Button
              variant="secondary"
              size="large"
              className={styles.submitBtn}
              onClick={handleClose}
            >
              {t('global.cancel')}
            </Button>
            <Button
              type="submit"
              variant="primary"
              size="large"
              className={styles.submitBtn}
              loading={formik.isSubmitting}
            >
              {t('export.submit')}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

ExportModal.propTypes = {
  idModal: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  entity: PropTypes.shape({
    uid: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.oneOf(['owner', 'company']),
    isRealEstateAgency: PropTypes.bool,
  }),
};

ExportModal.defaultProps = {
  onClose: () => {},
  entity: null,
};

export default ExportModal;
