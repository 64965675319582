/* eslint-disable consistent-return  */

export const twentyYearsBackDate = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 20);
  return date;
};

export const getDateValue = (date) => {
  try {
    if (!date) return null;
    if (date.date) return new Date(date.date);
    return new Date(date);
  } catch {
    return null;
  }
};

export const exactDatePlusOneDay = (date) => {
  if (!date) return;
  const d = new Date(date);
  d.setDate(d.getDate() + 1);
  return d.toISOString();
};

export const getDateFilters = (filterName, filters, filterKey) => {
  const exact = filters[`${filterName}Date`]?.[0];
  const greaterThan = filters[`${filterName}StartDate`]?.[0];
  const lesserThan = filters[`${filterName}EndDate`]?.[0];

  if (exact) {
    return {
      [`filters${filterKey || `[${filterName}]`}[--gte--]`]: exact,
      [`filters${filterKey || `[${filterName}]`}[--lte--]`]: exactDatePlusOneDay(exact),
    };
  }

  if (greaterThan && lesserThan) {
    return {
      [`filters${filterKey || `[${filterName}]`}[--gte--]`]: greaterThan,
      [`filters${filterKey || `[${filterName}]`}[--lte--]`]: exactDatePlusOneDay(lesserThan),
    };
  }

  return {
    [`filters${filterKey || `[${filterName}]`}[--gte--]`]: exactDatePlusOneDay(greaterThan),
    [`filters${filterKey || `[${filterName}]`}[--lte--]`]: exactDatePlusOneDay(lesserThan),
  };
};
