/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-unresolved */
import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import Lightbox from 'yet-another-react-lightbox';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Counter from 'yet-another-react-lightbox/plugins/counter';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import 'yet-another-react-lightbox/plugins/counter.css';

import { utils, Picto } from 'ui-library-unlocker';

import Image from '../../atoms/Image/Image';

import useResponsive from '../../../hooks/useResponsive';

import styles from './PhotoGallery.module.scss';

function PhotoGallery({ pictures, className }) {
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const { isMobile } = useResponsive();

  const openLightbox = useCallback((i) => {
    setIndex(i);
    setOpen(true);
  }, []);

  return (
    <>
      <div
        className={utils.cn([
          styles.photoGallery,
          className,
        ])}
      >
        {pictures
          ?.slice(0, isMobile ? 1 : 5)
          ?.map((picture, i) => (
            <div
              key={i}
              className={utils.cn([
                styles.photoGallery__img__container,
                picture.main ? styles.photoGallery__img__container__main : null,
              ])}
              onClick={() => openLightbox(i)}
              tabIndex={0}
              role="button"
              onKeyDown={null}
            >
              <Image
                className={styles.photoGallery__img}
                src={picture.url}
                alt="interieur"
              />
              {picture.main && (
                <div className={styles.photoGallery__counter}>
                  <Picto
                    icon="camera"
                    width={14}
                    color="var(--color-tertiary)"
                  />
                  <span>{pictures.length}</span>
                </div>
              )}
            </div>
          ))}
      </div>
      <Lightbox
        index={index}
        open={open}
        close={() => setOpen(false)}
        plugins={[Thumbnails, Counter, Zoom]}
        slides={pictures?.map((picture) => ({
          src: picture.url,
          alt: 'interieur',
        }))}
        carousel={{
          finite: true,
        }}
        styles={{ root: { '--yarl__color_backdrop': 'rgba(0, 0, 0, .8)' } }}
        controller={{
          closeOnBackdropClick: true,
        }}
      />
    </>
  );
}

PhotoGallery.propTypes = {
  pictures: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string.isRequired,
    main: PropTypes.bool,
  })).isRequired,
  className: PropTypes.string,
};

PhotoGallery.defaultProps = {
  className: '',
};

export default PhotoGallery;
