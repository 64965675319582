import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { utils } from 'ui-library-unlocker';

import styles from './ConsumptionScore.module.scss';

function ConsumptionScore({
  score,
  className,
  type,
}) {
  const scoreLabel = useMemo(() => {
    if (typeof score !== 'number') return 'G';
    if (score <= 70) return 'A';
    if (score <= 110) return 'B';
    if (score <= 180) return 'C';
    if (score <= 250) return 'D';
    if (score <= 330) return 'E';
    if (score <= 420) return 'F';
    return 'G';
  }, [score]);

  const palette = useMemo(() => ({
    dpe: {
      A: {
        bgColor: '#03894F',
        textColor: '#FFFFFF',
      },
      B: {
        bgColor: '#3CA525',
        textColor: '#FFFFFF',
      },
      C: {
        bgColor: '#6EBC5D',
        textColor: '#000000',
      },
      D: {
        bgColor: '#F1E600',
        textColor: '#000000',
      },
      E: {
        bgColor: '#EBB401',
        textColor: '#000000',
      },
      F: {
        bgColor: '#E3812A',
        textColor: '#000000',
      },
      G: {
        bgColor: '#CF1E15',
        textColor: '#FFFFFF',
      },
    },
    ges: {
      A: {
        bgColor: '#ABDBF9',
        textColor: '#000000',
      },
      B: {
        bgColor: '#8FB3D3',
        textColor: '#000000',
      },
      C: {
        bgColor: '#7A91B1',
        textColor: '#FFFFFF',
      },
      D: {
        bgColor: '#626F91',
        textColor: '#FFFFFF',
      },
      E: {
        bgColor: '#4D5272',
        textColor: '#FFFFFF',
      },
      F: {
        bgColor: '#443F5D',
        textColor: '#FFFFFF',
      },
      G: {
        bgColor: '#281B37',
        textColor: '#FFFFFF',
      },
    },
  }), []);

  return (
    <div className={utils.cn([styles.consumptionScore, className])}>
      {Object.entries(palette[type])?.map(([key, colors]) => (
        <div
          key={key}
          className={styles.consumptionScore__case}
          style={{
            backgroundColor: colors.bgColor,
            color: colors.textColor,
            height: scoreLabel === key ? 23 : '100%',
          }}
        >
          {scoreLabel === key && scoreLabel}
        </div>
      ))}
    </div>
  );
}

ConsumptionScore.propTypes = {
  score: PropTypes.number.isRequired,
  className: PropTypes.string,
  type: PropTypes.oneOf(['dpe', 'ges']).isRequired,
};

ConsumptionScore.defaultProps = {
  className: '',
};

export default ConsumptionScore;
