import parse from 'html-react-parser';
import DOMPurify from 'dompurify';

export const purifyHtml = (html, additionalTags = []) => DOMPurify.sanitize(html, {
  ALLOWED_TAGS: ['b', 'i', 'a', 'br', 'p', 'span', 'div', 'em', 'small', ...(additionalTags || [])],
  ALLOWED_ATTR: ['style', 'target', 'href'],
});

export const parseSanitized = (html, additionalTags) => parse(purifyHtml(html, additionalTags));

export const formatMoney = (amount) => {
  if (amount === null || amount === undefined) {
    return '';
  }
  return `${amount.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} €`;
};
