import * as yup from 'yup';

const addressSchema = yup.object().shape({
  street: yup.string().required('global.form.errors.required'),
  streetDetails: yup.string().nullable(),
  streetNumber: yup.string().nullable(),
  zipCode: yup.string().required('global.form.errors.required'),
  latitude: yup.number().nullable(),
  longitude: yup.number().nullable(),
  countryCode: yup.string().required('global.form.errors.required'),
  city: yup.string().required('global.form.errors.required'),
});

export default addressSchema;
