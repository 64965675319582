import React, {
  useState,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useMediaQuery } from 'react-responsive';
import {
  Tag,
  utils,
  Picto,
} from 'ui-library-unlocker';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';
import { Link, useNavigate } from 'react-router-dom';

// Components
import Tooltip from '../../components/atoms/Tooltip/Tooltip';
import TableOptions from '../../components/atoms/TableOptions/TableOptions';
import DataTable from '../../components/organisms/DataTable/DataTable';

// Services
import {
  getPaymentsFromAdmin,
  getPropertiesFromAdmin,
  getUsersFromAdmin,
} from '../../services/admin';
import { getLessorPayments } from '../../services/payment';
import { getProperties } from '../../services/property';
import { getTenants } from '../../services/tenant';
import { getLeaseIBAN } from '../../services/lease';

// Hooks
import useRoles from '../../hooks/useRoles';
import useFilters from '../../hooks/useFilters';
import useDocumentTitle from '../../hooks/useDocumentTitle';

// Utils
import { formatAddress } from '../../utils/properties';
import { getPaymentAndAccountStatusVariant } from '../../utils/variants';

// Styles
import styles from './CashOutList.module.scss';

// Constants
import { PAYMENT_AND_ACCOUNT_STATUS_LIST, SEARCH_SCOPE_LIST } from '../../utils/constants';

const SEARCH_SCOPE = {
  [SEARCH_SCOPE_LIST.TENANTS]: 'payer',
  [SEARCH_SCOPE_LIST.LEASES]: 'lease',
  [SEARCH_SCOPE_LIST.PROPERTIES]: 'property',
};

function CashOutList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isUserAdmin } = useRoles();
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const { filters } = useFilters();
  useDocumentTitle(t('cashout.browserTitle'));

  const [tooltipOpen, setTooltipOpen] = useState(null);
  const [data, setData] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);

  const ibanMutation = useMutation({
    mutationFn: getLeaseIBAN,
    onSuccess: () => {
      utils.toast.info(t('lease.table.ibanExportSuccess'));
    },
    onError: () => {
      utils.toast.error(t('global.form.errors.global'));
    },
  });

  const propertiesUID = useMemo(() => {
    if (!data) return [];
    return [...new Set(data.map((p) => p.property))];
  }, [data]);

  const tenantsUID = useMemo(() => {
    if (!data) return [];
    return [...new Set(data.map((p) => p.payer))];
  }, [data]);

  const {
    data: propertiesData,
    isFetching: propertiesFetching,
  } = useQuery({
    queryKey: ['cashout-properties-list', propertiesUID, isUserAdmin],
    queryFn: () => (isUserAdmin ? getPropertiesFromAdmin : getProperties)({
      filters: {
        uid: propertiesUID,
      },
    }),
    keepPreviousData: true,
    enabled: !!propertiesUID.length > 0,
  });

  const {
    data: tenantsData,
    isFetching: tenantsFetching,
  } = useQuery({
    queryKey: ['cashout-tenants-list', tenantsUID, isUserAdmin],
    queryFn: () => (isUserAdmin ? getUsersFromAdmin : getTenants)({
      filters: {
        uid: tenantsUID,
      },
    }),
    keepPreviousData: true,
    enabled: !!tenantsUID.length > 0,
  });

  const paymentStatusTransKey = useMemo(() => (
    `cashout.paymentStatus.${isUserAdmin ? 'admin' : 'lessor'}`
  ), [isUserAdmin]);

  const columns = useMemo(() => ([
    {
      header: t('cashout.table.columns.label'),
      accessorKey: 'friendlyName',
      size: 200,
      cell: ({ row: { original } }) => <span>{original?.friendlyName}</span>,
    },
    {
      header: t('cashout.table.columns.amountPaid'),
      accessorKey: 'amountPaid',
      size: 1,
      cell: ({ row: { original } }) => <span>{`${utils.centsToEuro(original.amountPaid)} €`}</span>,
    },
    {
      header: t('cashout.table.columns.amount'),
      accessorKey: 'amount',
      size: 1,
      cell: ({ row: { original } }) => <span>{`${utils.centsToEuro(original.amount)} €`}</span>,
    },
    {
      header: t('cashout.table.columns.receptionDate'),
      accessorKey: 'cashInDate',
      size: 1,
      cell: ({ row: { original } }) => (
        <span>
          {original.cashInDate ? format(new Date(original.cashInDate), 'dd MMM yyyy', { locale: fr }) : '--'}
        </span>
      ),
    },
    {
      header: t('cashout.table.columns.paymentMethod'),
      accessorKey: 'paymentMethod',
      size: 1,
      enableSorting: false,
      cell: ({ row: { original: { paymentMethod } } }) => (
        <span>
          {t(`global.paymentMethods.${paymentMethod}`)}
        </span>
      ),
    },
    {
      header: t('cashout.table.columns.status'),
      accessorKey: 'status',
      size: 150,
      cell: ({ row: { original } }) => (
        <Tag
          size="medium"
          variant={getPaymentAndAccountStatusVariant(original.status)}
          label={t(`${paymentStatusTransKey}.${original.status}`)}
          wrap={!isMobile}
        />
      ),
    },
    {
      header: t('cashout.table.columns.tenant'),
      accessorKey: 'tenant',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { payer } } }) => {
        const {
          firstName,
          lastName,
          uid: tenantUid,
        } = tenantsData?.data?.collection?.find((l) => l.uid === payer) || {};
        return (
          <Link to={`/tenant/${tenantUid}`} target="_blank">
            <p className="p-2-700">
              {`${firstName} ${lastName}`}
            </p>
          </Link>
        );
      },
    },
    {
      header: t('cashout.table.columns.property'),
      accessorKey: 'property',
      size: 150,
      enableSorting: false,
      cell: ({ row: { original: { property } } }) => {
        const {
          name: propertyName,
          address,
          uid: propertyUid,
        } = propertiesData?.data?.collection?.find((l) => l.uid === property) || {};
        return (
          <div>
            <div className={styles.nameAddress}>
              <Link to={`/property/${propertyUid}`} target="_blank">
                {propertyName}
                <Picto
                  icon="export-link"
                  width={9}
                  color="var(--color-secondary)"
                  className="m-l-5"
                />
              </Link>
              <p className="p-2-500">
                {formatAddress(address)}
              </p>
            </div>
          </div>
        );
      },
    },
    {
      header: '',
      accessorKey: 'additionalOptions',
      size: 50,
      enableSorting: false,
      cell: ({ row }) => (
        <div className={styles.seeMore}>
          <Picto
            id={`more-option-cashout-${row?.original?.uid}`}
            icon="more"
            width={24}
            onClick={(e) => {
              e.stopPropagation();
              setTooltipOpen(tooltipOpen === row?.original?.uid ? null : row?.original?.uid);
            }}
            color="var(--color-secondary)"
          />
          <Tooltip
            isOpen={tooltipOpen === row?.original?.uid}
            anchorId={`more-option-cashout-${row?.original?.uid}`}
            place="bottom"
            type="dark"
            effect="solid"
          >
            <TableOptions
              options={[
                {
                  id: 'manage',
                  label: t('global.listOptions.see'),
                  icon: <Picto icon="eye" width={24} color="var(--color-primary)" />,
                  onHoverIcon: <Picto icon="eye" width={24} color="var(--color-white)" />,
                  onClick: (e) => {
                    e.stopPropagation();
                    setTooltipOpen(null);
                    navigate(`/cashout/${row?.original?.uid}`);
                  },
                },
                {
                  id: 'iban',
                  label: t('global.listOptions.downloadIBAN'),
                  icon: <Picto icon="import" width={24} color="var(--color-primary)" />,
                  onHoverIcon: <Picto icon="import" width={24} color="var(--color-white)" />,
                  onClick: (e) => {
                    e.stopPropagation();
                    setTooltipOpen(null);
                    ibanMutation.mutate(row?.original?.lease);
                  },
                },
              ]}
            />
          </Tooltip>
        </div>
      ),
    },
  ]), [t, tooltipOpen, navigate, propertiesData, tenantsData, paymentStatusTransKey, ibanMutation, isMobile]);

  const allFiltersOptions = useMemo(() => [
    {
      name: 'status',
      type: 'checkbox',
      title: t('cashout.table.columns.status'),
      options: [
        // payment status
        // {
        //   value: 'scheduled',
        //   label: t('cashout.paymentStatus.scheduled'),
        // },
        // {
        //   value: 'scheduled_manual',
        //   label: t('cashout.paymentStatus.scheduled_manual'),
        // },
        // {
        //   value: 'pending',
        //   label: t('cashout.paymentStatus.pending'),
        // },
        {
          value: PAYMENT_AND_ACCOUNT_STATUS_LIST.READY_TO_CHECK,
          label: t(`${paymentStatusTransKey}.${PAYMENT_AND_ACCOUNT_STATUS_LIST.READY_TO_CHECK}`),
        },
        // {
        //   value: 'in_wiring',
        //   label: t('cashout.paymentStatus.in_wiring'),
        // },
        // {
        //   value: 'notified',
        //   label: t('cashout.paymentStatus.notified'),
        // },
        // {
        //   // success ?
        //   value: 'received,sent,ready_to_wire',
        //   label: t('cashout.paymentStatus.success'),
        // },
        // {
        //   value: 'failed',
        //   label: t('cashout.paymentStatus.failed'),
        // },
        // {
        //   value: 'canceled',
        //   label: t('cashout.paymentStatus.canceled'),
        // },
        // {
        //   value: 'not_doable',
        //   label: t('cashout.paymentStatus.not_doable'),
        // },
      ],
    },
    {
      name: 'cashInDate',
      multiName: ['cashInDateDate', 'cashInDateStartDate', 'cashInDateEndDate'],
      type: 'date',
      title: t('cashout.dateFilter'),
      options: [
        {
          value: filters?.cashInDateDate?.[0],
          label: `${t('global.filterDate.exactSelectedFilterLabel')} ${filters?.cashInDateDate?.[0]
            ? format(new Date(filters?.cashInDateDate?.[0]), 'dd/MM/yyyy', { locale: fr })
            : ''
          }`,
        },
        {
          value: filters?.cashInDateStartDate?.[0],
          label: `${t('global.filterDate.after')} ${filters?.cashInDateStartDate?.[0]
            ? format(new Date(filters?.cashInDateStartDate?.[0]), 'dd/MM/yyyy', { locale: fr })
            : ''
          }`,
        },
        {
          value: filters?.cashInDateEndDate?.[0],
          label: `${t('global.filterDate.before')} ${filters?.cashInDateEndDate?.[0]
            ? format(new Date(filters?.cashInDateEndDate?.[0]), 'dd/MM/yyyy', { locale: fr })
            : ''
          }`,
        },
      ],
    },
  ], [paymentStatusTransKey, filters]);

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={null}
      onClick={() => setTooltipOpen(null)}
    >
      <div className={styles.heading}>
        <h1>{t('cashout.title')}</h1>
      </div>
      <DataTable
        columns={columns}
        searchLabel={t('cashout.searchInput')}
        omniSearch
        omniSearchScope={SEARCH_SCOPE}
        getData={isUserAdmin ? getPaymentsFromAdmin : getLessorPayments}
        queryKey="cashout-payment-list"
        allFiltersOptions={allFiltersOptions}
        setData={(value) => setData(value)}
        setDataLoading={(value) => setDataLoading(value)}
        loading={propertiesFetching || tenantsFetching || dataLoading}
      />
    </div>
  );
}

export default CashOutList;
