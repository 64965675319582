import React from 'react';
import { useTranslation } from 'react-i18next';

function DiscoverLink() {
  const { t } = useTranslation();

  return (
    <a href="https://www.unlocker.io" target="_blank" rel="noreferrer">
      {t('global.discoverLink')}
    </a>
  );
}

export default DiscoverLink;
