import {
  useEffect, useMemo, useCallback, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@tanstack/react-query';
import {
  utils,
  ToggleInput,
} from 'ui-library-unlocker';

// components
import Notification from '../../components/molecules/Notification/Notification';
import NotifSkeleton from '../../components/templates/NotificationCenter/NotifSkeleton';

// hooks
import { useAppContext } from '../../store/context';

// services
import {
  getNotifications,
  hitUntilNotification,
  getLastUnreadNotification,
} from '../../services/notifications';

import styles from './Notifications.module.scss';

function NotificationsView() {
  const { t } = useTranslation();
  const {
    context: {
      notifications,
      totalNumberOfNotifications,
      hasUnreadNotifications,
    }, dispatch,
  } = useAppContext();

  const [filterUnread, setFilterUnread] = useState(false);
  const [hideLoader, setHideLoader] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    itemsPerPage: 20, // not too low or won't be able to scroll and fetch more on large screens
  });

  useEffect(() => {
    document.title = t('notifications.browserTitle');
  }, [t]);

  const unreadQuery = useQuery({
    queryKey: ['unread-notifications'],
    queryFn: async () => {
      const response = await getLastUnreadNotification();

      dispatch({
        type: 'SET_HAS_UNREAD_NOTIFICATIONS',
        payload: response?.data?.totalNumberOfItems > 0,
      });

      return response;
    },
    cacheTime: 0,
  });

  const notifQuery = useQuery({
    queryKey: ['notifications', pagination.page],
    queryFn: async () => {
      const response = await getNotifications(pagination);

      const fetchedNotifications = response?.data?.collection || [];
      const updatedNotifications = pagination.page === 1
        ? fetchedNotifications
        : [...notifications, ...fetchedNotifications];

      dispatch({
        type: 'SET_NOTIFICATIONS',
        payload: updatedNotifications,
      });

      setHideLoader(true);
      dispatch({
        type: 'SET_TOTAL_NUMBER_OF_NOTIFICATIONS',
        payload: response?.data?.totalNumberOfItems || 0,
      });

      return response;
    },
    cacheTime: 0,
  });

  const allReadMutation = useMutation({
    mutationFn: (timestamp) => hitUntilNotification(timestamp),
    onError: () => {
      utils.toast.error(t('global.form.errors.global'));
    },
  });

  useEffect(() => () => {
    dispatch({
      type: 'SET_TOTAL_NUMBER_OF_NOTIFICATIONS',
      payload: 0,
    });
    unreadQuery.refetch();
  }, []);

  const hasUnread = useMemo(() => (
    hasUnreadNotifications
    || notifications.some((notif) => !notif.hit)
  ), [notifications, hasUnreadNotifications]);

  const handleMarkAsRead = useCallback((id, hit) => {
    const updatedNotifications = notifications.map((notif) => {
      if (notif.id === id) {
        return {
          ...notif,
          hit,
        };
      }
      return notif;
    });
    dispatch({
      type: 'SET_NOTIFICATIONS',
      payload: updatedNotifications,
    });
    dispatch({
      type: 'SET_HAS_UNREAD_NOTIFICATIONS',
      payload: false,
    });
  }, [notifications, dispatch]);

  const handleMarkAllAsRead = useCallback(() => {
    if (!notifications.length) return;
    const updatedNotifications = notifications.map((notif) => ({
      ...notif,
      hit: true,
    }));
    const newestNotif = notifications.reduce((acc, notif) => {
      const notifTimestamp = new Date(notif.createdAt);
      return notifTimestamp > new Date(acc.createdAt) ? notif : acc;
    });
    allReadMutation.mutate(new Date(newestNotif.createdAt)?.getTime?.()?.toString?.());
    dispatch({
      type: 'SET_NOTIFICATIONS',
      payload: updatedNotifications,
    });
    dispatch({
      type: 'SET_HAS_UNREAD_NOTIFICATIONS',
      payload: false,
    });
  }, [notifications, allReadMutation, dispatch]);

  const handleInfiniteScroll = useCallback((e) => {
    const { target } = e;
    // if target is scrolled completely, fetch more data
    if (
      notifications.length < totalNumberOfNotifications
      && target.scrollHeight - target.scrollTop === target.clientHeight
    ) {
      setPagination((prev) => ({
        ...prev,
        page: prev.page + 1,
      }));
    }
  }, [notifications, totalNumberOfNotifications]);

  const displayNotifications = useMemo(() => {
    if (!hideLoader && notifQuery.isFetching) {
      return (
        <NotifSkeleton />
      );
    }
    const sortedNotifications = [...notifications]
      .filter((notif) => !filterUnread || !notif.hit)
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    if (!sortedNotifications.length) {
      return (
        <div className={styles.noNotif}>
          {t('notifications.noNotifications')}
        </div>
      );
    }
    return (
      <>
        {sortedNotifications.map((notif) => (
          <Notification
            key={notif.id}
            item={notif}
            onMarkAsRead={handleMarkAsRead}
          />
        ))}
        {totalNumberOfNotifications > notifications.length && (
          <NotifSkeleton />
        )}
      </>
    );
  }, [notifications, hideLoader, notifQuery, t, handleMarkAsRead, filterUnread]);

  return (
    <div className={styles.notificationsView}>
      <div className={styles.heading}>
        <h1>{t('notifications.title')}</h1>
      </div>
      <div className={styles.content}>
        <div className={utils.cn([styles.tooltip_subheader, 'm-b-10'])}>
          <ToggleInput
            id="filterUnread"
            name="filterUnread"
            className={styles.filterUnread}
            label={t('notifications.filterUnread')}
            checked={!!filterUnread}
            onChange={(check) => setFilterUnread(check)}
          />
          {hasUnread && (
            <span
              className={styles.markAllAsRead}
              role="button"
              tabIndex={0}
              onKeyDown={handleMarkAllAsRead}
              onClick={handleMarkAllAsRead}
            >
              {t('notifications.markAllAsRead')}
            </span>
          )}
        </div>
        <div
          className={styles.tooltipWrapper}
          onScroll={handleInfiniteScroll}
        >
          {displayNotifications}
        </div>
      </div>
    </div>
  );
}

export default NotificationsView;
