import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

// Components
import {
  ToggleInput,
  Message,
  Tag,
  utils,
} from 'ui-library-unlocker';

// Hooks
import useResponsive from '../../../hooks/useResponsive';

// Utils
import { displayError } from '../../../utils/forms/form';

// Styles
import styles from './InventorySigners.module.scss';

function InventorySigners() {
  const { t } = useTranslation();
  const { formik, updateInventoryMutation, isReadOnly } = useOutletContext();
  const { isMobile } = useResponsive();

  const handleChange = useCallback((index, value) => {
    formik.setFieldValue(`signers.${index}.wasPresent`, value);
    if (formik.values.uid) {
      updateInventoryMutation.mutate({
        ...formik.values,
        signers: formik.values.signers.map((signer, idx) => ({
          ...signer,
          wasPresent: index === idx ? value : signer.wasPresent,
        })),
      });
    }
  }, [formik, updateInventoryMutation]);

  const getTagVariant = useCallback((status) => {
    switch (status) {
      case 'signed':
        return 'success';
      case 'pending':
        return 'tertiary';
      default:
        return 'primary';
    }
  }, []);

  const error = displayError(t, formik, 'signers');

  const manager = useMemo(
    () => formik.values.signers
      ?.find((signer) => signer.role === 'manager'),
    [formik.values.signers],
  );

  const managerName = useMemo(() => {
    if (manager?.brandName) return manager.brandName;
    if (manager?.firstName && manager?.lastName) {
      return `${manager.firstName} ${manager.lastName}`;
    }
    return t('global.invitedByUnknown');
  }, [manager]);

  return (
    <form
      id="inventory-signers"
      className={styles.form}
    >
      {isMobile && (
        <h2 className={utils.cn([styles.title, 'm-b-25'])}>
          {t('inventory.crud.menu.signers')}
        </h2>
      )}
      {!formik.values.leaseUid ? (
        <Message
          content={t('inventory.crud.signers.mustSelectLease')}
          variant="info"
        />
      ) : (
        <>
          <h2>{t('inventory.crud.signers.manager')}</h2>
          <div className={utils.cn([styles.signer, 'm-t-25'])}>
            <span>
              {managerName}
            </span>
            {isReadOnly && (
            <Tag
              variant={getTagVariant(manager?.status)}
              label={t(`inventory.crud.signers.status.${manager?.status}`)}
            />
            )}
          </div>
          <h2 className="m-t-25">{t('inventory.crud.signers.tenants')}</h2>
          {error ? (
            <Message
              className="m-t-15"
              content={error}
              variant="error"
            />
          ) : (
            <Message
              className="m-t-15"
              content={t('inventory.crud.signers.info')}
              variant="info"
            />
          )}
          {formik.values.signers
            ?.filter((signer) => signer.role === 'tenant')
            ?.map((signer, index) => (
              <div key={signer.uid} className={utils.cn([styles.signer, 'm-t-25'])}>
                {!isReadOnly ? (
                  <ToggleInput
                    label={signer.firstName && signer.lastName
                      ? `${signer.firstName} ${signer.lastName}`
                      : t('global.invitedByUnknown')}
                    id={signer.uid}
                    name={`signers.${index + 1}`}
                    checked={!!signer.wasPresent}
                    onChange={(check) => handleChange(index + 1, check)}
                  />
                ) : (
                  <>
                    <span>
                      {signer.firstName && signer.lastName
                        ? `${signer.firstName} ${signer.lastName}`
                        : t('global.invitedByUnknown')}
                    </span>
                    <Tag
                      variant={getTagVariant(signer.status)}
                      label={t(`inventory.crud.signers.status.${signer.status}`)}
                    />
                  </>
                )}
              </div>
            ))}
        </>
      )}
    </form>
  );
}

export default InventorySigners;
