import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { utils, Picto } from 'ui-library-unlocker';
import PropertyCard from '../PropertyCard/PropertyCard';

import styles from './SameLocationList.module.scss';

function SameLocationList({
  properties,
  className,
  goBack,
}) {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const generatedResults = useMemo(() => properties.map((property) => (
    <PropertyCard
      item={property}
      key={property.objectID}
    />
  )), [properties]);

  return (
    <div
      className={utils.cn([
        styles.sameLocationList,
        className,
      ])}
    >
      <button
        className={styles.goBack}
        type="button"
        onClick={goBack}
      >
        <Picto
          icon="arrow-down"
          width={24}
          className={styles.backArrow}
        />
        <span>{t('global.goBack')}</span>
      </button>
      <h2>{t('search.sameLocation.title')}</h2>
      <span className={styles.count}>{t('search.sameLocation.count', { count: properties?.length || 0 })}</span>
      <div
        className={styles.list}
        style={{
          height: !isMobile ? 'calc(100vh - 264px)' : 'auto',
        }}
      >
        {generatedResults}
      </div>
    </div>
  );
}

SameLocationList.propTypes = {
  properties: PropTypes.arrayOf(PropTypes.shape({
    objectID: PropTypes.string.isRequired,
    _geoloc: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }).isRequired,
  })),
  className: PropTypes.string,
  goBack: PropTypes.func.isRequired,
};

SameLocationList.defaultProps = {
  properties: [],
  className: null,
};

export default SameLocationList;
