/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useState, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';

import { useAppContext } from '../../../../store/context';

// Components
import DocumentList from '../../../../components/organisms/DocumentList/DocumentList';

// Services
import { getPersonDocumentsFromAdmin } from '../../../../services/admin';

// Utils
import { handleDocumentDownload } from '../../../../utils/documents';

import styles from './UserDocuments.module.scss';

function UserDocuments() {
  const { t } = useTranslation();
  const { context: { user, uiBuilders } } = useAppContext();

  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  const match = useMatch('/user/:id');

  const physicalFileTypeExclude = ['company_identity'];

  const getDocumentType = () => uiBuilders['/person/ui']?.documentTypePPH || {};

  const {
    data: documentListData,
  } = useQuery({
    queryKey: ['manager-documents', user, page, itemsPerPage],
    queryFn: async () => {
      if (user) {
        const response = await getPersonDocumentsFromAdmin(match?.params?.id, {
          page,
          itemsPerPage,
        });
        return { data: { collection: response?.data } };
      }
      return [];
    },
    enabled: !!user,
    keepPreviousData: true,
  });

  const columns = useMemo(() => ([
    {
      header: t('global.documents.columns.headers.category'),
      accessorKey: 'type',
      size: 1,
      enableSorting: false,
      cell: ({ row: { original } }) => {
        if (!uiBuilders) return null;
        const documentType = getDocumentType()[original?.type];
        return (
          <div className={styles.documentTypeValue}>{documentType}</div>
        );
      },
    },
    {
      header: t('global.documents.columns.headers.name'),
      accessorKey: 'name',
      size: 400,
      enableSorting: false,
      cell: ({ row: { original } }) => {
        const fileName = original?.name;
        return (
          <div>
            <p
              tabIndex={0}
              role="button"
              onKeyDown={null}
              onClick={() => handleDocumentDownload(original, t)}
              className={styles.documentNameValue}
            >
              {fileName}
            </p>
          </div>
        );
      },
    },
  ]), [t, uiBuilders]);

  const fileList = useMemo(() => {
    if (uiBuilders) {
      const documentType = getDocumentType();
      return Object.keys(documentType).filter((key) => {
        // Temporary, we will have two specific document types for each user type
        if (key === 'pay_slip') return false;
        if (physicalFileTypeExclude.find((type) => type === key) !== undefined) return false;
        return true;
      });
    }
    return [];
  }, [uiBuilders]);

  return (
    <div className="m-t-50">
      <h2>{t('user.tabs.documents.title')}</h2>
      <DocumentList
        className="m-t-25"
        fileList={fileList}
        columns={columns}
        documentListData={documentListData}
        isReadOnly
        addButton={null}
        onAddBtnClick={() => null}
        onFileListElementAddClick={() => null}
        onFileListElementDelete={() => null}
        pagination={{
          page,
          itemsPerPage,
          setPage,
          setItemsPerPage,
        }}
      />
    </div>
  );
}

UserDocuments.propTypes = {
};

export default UserDocuments;
