/* eslint-disable func-names */
import * as yup from 'yup';
import { EXPORT_TYPES } from '../constants';

export const exportInitialValues = {
  type: EXPORT_TYPES.GENERAL,
  startDate: new Date(new Date().getFullYear(), 0, 1),
  endDate: new Date(new Date().getFullYear() + 1, 0, 1),
};

export const exportSchema = yup.object().shape({
  type: yup.string().oneOf(Object.values(EXPORT_TYPES)).required('global.form.errors.required'),
  startDate: yup.date().required('global.form.errors.required'),
  endDate: yup.date().when('type', {
    is: EXPORT_TYPES.GENERAL,
    then: yup.date().required('global.form.errors.required'),
    otherwise: yup.date().nullable(),
  }),
});
