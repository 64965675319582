/* eslint-disable no-return-assign */
import {
  useCallback, useMemo, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

// Components
import {
  PhotoDragOrTake,
  utils,
} from 'ui-library-unlocker';
import ImageList from '../../../components/organisms/ImageList/ImageList';

// Hooks
import useFileUpload from '../../../hooks/useFileUpload';
import { useAppContext } from '../../../store/context';
import useResponsive from '../../../hooks/useResponsive';

// Styles
import styles from './InventoryRooms.module.scss';

function PhotoSection({
  title,
  id,
  data,
  formik,
  formikKey,
  updateInventoryMutation,
}) {
  const { t } = useTranslation();
  const { context: { user } } = useAppContext();
  const { isReadOnly } = useOutletContext();
  const { isTablet } = useResponsive();

  const [isUploadingPictures, setIsUploadingPictures] = useState(false);
  const [shouldSavePictures, setShouldSavePictures] = useState(false);

  const handleFiles = useCallback((fileList, index, callback, onEnd) => {
    const file = fileList[index];
    if (!file) return onEnd();
    if (file.size > 10000000) {
      utils.toast.error(t('global.documents.addDocumentForm.errors.size.messageWithName', {
        name: file.name,
      }));
      return handleFiles(fileList, index + 1, callback, onEnd);
    }
    const image = new Image();
    image.src = URL.createObjectURL(file);
    return image.onload = () => {
      callback({
        file,
        data: {
          filename: file.name,
          type: 'inventoryPicture',
          userUid: user?.username,
          metadata: {
            documentSize: file.size,
            extension: file.type?.split('/')[1],
            width: image.naturalWidth,
            height: image.naturalHeight,
          },
        },
      });
      handleFiles(fileList, index + 1, callback, onEnd);
    };
  }, [t, user]);

  const [{ uploadFiles }] = useFileUpload();

  const pictures = useMemo(() => {
    if (Array.isArray(data.pictures)) {
      return data.pictures.map((picture) => ({
        uid: picture.documentUid,
        url: `https://${process.env.REACT_APP_MEDIA_URL}/${picture.key}?p=inventory_thumbnail`,
        lightboxUrl: `https://${process.env.REACT_APP_MEDIA_URL}/${picture.key}?p=inventory_full_screen`,
      }));
    }
    return [];
  }, [data]);

  const updatePictures = useCallback((pics) => {
    const newPictures = [
      ...(data.pictures || []),
      ...pics.map((pic) => ({
        documentUid: pic.uid,
        key: pic.cloudImageKey,
      })),
    ];
    formik.setFieldValue(`${formikKey}.pictures`, newPictures);
  }, [formik, formikKey, data.pictures]);

  const deletePicture = useCallback((picUid) => {
    const newPictures = data.pictures.filter((pic) => pic.documentUid !== picUid);
    formik.setFieldValue(`${formikKey}.pictures`, newPictures);
    setShouldSavePictures(true);
  }, [formik, formikKey, data.pictures]);

  const onCameraSubmit = useCallback((images) => {
    if (Array.isArray(images) && images.length > 0) {
      setIsUploadingPictures(true);
      const base64ToFile = (base64, filename) => {
        const arr = base64.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        // eslint-disable-next-line no-plusplus
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
      };
      const files = images.map((image, index) => base64ToFile(image, `photo_${Date.now()}_${index}.jpg`));
      const picturesToUpload = [];
      handleFiles(
        files,
        0,
        (picture) => picturesToUpload.push(picture),
        async () => {
          const res = await uploadFiles(picturesToUpload, null, () => setIsUploadingPictures(false));
          updatePictures(res);
          setIsUploadingPictures(false);
          setShouldSavePictures(true);
        },
      );
    }
  }, [updatePictures, uploadFiles, handleFiles, setIsUploadingPictures]);

  const onDrop = useCallback((files) => {
    if (Array.isArray(files) && files.length > 0) {
      setIsUploadingPictures(true);
      const picturesToUpload = [];
      handleFiles(
        files,
        0,
        (picture) => picturesToUpload.push(picture),
        async () => {
          const res = await uploadFiles(picturesToUpload, null, () => setIsUploadingPictures(false));
          updatePictures(res);
          setIsUploadingPictures(false);
          setShouldSavePictures(true);
        },
      );
    }
  }, [uploadFiles, updatePictures, handleFiles, setIsUploadingPictures]);

  useEffect(() => {
    if (shouldSavePictures) {
      updateInventoryMutation.mutate(formik.values);
      setShouldSavePictures(false);
    }
  }, [shouldSavePictures]);

  return (
    <div className="m-t-25">
      <h3 className="t-h3-700 m-b-25">{title}</h3>
      <ImageList
        className={pictures.length > 0 ? 'm-b-25' : ''}
        images={pictures}
        idSupplement={id}
        entityUID=""
        entityType="inventory"
        refetchImages={() => {}}
        isReadOnly={isReadOnly}
        deletePicture={deletePicture}
      />
      {!isReadOnly && (
        <PhotoDragOrTake
          fileDrop={{
            label: t('inventory.crud.rooms.photos.fileDrop.label'),
            info: t('inventory.crud.rooms.photos.fileDrop.info'),
            onDrop,
          }}
          camera={{
            label: t('inventory.crud.rooms.photos.camera.label'),
            constraints: {
              facingMode: isTablet ? { exact: 'environment' } : 'environment',
            },
            onClose: null,
            onSubmit: onCameraSubmit,
            className: styles.camera,
          }}
          loading={isUploadingPictures}
        />
      )}
    </div>
  );
}

PhotoSection.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  data: PropTypes.shape({
    pictures: PropTypes.arrayOf(PropTypes.shape({
      documentUid: PropTypes.string,
      key: PropTypes.string,
    })),
  }).isRequired,
  formik: PropTypes.shape({
    values: PropTypes.shape({}),
    setFieldValue: PropTypes.func,
  }).isRequired,
  formikKey: PropTypes.string.isRequired,
  updateInventoryMutation: PropTypes.shape({
    mutate: PropTypes.func,
  }).isRequired,
};

PhotoSection.defaultProps = {
  title: '',
  id: '',
};

export default PhotoSection;
