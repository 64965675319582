import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

import Picto from '../../atoms/Picto/Picto';

import cn from '../../../utils/cn';

import styles from './WithHeader.module.scss';

function WithHeader({
  children,
  navContent,
  logoBottom,
  isContained,
  className,
  logoLink,
  sideMenuOpen,
  onMenuToggle,
  displayBurger,
}) {
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  return (
    <>
      <div className={styles.header}>
        <div className={cn([isContained ? 'container' : null, styles.container, className])}>
          <div className={styles.contentLeft}>
            {isMobile && displayBurger
              ? (
                <Picto
                  onClick={onMenuToggle}
                  className="m-r-20"
                  width={24}
                  color="var(--color-white)"
                  icon={sideMenuOpen ? 'close' : 'menu'}
                />
              )
              : null}
            <a href={logoLink} className={cn([styles.logo, logoBottom ? styles.bottom : null])}>
              <Picto width={160} fill="var(--color-white)" icon="logo-horizontal" />
            </a>
          </div>
          {navContent || null}
        </div>
      </div>
      {children}
    </>
  );
}

WithHeader.propTypes = {
  children: PropTypes.node.isRequired,
  navContent: PropTypes.node,
  logoBottom: PropTypes.bool,
  isContained: PropTypes.bool,
  className: PropTypes.string,
  logoLink: PropTypes.string.isRequired,
  sideMenuOpen: PropTypes.bool,
  onMenuToggle: PropTypes.func,
  displayBurger: PropTypes.bool,
};

WithHeader.defaultProps = {
  navContent: null,
  logoBottom: false,
  isContained: true,
  sideMenuOpen: false,
  className: null,
  onMenuToggle: () => {},
  displayBurger: true,
};

export default WithHeader;
