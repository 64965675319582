import { useEffect } from 'react';

const useInterval = (callback, time = 1000, dependency = []) => {
  useEffect(() => {
    const interval = setInterval(callback, time);
    return () => clearInterval(interval);
  }, [...dependency, time, callback]);
};

export default useInterval;
