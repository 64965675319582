import * as yup from 'yup';
import { parsePhoneNumber } from 'libphonenumber-js';
import { utils } from 'ui-library-unlocker';
import { HOUSING_BENEFITS_STATUS_LIST } from './constants';

export const validatePhoneNumber = (name, countryCode, isRequired) => yup.string().test({
  name,
  message: 'global.form.errors.phone',
  test: (value, context) => {
    try {
      if (value === null && !isRequired) return true;
      const fullNumber = utils.getCompletePhoneNumber({
        countryCode: context?.parent[countryCode],
        phone: value,
      });

      if (!fullNumber) return false;

      const phoneNumber = parsePhoneNumber(fullNumber);
      return phoneNumber.isValid();
    } catch (err) {
      return false;
    }
  },
}).nullable('global.form.errors.required');

export const canEditOrDeleteHousingBenefit = (status, recurrent) => {
  if ([
    HOUSING_BENEFITS_STATUS_LIST.PENDING,
    HOUSING_BENEFITS_STATUS_LIST.WAITING_VALIDATION,
    HOUSING_BENEFITS_STATUS_LIST.VALIDATION_ERROR,
  ].includes(status)) {
    return true;
  }
  if (
    status === HOUSING_BENEFITS_STATUS_LIST.SCHEDULED
    && recurrent
  ) {
    return true;
  }
  return false;
};
