import { useCallback, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

// Components
import { Button, RadioGroup, utils } from 'ui-library-unlocker';
import Modal from '../../../molecules/Modal/Modal';

// Styles
import styles from './PropertyForm.module.scss';

function ImpactLeaseModal({
  id,
  onSubmit,
  onCancel,
  loading,
}) {
  const { t } = useTranslation();
  const [shouldImpactLease, setShouldImpactLease] = useState(false);

  const options = useMemo(() => [
    {
      label: t('property.crud.impactLeaseModal.checkboxNoImpact'),
      value: false,
    },
    {
      label: t('property.crud.impactLeaseModal.checkboxImpact'),
      value: true,
    },
  ], [t]);

  const handleSubmit = useCallback(() => {
    onSubmit(shouldImpactLease);
  }, [onSubmit, shouldImpactLease]);

  return createPortal(
    <Modal
      id={id}
      onClose={onCancel}
      title={t('property.crud.impactLeaseModal.title')}
    >
      <div>
        <p>
          <Trans
            i18nKey="property.crud.impactLeaseModal.description"
          />
        </p>
        <RadioGroup
          name="shouldImpactLease"
          className={utils.cn([styles.radioGroupImpactLease, 'm-t-25'])}
          options={options}
          value={shouldImpactLease}
          onChange={setShouldImpactLease}
        />
      </div>
      <div className={utils.cn([
        styles.impactLeaseModalButtonContainer,
        'm-t-40',
      ])}
      >
        <Button
          onClick={handleSubmit}
          size="large"
          label={t('global.confirmationModal.confirm')}
          loading={loading}
        />
        <Button
          onClick={onCancel}
          size="large"
          variant="secondary"
          label={t('global.confirmationModal.cancel')}
        />
      </div>
    </Modal>,
    document.querySelector('body'),
  );
}

ImpactLeaseModal.propTypes = {
  id: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

ImpactLeaseModal.defaultProps = {
  id: 'impact-lease-modal',
  loading: false,
};

export default ImpactLeaseModal;
