/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import { useFormik } from 'formik';
import {
  Button,
  CheckboxGroup,
  ToggleInput,
  ToggleButton,
} from 'ui-library-unlocker';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import useFilters from '../../../hooks/useFilters';

import styles from './PropertySearchFilterForm.module.scss';

function PropertySearchFilterForm({ allOptions, onSubmit }) {
  const { t } = useTranslation();
  const { filters, resetFilters } = useFilters();
  const formik = useFormik({
    initialValues: {
      type: null,
      numberOfRooms: [],
      ...filters,
    },
    enableReinitialize: true,
  });

  return (
    <>
      <div className={styles.filters}>
        <div className={styles.filterGroup}>
          <ToggleInput
            id="showLeased"
            name="showLeased"
            checked={!!formik.values.showLeased}
            label={t('search.filter.showLeased')}
            onChange={(check) => formik.setFieldValue('showLeased', check)}
          />
        </div>
        <div className={styles.filterGroup}>
          <h3>{t('search.filter.type')}</h3>
          <CheckboxGroup
            name="type"
            value={formik.values.type}
            onChange={(v) => formik.setFieldValue('type', v)}
            options={allOptions?.type}
          />
        </div>
        <div className={styles.filterGroup}>
          <h3>{t('search.filter.numberOfRooms.title')}</h3>
          <ToggleButton
            value={formik.values.numberOfRooms}
            onChange={(v) => formik.setFieldValue('numberOfRooms', v)}
            options={allOptions?.numberOfRooms}
            clearLabel={t('search.filter.numberOfRooms.clear')}
            multiple
          />
        </div>
      </div>
      <div className={styles.stickyBottom}>
        <hr className={styles.filterSeparator} />
        <div className={styles.filterActions}>
          <p
            tabIndex={0}
            role="button"
            onKeyDown={null}
            className={styles.reinit}
            onClick={() => {
              resetFilters();
              formik.resetForm();
            }}
          >
            {t('search.filter.reinit')}
          </p>
          <Button
            type="submit"
            variant="primary"
            onClick={() => onSubmit(formik.values)}
          >
            {t('search.filter.submit')}
          </Button>
        </div>
      </div>
    </>
  );
}

PropertySearchFilterForm.propTypes = {
  allOptions: PropTypes.shape({
    status: PropTypes.arrayOf(PropTypes.shape({})),
    type: PropTypes.arrayOf(PropTypes.shape({})),
    numberOfRooms: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default PropertySearchFilterForm;
