import * as yup from 'yup';
import { utils } from 'ui-library-unlocker';
import { twoDecimalPlaces } from '../../../utils/forms/form';

export const documentInitialValues = {
  customName: '',
  filename: '',
  type: null,
  userUid: null,
  metadata: null,
  charge: null,
  amount: null,
  splits: [],
  splitTotal: 0,
};

export const getDocumentSchema = ({ documentType }) => yup.object().shape({
  customName: yup.string().max(50, 'Filename too long').required('global.form.errors.required'),
  filename: yup.string(),
  type: yup.string().nullable().oneOf(documentType, 'global.form.errors.required'),
  userUid: yup.string().required('global.form.errors.required'),
  metadata: yup.object().shape(),
  charge: yup.string().required('global.form.errors.required').nullable(),
  amount: yup
    .number()
    .typeError('global.form.errors.number')
    .moreThan(-1, 'global.form.errors.negativeNumber')
    .required('global.form.errors.required')
    .test(
      'is-decimal',
      'global.form.errors.twoDecimalPlaces',
      (value) => !value || twoDecimalPlaces(value),
    ),
  splits: yup.array().of(yup.object().shape({
    amount: yup
      .number()
      .required('global.form.errors.required')
      .typeError('global.form.errors.required')
      .moreThan(-1, 'global.form.errors.negativeNumber')
      .test(
        'is-decimal',
        'global.form.errors.twoDecimalPlaces',
        (value) => !value || twoDecimalPlaces(value),
      )
      .test({
        message: 'global.form.errors.maxValue',
        test: function test(value, context) {
          const { totalRent } = context?.options?.context || {};
          return !totalRent || utils.euroToCents(value) <= totalRent;
        },
      }),
    date: yup.date().required('global.form.errors.required'),
  })),
  splitTotal: yup.number().test({
    message: 'property.tabs.invoices.errors.splitTotal',
    test: function test(_, context) {
      const { splits, amount } = context?.parent || {};
      if (!splits || !splits.length || !amount) {
        return true;
      }
      const total = splits.reduce((acc, split) => acc + utils.euroToCents(split.amount), 0);
      return total === utils.euroToCents(amount);
    },
  }),
});
