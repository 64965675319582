import * as yup from 'yup';

export const forgotPasswordInitialValues = {
  username: '',
};

const forgotPasswordSchema = yup.object().shape({
  username: yup.string().email('global.form.errors.email').required('global.form.errors.required'),
});

export default forgotPasswordSchema;
