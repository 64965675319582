/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import cn from '../../../utils/cn';

import styles from './CompletionDashboard.module.scss';

import CompletionStep from '../../organisms/CompletionStep/CompletionStep';
import Picto from '../../atoms/Picto/Picto';

const CompletionDashboard = ({
  title,
  className,
  items,
  minHeight,
  loading,
  windowWidth,
}) => {
  const [hasExpanded, setHasExpanded] = useState(false);

  const MOBILE = windowWidth <= 1024;

  const getWidth = (width) => {
    if (MOBILE) return '100%';
    if (hasExpanded) return 0;
    return width;
  };

  return (
    <div
      className={cn([
        styles.completionDashboard,
        className,
        hasExpanded && styles.hasExpanded,
      ])}
    >

      <h2 className={styles.title}>{title}</h2>

      {loading ? (
        <Picto icon="loading" width={20} color="var(--color-secondary)" />
      ) : (
        <div
          className={styles.completionList}
          style={{
            height: MOBILE ? undefined : minHeight,
          }}
        >
          {items?.map((el) => (
            <CompletionStep
              key={el.id}
              {...el}
              onExpand={(value) => setHasExpanded(value)}
              width={getWidth(el.width)}
              className={styles.compStep}
              windowWidth={windowWidth}
            />
          ))}
        </div>
      )}

    </div>
  );
};

CompletionDashboard.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loading: PropTypes.bool,
  windowWidth: PropTypes.number,
};

CompletionDashboard.defaultProps = {
  className: null,
  minHeight: 'auto',
  loading: false,
  windowWidth: 1920,
};

export default CompletionDashboard;
