import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Picto } from 'ui-library-unlocker';

// utils
import { showModal } from '../../../utils/modal';
import useHover from '../../../hooks/useHover';

import styles from './AddIbanBtn.module.scss';

function AddIbanBtn({
  modalId,
  parentRef,
}) {
  const isHovering = useHover(parentRef);

  const handleClick = useCallback(() => {
    showModal(modalId);
  }, [modalId]);

  return (
    <div
      className={styles.addIbanBtn}
      onClick={handleClick}
      onKeyDown={handleClick}
      role="button"
      tabIndex={0}
    >
      <Picto
        icon="plus"
        width={35}
        color={isHovering ? 'var(--color-secondary-300)' : 'var(--color-primary-300)'}
      />
    </div>
  );
}

AddIbanBtn.propTypes = {
  modalId: PropTypes.string.isRequired,
  parentRef: PropTypes.instanceOf(Object).isRequired,
};

AddIbanBtn.defaultProps = {
};

export default AddIbanBtn;
