/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';
import {
  UnlockerLoader,
} from 'ui-library-unlocker';

// Components
import ProfileDocumentsList from '../../../components/organisms/DocumentList/ProfileDocumentsList/ProfileDocumentsList';

// Services
import { getOwnerDocumentList } from '../../../services/person';

// Constants
import { RENT_DISTRIBUTION_STATUS } from '../../../utils/constants';

function OwnerDocuments({
  rentDistribution,
}) {
  const { t } = useTranslation();

  const match = useMatch('/owner/:id');

  const {
    data: documentListData,
    isLoading: documentListLoading,
    refetch: refetchDocumentList,
  } = useQuery({
    queryKey: ['owner-documents', match?.params?.id],
    queryFn: () => getOwnerDocumentList(match?.params?.id),
    keepPreviousData: true,
  });

  const isReadOnly = useMemo(() => true, []);

  if (documentListLoading) return <UnlockerLoader size={200} align="left" />;

  return (
    <div className="m-t-50">
      <h2>{t('owner.tabs.documents.title')}</h2>
      <ProfileDocumentsList
        documentsData={documentListData?.data?.collection || []}
        refetchDocuments={refetchDocumentList}
        userUID={match?.params?.id || ''}
        rentDistribution={rentDistribution}
        isReadOnly={isReadOnly}
      />
    </div>
  );
}

OwnerDocuments.propTypes = {
  rentDistribution: PropTypes.oneOf(Object.values(RENT_DISTRIBUTION_STATUS)),
};

OwnerDocuments.defaultProps = {
  rentDistribution: null,
};

export default OwnerDocuments;
