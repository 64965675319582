/* eslint-disable max-len */
import api from './_client';

/**
 *
 * @param {number} page page number
 * @param {number} itemsPerPage number of items per page
 * @param {Object} filters number of items per page
 * @returns {import('axios').AxiosResponse<any>}
 */
export const getConversations = ({ page, itemsPerPage, filters = {} }) => {
  const apiFilters = {
    'filters[status][--in--]': filters?.status?.join?.(','),
    q: filters.search?.[0],
  };
  return api.get('/messaging/conversations', {
    params: {
      page,
      itemsPerPage,
      ...apiFilters,
    },
  });
};

export const createConversation = (data) => api.post('/messaging/conversations', data);

export const getConversation = (uid) => api.get(`/messaging/conversations/${uid}`);

export const getMessagesFromConversation = (conversationUid, { page, itemsPerPage, filters = {} }) => {
  const apiFilters = {
    'filters[status][--in--]': filters?.status?.join?.(','),
    q: filters.search?.[0],
  };
  return api.get(`/messaging/conversations/${conversationUid}/messages`, {
    params: {
      page,
      itemsPerPage,
      ...apiFilters,
    },
  });
};

export const hitMessage = (messageUid) => api.post(`/messaging/messages/${messageUid}/hit`);

export const sendUserMessage = (conversationUid, data) => api.post(`/messaging/conversations/${conversationUid}/send`, data);
