/* eslint-disable react/no-array-index-key */
import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Link, useParams, useLocation,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components
import {
  utils,
  Picto,
} from 'ui-library-unlocker';

// Hooks
import useScroll from '../../../hooks/useScroll';
import useResponsive from '../../../hooks/useResponsive';

// Constants
import { INVENTORY_ROOMS } from '../../../utils/constants';

// Styles
import styles from './InventoryMenu.module.scss';

function InventoryMenu({
  className,
  rooms,
  propertyUid,
  close,
}) {
  const { id } = useParams();
  const { t } = useTranslation();
  const { scrollToElement } = useScroll();
  const { hash, pathname } = useLocation();
  const { isMobile } = useResponsive();

  const roomTypeOptions = useMemo(() => INVENTORY_ROOMS.map((room) => ({
    label: room.label,
    value: room.type,
  })), []);

  const listItems = useMemo(() => [
    {
      label: 'general',
      icon: 'etat-lieux',
      to: isMobile ? '#inventory-general' : '',
    },
    {
      label: 'meters',
      icon: 'charge',
      to: isMobile ? '#inventory-meters' : '/meters',
      disabled: !propertyUid,
    },
    {
      label: 'keys',
      icon: 'key',
      to: isMobile ? '#inventory-keys' : '/keys',
      disabled: !propertyUid,
    },
    {
      label: 'rooms',
      icon: 'house-2',
      to: isMobile ? '#inventory-rooms' : '/rooms',
      nestedRoutes: rooms?.map((room, index) => {
        const sameTypeBeforeCount = rooms.slice(0, index).filter((r) => r.type === room.type).length;
        return ({
          label: `${
            roomTypeOptions.find((typeItem) => typeItem.value === room.type)?.label
            || t('inventory.crud.rooms.new')
          }${sameTypeBeforeCount > 0 ? ` (${sameTypeBeforeCount + 1})` : ''}`,
          to: `#${`rooms-${index}`}`,
        });
      }),
      disabled: !propertyUid,
    },
    {
      label: 'signers',
      icon: 'sign',
      to: isMobile ? '#inventory-signers' : '/signers',
      disabled: !propertyUid,
    },
  ], [rooms, propertyUid, roomTypeOptions, t, isMobile]);

  const handleClose = useCallback(() => {
    if (isMobile) close();
  }, [close, isMobile]);

  const handleNestedLinkClick = useCallback((linkHash) => {
    setTimeout(() => scrollToElement(linkHash.slice(1), 'instant'), 50);
    handleClose();
  }, [handleClose]);

  const getIsActive = useCallback((item) => {
    const pathLast = pathname.split('/').pop();
    if (isMobile) {
      return (
        hash === item.to
      || item.nestedRoutes?.map((nested) => nested.to)?.includes(hash)
      );
    }
    if (pathLast === id) return item.to === '';
    return pathLast === item.to.replace('/', '');
  }, [pathname, id, isMobile, hash]);

  const renderMenu = useCallback(() => listItems.map((item) => {
    const isActive = getIsActive(item);
    return (
      <li key={item.label}>
        <Link
          to={`/inventory/${id}${item.to || ''}`}
          onClick={() => (isMobile ? handleNestedLinkClick(item.to) : handleClose())}
          disabled={item.disabled}
          className={isActive ? 'active' : null}
        >
          <div>
            <Picto
              icon={item.icon}
              width={20}
              color={isActive ? 'var(--color-secondary)' : 'var(--color-primary)'}
            />
          </div>
          <div className={styles.listItemLabel}>
            <span>
              {t(`inventory.crud.menu.${item.label}`)}
            </span>
          </div>
        </Link>
        {item.nestedRoutes && (
          <ul className={styles.nestedRoutes}>
            {item.nestedRoutes.map((nestedItem, index) => (
              <li key={index}>
                <Link
                  to={`/inventory/${id}${isMobile ? '' : item.to}${nestedItem.to}`}
                  onClick={() => handleNestedLinkClick(nestedItem.to)}
                  className={hash === nestedItem.to ? styles.active : ''}
                  disabled={item.disabled}
                >
                  <span>
                    {nestedItem.label}
                  </span>
                </Link>
              </li>
            ))}
          </ul>
        )}
      </li>
    );
  }), [listItems, id, t, hash, handleNestedLinkClick, getIsActive, isMobile, handleClose]);

  return (
    <nav className={utils.cn([styles.container, className])}>
      <ul>
        {renderMenu()}
      </ul>
    </nav>
  );
}

InventoryMenu.propTypes = {
  className: PropTypes.string,
  rooms: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  close: PropTypes.func.isRequired,
  propertyUid: PropTypes.string,
};

InventoryMenu.defaultProps = {
  className: '',
  propertyUid: null,
};

export default InventoryMenu;
