/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { utils } from 'ui-library-unlocker';

import styles from './TableOptions.module.scss';

function TableOptions({ options }) {
  const [hovered, setHovered] = useState(null);

  if (options?.filter((elem) => elem != null)?.length === 0) {
    return null;
  }

  return (
    <ul className={styles.wrapper}>
      {options?.filter((elem) => elem != null)?.map((option, index) => {
        const isHovered = hovered === index;

        return (
          <li
            key={option?.id || option?.label}
            onMouseEnter={() => setHovered(index)}
            onMouseLeave={() => setHovered(null)}
            onClick={(e) => option.onClick(e)}
            className={utils.cn([styles.option, isHovered ? styles.hovered : null])}
          >
            <div className={styles.icon}>
              {isHovered ? option.onHoverIcon : option.icon}
            </div>
            <div className={styles.label}>
              {option.label}
            </div>
          </li>
        );
      })}
    </ul>
  );
}

TableOptions.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default TableOptions;
