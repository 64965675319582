import PropTypes from 'prop-types';

// Components
import {
  utils,
  Button,
} from 'ui-library-unlocker';
import Image from '../../atoms/Image/Image';

// Styles
import styles from './UploadedImage.module.scss';

function UploadedImage({
  src,
  onViewClick,
  onDeleteClick,
  className,
  isReadOnly,
}) {
  return (
    <div
      className={utils.cn([styles.imageUpload__img__container, className])}
      tabIndex={0}
      role="button"
      onKeyDown={null}
    >
      <Image
        className={styles.imageUpload__img}
        src={src}
        alt="image"
      />
      <div className={styles.pictoContainer}>
        <Button
          variant="secondary"
          icon="eye"
          size="medium"
          onClick={onViewClick}
          className={styles.picto}
        />
        {!isReadOnly && (
          <Button
            variant="secondary"
            icon="trash"
            size="medium"
            onClick={onDeleteClick}
            className={styles.picto}
          />
        )}
      </div>
    </div>
  );
}

UploadedImage.propTypes = {
  src: PropTypes.string,
  onViewClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  className: PropTypes.string,
  isReadOnly: PropTypes.bool,
};

UploadedImage.defaultProps = {
  src: '',
  onViewClick: () => {},
  onDeleteClick: () => {},
  className: '',
  isReadOnly: false,
};

export default UploadedImage;
