/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, {
  useState,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import {
  Tag,
  utils,
} from 'ui-library-unlocker';
import { centsToEuro } from 'ui-library-unlocker/src/utils/money';

// Components
import DataTable from '../../../components/organisms/DataTable/DataTable';

// Services
import { getUserPayments } from '../../../services/payment';
import { getConnectedTenantLeases } from '../../../services/lease';

// Hooks
import useFilters from '../../../hooks/useFilters';

// Utils
import { formatAddress } from '../../../utils/properties';
import { getPaymentAndAccountStatusVariant } from '../../../utils/variants';

// Constants
import { PAYMENT_AND_ACCOUNT_STATUS_LIST } from '../../../utils/constants';

import styles from './PaymentsHistory.module.scss';

const STATUS_FILTERS = [
  PAYMENT_AND_ACCOUNT_STATUS_LIST.SUCCESS,
  PAYMENT_AND_ACCOUNT_STATUS_LIST.FAILED,
  PAYMENT_AND_ACCOUNT_STATUS_LIST.CANCELED,
  PAYMENT_AND_ACCOUNT_STATUS_LIST.SCHEDULING_ERROR,
];

function PaymentsHistory() {
  const { t } = useTranslation();
  const { filters } = useFilters();

  const [data, setData] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);

  const leases = useMemo(() => {
    if (!data) return [];
    return [...new Set(data.map((p) => p.lease))];
  }, [data]);

  const {
    data: leaseListData,
    isFetching: leaseListFetching,
  } = useQuery({
    queryKey: ['payment-history-lease-list', leases],
    queryFn: () => getConnectedTenantLeases({
      filters: {
        uid: leases,
      },
    }),
    keepPreviousData: true,
    enabled: !!leases.length > 0,
  });

  const ratio = useMemo(() => (amount = 0, amountPaid = 0) => {
    if (amount === null || amountPaid === null) return 0;
    return amountPaid - amount;
  }, []);

  const columns = useMemo(() => ([
    {
      header: t('payment.table.columns.property'),
      accessorKey: 'propertyName',
      size: 200,
      enableSorting: false,
      cell: ({ row: { original: { lease } } }) => {
        const {
          propertyName,
          propertyAddress,
        } = leaseListData?.data?.collection?.find((l) => l.uid === lease) || {};
        return (
          <div>
            <div className={styles.nameAddress}>
              <p className="p-2-700">
                {propertyName}
              </p>
              <p className="p-2-500">
                {formatAddress(propertyAddress)}
              </p>
            </div>
          </div>
        );
      },
    },
    {
      header: t('payment.table.columns.date'),
      accessorKey: 'date',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { month, year } } }) => (
        <div>
          <span>{month && year ? `${t(`global.months.${month}`)} ${year}` : '-'}</span>
        </div>
      ),
    },
    {
      header: t('payment.table.columns.paymentMethod'),
      accessorKey: 'paymentMethod',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { paymentMethod } } }) => (
        <span>
          {t(`global.paymentMethods.${paymentMethod}`)}
        </span>
      ),
    },
    {
      header: t('payment.table.columns.amountDue'),
      accessorKey: 'amount',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { amount } } }) => (
        <div>
          <span>{amount != null ? `${centsToEuro(amount)} €` : '-'}</span>
        </div>
      ),
    },
    {
      header: t('payment.table.columns.amountPaid'),
      accessorKey: 'amountPaid',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { amountPaid } } }) => (
        <div>
          <span>{amountPaid != null ? `${centsToEuro(amountPaid)} €` : '-'}</span>
        </div>
      ),
    },
    {
      header: t('payment.table.columns.ratio'),
      accessorKey: 'ratio',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { amount, amountPaid } } }) => {
        const ratioValue = ratio(amount, amountPaid);
        const operator = ratioValue > 0 ? '+' : '';
        return (
          <div
            className={utils.cn([
              styles.ratio,
              ratioValue >= 0 ? styles.positive : styles.negative,
            ])}
          >
            <span>{`${operator}${centsToEuro(ratioValue)} €`}</span>
          </div>
        );
      },
    },
    {
      header: t('payment.table.columns.status'),
      accessorKey: 'status',
      size: 150,
      enableSorting: false,
      cell: ({ row: { original: { status } } }) => (
        <div>
          <Tag
            label={t(`payment.table.status.${status}`)}
            size="medium"
            variant={getPaymentAndAccountStatusVariant(status)}
          />
        </div>
      ),
    },
  ]), [t, leaseListData]);

  const allFiltersOptions = useMemo(() => [
    {
      name: 'status',
      type: 'checkbox',
      title: t('payment.table.columns.status'),
      options: STATUS_FILTERS.map((status) => ({
        value: status,
        label: t(`payment.table.status.${status}`),
      })),
    },
  ], []);

  return (
    <div>
      <DataTable
        columns={columns}
        searchLabel={t('payment.search')}
        getData={getUserPayments}
        queryKey="payment-list"
        allFiltersOptions={allFiltersOptions}
        setData={(value) => setData(value)}
        setDataLoading={(value) => setDataLoading(value)}
        loading={leaseListFetching || dataLoading}
        forcedFilters={{
          status: filters?.status?.length > 0 ? filters?.status : STATUS_FILTERS,
          orderBy: { property: 'dueDate', direction: 'DESC' },
        }}
      />
    </div>
  );
}

export default PaymentsHistory;
