import React, {
  useMemo,
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate, useMatch, Link } from 'react-router-dom';
import { isEmpty, debounce } from 'lodash';
import TagManager from 'react-gtm-module';

// Components
import {
  utils,
  TextInput,
  ToggleInput,
  SelectInput,
  WysiwygInput,
  Button,
  Message,
  UnlockerLoader,
} from 'ui-library-unlocker';
import AddressInput from '../../../molecules/AddressInput/AddressInput';
import FormInfoRequired from '../../../atoms/FormInfoRequired/FormInfoRequired';
import ImpactLeaseModal from './ImpactLeaseModal';

// Services
import { createProperty, updateProperty } from '../../../../services/property';
import { updatePropertyFromAdmin } from '../../../../services/admin';
import { getOwners } from '../../../../services/owner';
import { getManagers } from '../../../../services/person';

// Context
import { useAppContext } from '../../../../store/context';

// Hooks
import useScroll from '../../../../hooks/useScroll';
import useRoles from '../../../../hooks/useRoles';
import useProfileCompletion from '../../../../hooks/useProfileCompletion';
import useProfile from '../../../../hooks/useProfile';

// Utils
import propertySchema, { propertyInitialValues } from '../../../../utils/forms/propertySchema';
import { displayError, errorFocusSubmit, isFieldValid } from '../../../../utils/forms/form';
import { showModal, hideModal, checkIsModalOpen } from '../../../../utils/modal';

// Styles
import styles from './PropertyForm.module.scss';

// Constants
import { ENROLMENT_STATUS, PROPERTY_STATUS } from '../../../../utils/constants';

const IMPACT_LEASE_MODAL_ID = 'impact-lease-modal';

function PropertyForm({ isEditing, propertyQuery }) {
  const { t } = useTranslation();
  const { context: { user, uiBuilders, me } } = useAppContext();
  const { profile } = useProfile();
  const { onboardingStatus } = useProfileCompletion();
  const { scrollContentToTop } = useScroll();
  const navigate = useNavigate();
  const { isUserRealEstateManager, isUserAdmin } = useRoles();

  const [statusMessage, setStatusMessage] = useState({ displayed: false, value: '' });
  const [ownerQuery, setOwnerQuery] = useState('');
  const [managerQuery, setManagerQuery] = useState('');

  const propertyBuilders = useMemo(() => (uiBuilders ? uiBuilders['/property/ui'] : null), [uiBuilders]);

  const userType = useMemo(() => {
    if (isUserAdmin) return 'admin';
    if (isUserRealEstateManager) return 'manager';
    return 'owner';
  }, [isUserAdmin, isUserRealEstateManager]);

  const match = useMatch('/property/:id');

  const {
    data: propertyData,
    isFetched: isPropertyFetched,
    error: propertyError,
    refetch: propertyRefetch,
  } = propertyQuery || {};

  const {
    data: ownerListData,
    isFetching: isOwnerListFetching,
    isLoading: isOwnerListLoading,
  } = useQuery({
    queryKey: ['owner-list-property-form', ownerQuery],
    queryFn: () => getOwners({
      page: 1,
      itemsPerPage: 100,
      filters: {
        search: [ownerQuery],
      },
    }),
    keepPreviousData: true,
    enabled: isUserRealEstateManager,
  });

  const {
    data: managerListData,
    isFetching: isManagerListFetching,
    isLoading: isManagerListLoading,
  } = useQuery({
    queryKey: ['managers-list', managerQuery],
    queryFn: () => getManagers({
      page: 1,
      itemsPerPage: 100,
      filters: {
        onboardingStatus: [
          ENROLMENT_STATUS.PROFILE_DONE,
          ENROLMENT_STATUS.COMPLETED,
          ENROLMENT_STATUS.ENROLMENT_BYPASSED,
        ],
        search: [managerQuery],
      },
    }),
    keepPreviousData: true,
  });

  const handleSearchOwner = useCallback(debounce((value) => {
    setOwnerQuery(value);
  }, 500), [setOwnerQuery]);

  const handleSearchManager = useCallback(debounce((value) => {
    setManagerQuery(value);
  }, 500), [setManagerQuery]);

  const profileCompaniesNotRealEstate = useMemo(() => {
    if (Array.isArray(me?.aclMatrix?.companies)) {
      return me.aclMatrix.companies.filter((company) => company.owner && !company.isRealEstateAgency);
    }
    return [];
  }, [me]);

  const ownerListOptions = useMemo(() => {
    if (profile && user) {
      const options = [];
      if (!isUserRealEstateManager) {
        options.push({
          value: user.username,
          label: `${profile.firstName} ${profile.lastName}`,
        });
      } else {
        ownerListData?.data?.collection
          ?.filter((owner) => (
            [
              ENROLMENT_STATUS.PROFILE_DONE,
              ENROLMENT_STATUS.COMPLETED,
              ENROLMENT_STATUS.ENROLMENT_BYPASSED,
            ].includes(owner.onboardingStatus)
            || owner.delegatedTo === user.username
          ))
          ?.forEach((owner) => {
            options.push({
              value: owner.uid,
              label: `${owner.firstName} ${owner.lastName}`,
            });
            if (Array.isArray(owner.companies)) {
              owner.companies
                .filter((company) => !company.isRealEstateAgency)
                .forEach((company) => {
                  options.push({
                    value: company.uid,
                    label: company.brandName,
                    parent: {
                      value: owner.uid,
                      label: `${owner.firstName} ${owner.lastName}`,
                    },
                  });
                });
            }
          });
      }
      profileCompaniesNotRealEstate.forEach((company) => {
        options.push({
          value: company.uid,
          label: company.brandName,
          parent: !isUserRealEstateManager
            ? {
              value: user.username,
              label: `${profile.firstName} ${profile.lastName}`,
            }
            : undefined,
        });
      });
      return options;
    }
    return [];
  }, [profileCompaniesNotRealEstate, user, isUserRealEstateManager, profile, ownerListData]);

  const managerListOptions = useMemo(() => {
    if (managerListData?.data?.collection) {
      return managerListData?.data?.collection?.map((manager) => ({
        value: manager.uid,
        label: manager.brandName,
      }));
    }
    return [];
  }, [managerListData]);

  const propertyMutation = useMutation({
    mutationFn: (data) => {
      if (isEditing) {
        if (isUserAdmin) return updatePropertyFromAdmin(match?.params?.id, data);
        return updateProperty(match?.params?.id, data);
      }
      TagManager.dataLayer({
        dataLayer: {
          event: 'property_created',
          userType,
        },
      });
      return createProperty(data);
    },
    onSuccess: ({ response, status, data }) => {
      const s = status || response?.status;
      switch (s) {
        case 201:
          utils.toast.success(t('property.tabs.generalInfo.formSuccess'));
          setStatusMessage({ displayed: true, value: t('property.tabs.generalInfo.formSuccess') });
          window.scrollTo({ top: 0, behavior: 'smooth' });
          navigate(`/property/${data?.uid}`);
          break;
        case 204:
          if (checkIsModalOpen(IMPACT_LEASE_MODAL_ID)) {
            hideModal(IMPACT_LEASE_MODAL_ID);
          }
          propertyRefetch().then(() => {
            setStatusMessage({ displayed: true, value: t('property.tabs.generalInfo.formSuccess') });
            scrollContentToTop();
          });
          break;
        default:
          break;
      }
      formik.setSubmitting(false);
    },
    onError: (err) => {
      if (err?.response) {
        switch (err?.response?.status) {
          case 400: {
            utils.toast.error(t('global.form.errors.generic'));
            break;
          }
          case 403: {
            utils.toast.error(t('global.form.errors.forbidden'));
            break;
          }
          default: {
            utils.toast.error(t('global.form.errors.generic'));
            break;
          }
        }
      }
      formik.setSubmitting(false);
    },
  });

  const initialValues = useMemo(() => {
    if (propertyError) return propertyData;
    if (propertyData) {
      return {
        ...propertyData?.data,
        rentExcludingCharges: utils.centsToEuro(propertyData?.data?.rentExcludingCharges),
        rentSupplement: utils.centsToEuro(propertyData?.data?.rentSupplement || 0),
        charges: utils.centsToEuro(propertyData?.data?.charges),
        fees: utils.centsToEuro(propertyData?.data?.fees || 0),
        impactActualLease: false,
      };
    }
    return propertyInitialValues;
  }, [user, propertyData, propertyError]);

  const userHasManagementRights = useMemo(() => {
    if (isUserAdmin) return true;
    if (user && me?.aclMatrix?.companies && propertyData?.data?.managerUid) {
      const options = [user.username];
      if (Array.isArray(me.aclMatrix.companies)) {
        const userCompanies = me.aclMatrix.companies
          .filter((company) => company.owner || (!company.owner && !company.isRealEstateAgency));
        userCompanies.forEach((company) => {
          options.push(company.uid);
        });
      }
      return options.includes(propertyData.data.managerUid);
    }
    return false;
  }, [propertyData, user, me, isUserAdmin]);

  const editProperty = useCallback((data) => {
    propertyMutation.mutate({
      ...data,
      rentExcludingCharges: utils.euroToCents(data.rentExcludingCharges),
      rentSupplement: utils.euroToCents(data.rentSupplement || 0),
      charges: utils.euroToCents(data.charges),
      fees: userHasManagementRights
        ? utils.euroToCents(data.fees)
        : undefined,
      impactActualLease: !!data.impactActualLease,
    });
  }, [propertyMutation, user, userHasManagementRights]);

  const formik = useFormik({
    initialValues,
    validate: (values) => {
      try {
        propertySchema.validateSync(values, {
          context: {
            needFees: isUserRealEstateManager && userRealEstateAgency.uid === values.managerUid,
          },
          abortEarly: false,
        });
      } catch (err) {
        const errorList = err.inner.reduce((errors, error) => {
          errors[error.path] = error.message;
          return errors;
        }, {});

        return errorList;
      }
      return {};
    },
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (isEditing && values.status === PROPERTY_STATUS.LEASED && values.fees !== initialValues.fees) {
        showModal(IMPACT_LEASE_MODAL_ID);
        formik.setSubmitting(false);
      } else {
        editProperty(values);
      }
    },
  });

  const statusOptions = useMemo(() => {
    if (!propertyBuilders?.propertyStatus) return [];
    return Object.keys(propertyBuilders.propertyStatus).map((status) => ({
      value: status,
      label: propertyBuilders.propertyStatus[status],
    }));
  }, [propertyBuilders]);

  const typeOptions = useMemo(() => {
    if (!propertyBuilders?.propertyTypes) return [];
    return Object.keys(propertyBuilders.propertyTypes).map((type) => ({
      value: type,
      label: propertyBuilders?.propertyTypes[type],
    }));
  }, [propertyBuilders]);

  const furnishedOptions = useMemo(() => [
    {
      label: t('property.crud.form.isFurnished'),
      value: true,
    },
    {
      label: t('property.crud.form.isUnfurnished'),
      value: false,
    },
  ], [propertyBuilders]);

  const chargesRepartitionOptions = [
    {
      label: t('property.crud.form.provision'),
      value: 'provision',
    },
    {
      label: t('property.crud.form.lumpSum'),
      value: 'lumpSum',
    },
  ];

  const isOwnManager = useMemo(() => {
    if (formik.values.ownerUid || formik.values.managerUid) {
      return formik.values.ownerUid === formik.values.managerUid;
    }
    return false;
  }, [formik.values.ownerUid, formik.values.managerUid]);

  const ownerBelongsToUser = useMemo(() => {
    if (formik.values.ownerUid) {
      return profileCompaniesNotRealEstate.map((company) => company.uid).includes(formik.values.ownerUid);
    }
    return false;
  }, [formik.values.ownerUid, profileCompaniesNotRealEstate]);

  const userRealEstateAgency = useMemo(() => {
    if (Array.isArray(me?.aclMatrix?.companies)) {
      return me.aclMatrix.companies.find((company) => company.owner && company.isRealEstateAgency);
    }
    return null;
  }, [me]);

  const isFormError = useMemo(() => !isEmpty(formik.errors), [formik.errors]);

  const needOwner = useMemo(
    () => (
      isUserRealEstateManager
      && !me?.aclMatrix?.propertyOwnerRelations.length
      && profileCompaniesNotRealEstate.length === 0
    ),
    [isUserRealEstateManager, me, profileCompaniesNotRealEstate],
  );

  const isReadOnly = useMemo(() => {
    if (isUserAdmin) return false;
    if (!isEditing) return false;
    return !userHasManagementRights;
  }, [isEditing, isUserAdmin, userHasManagementRights]);

  const isFormReady = useMemo(() => (
    isPropertyFetched
    && (!isOwnerListLoading || !isUserRealEstateManager)
    && !isManagerListLoading
  ), [isPropertyFetched, isOwnerListLoading, isManagerListLoading]);

  const selectedOwner = useMemo(() => (formik.values.ownerUid ? ({
    value: formik.values.ownerUid,
    label: formik.values.ownerName,
  }) : null), [formik.values.ownerUid, formik.values.ownerName]);

  const selectedManager = useMemo(() => (formik.values.managerUid ? ({
    value: formik.values.managerUid,
    label: formik.values.managerName,
  }) : null), [formik.values.managerUid, formik.values.managerName]);

  const closeImpactModal = useCallback(() => {
    hideModal(IMPACT_LEASE_MODAL_ID);
  }, []);

  const handleConfirmLeaseImpact = useCallback((impactActualLease) => {
    editProperty({
      ...formik.values,
      impactActualLease,
    });
  }, [editProperty, formik.values]);

  if (!isFormReady) return <UnlockerLoader size={200} align="left" />;

  if (needOwner) {
    return (
      <>
        <Message className="m-t-30" content={t('property.crud.needOwner')} />
        <div className={utils.cn(['m-t-30', styles.needOwner])}>
          <Button onClick={() => navigate('/owner/add')}>
            {t('property.crud.addFirstOwner')}
          </Button>
          <Link className="p-2-700" to="/property">
            {t('global.goBack')}
          </Link>
        </div>
      </>
    );
  }

  const isAllowedToSubmit = (onboardingStatus !== ENROLMENT_STATUS.PENDING) || isUserAdmin;

  return (
    <div className={styles.wrapper}>
      <form className={styles.form} onSubmit={!isAllowedToSubmit ? null : errorFocusSubmit(formik.handleSubmit)}>
        {!isAllowedToSubmit && !isEditing && (
          <Message
            className="m-b-30"
            variant="info"
            content={t('completeProfile.views.properties.level1Info')}
          />
        )}
        {isReadOnly && (
          <Message
            className="m-b-30"
            variant="info"
            content={t('property.crud.onlyManagerCanEdit')}
          />
        )}
        {/* General Info */}
        <h2 className={utils.cn(['m-b-30', styles.formTitleWidth])}>
          {t('property.subtitles.generalInfo.personalInfo')}
        </h2>
        <FormInfoRequired />
        {isEditing && (
        <TextInput
          type="text"
          id="unlockerId"
          name="unlockerId"
          className="m-t-25"
          label={t('property.crud.form.unlockerId')}
          value={formik.values.unlockerID}
          disabled
        />
        )}
        <SelectInput
          className="m-t-25"
          id="status"
          name="status"
          label={t('property.crud.form.status')}
          options={statusOptions}
          error={displayError(t, formik, 'status')}
          valid={isFieldValid(formik, 'status', null, initialValues?.status)}
          onChange={(value) => {
            formik.setFieldValue('status', value.value);
          }}
          onBlur={formik.handleBlur}
          value={statusOptions.find((status) => status.value === formik.values.status)}
          disabled={isReadOnly}
        />
        <TextInput
          type="text"
          id="name"
          name="name"
          className="m-t-25"
          label={t('property.crud.form.name')}
          error={displayError(t, formik, 'name')}
          valid={isFieldValid(formik, 'name', null, initialValues?.name)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
          disabled={isReadOnly}
        />
        <SelectInput
          className="m-t-25"
          id="type"
          name="type"
          label={t('property.crud.form.type')}
          options={typeOptions}
          error={displayError(t, formik, 'type')}
          valid={isFieldValid(formik, 'type', null, initialValues?.type)}
          onChange={(value) => {
            formik.setFieldValue('type', value.value);
          }}
          onBlur={formik.handleBlur}
          value={typeOptions.find((type) => type.value === formik.values.type)}
          disabled={isReadOnly}
        />
        <SelectInput
          className="m-t-25"
          id="furnished"
          name="furnished"
          label={t('property.crud.form.furnished')}
          options={furnishedOptions}
          error={displayError(t, formik, 'furnished')}
          valid={isFieldValid(formik, 'furnished', { inputType: 'boolean' }, initialValues?.furnished)}
          onChange={(value) => {
            formik.setFieldValue('furnished', value.value);
          }}
          onBlur={formik.handleBlur}
          value={furnishedOptions.find((furnished) => furnished.value === formik.values.furnished)}
          disabled={isReadOnly}
        />

        {/* Address */}
        <h2 className={utils.cn(['m-t-50 m-b-30', styles.formTitleWidth])}>
          {t('property.subtitles.generalInfo.address')}
        </h2>
        <FormInfoRequired />
        {!isEditing || (isEditing && propertyData?.data?.address) ? (
          <AddressInput
            id="address"
            name="address"
            className="m-t-25"
            label={t('property.crud.form.address')}
            error={displayError(t, formik, 'address')}
            valid={isFieldValid(formik, 'address', null, initialValues?.address)}
            onAddressSelect={(value) => formik.setFieldValue('address', value)}
            value={formik.values.address || null}
            disabled={isReadOnly}
            countryRestrictions={['fr', 'gp', 're', 'mq', 'gf', 'nc', 'yt', 'pf']}
          />
        ) : null}

        {/* Description */}
        <h2 className={utils.cn(['m-t-50 m-b-30', styles.formTitleWidth])}>
          {t('property.subtitles.generalInfo.description')}
        </h2>
        <FormInfoRequired content={t('global.form.info.mandatoryExceptOptional')} />
        <TextInput
          min="0"
          type="number"
          id="constructionYear"
          name="constructionYear"
          className="m-t-25"
          label={t('property.crud.form.constructionYear')}
          error={displayError(t, formik, 'constructionYear')}
          valid={isFieldValid(formik, 'constructionYear', null, initialValues?.constructionYear)}
          onChange={(e) => {
            if (e.target.value === '') {
              formik.setFieldValue('constructionYear', null);
              return;
            }
            formik.handleChange(e);
          }}
          onBlur={formik.handleBlur}
          value={formik.values.constructionYear}
          disabled={isReadOnly}
        />
        <TextInput
          type="text"
          id="tantiemes"
          name="tantiemes"
          className="m-t-25"
          label={t('property.crud.form.tantiemes')}
          error={displayError(t, formik, 'tantiemes')}
          valid={isFieldValid(formik, 'tantiemes', null, initialValues?.tantiemes)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.tantiemes}
          disabled={isReadOnly}
        />
        <TextInput
          min="0"
          type="number"
          id="surface"
          name="surface"
          className="m-t-25"
          label={t('property.crud.form.surface')}
          error={displayError(t, formik, 'surface')}
          valid={isFieldValid(formik, 'surface', null, initialValues?.surface)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.surface}
          disabled={isReadOnly}
        />
        <TextInput
          min="0"
          type="number"
          id="numberOfRooms"
          name="numberOfRooms"
          className="m-t-25"
          label={t('property.crud.form.numberOfRooms')}
          error={displayError(t, formik, 'numberOfRooms')}
          valid={isFieldValid(formik, 'numberOfRooms', null, initialValues?.numberOfRooms)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.numberOfRooms}
          disabled={isReadOnly}
        />
        <TextInput
          min="0"
          type="number"
          id="numberOfBathrooms"
          name="numberOfBathrooms"
          className="m-t-25"
          label={t('property.crud.form.numberOfBathrooms')}
          error={displayError(t, formik, 'numberOfBathrooms')}
          valid={isFieldValid(formik, 'numberOfBathrooms', null, initialValues?.numberOfBathrooms)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.numberOfBathrooms}
          disabled={isReadOnly}
        />
        <TextInput
          min="0"
          type="number"
          id="numberOfBedrooms"
          name="numberOfBedrooms"
          className={utils.cn([styles.formInput, 'm-t-25'])}
          label={t('property.crud.form.numberOfBedrooms')}
          error={displayError(t, formik, 'numberOfBedrooms')}
          valid={isFieldValid(formik, 'numberOfBedrooms', null, initialValues?.numberOfBedrooms)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.numberOfBedrooms}
          disabled={isReadOnly}
        />
        <WysiwygInput
          className="m-t-25"
          label={t('property.crud.form.description')}
          onChange={(value) => formik.setFieldValue('description', value)}
          error={displayError(t, formik, 'description', 'wysiwyg')}
          valid={isFieldValid(formik, 'description', null, initialValues?.description)}
          defaultValue={isEditing ? propertyData?.data?.description : null}
          disabled={isReadOnly}
        />

        {/* Informations locatives */}
        <h2 className={utils.cn(['m-t-50 m-b-30', styles.formTitleWidth])}>
          {t('property.subtitles.generalInfo.rentingInfo')}
        </h2>
        <FormInfoRequired />
        <TextInput
          min="0"
          step="0.01"
          type="number"
          id="rentExcludingCharges"
          name="rentExcludingCharges"
          className="m-t-25"
          label={t('property.crud.form.rentExcludingCharges')}
          error={displayError(t, formik, 'rentExcludingCharges')}
          valid={isFieldValid(formik, 'rentExcludingCharges')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.rentExcludingCharges}
          disabled={isReadOnly}
          icon="euro"
          iconSize={20}
          iconColor="var(--color-primary-300)"
        />
        <TextInput
          min="0"
          step="0.01"
          type="number"
          id="rentSupplement"
          name="rentSupplement"
          className="m-t-25"
          label={t('property.crud.form.rentSupplement')}
          error={displayError(t, formik, 'rentSupplement')}
          valid={isFieldValid(formik, 'rentSupplement', null, initialValues?.rentSupplement)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.rentSupplement}
          disabled={isReadOnly}
          icon="euro"
          iconSize={20}
          iconColor="var(--color-primary-300)"
        />
        <TextInput
          min="0"
          step="0.01"
          type="number"
          id="charges"
          name="charges"
          className="m-t-25"
          label={t('property.crud.form.charges')}
          error={displayError(t, formik, 'charges')}
          valid={isFieldValid(formik, 'charges', null, initialValues?.charges)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.charges}
          disabled={isReadOnly}
          icon="euro"
          iconSize={20}
          iconColor="var(--color-primary-300)"
        />
        {!!formik.values.charges && parseFloat(formik.values.charges) > 0 && (
          <SelectInput
            id="chargesRepartition"
            name="chargesRepartition"
            options={chargesRepartitionOptions}
            label={t('property.crud.form.chargesRepartition')}
            className="m-t-25"
            error={displayError(t, formik, 'chargesRepartition')}
            valid={isFieldValid(
              formik,
              'chargesRepartition',
              null,
              initialValues?.chargesRepartition,
            )}
            onChange={(value) => formik.setFieldValue('chargesRepartition', value.value)}
            onBlur={formik.handleBlur}
            value={chargesRepartitionOptions.find((type) => type.value === formik.values.chargesRepartition)}
            disabled={isReadOnly}

          />
        )}
        <ToggleInput
          id="heatingChargesIncluded"
          name="heatingChargesIncluded"
          className="m-t-25"
          label={t('property.crud.form.heatingChargesIncluded')}
          checked={!!formik.values.heatingChargesIncluded}
          onChange={(check) => formik.setFieldValue('heatingChargesIncluded', check)}
          disabled={isReadOnly}
        />
        <ToggleInput
          id="coldWaterChargesIncluded"
          name="coldWaterChargesIncluded"
          className="m-t-25"
          label={t('property.crud.form.coldWaterChargesIncluded')}
          checked={!!formik.values.coldWaterChargesIncluded}
          onChange={(check) => formik.setFieldValue('coldWaterChargesIncluded', check)}
          disabled={isReadOnly}
        />
        <ToggleInput
          id="hotWaterChargesIncluded"
          name="hotWaterChargesIncluded"
          className="m-t-25"
          label={t('property.crud.form.hotWaterChargesIncluded')}
          checked={!!formik.values.hotWaterChargesIncluded}
          onChange={(check) => formik.setFieldValue('hotWaterChargesIncluded', check)}
          disabled={isReadOnly}
        />
        <WysiwygInput
          className="m-t-25"
          label={t('property.crud.form.otherChargesIncluded')}
          onChange={(value) => formik.setFieldValue('otherChargesIncluded', value)}
          error={displayError(t, formik, 'otherChargesIncluded', 'wysiwyg')}
          valid={isFieldValid(formik, 'otherChargesIncluded', null, initialValues?.otherChargesIncluded)}
          defaultValue={isEditing ? propertyData?.data?.otherChargesIncluded : null}
          disabled={isReadOnly}
        />
        <TextInput
          type="text"
          id="deliveryPoint"
          name="deliveryPoint"
          className={utils.cn(['m-t-30', styles.deliveryPointInput])}
          label={t('property.crud.form.deliveryPoint')}
          error={displayError(t, formik, 'deliveryPoint')}
          valid={isFieldValid(formik, 'deliveryPoint', null, initialValues?.deliveryPoint)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.deliveryPoint}
          disabled={isReadOnly}
        />
        <TextInput
          type="text"
          id="taxNumber"
          name="taxNumber"
          className={utils.cn(['m-t-30'])}
          label={t('property.crud.form.taxNumber')}
          error={displayError(t, formik, 'taxNumber')}
          valid={isFieldValid(formik, 'taxNumber', null, initialValues?.taxNumber)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.taxNumber}
          disabled={isReadOnly}
        />
        <TextInput
          type="text"
          id="cadastralRef"
          name="cadastralRef"
          className={utils.cn(['m-t-30'])}
          label={t('property.crud.form.cadastralRef')}
          error={displayError(t, formik, 'cadastralRef')}
          valid={isFieldValid(formik, 'cadastralRef', null, initialValues?.cadastralRef)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.cadastralRef}
          disabled={isReadOnly}
        />

        {/* Owner and Manager */}
        <h2 className={utils.cn(['m-t-50 m-b-30', styles.formTitleWidth])}>
          {t('property.subtitles.generalInfo.ownerInfo')}
        </h2>
        <FormInfoRequired />
        <SelectInput
          className="m-t-25"
          id="ownerUid"
          name="ownerUid"
          label={t('property.crud.form.ownerUid')}
          options={ownerListOptions}
          error={displayError(t, formik, 'ownerUid')}
          valid={isFieldValid(formik, 'ownerUid')}
          onChange={(value) => {
            formik.setFieldValue('ownerUid', value.value);
            formik.setFieldValue('ownerName', value.label);
            if (
              isUserRealEstateManager
              // ownerBelongsToUser not yet updated
              && !profileCompaniesNotRealEstate.map((company) => company.uid).includes(value.value)
            ) {
              formik.setFieldValue('managerUid', userRealEstateAgency?.uid);
              formik.setFieldValue('managerName', userRealEstateAgency?.brandName);
            } else if (isOwnManager) {
              formik.setFieldValue('managerUid', value.value);
              formik.setFieldValue('managerName', value.label);
            }
          }}
          onInputChange={(value) => handleSearchOwner(value)}
          onBlur={formik.handleBlur}
          value={selectedOwner}
          disabled={isReadOnly}
          isLoading={isOwnerListFetching}
        />

        <h2 className={utils.cn(['m-t-50 m-b-30', styles.formTitleWidth])}>
          {t('property.subtitles.generalInfo.managerInfo')}
        </h2>
        <FormInfoRequired />
        {(
          !isUserRealEstateManager
          || (isUserRealEstateManager && ownerBelongsToUser)
        ) && (
          <ToggleInput
            id="isOwnManager"
            name="isOwnManager"
            className={utils.cn(['m-t-30', styles.isOwnManager])}
            label={t('property.tabs.generalInfo.form.isOwnManagerInfo')}
            checked={isOwnManager}
            onChange={(check) => {
              if (!check) formik.setFieldValue('managerUid', '');
              else formik.setFieldValue('managerUid', formik.values.ownerUid);
            }}
          />
        )}
        {!isOwnManager && (
          <SelectInput
            className="m-t-25"
            id="managerUid"
            name="managerUid"
            label={t('property.crud.form.managerUid')}
            options={managerListOptions}
            error={displayError(t, formik, 'managerUid')}
            valid={isFieldValid(formik, 'managerUid')}
            onChange={(value) => {
              formik.setFieldValue('managerUid', value.value);
              formik.setFieldValue('managerName', value.label);
              formik.setFieldTouched('managerUid', true);
            }}
            onInputChange={(value) => handleSearchManager(value)}
            onBlur={formik.handleBlur}
            value={selectedManager}
            disabled={isUserRealEstateManager && !ownerBelongsToUser}
            isLoading={isManagerListFetching}
          />
        )}
        { (
          (isUserRealEstateManager && userRealEstateAgency.uid === formik.values.managerUid)
          || (isUserAdmin && !isOwnManager)
        ) && (
          <TextInput
            type="number"
            min="0"
            step="0.01"
            id="fees"
            name="fees"
            className="m-t-25"
            label={t('property.crud.form.fees')}
            info={t('property.crud.form.feesInfo')}
            error={displayError(t, formik, 'fees')}
            valid={isFieldValid(formik, 'fees', null, initialValues?.fees)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.fees}
            disabled={isReadOnly}
          />
        ) }

        {isFormError
          ? <Message variant="error" className="m-t-30" content={t('global.form.errors.localGeneric')} />
          : null}
        {isAllowedToSubmit && !isReadOnly && (
          <div className={styles.submit}>
            <Button
              type="submit"
              size="large"
              loading={formik.isSubmitting}
              label={t('global.validate')}
            />
            <button
              type="button"
              className={styles.cancel}
              onClick={() => {
                formik.resetForm();
                utils.toast.info(t('profile.personalInfo.form.cancelMessage'));
              }}
            >
              {t('global.cancel')}
            </button>
          </div>
        )}
      </form>
      <div>
        {statusMessage?.displayed ? <Message content={statusMessage.value} /> : null}
      </div>
      <ImpactLeaseModal
        id={IMPACT_LEASE_MODAL_ID}
        onSubmit={handleConfirmLeaseImpact}
        onCancel={closeImpactModal}
        loading={propertyMutation.isLoading}
      />
    </div>
  );
}

PropertyForm.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  propertyQuery: PropTypes.shape().isRequired,
};

export default PropertyForm;
