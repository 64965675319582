/* eslint-disable func-names */
import * as yup from 'yup';

const revisionSchema = yup.object().shape({
  // prefilled
  rentExcludingCharges: yup
    .number()
    .typeError('global.form.errors.number')
    .moreThan(-1, 'global.form.errors.negativeNumber')
    .required('global.form.errors.required'),
  charges: yup
    .number()
    .typeError('global.form.errors.number')
    .moreThan(-1, 'global.form.errors.negativeNumber')
    .required('global.form.errors.required'),
  heatingChargesIncluded: yup.boolean(),
  coldWaterChargesIncluded: yup.boolean(),
  hotWaterChargesIncluded: yup.boolean(),
  isAfterConstructionWork: yup.boolean(),
});

export default revisionSchema;
