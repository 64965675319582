/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useState, useMemo } from 'react';
import { useFormik } from 'formik';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';
import {
  TextInput,
  SelectInput,
  Button,
  utils,
} from 'ui-library-unlocker';

import { useAppContext } from '../../../store/context';

// Components
import FileInput from '../../../components/molecules/FileInput/FileInput';
import Modal from '../../../components/molecules/Modal/Modal';
import DocumentList from '../../../components/organisms/DocumentList/DocumentList';
import FormInfoRequired from '../../../components/atoms/FormInfoRequired/FormInfoRequired';

// Hooks
import useFileUpload from '../../../hooks/useFileUpload';

// Services
import { getTenantDocumentList, deleteUserDocument } from '../../../services/person';
import { createTenantDocument } from '../../../services/tenant';

// Utils
import { handleDocumentDownload } from '../../../utils/documents';

import { showModal, hideModal } from '../../../utils/modal';
import { displayError, isFieldValid } from '../../../utils/forms/form';

import { getDocumentSchema, documentInitialValues } from './documentSchema';

import styles from './TenantDocuments.module.scss';

function TenantDocuments() {
  const { t } = useTranslation();
  const { context: { user, uiBuilders } } = useAppContext();

  const [isHugeDocument, setHugeDocument] = useState(false);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  const match = useMatch('/tenant/:id');

  const addDocumentModalId = 'add-document-modal';

  const physicalFileTypeExclude = ['company_identity'];

  const {
    data: documentListData,
    isFetching: documentListFetching,
    refetch: refetchDocumentList,
  } = useQuery({
    queryKey: ['tenant-documents', user, page, itemsPerPage],
    queryFn: async () => getTenantDocumentList(match?.params?.id, {
      page,
      itemsPerPage,
    }),
    enabled: !!user,
    keepPreviousData: true,
  });

  const documentDeleteMutation = useMutation({
    mutationFn: deleteUserDocument,
    onSuccess: ({ response, status }) => {
      const s = status || response?.status;
      switch (s) {
        case 204:
          utils.toast.success(t('global.documents.delete.success'));
          refetchDocumentList();
          break;
        default:
          break;
      }
    },
    onError: (err) => {
      if (err?.response) {
        switch (err?.response?.status) {
          case 400: {
            utils.toast.error(t('global.form.errors.generic'));
            break;
          }
          default:
            break;
        }
      }
    },
  });

  const validationSchema = useMemo(() => {
    if (uiBuilders) {
      const documentType = uiBuilders['/person/ui'].documentTypePPH;
      return getDocumentSchema({ documentType: Object.keys(documentType) });
    }
    return getDocumentSchema({ documentType: [] });
  }, [uiBuilders]);

  const documentTypeOptions = useMemo(() => {
    if (uiBuilders) {
      const documentType = uiBuilders['/person/ui'].documentTypePPH;
      return Object.keys(documentType).filter((key) => {
        if (physicalFileTypeExclude.find((type) => type === key) !== undefined) return false;
        return true;
      }).map((key) => ({
        value: key,
        label: documentType[key],
      }));
    }
    return [];
  }, [uiBuilders]);

  const initialValues = useMemo(() => {
    if (user) {
      return {
        ...documentInitialValues,
        userUid: match?.params?.id,
      };
    }
    return documentInitialValues;
  }, [match]);

  // DOCUMENT UPLOAD
  const DOCUMENT_TYPE = 'userDocuments';

  const resetDocumentModal = () => {
    hideModal(addDocumentModalId);
    setFileType(null);
    setFile(null);
    setHugeDocument(false);
    formik.resetForm();
  };

  const createDocumentCb = async (documentUid, fileName) => {
    const createdDocument = await createTenantDocument({
      type: fileType,
      name: fileName,
      documentUid,
      personUid: match?.params?.id,
    });
    refetchDocumentList();
    resetDocumentModal();
    return createdDocument;
  };

  const [{ uploadFile, setFileType, setFile }, {
    fileType, file, isUploading,
  }] = useFileUpload(createDocumentCb);

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (typeof file === 'object') {
        uploadFile({
          ...values,
          type: DOCUMENT_TYPE,
        });
      } else {
        utils.toast.info(t('global.documents.addDocumentForm.errors.noFileSelected'));
      }
    },
  });

  const columns = useMemo(() => ([
    {
      header: t('global.documents.columns.headers.category'),
      accessorKey: 'type',
      size: 1,
      enableSorting: false,
      cell: ({ row: { original } }) => {
        if (!uiBuilders) return null;
        const documentType = (uiBuilders['/person/ui'].documentTypePPH)?.[original?.type];
        return (
          <div className={styles.documentTypeValue}>{documentType}</div>
        );
      },
    },
    {
      header: t('global.documents.columns.headers.name'),
      accessorKey: 'name',
      size: 400,
      enableSorting: false,
      cell: ({ row: { original } }) => {
        const fileName = original?.name;
        return (
          <div>
            <p
              tabIndex={0}
              role="button"
              onKeyDown={null}
              onClick={() => handleDocumentDownload(original, t)}
              className={styles.documentNameValue}
            >
              {fileName}
            </p>
          </div>
        );
      },
    },
  ]), [t, uiBuilders]);

  const fileList = useMemo(() => {
    if (uiBuilders) {
      const documentType = uiBuilders['/person/ui'].documentTypePPH;
      return Object.keys(documentType).filter((key) => {
        if (physicalFileTypeExclude.find((type) => type === key) !== undefined) return false;
        return true;
      });
    }
    return [];
  }, [uiBuilders]);

  const handleFileSelect = (e) => {
    const f = e?.target?.files[0];

    if (f?.size > 10000000) {
      utils.toast.error(t('global.form.errors.file.tooLarge'));
      setHugeDocument(true);
      return;
    }

    if (f) {
      const setFileFields = (image = null) => {
        const dim = !image
          ? {}
          : {
            width: image.naturalWidth,
            height: image.naturalHeight,
          };

        formik.setFieldValue('filename', f?.name);
        formik.setFieldValue('metadata', {
          documentSize: f?.size,
          extension: f?.type?.split('/')[1],
          ...dim,
        });
        setFile(f);

        if (image) image.removeEventListener('load', setFileFields);
      };

      if (f?.type === 'application/pdf') {
        const reader = new FileReader();
        reader.onload = () => setFileFields(null);
        reader.readAsText(f);
      } else {
        const image = new Image();
        image.src = URL.createObjectURL(f);
        image.addEventListener('load', () => setFileFields(image));
      }
    } else {
      setFile(null);
      formik.setFieldValue('filename', '');
      formik.setFieldValue('metadata', null);
    }
  };

  return (
    <div className="m-t-50">
      <h2>{t('tenant.tabs.documents.title')}</h2>
      <div className="m-t-10 m-b-60">
        <FormInfoRequired content={t('global.documents.info')} />
      </div>
      <DocumentList
        className="m-t-25"
        fileList={fileList}
        columns={columns}
        documentListData={documentListData}
        addButton={{
          enabled: true,
          label: t('global.documents.addDocument'),
          icon: 'plus',
        }}
        onAddBtnClick={() => {
          showModal(addDocumentModalId);
        }}
        onFileListElementAddClick={(element) => {
          const { type: documentType } = element;

          setFileType(documentType);
          formik.setFieldValue('type', documentType);
          showModal(addDocumentModalId);
        }}
        onFileListElementDelete={(element) => {
          documentDeleteMutation.mutate(element?.uid);
        }}
        pagination={{
          page,
          itemsPerPage,
          setPage,
          setItemsPerPage,
        }}
        isDocumentListFetching={documentListFetching}
      />
      <Modal
        className={styles.modal}
        id={addDocumentModalId}
        title={isHugeDocument
          ? t('global.documents.addDocumentForm.errors.size.title')
          : t('global.documents.addDocumentForm.title')}
        size="large"
        onClose={() => {
          hideModal(addDocumentModalId);
        }}
      >
        {!isHugeDocument ? (
          <form onSubmit={formik.handleSubmit} className={styles.addForm}>
            <SelectInput
              isSearchable={false}
              className="m-t-25"
              id="type"
              name="type"
              label={t('global.documents.addDocumentForm.fields.type')}
              options={documentTypeOptions}
              onChange={(value) => {
                setFileType(value.value);
                formik.setFieldValue('type', value.value);
              }}
              onBlur={formik.handleBlur}
              error={displayError(t, formik, 'type')}
              valid={isFieldValid(formik, 'type', null, initialValues?.type)}
              value={documentTypeOptions.find((type) => type.value === fileType)}
            />
            <TextInput
              type="text"
              id="customName"
              name="customName"
              className="m-t-25"
              label={t('global.documents.addDocumentForm.fields.name')}
              error={displayError(t, formik, 'customName')}
              valid={isFieldValid(formik, 'customName', null, initialValues?.customName)}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.customName}
            />
            <FileInput
              className="m-t-25"
              id="tenant-file"
              name="tenant-file"
              label={t('global.documents.addDocumentForm.fields.file')}
              help={t('global.documents.addDocumentForm.fileHelp')}
              accept="image/jpg,image/png,image/jpeg,application/pdf"
              value={file}
              onChange={handleFileSelect}
            />
            <div className={styles.submitBtn}>
              <Button
                type="submit"
                loading={isUploading}
                className="m-t-25"
                label={t('global.documents.addDocumentForm.submit')}
              />
            </div>
          </form>
        ) : (
          <div className={styles.hugeDocument}>
            <p className={styles.errorModalSubtitle}>{t('global.documents.addDocumentForm.errors.size.message')}</p>
            <div className={styles.submitBtn}>
              <Button
                className="m-t-25"
                label={t('global.documents.addDocumentForm.errors.size.action')}
                onClick={() => {
                  formik.resetForm();
                  setFileType(null);
                  setFile(null);
                  setHugeDocument(false);
                }}
              />
            </div>
          </div>
        ) }
      </Modal>
    </div>
  );
}

TenantDocuments.propTypes = {
};

export default TenantDocuments;
