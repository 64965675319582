/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Tag } from 'ui-library-unlocker';

import styles from './FiltersSelected.module.scss';

function FiltersSelected({
  filtersSelected,
  filtersList,
  onRemove,
  onRemoveAll,
  tagVariant,
  tagSize,
}) {
  const { t } = useTranslation();

  const list = useMemo(() => {
    const results = [];
    Object.entries(filtersSelected)
      .filter(([key, _]) => key !== 'city')
      .forEach(([key, value]) => {
        value.forEach((v) => results.push({
          filterKey: key,
          filterValue: v,
        }));
      });
    return results;
  }, [filtersSelected]);

  const getFilterValueOnRemove = useCallback(({ filterKey, filterValue }) => {
    const newValue = filtersSelected[filterKey]?.filter((value) => value !== filterValue);
    if (!newValue) return null;
    return onRemove(filterKey, newValue);
  }, [onRemove]);

  const getFilterLabel = useCallback((filter) => filtersList
    ?.find((filterEl) => {
      if (Array.isArray(filterEl?.multiName)) {
        return filterEl?.multiName?.includes(filter.filterKey);
      }
      return filterEl?.name === filter.filterKey;
    })
    ?.options?.find((option) => option?.value === filter.filterValue)
    ?.label, [filtersList]);

  return list?.length > 0 && (
    <div className={styles.filtersSelected}>
      {list.map((filter) => (
        <Tag
          key={filter.filterKey + filter.filterValue}
          label={getFilterLabel(filter)}
          variant={tagVariant}
          onClose={() => getFilterValueOnRemove(filter)}
          showCloseIcon
          size={tagSize}
        />
      ))}
      {list?.length > 0 ? (
        <button type="button" className={styles.filter} onClick={onRemoveAll}>
          <span className={tagSize === 'large' ? 'p-1-700' : 'p-2-700'}>
            {t('global.filters.eraseAll')}
          </span>
        </button>
      ) : null}
    </div>
  );
}

FiltersSelected.propTypes = {
  filtersSelected: PropTypes.shape().isRequired,
  filtersList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  tagVariant: PropTypes.string,
  tagSize: PropTypes.string,
};

FiltersSelected.defaultProps = {
  onRemove: () => {},
  onRemoveAll: () => {},
  tagVariant: 'transparent',
  tagSize: 'large',
};

export default FiltersSelected;
