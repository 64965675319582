import { useQuery } from '@tanstack/react-query';

import { getUserProfile } from '../services/profile';

const useProfile = () => {
  const {
    data: userProfileData,
    isLoading: userProfileLoading,
    isFetching: userProfileFetching,
  } = useQuery({
    queryKey: ['user-profile'],
    queryFn: getUserProfile,
  });

  return {
    profile: userProfileData?.data,
    isLoadingProfile: userProfileLoading || userProfileFetching,
  };
};

export default useProfile;
