/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

// Components
import {
  Button,
  utils,
  Message,
  UnlockerLoader,
} from 'ui-library-unlocker';
import Modal from '../../../components/molecules/Modal/Modal';
import CompanyDocumentsList from '../../../components/organisms/DocumentList/CompanyDocumentsList/CompanyDocumentsList';

// Services
import { requestCompanyEnrolment } from '../../../services/company';
import { getDocumentList } from '../../../services/document';
import { getCompanyDocumentsFromAdmin } from '../../../services/admin';

// Hooks
import useRoles from '../../../hooks/useRoles';
import { useAppContext } from '../../../store/context';

// Utils
import { showModal, hideModal } from '../../../utils/modal';

// Constants
import { ENROLMENT_STATUS, DOC_TYPES } from '../../../utils/constants';

import styles from './CompanyDocuments.module.scss';

const ACCEPTED_DOCUMENTS_ADDRESS_PROOF__MODAL_ID = 'accepted-documents-address-proof-modal';
const VALIDATION_REQUEST_MODAL_ID = 'send-validation-request-confirm-modal';

function CompanyDocuments({ companyData, refetchCompany }) {
  const { t } = useTranslation();
  const { context: { user, uiBuilders } } = useAppContext();
  const navigate = useNavigate();
  const { isUserAdmin } = useRoles();

  const [missingDocuments, setMissingDocuments] = useState(null);

  const match = useMatch('/company/:id');

  const {
    data: documentListData,
    isFetching: documentListFetching,
    refetch: refetchDocumentList,
  } = useQuery({
    queryKey: ['company-documents', user, isUserAdmin],
    queryFn: async () => {
      if (isUserAdmin) {
        const response = await getCompanyDocumentsFromAdmin(match?.params?.id);
        return { data: { collection: response?.data } };
      }
      return getDocumentList({
        filters: {
          companyUID: match?.params?.id,
        },
      });
    },
    enabled: !!user,
    keepPreviousData: true,
  });

  // KBIS
  const kbisColumns = useMemo(() => ([
    {
      header: t('global.docStep.kbis.columns.date'),
      accessorKey: 'date',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { date } } }) => (
        <div>
          {date ? format(new Date(date), 'dd MM yyyy', { locale: fr }) : null}
        </div>
      ),
    },
  ]), [t]);

  // Justificatifs d'identite
  const identityProofColumns = useMemo(() => ([
    {
      header: t('global.docStep.identityProof.columns.expireDate'),
      accessorKey: 'date',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { date } } }) => (
        <div>
          {date ? format(new Date(date), 'dd MM yyyy', { locale: fr }) : null}
        </div>
      ),
    },
  ]), [t]);

  const getAcceptedDocumentsAddressProofContent = useMemo(() => (
    <div className={styles.acceptedDocuments}>
      <h3 className="m-t-30 m-b-10">
        {t('profile.documents.acceptedDocuments.unlistedPrivateCompany.addressIdTitle')}
      </h3>
      <ul className={styles.acceptedDocumentsList}>
        <li>{t('profile.documents.acceptedDocuments.unlistedPrivateCompany.addressId1')}</li>
        <li>{t('profile.documents.acceptedDocuments.unlistedPrivateCompany.addressId2')}</li>
        <li>{t('profile.documents.acceptedDocuments.unlistedPrivateCompany.addressId3')}</li>
        <li>{t('profile.documents.acceptedDocuments.unlistedPrivateCompany.addressId4')}</li>
        <li>{t('profile.documents.acceptedDocuments.unlistedPrivateCompany.addressId5')}</li>
        <li>{t('profile.documents.acceptedDocuments.unlistedPrivateCompany.addressId6')}</li>
        <li>{t('profile.documents.acceptedDocuments.unlistedPrivateCompany.addressId7')}</li>
        <li>{t('profile.documents.acceptedDocuments.unlistedPrivateCompany.addressId8')}</li>
      </ul>
    </div>
  ), [t]);

  const validationBtnLabel = useMemo(() => {
    switch (companyData?.data?.onboardingStatus) {
      case ENROLMENT_STATUS.PROFILE_DONE:
        return t('profile.submit');
      case ENROLMENT_STATUS.ENROLMENT_PENDING:
      case ENROLMENT_STATUS.ENROLMENT_REQUESTED:
      case ENROLMENT_STATUS.ENROLMENT_CHALLENGED:
      case ENROLMENT_STATUS.ENROLMENT_DENIED:
        return t('profile.submitted');
      case ENROLMENT_STATUS.ENROLMENT_BYPASSED:
      case ENROLMENT_STATUS.COMPLETED:
        return t('profile.submitValidated');
      default:
        return t('profile.submit');
    }
  }, [companyData, t]);

  const canSendValidationRequest = useMemo(() => {
    if (documentListData?.data?.collection?.length < 1) return false;
    const { onboardingStatus } = companyData?.data ?? {};
    if (!onboardingStatus) return false;
    return [
      ENROLMENT_STATUS.PROFILE_DONE,
      ENROLMENT_STATUS.ENROLMENT_DENIED,
      ENROLMENT_STATUS.ENROLMENT_CHALLENGED,
    ].includes(onboardingStatus);
  }, [companyData, documentListData?.data?.collection?.length]);

  const enrolmentMutation = useMutation({
    mutationFn: () => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'sent_validation_request',
          entityType: 'company',
        },
      });
      return requestCompanyEnrolment(match?.params?.id);
    },
    onSuccess: () => {
      utils.toast.success(t('profile.documents.validationSentSuccess'));
      refetchDocumentList();
      refetchCompany();
      setMissingDocuments(null);
      hideModal(VALIDATION_REQUEST_MODAL_ID);
      navigate('/');
    },
    onError: (err) => {
      if (err?.response) {
        switch (err?.response?.status) {
          case 400: {
            switch (err?.response?.data?.message) {
              case 'COMPANY_ENROLMENT_NATURAL_PERSON_DOCUMENTS_REQUIREMENT_EXCEPTION': {
                utils.toast.error(t('profile.paymentAccount.errorPersonMissingDocuments'));
                break;
              }
              case 'COMPANY_ENROLMENT_NATURAL_PERSON_PROFILE_REQUIREMENT_EXCEPTION': {
                utils.toast.error(t('profile.paymentAccount.needProfileDone'));
                break;
              }
              case 'ENROLMENT_COMPANY_DOCUMENTS_REQUIREMENT_EXCEPTION': {
                utils.toast.error(t('profile.paymentAccount.errorMissingDocuments'));
                const { errors } = err?.response?.data || {};
                TagManager.dataLayer({
                  dataLayer: {
                    event: 'incomplete_validation_request',
                    entityType: 'company',
                  },
                });
                if (errors) {
                  const missing = Object.values(errors);
                  setMissingDocuments(missing || null);
                }
                break;
              }
              default:
                utils.toast.error(t('global.form.errors.global'));
                break;
            }
            break;
          }
          case 409: {
            utils.toast.error(t('profile.paymentAccount.errorInProgress'));
            break;
          }
          default:
            break;
        }
        hideModal(VALIDATION_REQUEST_MODAL_ID);
      }
    },
  });

  const displayMissingDocuments = useMemo(() => {
    if (!missingDocuments) return null;
    const docTypeEntries = Object.entries(DOC_TYPES);
    return (
      <>
        <p className="p-2-700 m-b-10">
          {t('profile.documents.missingDocuments')}
        </p>
        <ul className={styles.missingList}>
          {missingDocuments.map((doc) => {
            const docKey = docTypeEntries.find(([, value]) => value === doc?.toLowerCase())?.[0];
            return (
              <li key={doc}>{t(`global.docStep.${docKey}.title`)}</li>
            );
          })}
        </ul>
      </>
    );
  }, [missingDocuments, t]);

  const isAssociation = useMemo(() => companyData?.data?.legalCategory === '92', [companyData]);

  const docSteps = useMemo(() => [
    // KBIS
    // RNA / JO / ARUP for associations
    {
      docType: isAssociation ? 'decree' : 'kbis',
      count: 1,
      additionalColumns: isAssociation ? [] : kbisColumns,
    },
    // Justificatif de pouvoir
    {
      docType: 'powerProof',
      optional: true,
      optionalTitle: `global.docStep.powerProof.optional.${isAssociation ? 'assocLabel' : 'label'}`,
    },
    // Justificatif d'identite
    {
      docType: 'identityProof',
      count: 2,
      additionalColumns: identityProofColumns,
    },
    // Liste des bénéficiaires effectifs +25%
    {
      docType: 'beneficiariesList',
      count: 3,
    },
    // Bénéficiaires justificatifs identite
    {
      docType: ['benefIdentities', 'benefIdentities2', 'benefIdentities3'],
    },
    // Chiffre d'affaire
    // deprecated
    {
      docType: 'turnover',
      count: 4,
      optional: true,
    },
    // Sirene
    isAssociation ? {
      docType: 'sirene',
      count: 5,
    } : null,
    // Justificatif d'adresse
    {
      docType: 'addressProof',
      count: isAssociation ? 6 : 5,
      infoModalId: ACCEPTED_DOCUMENTS_ADDRESS_PROOF__MODAL_ID,
    },
    // IBAN
    {
      docType: 'iban',
      count: isAssociation ? 7 : 6,
    },
    // Autres
    {
      docType: 'others',
      count: isAssociation ? 8 : 7,
      maxFiles: 10,
      optional: true,
    },
  ].filter(Boolean), [isAssociation, kbisColumns, identityProofColumns]);

  if (!uiBuilders || documentListFetching) return <UnlockerLoader size={300} />;

  return (
    <div className={utils.cn(['m-t-50', styles.companyDocs])}>
      <h2>{t('company.crud.docsTitle')}</h2>

      <CompanyDocumentsList
        documentsData={documentListData?.data?.collection || []}
        docSteps={docSteps}
        refetchDocuments={refetchDocumentList}
        companyUID={match?.params?.id}
      />

      {missingDocuments?.length > 0 && (
        <div className={styles.missingBlock}>
          <Message
            className="m-t-30"
            icon={null}
            variant="alert"
            content={displayMissingDocuments}
          />
        </div>
      )}

      <Button
        className="m-t-40 center-block"
        icon="etat-lieux"
        size="large"
        disabled={!canSendValidationRequest}
        onClick={() => {
          showModal(VALIDATION_REQUEST_MODAL_ID);
        }}
      >
        {validationBtnLabel}
      </Button>

      {/* Send validation request modal */}
      <Modal
        id={VALIDATION_REQUEST_MODAL_ID}
        title={t('profile.documents.confirmSendValidation')}
        size="large"
        onClose={() => hideModal(VALIDATION_REQUEST_MODAL_ID)}
      >
        <Message
          variant="info"
          content={t('company.validationRequestProfileWarn')}
        />
        <Button
          className="m-t-40 center-block"
          size="large"
          loading={enrolmentMutation?.isLoading}
          onClick={enrolmentMutation.mutate}
        >
          {t('global.validate')}
        </Button>
      </Modal>

      {/* Accepted Documents Modal for Address proof */}
      <Modal
        id={ACCEPTED_DOCUMENTS_ADDRESS_PROOF__MODAL_ID}
        title={t('profile.documents.acceptedDocumentsTitle')}
        size="large"
        onClose={() => {
          hideModal(ACCEPTED_DOCUMENTS_ADDRESS_PROOF__MODAL_ID);
        }}
      >
        {getAcceptedDocumentsAddressProofContent}
      </Modal>
    </div>
  );
}

CompanyDocuments.propTypes = {
  companyData: PropTypes.shape({
    data: PropTypes.shape({
      legalCategory: PropTypes.string,
      onboardingStatus: PropTypes.string,
    }),
  }).isRequired,
  refetchCompany: PropTypes.func.isRequired,
};

export default CompanyDocuments;
