import { useMemo, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Link, useLocation, useMatch } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

// Components
import {
  Picto, Tag, utils, Message,
} from 'ui-library-unlocker';
import PersonPaymentAccount from '../../../components/organisms/PersonPaymentAccount/PersonPaymentAccount';
import UserDocuments from './UserDocuments/UserDocuments';
import PersonalInfoForm from '../../../components/organisms/forms/PersonalInfoForm/PersonalInfoForm';
import TenantForm from '../../../components/organisms/forms/TenantForm/TenantForm';

// Services
import { getUserFromAdmin, getAccountingFromAdmin } from '../../../services/admin';

// Utils
import { getPersonStatusVariant } from '../../../utils/variants';

// Styles
import styles from './UserEdit.module.scss';

function UserEditView() {
  const { t } = useTranslation();
  const location = useLocation();

  const match = useMatch('/user/:id');

  useEffect(() => {
    switch (location.hash) {
      case '#documents':
        document.title = t('user.tabs.documents.browserTitle');
        break;
      case '#payment-account':
        document.title = t('user.tabs.paymentAccount.browserTitle');
        break;
      default:
        document.title = t('user.tabs.generalInfo.browserTitle');
        break;
    }
  }, [location, t]);

  const userQuery = useQuery({
    queryKey: ['user-by-id', match?.params?.id],
    queryFn: () => getUserFromAdmin(match?.params?.id),
    keepPreviousData: true,
  });

  const accountQuery = useQuery({
    queryKey: ['account-onboarding-by-id', match?.params?.id],
    queryFn: () => getAccountingFromAdmin(match?.params?.id),
    keepPreviousData: true,
  });

  const {
    firstName,
    lastName,
    legalName,
    status,
    isDelegated,
    delegatedTo,
    underPendingInvitation,
  } = userQuery.data?.data || {};

  const tabs = useMemo(() => [
    {
      label: t('user.tabs.generalInfo.tabTitle'),
      title: t('user.tabs.generalInfo.title'),
      tab: 'lessor',
      hash: '',
      activePicto: <Picto color="var(--color-secondary)" width="100%" icon="trace-3512" />,
      component: !userQuery.isLoading
        ? <PersonalInfoForm isReadOnly isProfilePristine={false} userQuery={userQuery} userMutation={{}} />
        : <div className={styles.wrapper}>{t('global.loading')}</div>,
      displayed: userQuery.data?.data?.role === 'ROLE_LESSOR',
    },
    {
      label: t('user.tabs.generalInfo.tabTitle'),
      title: t('user.tabs.generalInfo.title'),
      tab: 'tenant',
      hash: '',
      activePicto: <Picto color="var(--color-secondary)" width="100%" icon="trace-3512" />,
      component: !userQuery.isLoading
        ? <TenantForm isEditing tenantQuery={userQuery} />
        : <div className={styles.wrapper}>{t('global.loading')}</div>,
      displayed: userQuery.data?.data?.role === 'ROLE_USER',
    },
    {
      label: t('user.tabs.documents.tabTitle'),
      title: t('user.tabs.documents.title'),
      hash: 'documents',
      activePicto: <Picto color="var(--color-secondary)" width="100%" icon="trace-3511" />,
      component: (
        <UserDocuments />
      ),
      displayed: true,
    },
    {
      label: t('user.tabs.paymentAccount.tabTitle'),
      title: t('user.tabs.paymentAccount.title'),
      hash: 'payment-account',
      activePicto: <Picto color="var(--color-secondary)" width="100%" icon="trace-3512" />,
      component: (
        <PersonPaymentAccount
          entity="user"
          dataQuery={accountQuery}
          entityOnboardingStatus={userQuery.data?.data.onboardingStatus}
        />
      ),
      displayed: userQuery.data?.data?.role === 'ROLE_LESSOR',
    },
  ], [t, location, userQuery.status, accountQuery.status]);

  const currentTab = useMemo(() => {
    const hash = location.hash.replace('#', '');
    let tab = tabs.find((cur) => cur.hash === hash);

    if (hash === '') {
      if (userQuery.data?.data?.role === 'ROLE_USER') {
        tab = tabs.find((cur) => cur.tab === 'tenant');
      } else {
        tab = tabs.find((cur) => cur.tab === 'lessor');
      }
    }

    return tab || tabs[0];
  }, [tabs, location]);

  return (
    <div>
      <div className={styles.heading}>
        <div className={styles.title}>
          <h1>
            {t('user.crud.editTitle')}
          </h1>
          {userQuery.data?.data && (
            <div className={utils.cn(['m-t-20', styles.editDetails])}>
              <h2 className="t-h2-500">
                {`${firstName} ${lastName} ${legalName ? `- ${legalName}` : ''}`}
              </h2>
              <Tag
                label={t(`global.personStatus.${underPendingInvitation ? 'isPendingInvitation' : status}`)}
                size="medium"
                variant={getPersonStatusVariant(status, underPendingInvitation)}
              />
            </div>
          )}
        </div>
        <ul className={styles.tabList}>
          {tabs.filter((tab) => tab.displayed).map((tab) => {
            const isActive = tab.hash === currentTab.hash;

            return (
              <li className={utils.cn([styles.tabItem, isActive ? styles.active : null])} key={tab.hash}>
                <Link to={`#${tab.hash}`}>{tab.label}</Link>
                {isActive && tab.activePicto}
              </li>
            );
          })}
        </ul>
      </div>
      {isDelegated && (
        <Message
          variant="info"
          content={(
            <>
              <Trans
                i18nKey="user.crud.delegated"
                components={{
                  a: <Link to={`/user/${delegatedTo}`} target="_blank" />,
                }}
              />
              <Picto
                icon="export-link"
                width={11}
                color="var(--color-secondary)"
                className="m-l-5"
              />
            </>
)}
          className={styles.isDelegated}
        />
      )}
      <div className={styles.content}>
        {currentTab.component}
      </div>
    </div>
  );
}

UserEditView.propTypes = {
};

export default UserEditView;
