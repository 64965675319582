/* eslint-disable no-underscore-dangle */
import {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
  useDeferredValue,
} from 'react';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { omitBy, groupBy } from 'lodash';
import { useMediaQuery } from 'react-responsive';

import {
  SelectInput,
  Picto,
  utils,
  UnlockerLoader,
  Pagination,
} from 'ui-library-unlocker';

import SideModal from '../../components/molecules/SideModal/SideModal';
import PropertySearchFilterModal from './PropertySearchFilterModal/PropertySearchFilterForm';
import FiltersSelected from '../../components/molecules/FiltersSelected/FiltersSelected';
import SearchMap from './SearchMap/SearchMap';
import PropertyCard from './PropertyCard/PropertyCard';
import PropertyDetails from './PropertyDetails/PropertyDetails';
import SameLocationList from './SameLocationList/SameLocationList';

import useFilters from '../../hooks/useFilters';
import useAlgolia from '../../hooks/useAlgolia';
import { useAppContext } from '../../store/context';

import { parseSanitized } from '../../utils/html';

import styles from './Search.module.scss';

const IS_PUBLIC = !localStorage.getItem('accessToken');

function SearchView() {
  const { t } = useTranslation();
  const {
    filters,
    setFilters,
    resetFilters,
  } = useFilters();
  const algolia = useAlgolia(filters);
  const filtersTagRef = useRef();
  const { id } = useParams();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const { context: { searchMap } } = useAppContext();

  const [filterOpen, setFilterOpen] = useState(false);
  const [results, setResults] = useState([]);
  const [listLoaded, setListLoaded] = useState(false);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [nbResults, setNbResults] = useState(0);
  // Multiple location can be on the same coordinates, we show them in a specific way
  const [sameLocationProperties, setSameLocationProperties] = useState({});
  const [sameLocationSelected, setSameLocationSelected] = useState(null);
  const [cityQuery, setCityQuery] = useState('');
  const deferredCityQuery = useDeferredValue(cityQuery);

  useEffect(() => {
    if (id) document.title = t('search.propertyDetails.browserTitle');
    else document.title = t('search.browserTitle');
  }, [id]);

  const typeFilters = useMemo(() => [
    {
      value: 'apartment',
      label: t('property.table.type.apartment'),
    },
    {
      value: 'house',
      label: t('property.table.type.house'),
    },
    {
      value: 'garage',
      label: t('property.table.type.garage'),
    },
    {
      value: 'parking',
      label: t('property.table.type.parking'),
    },
    {
      value: 'box',
      label: t('property.table.type.box'),
    },
    {
      value: 'office',
      label: t('property.table.type.office'),
    },
    {
      value: 'festival_hall',
      label: t('property.table.type.festival_hall'),
    },
    {
      value: 'local_business',
      label: t('property.table.type.local_business'),
    },
    {
      value: 'other',
      label: t('property.table.type.other'),
    },
  ], [t]);

  const numberOfRoomsFilters = useMemo(() => [
    {
      value: '1',
      label: '1',
    },
    {
      value: '2',
      label: '2',
    },
    {
      value: '3',
      label: '3',
    },
    {
      value: '4',
      label: '4 +',
    },
  ], [t]);

  const allFilters = useMemo(() => [
    {
      name: 'type',
      options: typeFilters,
    },
    {
      name: 'numberOfRooms',
      options: numberOfRoomsFilters.map((f) => ({
        value: f.value,
        label: t('search.filter.numberOfRooms.label', { count: parseInt(f.value, 10) }),
      })),
    },
    {
      name: 'showLeased',
      options: [
        {
          value: 'true',
          label: t('search.filter.leased'),
        },
      ],
    },
  ], [typeFilters, numberOfRoomsFilters]);

  const filtersWithoutSearch = omitBy(filters, (_value, key) => key === 'search');

  const searchPropertiesMutation = useMutation(algolia.search, {
    onSuccess: ({ hits, nbHits }) => {
      const groupedProperties = groupBy(hits, (hit) => JSON.stringify(hit?._geoloc));
      setResults(hits ?? []);
      setNbResults(nbHits ?? 0);
      setListLoaded(true);
      setSameLocationProperties(
        Object.keys(groupedProperties).reduce((acc, key) => {
          if (groupedProperties[key]?.length > 1) {
            acc[key] = groupedProperties[key];
          }
          return acc;
        }, {}),
      );
    },
  });

  const searchCitiesMutation = useMutation(algolia.searchCity);

  const markers = useMemo(() => results.map((result) => ({
    position: {
      lat: result._geoloc.lat,
      lng: result._geoloc.lng,
    },
    propertyStatus: result.status,
    rentExcludingCharges: result.rentExcludingCharges,
    charges: result.charges,
    objectID: result.objectID,
  })), [results]);

  useEffect(() => {
    const menuPadding = document.getElementById('menu-content')?.style.getPropertyValue('padding');
    document.getElementById('menu-content')?.style.setProperty('padding', '0px', 'important');

    return () => {
      document.getElementById('menu-content')?.style.setProperty('padding', menuPadding, 'important');
    };
  }, []);

  useEffect(() => {
    searchCitiesMutation.mutate({ city: deferredCityQuery, withFilters: true });
  }, [deferredCityQuery, filters]);

  useEffect(() => {
    searchPropertiesMutation.mutate({
      page: page - 1,
      hitsPerPage: itemsPerPage,
    });
  }, [filters, page, itemsPerPage]);

  useEffect(() => {
    if (
      searchMap
      && searchPropertiesMutation.isSuccess
      && filters.city
      && searchPropertiesMutation.data?.hits?.length
    ) {
      const markerHits = searchPropertiesMutation.data?.hits;
      const totalMarkers = markerHits?.length;
      if (totalMarkers) {
        // Calcul des coordonnées moyennes
        const averageLat = markerHits.reduce((sum, marker) => sum + (marker._geoloc?.lat ?? 0), 0) / totalMarkers;
        const averageLng = markerHits.reduce((sum, marker) => sum + (marker._geoloc?.lng ?? 0), 0) / totalMarkers;

        searchMap.panTo({
          lat: averageLat,
          lng: averageLng,
        });
      }
    }
  }, [searchMap, searchPropertiesMutation.isSuccess, filters.city]);

  const generatedResults = useMemo(() => {
    if (!results.length) {
      return (
        <div className={styles.noResults}>
          <span>{t('search.noResults')}</span>
          <Picto icon="scribble-cross-medium" width="15" color="var(--color-secondary)" />
        </div>
      );
    }
    return results.map((result) => (
      <PropertyCard
        item={result}
        key={result.objectID}
      />
    ));
  }, [results]);

  const filtersTagsOffset = () => {
    const height = filtersTagRef.current?.offsetHeight || 0;
    return height ? height + 20 : 0;
  };

  const cityOptions = useMemo(() => searchCitiesMutation.data?.facetHits?.map((hit) => ({
    value: hit.value,
    label: parseSanitized(`${hit.highlighted} <small>(${hit.count})</small>`),
  })) ?? [], [searchCitiesMutation.data]);

  const allFiltersOptions = useMemo(() => ({
    type: typeFilters,
    numberOfRooms: numberOfRoomsFilters,
  }), [t, typeFilters]);

  const displaySameLocation = useCallback((location) => setSameLocationSelected(
    sameLocationProperties[JSON.stringify(location)],
  ), [sameLocationProperties]);

  return (
    <div
      className={utils.cn([
        styles.searchView,
        IS_PUBLIC ? styles.isPublic : null,
      ])}
    >
      <div className={styles.searchCol}>
        <SelectInput
          id="searchInput"
          name="searchInput"
          className={styles.searchInput}
          label={t('search.searchInput')}
          options={cityOptions}
          onChange={(value) => {
            setFilters('city', value ? value.value : []);
          }}
          onInputChange={(value) => {
            setCityQuery(value);
          }}
          value={filters.city ? { value: filters.city[0], label: filters.city[0] } : null}
          isClearable
          noFiltering
        />
        <div className={styles.filters}>
          <div className={utils.cn(['m-t-20', 'm-b-20', styles.filtersHeading])}>
            <span className={styles.resultsCount}>{t('search.resultsCount', { count: results?.length || 0 })}</span>
            <button type="button" className={styles.filterBtn} onClick={() => setFilterOpen(true)}>
              <Picto icon="setting" width="20" />
              <span>{t('search.filter.title')}</span>
            </button>
          </div>
          <div ref={filtersTagRef} className={styles.filtersTags}>
            <FiltersSelected
              filtersSelected={filtersWithoutSearch}
              filtersList={allFilters}
              onRemove={(filterKey, filterValue) => {
                setFilters(filterKey, filterValue);
              }}
              onRemoveAll={() => resetFilters({ city: filters.city })}
              tagVariant="white"
              tagSize="medium"
            />
          </div>
        </div>
        <SideModal
          open={filterOpen}
          onClose={() => setFilterOpen(false)}
          title={t('search.filter.title')}
        >
          <PropertySearchFilterModal
            allOptions={allFiltersOptions}
            onSubmit={(values) => {
              resetFilters(values);
              setFilterOpen(false);
            }}
          />
        </SideModal>
        <div
          id="mapResultsList"
          className={styles.resultsList}
          style={{
            height: !isMobile
              ? `calc(100vh - 311px - ${filtersTagsOffset()}px ${IS_PUBLIC ? '- 65px' : ''})`
              : 'auto',
          }}
        >
          {searchPropertiesMutation.isLoading ? (
            <UnlockerLoader size={200} />
          )
            : generatedResults }
        </div>
        {!searchPropertiesMutation.isLoading && nbResults ? (
          <div className={styles.pagination}>
            <Pagination
              breakLine="..."
              totalCount={nbResults || 0}
              currentPage={page}
              onPageChange={(p) => setPage(p)}
              initialItemsPerPage={itemsPerPage}
              onItemsPerPageChange={(items) => setItemsPerPage(items)}
            />
          </div>
        ) : null}
      </div>
      {sameLocationSelected && (
        <SameLocationList
          className={styles.searchCol}
          properties={sameLocationSelected}
          goBack={() => setSameLocationSelected(null)}
        />
      )}
      <div className={styles.content}>
        <SearchMap
          markers={markers}
          displaySameLocation={(loc) => displaySameLocation(loc)}
          hideSameLocation={() => setSameLocationSelected(null)}
        />
      </div>
      {id && (
        <PropertyDetails
          className={styles.propertyDetails}
          property={results?.find((result) => result.objectID === id)}
          listLoaded={listLoaded}
          objectID={id}
        />
      )}
    </div>
  );
}

export default SearchView;
