/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useState, useRef, useEffect, useCallback, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { utils } from 'ui-library-unlocker';
import { useParams } from 'react-router-dom';
import TagManager from 'react-gtm-module';

// Components
import MessageBlock from '../../../../components/molecules/MessageBlock/MessageBlock';
import MessageSender from '../../../../components/molecules/MessageSender/MessageSender';

// Hooks
import useConversation from '../../../../hooks/useConversation';
import useRoles from '../../../../hooks/useRoles';

import styles from './MessagesFeed.module.scss';

function MessagesFeed() {
  const { t } = useTranslation();
  const params = useParams();
  const [content, setContent] = useState('');
  const [attachmentsToSend, setAttachmentsToSend] = useState([]);
  const messagesRef = useRef(null);
  const { isUserAdmin, isUserRealEstateManager } = useRoles();

  const {
    conversation,
    messages,
    authors,
    subject,
    date,
    address,
    sendMessage,
    isConversationLoading,
    isFetching,
  } = useConversation(params.uid);

  const userType = useMemo(() => {
    if (isUserAdmin) return 'admin';
    if (isUserRealEstateManager) return 'manager';
    return 'owner';
  }, [isUserAdmin, isUserRealEstateManager]);

  const handleMessageSend = useCallback(async () => {
    try {
      if (content?.length < 1) {
        utils.toast.info(t('messages.emptyMessageError'));
        return;
      }
      TagManager.dataLayer({
        dataLayer: {
          event: 'message_sent',
          userType,
        },
      });
      await sendMessage({
        content,
        files: attachmentsToSend,
      });
      setContent('');
      setAttachmentsToSend([]);
    } catch (error) {
      //
    }
  }, [content, attachmentsToSend, sendMessage, t]);

  useEffect(() => {
    if (messagesRef.current && !isConversationLoading) {
      setTimeout(() => {
        messagesRef.current.scrollTo({ top: messagesRef.current.scrollHeight, behavior: 'smooth' });
      }, 200);
    }
  }, [messages, isConversationLoading]);

  if (isConversationLoading) return (<div>{t('global.loading')}</div>);

  return (
    <div className={styles.messageFeed}>
      {isFetching && <div className={styles.loader} />}
      <div className={styles.messageFeedHeader}>
        <h2 className="h-2-700">{subject}</h2>
        <p className={utils.cn(['p-3-500', styles.date])}>{date}</p>
        <div className={styles.recipient}>
          <p className={utils.cn(['p-2-500', styles.recipientHeader])}>
            {t('messages.recipient')}
          </p>
          <div>
            <p className={utils.cn(['p-2-500'])}>
              {`${conversation?.recipient?.firstName || t('messages.admin.firstName')} 
              ${conversation?.recipient?.lastName || t('messages.admin.lastName')}`}
            </p>
            <p className="p-2-500">
              {conversation?.recipient ? address : null}
            </p>
          </div>
        </div>
        <hr className={utils.cn(['m-t-20', styles.separator])} />
      </div>
      <div ref={messagesRef} className={styles.messageFeedMessages}>
        {messages.map((message, index) => (
          <MessageBlock
            key={message.uid}
            message={message}
            index={index}
            author={authors.find((author) => author.uid === message.authorUid) || authors?.[1]}
          />
        ))}
      </div>
      <div className={styles.messageFeedInputWrapper}>
        <MessageSender
          onChange={(value) => setContent(value)}
          onAttachmentsChange={(value) => setAttachmentsToSend(value)}
          attachments={attachmentsToSend}
          value={content}
          onMessageSend={handleMessageSend}
        />
      </div>
    </div>
  );
}

export default MessagesFeed;
