import React, { useState, useMemo } from 'react';
import { useFormik } from 'formik';
// import { Link } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import {
  utils, TextInput, Button, Message,
} from 'ui-library-unlocker';

import { useAppContext } from '../../../../store/context';

import { changeUserEmail, changeUserPassword } from '../../../../services/identity';

import {
  emailLoginInfoInitialValues,
  passwordLoginInfoInitialValues,
  emailLoginInfoSchema,
  passwordLoginInfoSchema,
} from '../../../../utils/forms/loginInfoSchema';

import { displayError, isFieldValid } from '../../../../utils/forms/form';

import styles from './LoginInfoForm.module.scss';

function LoginInfoForm() {
  const { t } = useTranslation();
  const { context: { me } } = useAppContext();
  const [statusMessage, setStatusMessage] = useState({ displayed: false, value: '' });

  const emailMutation = useMutation({
    mutationFn: changeUserEmail,
    onSuccess: ({ response, status }) => {
      const s = status || response?.status;
      switch (s) {
        case 204:
          setStatusMessage({ displayed: true, value: t('profile.loginInfo.form.emailSuccess') });
          break;
        default:
          break;
      }
    },
    onError: (err) => {
      if (err?.response) {
        switch (err?.response?.status) {
          case 400: {
            utils.toast.error(t('global.form.errors.generic'));
            break;
          }
          default:
            break;
        }
      }
    },
  });

  const passwordMutation = useMutation({
    mutationFn: changeUserPassword,
    onSuccess: ({ response, status }) => {
      const s = status || response?.status;
      switch (s) {
        case 204:
          utils.toast.success(t('profile.loginInfo.form.passwordSuccess'));
          setStatusMessage({ displayed: true, value: t('profile.loginInfo.form.passwordSuccess') });
          break;
        default:
          break;
      }
    },
    onError: (err) => {
      if (err?.response) {
        switch (err?.response?.status) {
          case 400: {
            utils.toast.error(t('global.form.errors.generic'));
            break;
          }
          default:
            break;
        }
      }
    },
  });

  const initialEmailValues = useMemo(() => {
    if (!me) return emailLoginInfoInitialValues;
    if (me) return me;
    return emailLoginInfoInitialValues;
  }, [me]);

  const emailFormik = useFormik({
    initialValues: initialEmailValues,
    validationSchema: emailLoginInfoSchema,
    validateOnChange: false,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values) => emailMutation.mutate(values),
  });

  const passwordFormik = useFormik({
    initialValues: passwordLoginInfoInitialValues,
    validationSchema: passwordLoginInfoSchema,
    validateOnChange: false,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values) => passwordMutation.mutate(values),
  });

  if (!me) return <div className={styles.wrapper}>{t('global.loading')}</div>;

  return (
    <div className={styles.wrapper}>
      <div className={styles.form}>
        <h2>{t('profile.loginInfo.title')}</h2>
        <form className={styles.form} onSubmit={emailFormik.handleSubmit}>
          <h4 className="m-t-50 m-b-20">{t('profile.loginInfo.subtitle1')}</h4>
          <TextInput
            type="text"
            id="email"
            name="email"
            label={t('profile.loginInfo.form.email')}
            error={displayError(t, emailFormik, 'email')}
            valid={isFieldValid(emailFormik, 'email')}
            onChange={emailFormik.handleChange}
            onBlur={emailFormik.handleBlur}
            value={emailFormik.values.email}
            autoComplete="off"
          />
          <div className={styles.submit}>
            <Button
              type="submit"
              size="large"
              label={t('profile.loginInfo.form.submit')}
            />
          </div>
        </form>

        <form className={styles.form} onSubmit={passwordFormik.handleSubmit}>
          <h4 className="m-t-50 m-b-20">{t('profile.loginInfo.subtitle2')}</h4>
          <TextInput
            type="password"
            id="previousPassword"
            name="previousPassword"
            className="m-t-25"
            label={t('profile.loginInfo.form.password')}
            error={displayError(t, passwordFormik, 'previousPassword')}
            valid={isFieldValid(passwordFormik, 'previousPassword')}
            onChange={passwordFormik.handleChange}
            onBlur={passwordFormik.handleBlur}
            value={passwordFormik.values.previousPassword}
            autoComplete="new-password"
          />
          <TextInput
            type="password"
            id="proposedPassword"
            name="proposedPassword"
            className="m-t-25"
            label={t('profile.loginInfo.form.newPassword')}
            info={t('global.form.info.password')}
            error={displayError(t, passwordFormik, 'proposedPassword')}
            valid={isFieldValid(passwordFormik, 'proposedPassword')}
            onChange={passwordFormik.handleChange}
            onBlur={passwordFormik.handleBlur}
            value={passwordFormik.values.proposedPassword}
            autoComplete="new-password"
          />
          <div className={styles.submit}>
            <Button
              type="submit"
              size="large"
              label={t('profile.loginInfo.form.submit')}
            />
          </div>
        </form>
      </div>
      <div>
        {statusMessage?.displayed ? <Message content={statusMessage.value} /> : null}
      </div>
    </div>
  );
}

export default LoginInfoForm;
