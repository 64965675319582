import React from 'react';
import { ToastContainer } from 'react-toastify';

function Toast() {
  return (
    <ToastContainer
      position="bottom-left"
      hideProgressBar
      newestOnTop={false}
      closeOnClick={false}
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
      closeButton
      autoClose={8000}
    />
  );
}

export default Toast;
