import * as yup from 'yup';
import { ONBOARDING_ROLES, RENT_DISTRIBUTION_STATUS } from '../constants';

import addressSchema from './addressSchema';
import { personalInfoInitialValues } from './personalInfoSchema';
import { companyDetailsInitialValues, legalCategories } from './companyDetailsSchema';
import { validatePhoneNumber } from '../validation';

// OnobardingProfile1

export const onboardingProfile1InitialValues = {
  ...personalInfoInitialValues,
  hasAtLeastOneCompany: false,
  idNumber: '',
};

export const onboardingProfile1Schema = yup.object().shape({
  lastName: yup.string()
    .matches(/^[^0-9]*$/, 'global.form.errors.invalid')
    .required('global.form.errors.required').nullable(),
  firstName: yup.string()
    .matches(/^[^0-9]*$/, 'global.form.errors.invalid')
    .required('global.form.errors.required').nullable(),
  mobilePhoneCountryCode: yup.string().required('global.form.errors.required').nullable(),
  mobilePhoneNumber: validatePhoneNumber('mobilePhoneNumber', 'mobilePhoneCountryCode')
    .required('global.form.errors.required'),
  birthDate: yup
    .date()
    .max(
      new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate()),
      'profile.personalInfo.errors.underaged',
    )
    .required('global.form.errors.required')
    .nullable(),
  hasAtLeastOneCompany: yup.boolean(),
  idNumber: yup.string().when(['$onboardingRole', 'hasAtLeastOneCompany'], {
    is: (onboardingRole, hasAtLeastOneCompany) => (
      onboardingRole !== ONBOARDING_ROLES.TENANT && hasAtLeastOneCompany
    ),
    then: yup
      .string()
      .length(14, 'profile.companyDetails.formErrors.idNumberLength')
      .required('global.form.errors.required'),
    otherwise: yup.string().nullable(),
  }),
});

// OnbardingProfile2

export const onboardingProfile2InitialValues = {
  ...personalInfoInitialValues,
};

export const onboardingProfile2Schema = yup.object().shape({
  rentDistribution: yup
    .string()
    .required('global.form.errors.required')
    .oneOf([
      RENT_DISTRIBUTION_STATUS.ONLY_WITH_PERSONAL_ACCOUNT,
      RENT_DISTRIBUTION_STATUS.ONLY_WITH_COMPANIES_ACCOUNT,
      RENT_DISTRIBUTION_STATUS.WITH_BOTH_PERSONAL_AND_COMPANIES_ACCOUNT,
      RENT_DISTRIBUTION_STATUS.REAL_ESTATE_AGENCY_WITH_NO_PROPERTIES,
    ], 'global.form.errors.invalid'),
});

// OnobardingProfile3

export const onboardingProfile3InitialValues = {
  ...personalInfoInitialValues,
};

export const onboardingProfile3Schema = yup.object().shape({
  gender: yup
    .string()
    .oneOf(['male', 'female', 'none'], 'global.form.errors.invalid')
    .required('global.form.errors.required')
    .nullable(),
  title: yup
    .string()
    .oneOf(['mr', 'ms', 'undefined', null], 'global.form.errors.invalid')
    .required('global.form.errors.required')
    .nullable(),
  birthCity: yup
    .string()
    .required('global.form.errors.required')
    .nullable(),
  birthCountry: yup
    .string()
    .required('global.form.errors.required')
    .nullable(),
  birthDepartment: yup
    .string()
    .required('global.form.errors.required')
    .nullable(),
  nationality: yup
    .string()
    .required('global.form.errors.required')
    .nullable(),
  address: addressSchema
    .required('global.form.errors.required')
    .nullable(),
  iban: yup.string()
    .when('rentDistribution', {
      is: (rentDistribution) => [
        RENT_DISTRIBUTION_STATUS.ONLY_WITH_PERSONAL_ACCOUNT,
        RENT_DISTRIBUTION_STATUS.WITH_BOTH_PERSONAL_AND_COMPANIES_ACCOUNT,
      ].includes(rentDistribution),
      then: yup.string()
        .length(27, 'global.form.errors.iban')
        .test({
          message: 'global.form.errors.ibanFormat',
          test: function test(value) {
            return value?.length > 0 ? value.startsWith('FR') : true;
          },
        })
        .required('global.form.errors.required')
        .nullable(),
      otherwise: yup.string()
        .test({
          name: 'iban-format',
          test: function test(value, ctx) {
            if (!value) return true;
            if (!value.startsWith('FR')) return ctx.createError({ message: 'global.form.errors.ibanFormat' });
            if (value.length !== 27) return ctx.createError({ message: 'global.form.errors.iban' });
            return true;
          },
        })
        .nullable(),
    }),
});

// OnboardingCompany1

export const onboardingCompany1InitialValues = {
  ...companyDetailsInitialValues,
};

export const onboardingCompany1Schema = yup.object().shape({
  legalName: yup.string().nullable().required('global.form.errors.required'),
  brandName: yup.string().nullable().required('global.form.errors.required'),
  address: addressSchema.required('global.form.errors.required').nullable(),
  legalCategory: yup.string()
    .oneOf(legalCategories.map((category) => category.value), 'global.form.errors.invalid')
    .required('global.form.errors.required')
    .nullable(),
});

// OnboardingCompany2

export const onboardingCompany2InitialValues = {
  ...companyDetailsInitialValues,
};

export const onboardingCompany2Schema = yup.object().shape({
  registrationDate: yup.date().required('global.form.errors.required'),
  registrationPlace: yup.string().required('global.form.errors.required').nullable(),
  registrationCountry: yup.string().required('global.form.errors.required'),
  rcsRegisterLocation: yup
    .string()
    .when('$isRealEstateAgency', {
      is: true,
      then: yup.string().required('global.form.errors.required').nullable(),
      otherwise: yup.string().strip(),
    }),
  taxCity: yup.string().required('global.form.errors.required').nullable(),
  taxZipCode: yup.string().required('global.form.errors.required'),
  taxCountry: yup.string().required('global.form.errors.required'),
  annualTurnover: yup.string().required('global.form.errors.required').nullable(),
  vatNumber: yup.string().nullable().required('global.form.errors.required'),
  proCivilLiability: yup
    .string()
    .when('$isRealEstateAgency', {
      is: true,
      then: yup.string().required('global.form.errors.required').nullable(),
      otherwise: yup.string().strip(),
    }),
  warrantyFund: yup
    .string()
    .when('$isRealEstateAgency', {
      is: true,
      then: yup.string().required('global.form.errors.required').nullable(),
      otherwise: yup.string().strip(),
    }),
  proCardNumber: yup
    .string()
    .when('$isRealEstateAgency', {
      is: true,
      then: yup.string().required('global.form.errors.required').nullable(),
      otherwise: yup.string().strip(),
    }),
  iban: yup.string()
    .length(27, 'global.form.errors.iban')
    .test({
      message: 'global.form.errors.ibanFormat',
      test: function test(value) {
        return value?.length > 0 ? value.startsWith('FR') : true;
      },
    })
    .required('global.form.errors.required')
    .nullable(),
});
