/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import fr from 'date-fns/locale/fr';
import { utils, Picto } from 'ui-library-unlocker';

// Hooks
import { useAppContext } from '../../../store/context';

// Utils
import { handleDocumentDownload } from '../../../utils/documents';

import styles from './MessageBlock.module.scss';

function MessageBlock({ message, author, index }) {
  const { t } = useTranslation();
  const { context: { user } } = useAppContext();
  const [attachments, setAttachments] = useState([]);

  const { documents, authorUid } = message || {};
  const { firstName, lastName } = author || {};

  const date = useMemo(() => {
    if (message) {
      const { createdAt: { date: msgDate } } = message;
      return format(new Date(msgDate), 'dd MMM. yyyy', { locale: fr });
    }
    return null;
  }, [message]);

  useEffect(() => {
    if (!documents || documents?.length < 1) return;
    setAttachments(documents);
  }, [documents]);

  return (
    <div className={utils.cn([
      styles.messageBlock,
      authorUid === user?.username ? styles.messageBlockRight : styles.messageBlockLeft,
      index === 0 ? 'm-t-20' : 'm-t-40',
    ])}
    >
      <p className={utils.cn(['p-3-500', styles.date])}>{date}</p>
      <p className={utils.cn(['m-t-5 p-2-500', styles.name])}>
        {`${firstName} ${lastName}`}
      </p>
      <p className="m-t-5 p-1-500">
        {message.content}
      </p>

      {attachments?.length > 0 ? (
        <ul className={styles.attachmentList}>
          {attachments.map((attachment) => (
            <li key={attachment.uid} className={styles.attachmentElement}>
              <Picto
                icon="import"
                width={24}
                color="var(--color-secondary)"
                onClick={() => (attachment?.uid ? handleDocumentDownload(attachment, t) : null)}
              />
              <div>
                <p
                  className={utils.cn([
                    'p-2-500 m-t-5',
                    styles.documentNameValue,
                  ])}
                  tabIndex={0}
                  role="button"
                  onKeyDown={null}
                  onClick={() => (attachment?.uid ? handleDocumentDownload(attachment, t) : null)}
                >
                  {attachment.fileName}
                </p>
              </div>
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
}

MessageBlock.propTypes = {
  message: PropTypes.shape().isRequired,
  author: PropTypes.shape().isRequired,
  index: PropTypes.number.isRequired,
};

export default MessageBlock;
