import { useRef } from 'react';
import PropTypes from 'prop-types';

import { t } from 'i18next';
import Modal from '../../molecules/Modal/Modal';
import RegisterPaymentForm from '../../organisms/forms/RegisterPaymentForm/RegisterPaymentForm';

import { hideModal } from '../../../utils/modal';

import styles from './RegisterPaymentModal.module.scss';

function RegisterPaymentModal({
  id,
}) {
  const ref = useRef(null);
  return (
    <Modal
      className={styles.modal}
      id={id}
      title={t('dashboard.registerPayment.title')}
      onClose={() => hideModal(id)}
      size="large"
      ref={ref}
    >
      <div className={styles.modalContent}>
        <RegisterPaymentForm
          onSuccessfulSubmit={() => hideModal(id)}
          menuPortalTarget={document?.body}
        />
      </div>
    </Modal>
  );
}

RegisterPaymentModal.propTypes = {
  id: PropTypes.string.isRequired,
};

RegisterPaymentModal.defaultProps = {
};

export default RegisterPaymentModal;
