const useScroll = () => {
  const scrollContentToTop = (scrollType = 'smooth') => {
    const content = document.getElementById('menu-content');
    content?.scrollTo({ top: 0, behavior: scrollType });
  };

  const scrollToElement = (elementId, scrollType = 'smooth') => {
    const element = document.getElementById(elementId);
    element?.scrollIntoView({ behavior: scrollType });
  };

  return {
    scrollContentToTop,
    scrollToElement,
  };
};

export default useScroll;
