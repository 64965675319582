/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { Link, useLocation } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useTranslation, Trans } from 'react-i18next';
import TagManager from 'react-gtm-module';
import {
  utils,
  TextInput,
  Button,
  Message,
  CheckboxInput,
} from 'ui-library-unlocker';

import { registerUser, resendConfirm } from '../../../../services/identity';
import registerSchema, { registerInitialValues } from '../../../../utils/forms/registerSchema';
import resendCodeSchema, { resendCodeInitialValues } from '../../../../utils/forms/resendCodeSchema';

import { displayError, isFieldValid } from '../../../../utils/forms/form';

import styles from './RegisterForm.module.scss';

// eslint-disable-next-line react/prop-types
function ExternalLink({ href, children }) {
  return <a href={href} target="_blank" rel="noreferrer">{children}</a>;
}

function RegisterForm({ label, action, registrationType }) {
  const { t } = useTranslation();
  const { hash } = useLocation();

  const mutation = useMutation({
    mutationFn: registerUser,
    onError: (error) => {
      if (error?.response) {
        switch (error?.response?.status) {
          case 400: {
            utils.toast.error(t('global.form.errors.generic'));
            break;
          }
          case 406: {
            switch (error?.response?.data?.message) {
              case 'ERR_IDENTITY_PASSWORD_FORMAT': {
                formik.setFieldError('password', t(`register.form.errorResponses.${error?.response.data.message}`));
                break;
              }
              default:
                break;
            }
            break;
          }
          case 409: {
            switch (error?.response?.data?.message) {
              case 'ERR_IDENTITY_USERNAME_CONFLICT': {
                formik.setFieldError('email', t(`register.form.errorResponses.${error?.response.data.message}`));
                utils.toast.error(t(`register.form.errorResponses.${error?.response.data.message}`));
                break;
              }
              default:
                break;
            }
            break;
          }
          default:
            break;
        }
      }
    },
  });

  const resendCodeMutation = useMutation({
    mutationFn: resendConfirm,
    onSuccess: (response) => {
      switch (response?.status) {
        case 204:
          utils.toast.success(t('register.resendCode.success'));
          break;
        case 400: {
          utils.toast.error(t('global.form.errors.generic'));
          break;
        }
        default:
          break;
      }
    },
    onError: () => {
      utils.toast.error(t('global.form.errors.generic'));
    },
  });

  const formik = useFormik({
    initialValues: registerInitialValues,
    validationSchema: registerSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: (values) => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'sign_up',
        },
      });
      mutation.mutate({
        ...values,
        registrationType,
      });
    },
  });

  const resendCodeFormik = useFormik({
    initialValues: resendCodeInitialValues,
    validationSchema: resendCodeSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: (values) => resendCodeMutation.mutate({ email: values.email }),
  });
  const formType = hash === '#resend-code' ? 'resend-code' : 'register';

  return (
    <>
      {mutation?.isSuccess ? (
        <>
          <Message content={t('register.form.success')} />
          <p className={styles.backToLogin}>
            <Link to="/login">
              {t('global.goBack')}
            </Link>
          </p>
        </>
      ) : (
        <>
          {formType === 'register' && (
          <>
            <p className={styles.youAre}>
              {t('register.youAre')}
            </p>
            <div className={utils.cn([styles.userSection, styles.tenant])}>
              <p className={styles.userTitle}>
                {label}
              </p>
              <p className={styles.userAction}>
                {action}
              </p>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <TextInput
                type="text"
                id="email"
                name="email"
                autoComplete="email"
                label={t('register.form.username')}
                error={displayError(t, formik, 'email')}
                valid={isFieldValid(formik, 'email')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              <TextInput
                type="password"
                id="password"
                name="password"
                className="m-t-25"
                label={t('register.form.password')}
                error={displayError(t, formik, 'password')}
                valid={isFieldValid(formik, 'password')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                info={t('global.form.info.password')}
                autoComplete="new-password"
              />

              <CheckboxInput
                name="terms-gtc"
                id="terms-gtc"
                className="m-t-25 p-2-500"
                label={(
                  <Trans
                    i18nKey="register.form.acceptedGTC"
                    t={t}
                    components={[<ExternalLink href="https://unlocker.io/conditions-generales" />, <br />]}
                  />
                  )}
                checked={formik.values.acceptedGTC}
                onChange={() => {
                  formik.setFieldValue('acceptedGTC', !formik.values.acceptedGTC);
                }}
                error={displayError(t, formik, 'acceptedGTC')}
              />
              <CheckboxInput
                name="terms-gdpr"
                id="terms-gdpr"
                className="m-t-20 p-2-500"
                label={(
                  <Trans
                    i18nKey="register.form.acceptedGDPR"
                    t={t}
                    components={[
                      <ExternalLink href="https://www.unlocker.io/politique-de-confidentialite" />,
                      <br />,
                    ]}
                  />
                  )}
                checked={formik.values.acceptedGDPR}
                onChange={() => {
                  formik.setFieldValue('acceptedGDPR', !formik.values.acceptedGDPR);
                }}
                error={displayError(t, formik, 'acceptedGDPR')}
              />
              <Button
                type="submit"
                className="m-t-30 center-block"
                size="large"
                label={t('register.form.submit')}
              />
            </form>
            <p className={styles.haveAccount}>
              {t('register.alreadyHaveAccount')}
              <Link to="/login">
                {t('register.signIn')}
              </Link>
            </p>
            <p className={utils.cn(['m-t-20 m-b-30 p-2-500', styles.legalContext])}>
              {t('register.form.legalContext')}
            </p>
          </>
          )}
          {formType === 'resend-code' && (
          <>
            <p className={utils.cn([styles.youAre, 'm-b-20'])}>
              {t('register.resendCode.description')}
            </p>
            <form onSubmit={resendCodeFormik.handleSubmit}>
              <TextInput
                type="email"
                id="email"
                name="email"
                label={t('register.form.email')}
                error={displayError(t, resendCodeFormik, 'email')}
                valid={isFieldValid(resendCodeFormik, 'email')}
                onChange={resendCodeFormik.handleChange}
                onBlur={resendCodeFormik.handleBlur}
                value={resendCodeFormik.values.email}
              />
              <p className={styles.backToLogin}>
                <Link to="/login">
                  {t('register.resendCode.backToLogin')}
                </Link>
              </p>
              <Button
                type="submit"
                className="m-t-30 center-block"
                size="large"
                label={t('global.form.submit')}
              />
            </form>
          </>
          )}
        </>
      )}
    </>
  );
}

RegisterForm.propTypes = {
  label: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  registrationType: PropTypes.string.isRequired,
};

export default RegisterForm;
