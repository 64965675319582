/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Button,
  Picto,
  Tag,
  Table,
  utils,
} from 'ui-library-unlocker';

// Utils
import { handleDocumentDownload } from '../../../utils/documents';
import { showModal } from '../../../utils/modal';

// Constants
import { DOC_TYPES } from '../../../utils/constants';

import styles from './DocStep.module.scss';

function DocStep({
  documentListData,
  docType,
  addModalId,
  setFileType,
  handleDelete,
  count,
  additionalColumns,
  title,
  maxFiles,
  optional,
  optionalTitle,
  infoModalId,
  textContent,
  isReadOnly,
}) {
  const { t } = useTranslation();

  const statusVariant = useMemo(() => (s) => {
    switch (s) {
      case 'validated':
        return 'secondary-outline';
      case 'pending':
        return 'secondary';
      case 'rejected':
        return 'error';
      default:
        return 'error';
    }
  }, []);

  const docTypeKey = useMemo(() => {
    if (Array.isArray(docType)) {
      return docType[0];
    }
    return docType;
  }, [docType]);

  const data = useMemo(() => {
    if (Array.isArray(docType) && documentListData) {
      const items = [];
      let missingType;
      docType.forEach((type) => {
        const target = documentListData.find((doc) => doc?.type === DOC_TYPES[type]);
        if (target) {
          items.push(target);
        } else if (!missingType) {
          missingType = DOC_TYPES[type];
        }
      });
      if (items.length < docType.length) {
        items.push({ type: missingType, add: true });
      }
      return items;
    }
    const target = documentListData?.filter((doc) => doc?.type === DOC_TYPES[docTypeKey]);
    if (target?.length >= maxFiles) {
      return target;
    }
    return [
      ...(target || []),
      {
        type: DOC_TYPES[docTypeKey],
        add: true,
      },
    ];
  }, [documentListData]);

  const columns = useMemo(() => ([
    {
      header: t(`global.docStep.${docTypeKey}.columns.category`),
      accessorKey: 'type',
      size: 150,
      enableSorting: false,
      cell: () => (
        <div className={styles.documentTypeValue}>{t(`global.docStep.${docTypeKey}.placeholder`)}</div>
      ),
    },
    {
      header: t(`global.docStep.${docTypeKey}.columns.fileName`),
      accessorKey: 'name',
      size: 200,
      enableSorting: false,
      cell: ({ row: { original } }) => {
        const fileName = original?.name;
        return fileName ? (
          <div>
            <p
              tabIndex={0}
              role="button"
              onKeyDown={null}
              onClick={() => handleDocumentDownload(original, t)}
              className={styles.documentNameValue}
            >
              {fileName}
            </p>
          </div>
        ) : null;
      },
    },
    ...(additionalColumns || []),
    {
      header: t(`global.docStep.${docTypeKey}.columns.status`),
      accessorKey: 'status',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { status } } }) => (
        <div>
          {status ? (
            <Tag
              variant={statusVariant(status)}
              size="medium"
              label={t(`global.docStep.status.${status}`)}
            />
          ) : null}
        </div>
      ),
    },
    {
      header: '',
      accessorKey: 'additionalOptions',
      size: 1,
      enableSorting: false,
      cell: ({ row: { original: { uid, add, type } } }) => {
        if (isReadOnly) return null;
        return (
          <div>
            {add ? (
              <Button
                icon="plus"
                onClick={() => {
                  setFileType(type);
                  showModal(addModalId);
                }}
                className={styles.addIcon}
                color="var(--color-secondary)"
                size="small"
              />
            ) : (
              <Picto
                icon="trash"
                onClick={() => handleDelete(uid)}
                className={styles.deleteIcon}
                color="var(--color-secondary)"
                width={20}
              />
            )}
          </div>
        );
      },
    },
  ]), [t, additionalColumns, docTypeKey, statusVariant, isReadOnly]);

  const showInfoModal = useCallback(() => {
    showModal(infoModalId);
  }, [infoModalId]);

  return (
    <div className={styles.docStep}>
      <div className={styles.stepTitle}>
        {count && (
          <Tag
            variant="secondary"
            size="small"
            label={t('global.docStep.step', { count })}
          />
        )}
        <div className={utils.cn([
          styles.stepTitleText,
          !count ? 'm-l-15' : '',
        ])}
        >
          <h3 className={!count ? styles.titleH4 : 't-h3-700'}>
            {t(`global.docStep.${docTypeKey}.${title || 'title'}`)}
          </h3>
          {optional && (
            <small>{t(optionalTitle || 'global.form.optional')}</small>
          )}
        </div>
        {infoModalId && (
          <Picto
            width={20}
            icon="info-circle"
            color="var(--color-secondary)"
            className={styles.infoIcon}
            onClick={showInfoModal}
          />
        )}
      </div>
      {textContent && (
        <div
          className={utils.cn([
            'm-t-25 m-b-25',
            !count ? 'm-l-15' : '',
            styles.textContent,
          ])}
        >
          {textContent}
        </div>
      )}
      <div className="m-t-20">
        <Table
          fullWidth
          columns={columns}
          data={data}
        />
      </div>
    </div>
  );
}

DocStep.propTypes = {
  documentListData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  docType: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  addModalId: PropTypes.string.isRequired,
  setFileType: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  count: PropTypes.number,
  additionalColumns: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string,
  maxFiles: PropTypes.number,
  optional: PropTypes.bool,
  optionalTitle: PropTypes.string,
  infoModalId: PropTypes.string,
  textContent: PropTypes.string,
  isReadOnly: PropTypes.bool,
};

DocStep.defaultProps = {
  count: null,
  additionalColumns: [],
  title: '',
  maxFiles: 1,
  optional: false,
  optionalTitle: '',
  infoModalId: null,
  textContent: '',
  isReadOnly: false,
};

export default DocStep;
