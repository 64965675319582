/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';
import {
  TextInput,
  ToggleInput,
  Button,
  utils,
} from 'ui-library-unlocker';

import { useAppContext } from '../../../store/context';

// Components
import FileInput from '../../../components/molecules/FileInput/FileInput';
import Modal from '../../../components/molecules/Modal/Modal';
import ImageModal from '../../../components/molecules/ImageModal/ImageModal';
import DocumentList from '../../../components/organisms/DocumentList/DocumentList';
import FormInfoRequired from '../../../components/atoms/FormInfoRequired/FormInfoRequired';

// Hooks
import useFileUpload from '../../../hooks/useFileUpload';

// Services
import {
  createPropertyPicture,
  getPropertyPictures,
  deletePropertyPicture,
  updatePropertyPicture,
  getPostAdPictures,
  createPostAdPicture,
  deletePostAdPicture,
} from '../../../services/property';

// Utils
import { showModal, hideModal } from '../../../utils/modal';
import { displayError, isFieldValid } from '../../../utils/forms/form';
import { handleDocumentDownload } from '../../../utils/documents';

import { getDocumentSchema, documentInitialValues } from './propertyPicturesSchema';

import styles from './PropertyPictures.module.scss';

function PropertyPictures({
  postAd,
  userUID,
  propertyUID,
}) {
  const { t } = useTranslation();
  const { context: { user } } = useAppContext();
  const match = useMatch('/property/:id');

  const [isHugeDocument, setHugeDocument] = useState(false);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(100);
  const [currentPictureOpened, setCurrentPictureOpened] = useState(null);
  const [isMainToggle, setIsMainToggle] = useState(false);

  const addPictureModalId = 'add-picture-modal';
  const pictureDisplayModalId = 'picture-display-modal';

  const {
    data: pictureListData,
    isFetching: pictureListFetching,
    refetch: pictureListRefetch,
  } = useQuery({
    queryKey: ['property-pictures', user, page, itemsPerPage, postAd, propertyUID],
    queryFn: async () => {
      if (postAd) {
        return getPostAdPictures(propertyUID, {
          page,
          itemsPerPage,
        });
      }
      if (user) {
        return getPropertyPictures(match?.params?.id, {
          page,
          itemsPerPage,
        });
      }
      return [];
    },
    enabled: !!user || !!postAd,
    keepPreviousData: true,
  });

  const pictureDeleteMutation = useMutation({
    mutationFn: postAd ? deletePostAdPicture : deletePropertyPicture,
    onSuccess: ({ response, status }) => {
      const s = status || response?.status;
      switch (s) {
        case 204:
          utils.toast.success(t('global.documents.delete.success'));
          pictureListRefetch();
          break;
        default:
          break;
      }
    },
    onError: (err) => {
      switch (err?.response?.status) {
        case 400: {
          utils.toast.error(t('global.form.errors.generic'));
          break;
        }
        default:
          utils.toast.error(t('global.form.errors.global'));
          break;
      }
    },
  });

  const initialValues = useMemo(() => ({
    ...documentInitialValues,
    userUid: postAd ? userUID : user?.username,
  }), [user, userUID, postAd]);

  // DOCUMENT UPLOAD
  const DOCUMENT_TYPE = 'propertyPicture';

  const resetDocumentModal = () => {
    hideModal(addPictureModalId);
    setFileType(null);
    setFile(null);
    setHugeDocument(false);
    setIsMainToggle(false);
    formik.resetForm();
  };

  const createDocumentCb = async (documentUid, fileName) => {
    const createdDocument = await (postAd ? createPostAdPicture : createPropertyPicture)(
      postAd ? propertyUID : match?.params?.id,
      {
        name: fileName,
        documentUid,
        main: isMainToggle,
      },
    ).catch((err) => {
      if (err?.response?.status === 403 && postAd) {
        utils.toast.error(t('postAd.property.errorResponses.alreadySubmitted'));
      }
    });
    pictureListRefetch();
    resetDocumentModal();
    return createdDocument;
  };

  const [{ uploadFile, setFileType, setFile }, { file, isUploading }] = useFileUpload(createDocumentCb, postAd);

  const handlePictureMainToggle = async (picture) => {
    try {
      await updatePropertyPicture(picture?.uid, {
        ...picture,
        main: !picture?.main,
      });
      pictureListRefetch();
    } catch (error) {
      //
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: getDocumentSchema(),
    enableReinitialize: true,
    onSubmit: (values) => {
      if (typeof file === 'object') {
        uploadFile({
          ...values,
          type: DOCUMENT_TYPE,
        });
      } else {
        utils.toast.info(t('global.documents.addDocumentForm.errors.noFileSelected'));
      }
    },
  });

  const columns = useMemo(() => ([
    {
      header: t('property.tabs.pictures.featuredPicture'),
      accessorKey: '#1',
      size: 1,
      enableSorting: false,
      cell: ({ row: { original } }) => (
        <div className={styles.main}>
          {!postAd && (
            <ToggleInput
              id={original?.documentUid}
              name={original?.documentUid}
              checked={original?.main}
              onChange={() => handlePictureMainToggle(original)}
            />
          )}
          {/* THUMBNAIL */}
          <div className={styles.thumbnail}>
            <img
              onClick={() => {
                setCurrentPictureOpened(original);
                showModal(pictureDisplayModalId);
              }}
              tabIndex={0}
              role="button"
              onKeyDown={null}
              src={original?.thumbnails?.find((thumbnail) => thumbnail?.format === 'property_thumbnail')?.url}
              alt={original?.name}
            />
          </div>
        </div>
      ),
    },
    {
      header: t('property.tabs.pictures.name'),
      accessorKey: 'name',
      size: 200,
      enableSorting: false,
      cell: ({ row: { original } }) => {
        const fileName = original?.name;
        return (
          <div>
            <p
              tabIndex={0}
              role="button"
              onKeyDown={null}
              onClick={() => handleDocumentDownload(original, t)}
              className={styles.documentNameValue}
            >
              {fileName}
            </p>
          </div>
        );
      },
    },
  ]), [t, postAd]);

  return (
    <div className={utils.cn([styles.wrapper, 'm-t-50'])}>
      <h2>{t('property.tabs.pictures.title')}</h2>
      <div className="m-t-10 m-b-60">
        <FormInfoRequired content={t('property.tabs.pictures.info')} />
      </div>
      <DocumentList
        className="m-t-25"
        fileList={[]}
        columns={columns}
        documentListData={pictureListData}
        addButton={{
          enabled: true,
          label: t('property.tabs.pictures.add'),
          icon: 'plus',
        }}
        onAddBtnClick={() => {
          showModal(addPictureModalId);
        }}
        onFileListElementAddClick={(element) => {
          const { type: documentType } = element;
          setFileType(documentType);
          formik.setFieldValue('type', documentType);
          showModal(addPictureModalId);
        }}
        onFileListElementDelete={(element) => {
          pictureDeleteMutation.mutate(element?.uid);
        }}
        pagination={{
          page,
          itemsPerPage,
          setPage,
          setItemsPerPage,
        }}
        isDocumentListFetching={pictureListFetching}
      />
      <ImageModal
        className={styles.modal}
        id={pictureDisplayModalId}
        onClose={() => {
          hideModal(pictureDisplayModalId);
          setCurrentPictureOpened(null);
        }}
      >
        {/* ORIGINAL */}
        {currentPictureOpened
          ? (
            <img
              src={currentPictureOpened?.thumbnails?.find((thumbnail) => thumbnail?.format === 'property_large')?.url}
              alt={currentPictureOpened?.name}
            />
          )
          : null}
      </ImageModal>
      <Modal
        className={styles.modal}
        id={addPictureModalId}
        title={isHugeDocument
          ? t('global.documents.addDocumentForm.errors.size.title')
          : t('global.documents.addDocumentForm.title')}
        size="large"
        onClose={() => {
          hideModal(addPictureModalId);
        }}
      >
        {!isHugeDocument ? (
          <form onSubmit={formik.handleSubmit} className={styles.addForm}>
            <TextInput
              type="text"
              id="customName"
              name="customName"
              className="m-t-25"
              label={t('global.documents.addDocumentForm.fields.name')}
              error={displayError(t, formik, 'customName')}
              valid={isFieldValid(formik, 'customName', null, initialValues?.customName)}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.customName}
            />
            {!postAd && (
              <ToggleInput
                className="m-t-25"
                id="isMain"
                name="isMain"
                label={t('property.tabs.pictures.toggleMainPicture')}
                checked={isMainToggle}
                onChange={() => setIsMainToggle(!isMainToggle)}
              />
            )}
            <FileInput
              className="m-t-25"
              id="profile-file"
              name="profile-file"
              label={t('global.documents.addDocumentForm.fields.file')}
              help={t('global.documents.addDocumentForm.fileHelp')}
              accept="image/jpg,image/png,image/jpeg,image/gif"
              value={file}
              onChange={(e) => {
                const f = e?.target?.files[0];

                if (f?.size > 10000000) setHugeDocument(true);
                if (f) {
                  const image = new Image();
                  image.src = URL.createObjectURL(f);

                  const setFileFields = () => {
                    formik.setFieldValue('filename', f?.name);
                    formik.setFieldValue('metadata', {
                      documentSize: f?.size,
                      extension: f?.type?.split('/')[1],
                      width: image.naturalWidth,
                      height: image.naturalHeight,
                    });
                    setFile(f);

                    image.removeEventListener('load', setFileFields);
                  };

                  image.addEventListener('load', setFileFields);
                } else {
                  setFile(null);
                  formik.setFieldValue('filename', '');
                  formik.setFieldValue('metadata', null);
                }
              }}
            />
            <div className={styles.submitBtn}>
              <Button
                type="submit"
                loading={isUploading}
                className="m-t-25"
                label={t('global.documents.addDocumentForm.submit')}
              />
            </div>
          </form>
        ) : (
          <div className={styles.hugeDocument}>
            <p className={styles.errorModalSubtitle}>{t('global.documents.addDocumentForm.errors.size.message')}</p>
            <div className={styles.submitBtn}>
              <Button
                className="m-t-25"
                label={t('global.documents.addDocumentForm.errors.size.action')}
                onClick={() => {
                  formik.resetForm();
                  setFileType(null);
                  setFile(null);
                  setHugeDocument(false);
                }}
              />
            </div>
          </div>
        ) }
      </Modal>
    </div>
  );
}

PropertyPictures.propTypes = {
  postAd: PropTypes.bool,
  userUID: PropTypes.string,
  propertyUID: PropTypes.string,
};

PropertyPictures.defaultProps = {
  postAd: false,
  userUID: null,
  propertyUID: null,
};

export default PropertyPictures;
