/* eslint-disable react/no-array-index-key */
import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

// Components
import {
  Button,
  SelectInput,
  TextAreaInput,
  Picto,
  utils,
} from 'ui-library-unlocker';
import QualityPicker from '../../../components/molecules/QualityPicker/QualityPicker';
import ConfirmationModal from '../../../components/organisms/ConfirmationModal/ConfirmationModal';
import PhotoSection from './PhotoSection';

// Utils
import { displayError } from '../../../utils/forms/form';
import { showModal, hideModal } from '../../../utils/modal';

// Constants
import { INVENTORY_FURNITURE_TYPES } from '../../../utils/constants';

// Styles
import styles from './InventoryRooms.module.scss';

function FurniturePiece({
  data,
  formikKey,
  handleRemoveFromList,
  roomIndex,
  furnitureIndex,
}) {
  const { t } = useTranslation();
  const { formik, updateInventoryMutation, isReadOnly } = useOutletContext();

  const handleBlur = useCallback((e) => {
    formik.handleBlur(e);
    if (formik.values.uid) {
      updateInventoryMutation.mutate(formik.values);
    }
  }, [formik, updateInventoryMutation]);

  const autoSaveOnChange = useCallback((field, value) => {
    formik.setFieldValue(`${formikKey}.${field}`, value);
    updateInventoryMutation.mutate({
      ...formik.values,
      rooms: formik.values.rooms.map((room, index) => {
        if (index === roomIndex) {
          return {
            ...room,
            furniture: room.furniture.map((furniture, i) => {
              if (i === furnitureIndex) {
                return {
                  ...furniture,
                  [field]: value,
                };
              }
              return furniture;
            }),
          };
        }
        return room;
      }),
    });
  }, [formik, formikKey, updateInventoryMutation, roomIndex, furnitureIndex]);

  return (
    <div className={styles.furniturePiece}>
      <div className={styles.furniturePieceHeader}>
        <SelectInput
          id={`${formikKey}.type`}
          name={`${formikKey}.type`}
          label={t('inventory.crud.rooms.furniture.type')}
          error={displayError(t, formik, `${formikKey}.type`, null, { nestedValue: true })}
          options={INVENTORY_FURNITURE_TYPES}
          onChange={(value) => autoSaveOnChange('type', value.value)}
          onBlur={formik.handleBlur}
          value={INVENTORY_FURNITURE_TYPES.find((typeItem) => typeItem.value === data.type) || null}
          disabled={isReadOnly}
        />
        {!isReadOnly && (
          <Button
            variant="danger"
            icon="trash"
            onClick={handleRemoveFromList}
          />
        )}
      </div>
      <QualityPicker
        className={utils.cn([styles.score, 'm-t-25', 'input'])}
        value={data.score}
        onChange={(value) => autoSaveOnChange('score', value)}
        error={displayError(t, formik, `${formikKey}.score`, null, { nestedValue: true })}
        disabled={isReadOnly}
      />
      <TextAreaInput
        className="m-t-25"
        id={`${formikKey}.comment`}
        name={`${formikKey}.comment`}
        label={`${t('inventory.crud.rooms.furniture.comment')} ${t('global.form.optional')}`}
        onChange={(e) => formik.setFieldValue(`${formikKey}.comment`, e.target.value || null)}
        onBlur={handleBlur}
        value={data.comment}
        error={displayError(t, formik, `${formikKey}.comment`, null, { nestedValue: true })}
        disabled={isReadOnly}
      />
      <PhotoSection
        title={t(`inventory.crud.rooms.furniture.photos.title.${data.type || 'default'}`)}
        id={`${formikKey}.pictures`}
        data={data}
        formik={formik}
        formikKey={formikKey}
        updateInventoryMutation={updateInventoryMutation}
      />
    </div>
  );
}

FurniturePiece.propTypes = {
  data: PropTypes.shape({
    type: PropTypes.string,
    score: PropTypes.number,
    comment: PropTypes.string,
  }).isRequired,
  formikKey: PropTypes.string.isRequired,
  handleRemoveFromList: PropTypes.func.isRequired,
  roomIndex: PropTypes.number.isRequired,
  furnitureIndex: PropTypes.number.isRequired,
};

function FurnitureList({
  data,
  formikKey,
  isCollapsed,
  toggleCollapse,
  roomIndex,
}) {
  const { t } = useTranslation();
  const { formik, updateInventoryMutation, isReadOnly } = useOutletContext();

  const [indexToDelete, setIndexToDelete] = useState(null);

  const DELETE_CONFIRM_MODAL_ID = `deleteFurniturePiece-${formikKey}`;

  const handleAddFurniturePiece = useCallback(() => {
    const newFurniture = [
      ...(data || []),
      {
        type: 'undefined',
        score: null,
        comment: null,
      },
    ];
    formik.setFieldValue(formikKey, newFurniture);
    updateInventoryMutation.mutate({
      ...formik.values,
      rooms: formik.values.rooms.map((room, index) => {
        if (index === roomIndex) {
          return {
            ...room,
            furniture: newFurniture,
          };
        }
        return room;
      }),
    });
  }, [formik, formikKey, data, roomIndex, updateInventoryMutation]);

  const handleDelete = useCallback((index) => {
    setIndexToDelete(index);
    showModal(DELETE_CONFIRM_MODAL_ID);
  }, []);

  const cancelDelete = useCallback(() => {
    setIndexToDelete(null);
    hideModal(DELETE_CONFIRM_MODAL_ID);
  }, []);

  const handleRemoveFromList = useCallback(() => {
    const newFurniture = data.filter((_, i) => i !== indexToDelete);
    formik.setFieldValue(formikKey, newFurniture);
    updateInventoryMutation.mutate({
      ...formik.values,
      rooms: formik.values.rooms.map((room, index) => {
        if (index === roomIndex) {
          return {
            ...room,
            furniture: newFurniture,
          };
        }
        return room;
      }),
    });
    cancelDelete();
  }, [formik, formikKey, data, indexToDelete, cancelDelete, roomIndex, updateInventoryMutation]);

  const renderFurniture = useCallback(() => {
    if (data) {
      return data.map((furniturePiece, index) => (
        <FurniturePiece
          key={index}
          data={furniturePiece}
          formikKey={`${formikKey}.${index}`}
          handleRemoveFromList={() => handleDelete(index)}
          roomIndex={roomIndex}
          furnitureIndex={index}
        />
      ));
    }
    return null;
  }, [data, formikKey, handleDelete, roomIndex]);

  return (
    <>
      <div>
        <div
          className={utils.cn([styles.furnitureListHeader, 'm-t-25'])}
          onClick={toggleCollapse}
          role="button"
          tabIndex="0"
          onKeyDown={toggleCollapse}
        >
          <Picto
            className={utils.cn([
              styles.furnitureListHeaderIcon,
              isCollapsed && styles.furnitureListHeaderIconCollapsed,
            ])}
            icon="arrow-down"
            width={20}
          />
          <h3 className="t-h3-700">{t('inventory.crud.rooms.furniture.title')}</h3>
          <small className={styles.furnitureListHeaderCount}>
            {data?.length || 0}
          </small>
        </div>
      </div>
      {!isCollapsed && (
      <>
        {renderFurniture()}
        {!isReadOnly && (
          <Button
            className="m-t-25"
            variant="secondary"
            label={t('inventory.crud.rooms.furniture.add')}
            icon="plus"
            onClick={handleAddFurniturePiece}
          />
        )}
      </>
      )}
      <ConfirmationModal
        id={DELETE_CONFIRM_MODAL_ID}
        onSubmit={handleRemoveFromList}
        onCancel={cancelDelete}
      />
    </>
  );
}

FurnitureList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  formikKey: PropTypes.string.isRequired,
  isCollapsed: PropTypes.bool,
  toggleCollapse: PropTypes.func,
  roomIndex: PropTypes.number.isRequired,
};

FurnitureList.defaultProps = {
  isCollapsed: false,
  toggleCollapse: () => {},
};

export default FurnitureList;
