import React, {
  useState,
  useMemo,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useMediaQuery } from 'react-responsive';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';
import {
  Picto, Tag, utils,
} from 'ui-library-unlocker';

// Components
import Tooltip from '../../../components/atoms/Tooltip/Tooltip';
import TableOptions from '../../../components/atoms/TableOptions/TableOptions';
import DataTable from '../../../components/organisms/DataTable/DataTable';

// Services
import { getUsersFromAdmin } from '../../../services/admin';
import { impersonate, forceConfirmUser } from '../../../services/identity';

// Utils
import { getEnrolmentStatusVariant, getPersonStatusVariant } from '../../../utils/variants';

// Hooks
import useDocumentTitle from '../../../hooks/useDocumentTitle';

// Styles
import styles from './UserList.module.scss';

// Constants
import { ENROLMENT_STATUS, SEARCH_SCOPE_LIST, PERSON_STATUS } from '../../../utils/constants';

const SEARCH_SCOPE = {
  [SEARCH_SCOPE_LIST.PROPERTY_OWNERS]: 'uid',
  [SEARCH_SCOPE_LIST.MANAGERS]: 'uid',
  [SEARCH_SCOPE_LIST.TENANTS]: 'uid',
};

const {
  PENDING,
  PROFILE_DONE,
  ENROLMENT_REQUESTED,
  ENROLMENT_PENDING,
  ENROLMENT_CHALLENGED,
  ENROLMENT_DENIED,
  ENROLMENT_BYPASSED,
  COMPLETED,
  NO_PROFILE,
} = ENROLMENT_STATUS;

function UsersListView() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  const [tooltipOpen, setTooltipOpen] = useState(null);
  const [optimisticEnabledUids, setOptimisticEnabledUids] = useState([]);

  useDocumentTitle(t('user.browserTitle'));

  const {
    mutate: postImpersonateMutate,
    isLoading: postImpersonateIsLoading,
  } = useMutation({
    mutationKey: ['impersonate'],
    mutationFn: impersonate,
    onError: () => {
      utils.toast.error(t('global.form.errors.global'));
    },
    onSettled: () => {
      setTooltipOpen(null);
    },
  });

  const {
    mutate: forceConfirmMutate,
    isLoading: forceConfirmIsLoading,
  } = useMutation({
    mutationKey: ['forceConfirm'],
    mutationFn: forceConfirmUser,
    onSuccess: (_, uid) => {
      utils.toast.success(t('user.table.userForceConfirmed'));
      setOptimisticEnabledUids((prev) => [
        ...prev,
        uid,
      ]);
    },
    onError: () => {
      utils.toast.error(t('global.form.errors.global'));
    },
  });

  const columns = useMemo(() => ([
    {
      header: t('user.table.columns.id'),
      accessorKey: 'unlockerId',
      size: 100,
    },
    {
      header: t('user.table.columns.createdAt'),
      accessorKey: 'createdAt',
      size: 100,
      cell: ({ row: { original: { createdAt } } }) => (
        <div>
          <span>{createdAt ? format(new Date(createdAt), 'dd MMM yyyy', { locale: fr }) : '-'}</span>
        </div>
      ),
    },
    {
      header: t('user.table.columns.name'),
      accessorKey: 'lastName',
      minSize: 150,
      cell: ({ row: { original: { firstName, lastName } } }) => (
        <span>
          {firstName}
          {' '}
          {lastName}
        </span>
      ),
    },
    {
      header: t('user.table.columns.phone'),
      accessorKey: 'phoneNumber',
      size: 1,
      enableSorting: false,
      cell: ({
        row: {
          original: {
            phoneNumber, phoneCountryCode, mobilePhoneNumber, mobilePhoneCountryCode,
          },
        },
      }) => (
        <div>
          <p className="p-2-500">
            {utils.getCompletePhoneNumber({ phone: mobilePhoneNumber, countryCode: mobilePhoneCountryCode })}
          </p>
          {phoneNumber != null ? (
            <p className="p-2-500">
              {utils.getCompletePhoneNumber({ phone: phoneNumber, countryCode: phoneCountryCode })}
            </p>
          ) : null}
        </div>
      ),
    },
    {
      header: t('user.table.columns.status'),
      accessorKey: 'status',
      size: 200,
      enableSorting: false,
      cell: ({ row: { original: { status, underPendingInvitation } } }) => (
        <div>
          <Tag
            label={t(`global.personStatus.${underPendingInvitation ? 'isPendingInvitation' : status}`)}
            size="medium"
            variant={getPersonStatusVariant(status, underPendingInvitation)}
            wrap={!isMobile}
          />
        </div>
      ),
    },
    {
      header: t('user.table.columns.onboardingStatus'),
      accessorKey: 'onboardingStatus',
      size: 200,
      enableSorting: false,
      cell: ({ row: { original: { onboardingStatus } } }) => (
        <Tag
          size="medium"
          variant={getEnrolmentStatusVariant(onboardingStatus)}
          label={t(`profile.profileState.${onboardingStatus}`)}
          wrap={!isMobile}
        />
      ),
    },
    {
      header: t('user.table.columns.type'),
      accessorKey: 'role',
      minSize: 1,
      enableSorting: false,
      cell: ({ row: { original: { role } } }) => (
        <span>
          <Tag
            label={t(`user.table.columns.role.${role}`)}
            variant={role === 'ROLE_USER' ? 'secondary-outline' : 'tertiary'}
            size="medium"
          />
        </span>
      ),
    },
    {
      header: '',
      accessorKey: 'additionalOptions',
      size: 1,
      enableSorting: false,
      cell: ({ row }) => (
        <div className={styles.seeMore}>
          <Picto
            id={`more-option-user-${row?.original?.uid}`}
            icon="more"
            width={24}
            onClick={(e) => {
              e.stopPropagation();
              setTooltipOpen(tooltipOpen === row?.original?.uid ? null : row?.original?.uid);
            }}
            color="var(--color-secondary)"
          />
          <Tooltip
            isOpen={tooltipOpen === row?.original?.uid}
            anchorId={`more-option-user-${row?.original?.uid}`}
            place="bottom"
            type="dark"
            effect="solid"
          >
            <TableOptions
              options={[
                {
                  id: 'edit',
                  label: t('global.listOptions.see'),
                  icon: <Picto icon="edit" width={24} color="var(--color-primary)" />,
                  onHoverIcon: <Picto icon="edit" width={24} color="var(--color-white)" />,
                  onClick: (e) => {
                    e.stopPropagation();
                    setTooltipOpen(null);
                    navigate(`/user/${row?.original?.uid}`);
                  },
                },
                row?.original?.status === PERSON_STATUS.ENABLED ? {
                  id: 'impersonate',
                  label: t('global.listOptions.impersonate'),
                  icon: postImpersonateIsLoading ? (
                    <Picto icon="loading" width={24} color="var(--color-primary)" />
                  ) : (
                    <Picto icon="user" width={24} color="var(--color-primary)" />
                  ),
                  onHoverIcon: postImpersonateIsLoading ? (
                    <Picto icon="loading" width={24} color="var(--color-white)" />
                  ) : (
                    <Picto icon="user" width={24} color="var(--color-white)" />
                  ),
                  onClick: (e) => {
                    e.stopPropagation();
                    postImpersonateMutate(row?.original);
                  },
                } : {
                  id: 'forceConfirm',
                  label: t('user.table.listOptions.forceConfirm'),
                  icon: forceConfirmIsLoading ? (
                    <Picto icon="loading" width={24} color="var(--color-primary)" />
                  ) : (
                    <Picto icon="tick-circle" width={24} color="var(--color-primary)" />
                  ),
                  onHoverIcon: forceConfirmIsLoading ? (
                    <Picto icon="loading" width={24} color="var(--color-white)" />
                  ) : (
                    <Picto icon="tick-circle" width={24} color="var(--color-white)" />
                  ),
                  onClick: (e) => {
                    e.stopPropagation();
                    forceConfirmMutate(row?.original?.uid);
                  },
                },
              ]}
            />
          </Tooltip>
        </div>
      ),
    },
  ]), [
    t,
    tooltipOpen,
    postImpersonateIsLoading,
    postImpersonateMutate,
    navigate,
    isMobile,
    forceConfirmIsLoading,
    forceConfirmMutate,
  ]);

  const allFiltersOptions = useMemo(() => [
    {
      name: 'role',
      type: 'checkbox',
      title: t('user.table.columns.type'),
      options: [
        {
          value: 'ROLE_USER',
          label: t('user.table.columns.role.ROLE_USER'),
        },
        {
          value: 'ROLE_LESSOR',
          label: t('user.table.columns.role.ROLE_LESSOR'),
        },
      ],
    },
    {
      name: 'onboardingStatus',
      type: 'checkbox',
      title: t('user.table.columns.onboardingStatus'),
      options: [
        {
          value: PENDING,
          label: t('profile.profileState.pending'),
        },
        {
          value: PROFILE_DONE,
          label: t('profile.profileState.profile_done'),
        },
        {
          value: `${ENROLMENT_REQUESTED},${ENROLMENT_PENDING},${ENROLMENT_CHALLENGED},${ENROLMENT_DENIED}`,
          label: t('profile.profileState.enrolment_requested'),
        },
        {
          value: `${ENROLMENT_BYPASSED},${COMPLETED}`,
          label: t('profile.profileState.completed'),
        },
        {
          value: NO_PROFILE,
          label: t('profile.profileState.no_profile'),
        },
      ],
    },
  ], []);

  const formatData = useCallback((data) => {
    if (!data) return data;
    const users = [...data];
    optimisticEnabledUids.forEach((uid) => {
      const userIndex = users.findIndex((user) => user.uid === uid);
      if (userIndex !== -1) {
        users[userIndex].status = PERSON_STATUS.ENABLED;
      }
    });
    return users;
  }, [optimisticEnabledUids]);

  const resetOptimistic = useCallback(() => {
    setOptimisticEnabledUids([]);
  }, []);

  return (
    <div
      onClick={() => setTooltipOpen(null)}
      role="presentation"
      onKeyDown={null}
    >
      <div className={styles.heading}>
        <h1>{t('user.title')}</h1>
      </div>
      <DataTable
        columns={columns}
        searchLabel={t('user.search')}
        omniSearch
        omniSearchScope={SEARCH_SCOPE}
        getData={getUsersFromAdmin}
        formatData={formatData}
        queryKey="user-list"
        allFiltersOptions={allFiltersOptions}
        onRefetch={resetOptimistic}
      />
    </div>
  );
}

export default UsersListView;
