/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-unresolved */
import React, {
  useState, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';

// LightBox
import {
  Lightbox, IconButton, useLightboxState, createIcon,
} from 'yet-another-react-lightbox';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Counter from 'yet-another-react-lightbox/plugins/counter';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import Download from 'yet-another-react-lightbox/plugins/download';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import 'yet-another-react-lightbox/plugins/counter.css';

// Components
import Picto from '../../atoms/Picto/Picto';

// Styles
import styles from './Camera.module.scss';

const DeleteIcon = createIcon('DeleteIcon', <Picto icon="trash" />);

const DeleteImageButton = ({ onClick }) => {
  const { currentSlide } = useLightboxState();

  const handleClick = useCallback(() => {
    onClick?.(currentSlide);
  }, [currentSlide]);

  return (
    <IconButton
      label="Delete"
      icon={DeleteIcon}
      onClick={handleClick}
    />
  );
};

DeleteImageButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const CameraGallery = ({ images, updateImages }) => {
  const [open, setOpen] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);

  const reversed = useMemo(() => [...images].reverse(), [images]);

  const list = useMemo(() => reversed.slice(0, 3), [reversed]);

  const slides = useMemo(() => reversed.map((img) => ({
    src: img,
    alt: 'photo',
  })), [reversed]);

  const openLightbox = useCallback(() => {
    setOpen(true);
  }, []);

  const closeLightbox = useCallback(() => {
    setOpen(false);
    setSlideIndex(0);
  }, []);

  const handleDelete = useCallback((slideToDelete) => {
    const newImages = images.filter((img) => img !== slideToDelete.src);
    updateImages?.(newImages);
    if (newImages.length === 0) {
      closeLightbox();
    }
  }, [images, updateImages]);

  const calculatedIndex = useMemo(() => {
    if (slideIndex >= images.length) return images.length - 1 || 0;
    return slideIndex;
  }, [images, slideIndex]);

  return (
    <>
      <div
        className={styles.imagesThumbnails}
        onClick={openLightbox}
        onKeyDown={openLightbox}
        role="presentation"
      >
        <div className={styles.thumbnailsContainer}>
          {list.map((image, index) => (
            <img
              key={index}
              src={image}
              alt="Capture"
              className={styles.thumbnail}
            />
          ))}
        </div>
      </div>
      <Lightbox
        open={open}
        close={closeLightbox}
        index={calculatedIndex}
        on={{ view: ({ index }) => setSlideIndex(index) }}
        plugins={[Thumbnails, Counter, Zoom, Download]}
        toolbar={{
          buttons: [
            <DeleteImageButton key="delete-button" onClick={handleDelete} />,
            'download',
            'zoom',
            'close',
          ],
        }}
        slides={slides}
        carousel={{
          finite: true,
        }}
        styles={{ root: { '--yarl__color_backdrop': 'rgba(0, 0, 0, .9)' } }}
        controller={{
          closeOnBackdropClick: true,
        }}
      />
    </>
  );
};

CameraGallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  updateImages: PropTypes.func.isRequired,
};

export default CameraGallery;
