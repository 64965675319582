/* eslint-disable max-len */
import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useMatch } from 'react-router-dom';

// Components
import {
  Button,
} from 'ui-library-unlocker';
import HousingBenefitsForm from '../../components/organisms/forms/HousingBenefitsForm/HousingBenefitsForm';
import BackButton from '../../components/atoms/BackButton/BackButton';
import AddDocumentModal from '../../components/organisms/AddDocumentModal/AddDocumentModal';
import HousingBenefitsImportValidation from './Import/HousingBenefitsImportValidation';

// Services
import { getHousingBenefit } from '../../services/housingBenefits';

// Hooks
import useDocumentTitle from '../../hooks/useDocumentTitle';

// Utils
import { showModal } from '../../utils/modal';

import styles from './HousingBenefitsEdit.module.scss';

const IMPORT_FILE_MODAL_ID = 'import-file-modal';

function HousingBenefitsEdit({ isEditing }) {
  const { t } = useTranslation();
  useDocumentTitle(t(`housingBenefits.crud.${isEditing ? 'edit' : 'add'}BrowserTitle`));
  const [formType, setFormType] = useState(isEditing ? 'crud' : null); // crud or import
  const [importData, setImportData] = useState(null);

  const match = useMatch('/housing-benefits/:id');

  const housingBenefitsQuery = useQuery({
    queryKey: ['housing-benefits-id', isEditing, match?.params?.id],
    queryFn: () => getHousingBenefit(match?.params?.id),
    cacheTime: 0,
    enabled: !!(isEditing && match?.params?.id !== 'add'),
  });

  const handleFileImport = useCallback((_, data) => {
    setFormType('import');
    setImportData(data);
  }, []);

  const handleImportBtn = useCallback(() => {
    showModal(IMPORT_FILE_MODAL_ID);
  }, []);

  const renderForm = useCallback(() => {
    if (!formType) {
      return (
        <div className="m-t-20">
          <Button onClick={() => setFormType('crud')}>
            {t('housingBenefits.crud.formType.crud')}
          </Button>
          <Button
            className="m-t-20"
            variant="secondary"
            label={t('housingBenefits.crud.formType.import')}
            icon="import"
            onClick={handleImportBtn}
          />
        </div>
      );
    }
    if (formType === 'crud') {
      return (
        <>
          {!isEditing && (
            <BackButton
              label={t('housingBenefits.goBack')}
              onClick={() => setFormType(null)}
              className="m-b-30"
            />
          )}
          <HousingBenefitsForm isEditing={isEditing} housingBenefitsQuery={housingBenefitsQuery} />
        </>
      );
    }
    if (formType === 'import') {
      return (
        <HousingBenefitsImportValidation
          importData={importData}
        />
      );
    }
    return null;
  }, [formType, isEditing, housingBenefitsQuery, setFormType]);

  return (
    <div>
      <div className={styles.heading}>
        <div className={styles.title}>
          <h1 className="m-b-30">{isEditing ? t('housingBenefits.crud.editTitle') : t('housingBenefits.crud.addTitle')}</h1>
        </div>
      </div>
      <div className={styles.content}>
        {renderForm()}
      </div>
      <AddDocumentModal
        modalId={IMPORT_FILE_MODAL_ID}
        entityType="housingBenefit"
        refetchDocumentList={handleFileImport}
        fileType="housing_benefits_import"
        documentType="housingBenefitsDocument"
        fileFormat="pdf"
      />
    </div>
  );
}

HousingBenefitsEdit.propTypes = {
  isEditing: PropTypes.bool.isRequired,
};

export default HousingBenefitsEdit;
