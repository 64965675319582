/* eslint-disable max-len */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// Components
import {
  Table,
  Tag,
  Picto,
  Message,
  utils,
} from 'ui-library-unlocker';

// Hooks
import useRoles from '../../../hooks/useRoles';
import { useAppContext } from '../../../store/context';

// Utils
import { getPersonStatusVariant } from '../../../utils/variants';

// Styles
import styles from './LeaseTenants.module.scss';

function LeaseTenants({ tenantList, isLoading }) {
  const { t } = useTranslation();
  const { isUserLessor } = useRoles();
  const { context: { user } } = useAppContext();

  const columns = useMemo(() => ([
    {
      header: t('lease.tenants.table.columns.id'),
      accessorKey: 'unlockerId',
      size: 100,
      enableSorting: false,
    },
    {
      header: t('lease.tenants.table.columns.name'),
      accessorKey: 'name',
      minSize: 1,
      enableSorting: false,
      cell: ({ row: { original: { firstName, lastName, uid } } }) => (
        <div className={styles.tableName}>
          {isUserLessor ? (
            <Link to={`/tenant/${uid}`} target="_blank">
              <p className="p-2-700">
                {`${firstName} ${lastName}`}
                <Picto icon="export-link" width={9} color="var(--color-secondary)" className={styles.externalLink} />
              </p>
            </Link>
          ) : (
            <p className="p-2-700">
              {`${firstName} ${lastName}`}
            </p>
          )}
        </div>
      ),
    },
    {
      header: t('lease.tenants.table.columns.email'),
      accessorKey: 'email',
      size: 100,
      enableSorting: false,
    },
    {
      header: t('lease.tenants.table.columns.phone'),
      accessorKey: 'mobilePhoneNumber',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { mobilePhoneCountryCode, mobilePhone } } }) => (
        <div>
          {utils.getCompletePhoneNumber({
            countryCode: mobilePhoneCountryCode,
            phone: mobilePhone,
          })}
        </div>
      ),
    },
    {
      header: t('lease.tenants.table.columns.status'),
      accessorKey: 'status',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { status } } }) => (
        <div>
          <Tag
            label={t(`global.personStatus.${status}`)}
            size="medium"
            variant={getPersonStatusVariant(status, status === 'isPendingInvitation')}
          />
        </div>
      ),
    },
    {
      header: t('tenant.table.columns.delegated'),
      accessorKey: 'delegated',
      size: 100,
      enableSorting: false,
      cell: ({ row }) => {
        const userHasDelegation = (
          row?.original?.isDelegated
          && user?.username === row?.original?.delegatedTo
        );
        return (
          <div>
            <span>
              {userHasDelegation ? (
                <Tag
                  label={t('tenant.table.columns.delegated')}
                  size="medium"
                  variant="tertiary"
                />
              ) : ''}
            </span>
          </div>
        );
      },
    },
  ]), [t]);

  return (
    <div className="m-t-50">
      <div className={styles.content}>
        {!isLoading && !tenantList.length ? (
          <Message content={t('global.form.errors.global')} />
        ) : (
          <Table
            fullWidth
            columns={columns}
            data={tenantList}
            isLoading={isLoading}
          />
        )}
      </div>
    </div>
  );
}

LeaseTenants.propTypes = {
  tenantList: PropTypes.arrayOf(PropTypes.shape()),
  isLoading: PropTypes.bool,
};

LeaseTenants.defaultProps = {
  tenantList: [],
  isLoading: false,
};

export default LeaseTenants;
