/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, {
  useState, useMemo, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

// Components
import {
  TextInput,
  SelectInput,
  Button,
  utils,
} from 'ui-library-unlocker';
import FileInput from '../../../components/molecules/FileInput/FileInput';
import Modal from '../../../components/molecules/Modal/Modal';
import PropertyInvoiceSplit from './PropertyInvoiceSplit';

// Services
import { createPropertyInvoice } from '../../../services/property';

// Hooks
import useFileUpload from '../../../hooks/useFileUpload';
import { useAppContext } from '../../../store/context';

// Utils
import { hideModal } from '../../../utils/modal';
import { displayError } from '../../../utils/forms/form';
import { getDocumentSchema, documentInitialValues } from './documentSchema';
import { handleFileSelect } from '../../../utils/documents';

// Constants
import { PROPERTY_STATUS } from '../../../utils/constants';

// Styles
import styles from './PropertyInvoices.module.scss';

// TODO: handle charge, amount
function AddPropertyInvoiceModal({
  refetchDocumentList,
  property,
  modalId,
}) {
  const { t } = useTranslation();
  const { context: { user, uiBuilders } } = useAppContext();

  const [isHugeDocument, setHugeDocument] = useState(false);

  const validationSchema = useMemo(() => {
    if (uiBuilders) {
      const { invoiceTypes } = uiBuilders['/property/ui'];
      return getDocumentSchema({ documentType: Object.keys(invoiceTypes) });
    }
    return getDocumentSchema({ documentType: [] });
  }, [uiBuilders]);

  const documentTypeOptions = useMemo(() => {
    if (uiBuilders) {
      const { invoiceTypes } = uiBuilders['/property/ui'];
      return Object.keys(invoiceTypes).map((key) => ({
        value: key,
        label: invoiceTypes[key],
      }));
    }
    return [];
  }, [uiBuilders]);

  const initialValues = useMemo(() => {
    if (user) {
      return {
        ...documentInitialValues,
        userUid: user?.username,
      };
    }
    return documentInitialValues;
  }, [user]);

  // DOCUMENT UPLOAD
  const DOCUMENT_TYPE = 'propertyDocument';

  const resetDocumentModal = () => {
    hideModal(modalId);
    setFileType(null);
    setFile(null);
    setHugeDocument(false);
    formik.resetForm();
  };

  const createDocumentCb = async (documentUid, fileName) => {
    const createdDocument = await createPropertyInvoice(property?.uid, {
      type: fileType,
      name: formik.values.customName || fileName,
      documentUid,
      date: new Date(),
      amount: utils.euroToCents(formik.values.amount),
      charge: formik.values.charge,
      splits: formik.values.splits?.map((split) => ({
        ...split,
        amount: utils.euroToCents(split.amount),
      })),
      withRentDistribution: formik.values.withRentDistribution,
    });
    refetchDocumentList();
    resetDocumentModal();
    return createdDocument;
  };

  const [{ uploadFile, setFileType, setFile }, {
    fileType, file, isUploading,
  }] = useFileUpload(createDocumentCb);

  const totalRent = useMemo(() => {
    if (property) {
      const {
        rentExcludingCharges = 0,
        charges = 0,
        // rentSupplement = 0,
      } = property;
      return rentExcludingCharges + charges/* + rentSupplement */;
    }
    return 0;
  }, [property]);

  const formik = useFormik({
    initialValues,
    validate: (values) => {
      try {
        validationSchema.validateSync(values, {
          context: {
            totalRent,
          },
          abortEarly: false,
        });
      } catch (err) {
        const errorList = err.inner.reduce((errors, er) => {
          errors[er.path] = er.message;
          return errors;
        }, {});

        return errorList;
      }
      return {};
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      if (typeof file === 'object') {
        const {
          amount,
          charge,
          splitTotal,
          splits,
          ...submitValues
        } = values;
        uploadFile({
          ...submitValues,
          type: DOCUMENT_TYPE,
        });
      } else {
        utils.toast.info(t('global.documents.addDocumentForm.errors.noFileSelected'));
      }
    },
  });

  const chargeOptions = useMemo(() => [
    {
      value: 'deductible',
      label: t('property.tabs.invoices.charge.deductible'),
    },
    {
      value: 'depreciable',
      label: t('property.tabs.invoices.charge.depreciable'),
    },
    {
      value: 'other',
      label: t('property.tabs.invoices.charge.other'),
    },
  ], []);

  const handleFileChange = useCallback((e) => {
    handleFileSelect(e, setHugeDocument, formik, setFile, t);
  }, [formik, setFile, t]);

  return (
    <Modal
      className={styles.modal}
      id={modalId}
      title={isHugeDocument
        ? t('global.documents.addDocumentForm.errors.size.title')
        : t('property.tabs.invoices.addDocument')}
      size="small"
      onClose={() => {
        hideModal(modalId);
      }}
    >
      {!isHugeDocument ? (
        <form onSubmit={formik.handleSubmit} className={styles.addForm}>
          <SelectInput
            isSearchable={false}
            id="type"
            name="type"
            label={t('global.documents.addDocumentForm.fields.type')}
            options={documentTypeOptions}
            onChange={(value) => {
              setFileType(value.value);
              formik.setFieldValue('type', value.value);
            }}
            onBlur={formik.handleBlur}
            error={displayError(t, formik, 'type')}
            value={documentTypeOptions.find((type) => type.value === fileType) || null}
          />
          <TextInput
            type="text"
            id="customName"
            name="customName"
            className="m-t-25"
            label={t('global.documents.addDocumentForm.fields.name')}
            error={displayError(t, formik, 'customName')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.customName}
          />
          <SelectInput
            isSearchable={false}
            id="charge"
            name="charge"
            className="m-t-25"
            label={t('property.tabs.invoices.fields.charge')}
            options={chargeOptions}
            error={displayError(t, formik, 'charge')}
            onChange={(value) => formik.setFieldValue('charge', value.value)}
            onBlur={formik.handleBlur}
            value={chargeOptions.find((type) => type.value === formik.values.charge) || null}
          />
          <TextInput
            type="number"
            min="0"
            step="0.01"
            id="amount"
            name="amount"
            className="m-t-25"
            label={t('property.tabs.invoices.fields.amount')}
            info={t('property.tabs.invoices.fields.amountRentSplitInfo', {
              totalRent: utils.centsToEuro(totalRent),
            })}
            error={displayError(t, formik, 'amount')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.amount}
            icon="euro"
            iconSize={20}
            iconColor="var(--color-primary-300)"
          />
          {property?.status === PROPERTY_STATUS.LEASED && (
            <PropertyInvoiceSplit totalRent={totalRent} formik={formik} />
          )}
          <FileInput
            className="m-t-25"
            id="property-file"
            name="property-file"
            label={t('global.documents.addDocumentForm.fields.file')}
            help={t('global.documents.addDocumentForm.fileHelp')}
            accept="image/jpg,image/png,image/jpeg,application/pdf"
            value={file}
            onChange={handleFileChange}
          />
          <div className={styles.submitBtn}>
            <Button
              type="submit"
              loading={isUploading}
              className="m-t-25"
              label={t('global.documents.addDocumentForm.submit')}
            />
          </div>
        </form>
      ) : (
        <div className={styles.hugeDocument}>
          <p className={styles.errorModalSubtitle}>{t('global.documents.addDocumentForm.errors.size.message')}</p>
          <div className={styles.submitBtn}>
            <Button
              className="m-t-25"
              label={t('global.documents.addDocumentForm.errors.size.action')}
              onClick={() => {
                formik.resetForm();
                setFileType(null);
                setFile(null);
                setHugeDocument(false);
              }}
            />
          </div>
        </div>
      ) }
    </Modal>
  );
}

AddPropertyInvoiceModal.propTypes = {
  refetchDocumentList: PropTypes.func.isRequired,
  property: PropTypes.shape({
    uid: PropTypes.string,
    rentExcludingCharges: PropTypes.number,
    charges: PropTypes.number,
    rentSupplement: PropTypes.number,
    status: PropTypes.oneOf(Object.values(PROPERTY_STATUS)),
  }).isRequired,
  modalId: PropTypes.string.isRequired,
};

export default AddPropertyInvoiceModal;
