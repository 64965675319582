/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import cn from '../../../utils/cn';

import styles from './ProgressBar.module.scss';

// onClose display a close icon if specified
const ProgressBar = ({
  value,
  total,
  className,
  size,
  hidden,
  showLabel,
  height,
}) => {
  const calculatePercent = useCallback((totalArg, valueArg) => (
    totalArg === 0 ? 100 : (Math.max(valueArg, 0) / totalArg) * 100
  ), []);

  const multiTotal = useMemo(() => {
    if (Array.isArray(value)) {
      return value.reduce((acc, item) => acc + item.value, 0);
    }
    return total;
  }, [value, total]);

  const percent = useMemo(() => {
    if (Array.isArray(value)) return 0;
    return calculatePercent(total, value);
  }, [total, value, calculatePercent]);

  return (
    <div
      className={cn([
        className,
        styles.progressBar,
        hidden ? styles.hidden : null,
      ])}
    >
      <div
        className={cn([
          styles.bar,
          styles[size],
        ])}
        style={{
          height: height ? `${height}px` : null,
          borderRadius: height ? `${height / 2}px` : null,
        }}
      >
        {Array.isArray(value) ? value.map((item) => (
          <div
            key={item.color}
            className={styles.progress}
            style={{
              width: `${Math.min(calculatePercent(multiTotal, item.value), 100)}%`,
              backgroundColor: item.color,
            }}
          />
        )) : (
          <div
            className={styles.progress}
            style={{
              width: `${Math.min(percent, 100)}%`,
            }}
          />
        )}
      </div>

      {showLabel && (
        <span className={styles.label}>
          {Math.floor(percent)}
          %
        </span>
      )}
    </div>
  );
};

ProgressBar.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.number,
      color: PropTypes.string,
    })),
  ]).isRequired,
  total: PropTypes.number,
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'fullWidth']),
  hidden: PropTypes.bool,
  showLabel: PropTypes.bool,
  height: PropTypes.number,
};

ProgressBar.defaultProps = {
  total: 100,
  className: null,
  size: 'medium',
  hidden: false,
  showLabel: true,
  height: null,
};

export default ProgressBar;
