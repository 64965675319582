import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { utils } from 'ui-library-unlocker';

import OutsideAlerter from '../../../hooks/useOutsideAlerter';

import styles from './Tooltip.module.scss';

function Tooltip({
  children, className, onClickOutside, ...props
}) {
  return createPortal(
    <OutsideAlerter onClickOutside={onClickOutside}>
      <ReactTooltip
        {...props}
        noArrow
        openOnClick
        className={utils.cn([
          className,
          styles.tooltip,
          styles.selector,
        ])}
      >
        {children}
      </ReactTooltip>
    </OutsideAlerter>,
    document.querySelector('body'),
  );
}

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClickOutside: PropTypes.func,
};

Tooltip.defaultProps = {
  className: null,
  onClickOutside: () => {},
};

export default Tooltip;
