/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-no-constructed-context-values */
import React, {
  useReducer, createContext, useContext, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import { Loader } from '@googlemaps/js-api-loader';

// Services
import { getMe, refreshUserToken } from '../services/identity';
import {
  getPropertyUI, getLeaseUI, getPersonUI, getMessagesUI,
} from '../services/uiBuilder';
import client from '../services/_client';
import { getConversations } from '../services/messages';

import { getUserFromToken } from '../utils/user';

// Store
import reducer from './reducer';

const loader = new Loader({
  apiKey: process.env.REACT_APP_MAP_API_KEY,
  id: '__googleMapsScriptId',
  version: 'beta', // go back to weekly when searchMap won't rely on google maps anymore
  libraries: ['places', 'marker'],
});

export const GlobalContext = createContext();

function ContextProvider({ children }) {
  const initialContext = {
    accessToken: localStorage.getItem('accessToken') || null,
    refreshToken: localStorage.getItem('refreshToken') || null,
    accessTokenBackup: localStorage.getItem('accessTokenBackup') || null,
    refreshTokenBackup: localStorage.getItem('refreshTokenBackup') || null,
    user: getUserFromToken(localStorage.getItem('accessToken') || null),
    me: null,
    sideMenuOpen: false,
    uiBuilders: null,
    emptyConversations: null,
    hasCompletedOnboardingFunnel: localStorage.getItem('hasCompletedOnboardingFunnel')
      ? localStorage.getItem('hasCompletedOnboardingFunnel') === 'true'
      : undefined,
    skipOnboarding: true,
    hasRealEstateAgency: localStorage.getItem('hasRealEstateAgency')
      ? localStorage.getItem('hasRealEstateAgency') === 'true'
      : undefined,
    searchMap: null,
    searchMapProperty: null,
    roleSpace: localStorage.getItem('roleSpace'),
    needDataTableRefetch: false,
    notifications: [],
    totalNumberOfNotifications: 0,
    hasUnreadNotifications: false,
  };
  const [context, dispatch] = useReducer(reducer, initialContext);

  const {
    refetch: refetchMe,
  } = useQuery({
    queryKey: ['me-data', context.accessToken],
    queryFn: async () => {
      const meResponse = await getMe();
      dispatch({
        type: 'SET_ME',
        payload: meResponse.data,
      });
      return meResponse;
    },
    enabled: !!context.accessToken,
  });

  useQuery({
    queryKey: ['user-conversations'],
    queryFn: async () => {
      const convRes = await getConversations({
        page: 1,
        itemsPerPage: 1,
      });
      dispatch({
        type: 'SET_EMPTY_CONVERSATIONS',
        payload: convRes?.data?.collection?.length === 0,
      });
      return convRes;
    },
    enabled: !!context.accessToken,
  });

  useQuery({
    queryKey: ['has-completed-onboarding-funnel', context.me],
    queryFn: async () => {
      const completedOnboardingFunnel = context.me?.hasCompletedOnboardingFunnel;
      dispatch({
        type: 'SET_HAS_COMPLETED_ONBOARDING_FUNNEL',
        payload: completedOnboardingFunnel,
      });
      localStorage.setItem('hasCompletedOnboardingFunnel', completedOnboardingFunnel);
      if (!completedOnboardingFunnel) {
        dispatch({
          type: 'SET_SKIP_ONBOARDING',
          payload: false,
        });
      }
      return completedOnboardingFunnel;
    },
    enabled: (
      !localStorage.getItem('hasCompletedOnboardingFunnel')
      && !!context.me
      && !!localStorage.getItem('accessToken')
    ),
  });

  // Check if user has real estate agency
  useEffect(() => {
    const userCompanies = context.me?.aclMatrix?.companies;
    if (!userCompanies) return;
    const someCompanyIsRealEstateAgency = userCompanies.some((company) => (
      company?.isRealEstateAgency
      && company?.owner
    ));
    dispatch({
      type: 'SET_HAS_REAL_ESTATE_AGENCY',
      payload: someCompanyIsRealEstateAgency,
    });
    localStorage.setItem('hasRealEstateAgency', someCompanyIsRealEstateAgency);
  }, [context.me?.aclMatrix?.companies]);

  // Google Maps
  useEffect(() => {
    loader
      .load()
      .then((google) => {
        dispatch({
          type: 'SET_GOOGLE_MAPS',
          payload: google,
        });
      });
  }, []);

  // UI Builders
  useEffect(() => {
    const fetchUIBuilders = async () => {
      const builders = await Promise.all([
        getPropertyUI(),
        getLeaseUI(),
        getPersonUI(),
        getMessagesUI(),
      ]);

      return builders.reduce((prev, acc) => {
        const { data } = acc;
        return {
          ...prev,
          [acc?.config?.url]: data,
        };
      }, {});
    };

    if (context?.user) {
      // User changed, fetch new data
      fetchUIBuilders().then((response) => {
        dispatch({
          type: 'SET_UI_BUILDERS',
          payload: response,
        });
      });
    }
  }, [context?.user]);

  // Send token in every request
  client.interceptors.request.use(
    (config) => {
      if (localStorage.getItem('accessToken') && !config.headers.Authorization && config.url !== '/auth/refresh') {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        };
      }
      return config;
    },
    (error) => Promise.reject(error),
  );

  // Refresh token if needed
  client.interceptors.response.use((response) => response, (error) => {
    const { user } = context;
    const logout = () => {
      // If the user was impersonating, we need to restore old token
      if (localStorage.getItem('accessTokenBackup') != null) {
        localStorage.setItem('accessToken', localStorage.getItem('accessTokenBackup'));
        localStorage.setItem('refreshToken', localStorage.getItem('refreshTokenBackup'));
        localStorage.removeItem('accessTokenBackup');
        localStorage.removeItem('refreshTokenBackup');
        localStorage.removeItem('impersonateIdentity');
        localStorage.setItem(
          'hasCompletedOnboardingFunnel',
          localStorage.getItem('hasCompletedOnboardingFunnelBackup'),
        );
        dispatch({
          type: 'SET_HAS_COMPLETED_ONBOARDING_FUNNEL',
          payload: localStorage.getItem('hasCompletedOnboardingFunnel') === 'true',
        });
        localStorage.removeItem('hasCompletedOnboardingFunnelBackup');
        localStorage.setItem('hasRealEstateAgency', localStorage.getItem('hasRealEstateAgencyBackup'));
        localStorage.removeItem('hasRealEstateAgencyBackup');
        localStorage.removeItem('roleSpace');
        dispatch({
          type: 'SET_ROLE_SPACE',
          payload: null,
        });
      } else {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('hasCompletedOnboardingFunnel');
        localStorage.removeItem('hasRealEstateAgency');
        localStorage.removeItem('roleSpace');
        dispatch({
          type: 'SET_AUTH',
          payload: {
            accessToken: null,
            refreshToken: null,
            user: null,
          },
        });
      }
      dispatch({
        type: 'SET_ACCESS_TOKEN_BACKUP',
        payload: null,
      });
    };
    const refreshTokenAndSave = () => refreshUserToken({
      refreshToken: localStorage.getItem('refreshToken'),
      username: user?.username,
    })
      .then(({ data: { accessToken, refreshToken } }) => {
        dispatch({
          type: 'SET_AUTH',
          payload: {
            accessToken: accessToken?.token,
            refreshToken,
            user: getUserFromToken(accessToken?.token),
          },
        });
        return accessToken?.token;
      });

    const status = error.response ? error.response.status : null;
    const requestUrl = error.response.config.url;

    // If the request originated from a refresh token request
    // we need to avoid an infinite loop
    if (requestUrl === '/identity-access/security/refresh-token') {
      logout();
      return Promise.reject(error);
    }

    if (status === 401) {
      return refreshTokenAndSave().then((accessToken) => {
        // eslint-disable-next-line no-param-reassign
        error.config.headers = {
          ...error.config.headers,
          Authorization: `Bearer ${accessToken}`,
        };
        // eslint-disable-next-line no-param-reassign
        error.config.baseURL = undefined;
        return client.request(error.config);
      })
        .catch((err) => Promise.reject(err));
    }
    return Promise.reject(error);
  });

  return (
    <GlobalContext.Provider value={{ context, dispatch, refetchMe }}>
      {children}
    </GlobalContext.Provider>
  );
}

ContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAppContext = () => useContext(GlobalContext);

export default ContextProvider;
