import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';

// Components
import {
  TextInput,
  RadioGroup,
  DateInput,
  Button,
  utils,
} from 'ui-library-unlocker';

// Components
import Modal from '../../../components/molecules/Modal/Modal';

// Services
import { retryPayment } from '../../../services/payment';

// Utils
import { displayError, isFieldValid } from '../../../utils/forms/form';

import styles from './RetryPaymentModal.module.scss';

function RetryPaymentModal({
  modalId,
  onClose,
  paymentUID,
  setPaymentRetries,
}) {
  const { t } = useTranslation();

  const minDate = useMemo(() => new Date(new Date().getTime() + 5 * 24 * 60 * 60 * 1000), []); // 5 days from now

  const initialValues = useMemo(() => ({
    paymentMethod: 'credit_transfer',
    date: minDate,
    label: '',
  }), []);

  const validationSchema = useMemo(() => yup.object().shape({
    paymentMethod: yup
      .string()
      .oneOf([
        'credit_transfer',
        'direct_debit',
      ])
      .required('global.form.errors.required'),
    date: yup.date().required('global.form.errors.required'),
    label: yup.string().nullable(),
  }), []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => retryPaymentMutation.mutate({
      ...values,
      label: values.label || null,
    }),
  });

  const retryPaymentMutation = useMutation({
    mutationFn: (data) => retryPayment(paymentUID, data),
    onSuccess: ({ data }) => {
      utils.toast.success(t('cashout.details.retryPayment.success'));
      handleClose();
      if (data && Array.isArray(data.children)) {
        setTimeout(() => setPaymentRetries((prev) => [...prev, ...data.children]), 500);
      }
    },
    onError: (err) => {
      switch (err?.response?.status) {
        case 400: {
          formik.setErrors(err.response.data?.errors);
          if (
            err.response.data?.message === 'PAYMENT_FORCE_RETRY_DATE_ERROR'
            || err.response.data?.message === 'PAYMENT_FORCE_RETRY_MANDATE_ERROR'
            || err.response.data?.message === 'PAYMENT_FORCE_RETRY_STATUS_ERROR'
          ) {
            utils.toast.error(t(`cashout.details.retryPayment.formErrors.${err.response.data.message}`));
          } else {
            utils.toast.error(t('global.form.errors.localGeneric'));
          }
          break;
        }
        case 500: {
          utils.toast.error(t('global.form.errors.generic'));
          break;
        }
        default: {
          utils.toast.error(t('global.form.errors.generic'));
          break;
        }
      }
    },
    onSettled: () => {
      formik.setSubmitting(false);
    },
  });

  const paymentMethods = useMemo(() => [
    {
      id: 'credit_transfer',
      label: t('cashout.details.retryPayment.form.paymentMethod.credit_transfer'),
      value: 'credit_transfer',
    },
    {
      id: 'direct_debit',
      label: t('cashout.details.retryPayment.form.paymentMethod.direct_debit'),
      value: 'direct_debit',
    },
  ], [t]);

  const handleClose = useCallback(() => {
    onClose();
    setTimeout(() => formik.resetForm(), 500);
  }, [onClose]);

  return (
    <Modal
      className={styles.modal}
      id={modalId}
      title={t('cashout.details.retryPayment.modalTitle')}
      size="large"
      onClose={handleClose}
    >
      <form onSubmit={formik.handleSubmit}>
        <RadioGroup
          name="paymentMethod"
          options={paymentMethods}
          value={formik.values.paymentMethod}
          onChange={(value) => formik.setFieldValue('paymentMethod', value)}
          className="m-b-25"
        />
        <DateInput
          id="date"
          name="date"
          className="m-b-25"
          label={t('cashout.details.retryPayment.form.date')}
          value={formik.values.date}
          onChange={(date) => formik.setFieldValue('date', date)}
          onBlur={formik.handleBlur}
          error={displayError(t, formik, 'date')}
          valid={isFieldValid(formik, 'date', null, initialValues?.date)}
          full
          minDate={minDate}
          portalId={styles.datepickerMenu}
        />
        <TextInput
          type="text"
          id="label"
          name="label"
          className={styles.fullInput}
          label={`${t('cashout.details.retryPayment.form.label')} ${t('global.form.optional')}`}
          error={displayError(t, formik, 'label')}
          valid={isFieldValid(formik, 'label', null, initialValues?.label)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.label}
        />
        <div className={utils.cn([styles.modalButtonContainer, 'm-t-30'])}>
          <Button
            type="submit"
            loading={formik.isSubmitting}
            label={t('global.validate')}
          />
          <p
            tabIndex={0}
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
            role="button"
            onKeyDown={handleClose}
            className={styles.cancel}
            onClick={handleClose}
          >
            {t('global.cancel')}
          </p>
        </div>
      </form>
    </Modal>
  );
}

RetryPaymentModal.propTypes = {
  modalId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  paymentUID: PropTypes.string.isRequired,
  setPaymentRetries: PropTypes.func.isRequired,
};

RetryPaymentModal.defaultProps = {
  onClose: () => {},
};

export default RetryPaymentModal;
