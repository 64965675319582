/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';

// Utils
import cn from '../../../utils/cn';

// Components
import Picto from '../Picto/Picto';

// Styles
import styles from './SelectInput.module.scss';

const getOptionBackground = (isFocused, isSelected, base) => {
  if (isSelected) return 'var(--color-secondary)';
  if (isFocused) return 'var(--color-primary-100)';
  return base?.background;
};

const stylesSelect = {
  indicatorSeparator: () => {},
  valueContainer: (provided) => ({
    ...provided,
    overflow: 'visible',
  }),
  placeholder: (provided) => ({
    ...provided,
    opacity: 0,
  }),
  menu: (base) => ({
    ...base,
    width: 'max-content',
    minWidth: '100%',
    position: 'absolute',
    right: 0,
    maxWidth: '100%',
    borderRadius: 10,
    boxShadow: 'none',
    border: '2px solid var(--color-primary-200)',
  }),
  control: (base) => ({
    ...base,
    label: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '100%',
    },
  }),
  option: (base, { data: { parent }, isFocused, isSelected }) => ({
    ...base,
    paddingLeft: parent ? '2rem' : base.paddingLeft,
    borderRadius: 0,
    transition: 'all 0.1s',
    fontFamily: 'var(--font-primary)',
    fontStyle: 'normal',
    fontWeight: isSelected ? 700 : 400,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    background: getOptionBackground(isFocused, isSelected, base),
    color: isSelected ? 'var(--color-white)' : base.color,
    cursor: 'pointer',
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
    borderRadius: 10,
  }),
};

function ValueContainer({ children, ...props }) {
  const {
    hasValue,
    selectProps: {
      name, isSearchable, inputValue, label,
    },
  } = props;
  return (
    <components.ValueContainer {...props}>
      <label
        htmlFor={name}
        className={cn([
          styles.label,
          hasValue || inputValue ? styles.isFocused : '',
          isSearchable ? styles.isSearchable : '',
        ])}
      >
        {label}
      </label>
      {children}
    </components.ValueContainer>
  );
}

function DropdownIndicator(props) {
  return (
    <components.DropdownIndicator {...props}>
      <Picto color="var(--color-secondary)" icon="arrow-down" width={24} />
    </components.DropdownIndicator>
  );
}
function ClearIndicator(props) {
  return (
    <components.ClearIndicator {...props}>
      <Picto
        icon="close"
        style={{
          height: '14px',
          marginRight: '5px',
        }}
      />
    </components.ClearIndicator>
  );
}

const SelectInput = forwardRef((props, ref) => {
  const {
    disabled,
    id,
    error,
    valid,
    info,
    options,
    noFiltering,
  } = props;

  const filterOption = useCallback((candidate, rawInput) => {
    if (!rawInput || noFiltering) return true;
    const input = rawInput.toLowerCase();
    const { label, value, data } = candidate;
    const labelToCompare = label?.outerHTML || label;
    const parentToCompare = data?.parent?.label?.outerHTML || data?.parent?.label;
    return (
      labelToCompare?.toLowerCase().includes(input)
      || parentToCompare?.toLowerCase()?.includes(input)
      || options.some((option) => {
        const optionLabelToCompare = option.label?.outerHTML || option.label;
        return (
          option.parent?.value === value
          && optionLabelToCompare?.toLowerCase()?.includes(input)
        );
      })
    );
  }, []);

  return (
    <div className={cn([
      styles.container,
      disabled ? styles.disabled : null,
      error ? styles.error : null,
      valid ? styles.valid : null,
    ])}
    >
      <Select
        instanceId={id}
        id={id}
        aria-invalid={error != null}
        inputId={id}
        classNamePrefix={`react-select-${id} react-select`}
        styles={stylesSelect}
        components={{
          DropdownIndicator,
          ClearIndicator,
          ValueContainer,
        }}
        noOptionsMessage={() => "Pas d'options"}
        filterOption={filterOption}
        ref={ref}
        {...props}
      />
      {error && typeof error === 'string' ? (
        <div className={cn([styles.context, styles.fieldError])}>
          {error}
        </div>
      ) : info && (
      <div className={cn([styles.context, styles.fieldInfo])}>
        {info}
      </div>
      )}
    </div>
  );
});

SelectInput.displayName = 'SelectInput';

SelectInput.propTypes = {
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  valid: PropTypes.bool,
  className: PropTypes.string,
  noFiltering: PropTypes.bool,
};

SelectInput.defaultProps = {
  disabled: false,
  error: null,
  valid: false,
  className: '',
  noFiltering: false,
};

export default SelectInput;
