/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useMatch, Link } from 'react-router-dom';
import {
  Table,
  Picto,
  Tag,
  utils,
} from 'ui-library-unlocker';

import { useAppContext } from '../../../store/context';

// Services
import { getTenantLeases, getLeaseDocuments } from '../../../services/lease';
import { getProperty } from '../../../services/property';

// Utils
import { handleDocumentDownload } from '../../../utils/documents';

import styles from './TenantSignedDocuments.module.scss';
import { getTenantFromAdmin } from '../../../services/admin';
import { getTenant } from '../../../services/tenant';
import useRoles from '../../../hooks/useRoles';

function TenantSignedDocuments() {
  const { t } = useTranslation();
  const { context: { user, uiBuilders } } = useAppContext();
  const { isUserAdmin } = useRoles();

  const match = useMatch('/tenant/:id');

  const tenantQuery = useQuery({
    queryKey: ['tenant-id'],
    queryFn: () => {
      if (isUserAdmin) return getTenantFromAdmin(match?.params?.id);
      return getTenant(match?.params?.id);
    },
    cacheTime: 0,
  });

  const {
    data: tenantLeasesData,
    isFetching: tenantLeasesFetching,
  } = useQuery({
    queryKey: ['tenant-leases', user],
    queryFn: async () => {
      try {
        // No pagination for now
        const tenantLeases = await getTenantLeases(match?.params?.id, {
          page: 1,
          itemsPerPage: 100,
        });
        const tenantLeasesFull = await Promise.all(tenantLeases.data.collection.map(async (lease) => {
          const leaseDocuments = await getLeaseDocuments(lease.uid, {
            page: 1,
            itemsPerPage: 100,
          });
          // TODO: back end to populate property field ?
          const property = await getProperty(lease.propertyUid);
          return {
            ...lease,
            property: property.data,
            documents: leaseDocuments.data.collection,
          };
        }));
        return tenantLeasesFull;
      } catch (error) {
        utils.toast.error(t('global.form.errors.global'));
        return null;
      }
    },
    enabled: true,
    keepPreviousData: true,
  });

  const fileList = useMemo(() => {
    if (uiBuilders) {
      return uiBuilders['/person/ui']?.documentTypePPH;
    }
    return [];
  }, [uiBuilders]);

  const propertyFileTypes = useMemo(() => {
    if (uiBuilders) {
      const { documentTypes } = uiBuilders['/property/ui'];
      return documentTypes;
    }
    return [];
  }, [uiBuilders]);

  const tableData = useMemo(() => {
    const data = [];
    if (tenantLeasesData) {
      tenantLeasesData.forEach((lease) => {
        data.push({
          dataType: 'leaseHeader',
          lease,
        });
        lease.documents.forEach((document) => {
          data.push({
            dataType: 'leaseDocument',
            lease,
            document,
          });
        });
      });
    }
    return data;
  }, [tenantLeasesData]);

  const meta = useMemo(() => ({
    rowStyles: tableData.map((row) => {
      if (row.dataType === 'leaseHeader') {
        return styles.leaseHeaderRow;
      }
      return styles.leaseDocumentRow;
    }),
  }), [tableData]);

  const columns = useMemo(() => ([
    {
      header: t('tenant.tabs.propertySignedDocuments.table.property'),
      accessorKey: 'type',
      size: 175,
      enableSorting: false,
      cell: ({ row: { original } }) => {
        if (original.dataType === 'leaseHeader') {
          const { uid, name, address } = original?.lease?.property || {};
          const {
            street, streetNumber, city, zipCode,
          } = address || {};

          return (
            <div className={styles.propertyCell}>
              <Link to={`/property/${uid}`} target="_blank" className={styles.name}>
                {name}
                <Picto icon="export-link" width={9} color="var(--color-secondary)" className={styles.externalLink} />
              </Link>
              <p className={utils.cn(['p-2-500 m-t-5', styles.propertyAddress])}>
                {streetNumber}
                {' '}
                {street}
                <br />
                {zipCode}
                {' '}
                {city}
              </p>
            </div>
          );
        }
        // TODO: dynamic document type
        return (
          <div className={utils.cn(['p-2-500', styles.documentTypeHeader])}>
            {propertyFileTypes?.[original?.document?.type] || t('global.documents.unkwownType')}
          </div>
        );
      },
    },
    {
      header: null,
      accessorKey: 'file',
      size: 400,
      enableSorting: false,
      cell: ({ row: { original } }) => {
        const { name } = original?.document || {};
        if (original.dataType === 'leaseDocument') {
          return (
            <div>
              <p
                tabIndex={0}
                role="button"
                onKeyDown={null}
                onClick={() => handleDocumentDownload(original?.document, t)}
                className={styles.documentNameValue}
              >
                {name}
              </p>
            </div>
          );
        }
        return null;
      },
    },
    {
      header: null,
      accessorKey: 'status',
      enableSorting: false,
      cell: ({ row: { original } }) => {
        if (original.dataType === 'leaseDocument') {
          const isSigned = original?.document?.signerUids?.length > 0;
          return (
            <Tag
              label={isSigned
                ? t('tenant.tabs.propertySignedDocuments.signed')
                : t('tenant.tabs.propertySignedDocuments.pending')}
              icon={isSigned ? 'tick-circle' : 'timer'}
              variant={isSigned ? 'primary' : 'secondary'}
            />
          );
        }
        return null;
      },
    },
  ]), [t, uiBuilders, fileList, propertyFileTypes]);

  return (
    <div className="m-t-50">
      <h2>{t('tenant.tabs.propertySignedDocuments.title')}</h2>
      <div className="m-t-30 m-b-30">
        <Table
          fullWidth
          columns={columns}
          data={tableData}
          meta={meta}
          isLoading={tenantLeasesFetching && tenantQuery?.isLoading}
        />
      </div>
    </div>
  );
}

TenantSignedDocuments.propTypes = {
};

export default TenantSignedDocuments;
