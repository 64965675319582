/* eslint-disable no-useless-escape */
const getNestedValue = (obj, path) => path.split(/[\.\[\]\'\"]/).filter(Boolean).reduce((o, k) => (o || {})[k], obj);

// eslint-disable-next-line no-unused-vars
export const displayError = (t, { errors, touched }, field, inputType = 'text', options = {}) => {
  if (errors == null || touched == null) return null;

  const error = options?.nestedValue ? getNestedValue(errors, field) : errors[field];
  const isTouched = options?.nestedValue ? getNestedValue(touched, field) : touched[field];
  // console.log(errors, field, error, touched);

  if (options?.errorIfUntouched && error) {
    if (typeof error === 'object' && Object.keys(error).length > 0) {
      return t(error[Object.keys(error)[0]]);
    }
    return t(error, { ...(options?.errorConcatValues || {}) });
  }
  if (isTouched && error) return t(error, { ...(options?.errorConcatValues || {}) });
  return null;
};

export const isFieldValid = ({ errors, touched, values }, field, options, initialValue = null) => {
  if (options?.inputType === 'select') {
    return values[field] && !errors[field];
  }
  if (options?.inputType === 'boolean') {
    if (touched[field] && (values[field] === true || values[field] === false)) return true;
    return false;
  }
  if (initialValue && values[field] === initialValue) return false;
  if (!values[field]) return false;
  if (touched[field] && !errors[field]) {
    return true;
  }
  return false;
};

export const errorFocusSubmit = (callback) => (e) => {
  callback(e);
  setTimeout(() => {
    document.querySelector('input[aria-invalid=true]')?.scrollIntoView({ behavior: 'smooth' });
  }, 50);
};

// Fonction personnalisée pour vérifier les décimales
export const twoDecimalPlaces = (value) => /^\d+(\.\d{1,2})?$/.test(value);
