import { useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { useNavigate, Navigate, useParams } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { useQuery } from '@tanstack/react-query';

// Components
import {
  WithHeader,
  Button,
  utils,
} from 'ui-library-unlocker';
import SearchView from '../Search';
import Footer from '../../../components/templates/Footer/Footer';

// Services
import { getPropertyUI } from '../../../services/uiBuilder';

// Hooks
import { useAppContext } from '../../../store/context';

import styles from './SearchPublicView.module.scss';

function SearchPublicView() {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { dispatch, context: { uiBuilders } } = useAppContext();

  useQuery({
    queryKey: ['property-builders'],
    queryFn: getPropertyUI,
    onSuccess: ({ data }) => {
      dispatch({
        type: 'SET_UI_BUILDERS',
        payload: {
          '/property/ui': data,
        },
      });
    },
    enabled: !uiBuilders,
  });

  const renderNav = useCallback(() => (
    <nav className={styles.nav}>
      <ul className={styles.list}>
        <li className={styles.item}>
          <Button
            onClick={() => navigate('/post-ad')}
            label={t('postAd.headerBtn')}
            size={isMobile ? 'small' : 'medium'}
            variant="default-outline"
          />
        </li>
        <li className={styles.item}>
          <Button
            onClick={() => {
              TagManager.dataLayer({
                dataLayer: {
                  event: 'login_from_map',
                  action: 'click',
                },
              });
              navigate('/login', { state: { from: window.location.pathname } });
            }}
            label={t('login.title')}
            size={isMobile ? 'small' : 'medium'}
          />
        </li>
      </ul>
    </nav>
  ), [isMobile, navigate, t]);

  if (localStorage.getItem('accessToken')) return <Navigate to={`/rechercher/${id || ''}`} />;

  return (
    <WithHeader
      logoLink="/register"
      navContent={renderNav()}
      isContained={false}
      className={utils.cn([
        styles.searchPublicView,
        'p-l-20 p-r-20',
      ])}
      displayBurger={false}
    >
      <SearchView />
      <Footer />
    </WithHeader>
  );
}

SearchPublicView.propTypes = {
};

SearchPublicView.defaultProps = {};

export default SearchPublicView;
