import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';

import { utils, Picto } from 'ui-library-unlocker';
import { showModal, hideModal } from '../../../utils/modal';

import useRoles from '../../../hooks/useRoles';
import { useAppContext } from '../../../store/context';

import { becomeLessor, refreshUserToken } from '../../../services/identity';
import { getUserFromToken } from '../../../utils/user';

import ConfirmationModal from '../../organisms/ConfirmationModal/ConfirmationModal';
import Tooltip from '../Tooltip/Tooltip';

import styles from './SwitchLessorTenantEnv.module.scss';

const CONFIRM_MODAL_ID = 'become-lessor-confirm';

function SwitchLessorTenantEnv() {
  const { t } = useTranslation();
  const { isUserTenant, userOriginallyLessor } = useRoles();
  const { dispatch, context: { user } } = useAppContext();
  const navigate = useNavigate();
  const wrapperRef = useRef(null);

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleLessor = useCallback(() => {
    navigate('/');
    localStorage.setItem('roleSpace', 'lessor');
    dispatch({
      type: 'SET_ROLE_SPACE',
      payload: 'lessor',
    });
    return setTooltipOpen(false);
  }, [navigate, dispatch]);

  const lessorMutation = useMutation({
    mutationFn: () => becomeLessor(),
    onSuccess: () => {
      hideModal(CONFIRM_MODAL_ID);
      refreshUserToken({
        refreshToken: localStorage.getItem('refreshToken'),
        username: user?.username,
      })
        .then(({ data: { accessToken, refreshToken } }) => {
          dispatch({
            type: 'SET_AUTH',
            payload: {
              accessToken: accessToken?.token,
              refreshToken,
              user: getUserFromToken(accessToken?.token),
            },
          });
          return handleLessor();
        });
    },
    onError: () => {
      utils.toast.error(t('global.form.errors.global'));
      hideModal(CONFIRM_MODAL_ID);
    },
  });

  const switchToLessor = useCallback(() => {
    if (!userOriginallyLessor) {
      return showModal(CONFIRM_MODAL_ID);
    }
    return handleLessor();
  }, [userOriginallyLessor, handleLessor]);

  const switchToTenant = useCallback(() => {
    navigate('/');
    localStorage.setItem('roleSpace', 'tenant');
    dispatch({
      type: 'SET_ROLE_SPACE',
      payload: 'tenant',
    });
    return setTooltipOpen(false);
  }, [navigate, dispatch]);

  const cancelBecome = useCallback(() => hideModal(CONFIRM_MODAL_ID), []);

  const confirmBecome = useCallback(() => lessorMutation.mutate(), [lessorMutation]);

  const variant = useMemo(() => {
    if (isUserTenant) return 'tenant';
    return 'lessor';
  }, [isUserTenant]);

  const handleMenu = useCallback(() => {
    setTooltipOpen((prev) => !prev);
  }, [tooltipOpen]);

  const options = useMemo(() => ([
    {
      id: 'lessor',
      label: t(userOriginallyLessor ? 'global.roleSpaces.lessor' : 'global.roleSpaces.becomeLessor'),
      onClick: switchToLessor,
      icon: 'trace-3902',
      iconColor: 'var(--color-secondary)',
    },
    {
      id: 'tenant',
      label: t('global.roleSpaces.tenant'),
      onClick: switchToTenant,
      icon: 'point-moyen-900',
      iconColor: 'var(--color-tertiary)',
    },
  ]), [t, switchToLessor, switchToTenant, userOriginallyLessor]);

  useEffect(() => {
    function handleScroll() {
      if (tooltipOpen) {
        setTooltipOpen(false);
      }
    }
    // Bind the event listener
    document.getElementById('menu')?.addEventListener('scroll', handleScroll);
    return () => {
      // Unbind the event listener on clean up
      document.getElementById('menu')?.removeEventListener('scroll', handleScroll);
    };
  }, [tooltipOpen]);

  const onClickOutsideTooltip = useCallback((e) => {
    if (!wrapperRef.current?.contains(e.target)) return setTooltipOpen(false);
    return null;
  }, [wrapperRef]);

  return (
    <>
      <div className={styles.switchLessorTenantEnv}>
        <div
          id="roleSpace"
          className={styles.switchLessorTenantEnv__current}
          onClick={handleMenu}
          role="button"
          onKeyDown={handleMenu}
          ref={wrapperRef}
          tabIndex={0}
        >
          <span>
            {options.find((option) => option.id === variant)?.label}
          </span>
          <Picto
            width={20}
            icon="arrow-down"
            color="var(--color-white)"
          />
        </div>
        <Tooltip
          className={styles.tooltip}
          isOpen={tooltipOpen}
          anchorId="roleSpace"
          place="bottom"
          effect="solid"
          onClickOutside={onClickOutsideTooltip}
        >
          <ul>
            {options
              .map((option) => (
                <li key={option.id}>
                  <div
                    className={styles.tooltip__option}
                    onClick={option.onClick}
                    role="button"
                    onKeyDown={option.onClick}
                    tabIndex={0}
                  >
                    <div className={styles.tooltip__option__content}>
                      <Picto
                        width={15}
                        icon={option.icon}
                        color={option.iconColor}
                      />
                      {option.label}
                    </div>
                    {option.id === variant && (
                      <Picto
                        width={13}
                        icon="tick"
                        color="var(--color-primary-400)"
                      />
                    )}
                  </div>
                </li>
              ))}
          </ul>
        </Tooltip>
      </div>
      <ConfirmationModal
        id={CONFIRM_MODAL_ID}
        onSubmit={confirmBecome}
        onCancel={cancelBecome}
        title={t('global.roleSpaces.confirmBecome')}
        size="small"
        loading={lessorMutation.isLoading}
      />
    </>
  );
}

export default SwitchLessorTenantEnv;
