import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Components
import { Picto, utils } from 'ui-library-unlocker';

// Styles
import styles from './FormInfoRequired.module.scss';

function FormInfoRequired({ className, content }) {
  const { t } = useTranslation();

  return (
    <div className={utils.cn([className, styles.help])}>
      <Picto color="var(--color-tertiary)" icon="trace-3902" width={15} />
      <div className={styles.text}>
        {content || t('global.form.info.allRequired')}
      </div>
    </div>
  );
}

FormInfoRequired.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  content: PropTypes.string,
};

FormInfoRequired.defaultProps = {
  className: '',
  content: '',
};

export default FormInfoRequired;
