/* eslint-disable no-lonely-if */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Button from '../../atoms/Button/Button';

import cn from '../../../utils/cn';

import styles from './ToggleButton.module.scss';

const ToggleButton = ({
  options,
  className,
  value,
  disabled,
  clearLabel,
  onChange,
  multiple,
}) => {
  const handleOnChange = useCallback((option) => {
    if (!multiple) {
      if (value === option.value) {
        onChange(null);
      } else {
        onChange(option.value);
      }
    } else {
      if (!value?.includes(option.value)) {
        onChange([...value, option.value]);
      } else {
        onChange(value.filter((v) => v !== option.value));
      }
    }
  }, [value, onChange, multiple]);

  const handleClear = useCallback(() => {
    onChange(multiple ? [] : null);
  }, [onChange, multiple]);

  const getVariant = useCallback((option) => {
    if (!multiple) {
      return value === option.value ? 'primary' : 'plain';
    }
    return value?.includes(option.value) ? 'primary' : 'plain';
  }, [value, multiple]);

  const getClearVariant = useCallback(() => {
    if (!multiple) {
      return value ? 'plain' : 'primary';
    }
    return value?.length ? 'plain' : 'primary';
  }, [value, multiple]);

  return (
    <div
      className={cn([
        styles.toggleButton,
        className,
      ])}
    >
      <Button
        label={clearLabel}
        onClick={handleClear}
        disabled={disabled}
        className={styles.btn}
        variant={getClearVariant()}
      />
      {options.map((option) => (
        <Button
          key={option.value}
          label={option.label}
          onClick={() => handleOnChange(option)}
          disabled={disabled}
          className={styles.btn}
          variant={getVariant(option)}
        />
      ))}
    </div>
  );
};

ToggleButton.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  })).isRequired,
  className: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  ]),
  disabled: PropTypes.bool,
  clearLabel: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
};

ToggleButton.defaultProps = {
  className: '',
  value: null,
  disabled: false,
  clearLabel: 'Clear',
  multiple: false,
};

export default ToggleButton;
