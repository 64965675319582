import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Picto, utils, WithHeader } from 'ui-library-unlocker';
import Footer from '../../components/templates/Footer/Footer';

import styles from './Maintenance.module.scss';

function Maintenance() {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('maintenance.browserTitle');
  }, [t]);

  return (
    <WithHeader
      logoLink="/register"
      isContained={false}
      className={utils.cn([
        'p-l-20 p-r-20',
      ])}
      displayBurger={false}
    >
      <div className={styles.maintenance}>
        <div
          className={styles.cover}
        >
          <Picto
            className={utils.cn([styles.decoration, styles.topDeco])}
            icon="point-moyen-900"
          />
          <Picto
            className={utils.cn([styles.decoration, styles.bottomDeco])}
            icon="gribouillon-6"
          />
          <img
            className={styles.img}
            src="/images/500.png"
            alt="Personne assise dans un salon avec un ordinateur sur les genoux"
          />
        </div>
        <div className={styles.content}>
          <div className={styles.title}>
            <h1>{t('maintenance.title')}</h1>
            <Picto
              className={styles.underline}
              icon="trace-3511"
              color="var(--color-tertiary)"
              width={380}
            />
          </div>
          <p>{t('maintenance.explanation')}</p>
        </div>
      </div>
      <Footer />
    </WithHeader>
  );
}

export default Maintenance;
