import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

// Components
import {
  utils,
  TextInput,
  Button,
  Picto,
  Message,
} from 'ui-library-unlocker';
import SearchInput from '../../../molecules/SearchInput/SearchInput';

// Services
import { postInternalTransfer, getBankAccountBalance } from '../../../../services/admin';

// Utils
import { internalTransferSchema, internalTransferInitialValues } from '../../../../utils/forms/internalTransferSchema';
import { displayError, errorFocusSubmit } from '../../../../utils/forms/form';
import { formatMoney } from '../../../../utils/html';

// Styles
import styles from './InternalTransferForm.module.scss';

// Constants
import { SEARCH_SCOPE_LIST } from '../../../../utils/constants';

const SEARCH_SCOPE = [
  SEARCH_SCOPE_LIST.ACCOUNTS,
];

function RecapItem({
  accountLabel,
  balance = 0,
  transferAmount = 0,
  loading = true,
  totalNegative = false,
  error = false,
}) {
  const { t } = useTranslation();

  const formattedTransferAmount = useMemo(() => {
    const operator = transferAmount > 0 ? '+' : '';
    return operator + formatMoney(utils.centsToEuro(transferAmount));
  }, [transferAmount]);

  const formattedBalance = useMemo(() => formatMoney(utils.centsToEuro(balance)), [balance]);

  const formattedTotal = useMemo(() => (
    formatMoney(utils.centsToEuro(balance + transferAmount))
  ), [balance, transferAmount]);

  const content = useMemo(() => {
    if (loading) {
      return <Picto width={20} icon="loading" color="var(--color-primary-500)" />;
    }
    if (error) {
      return <span className={styles.recapError}>{t('global.form.errors.global')}</span>;
    }
    return (
      <>
        <span className={styles.recapBalance}>{formattedBalance}</span>
        <span className={styles.recapTransferAmount}>{formattedTransferAmount}</span>
        <span
          className={utils.cn([
            styles.recapTotalAmount,
            totalNegative && styles.recapTotalAmountNegative,
          ])}
        >
          {formattedTotal}
        </span>
      </>
    );
  }, [loading, error, formattedBalance, formattedTransferAmount, formattedTotal, totalNegative, t]);

  return (
    <div className={styles.recapItem}>
      {accountLabel ? (
        <>
          <span className={styles.accountLabel}>{accountLabel}</span>
          {content}
        </>
      ) : (
        <span>{t('internalTransfer.crud.noSourceAccountSelected')}</span>
      )}
    </div>
  );
}

RecapItem.propTypes = {
  accountLabel: PropTypes.string,
  balance: PropTypes.number,
  transferAmount: PropTypes.number,
  loading: PropTypes.bool,
  totalNegative: PropTypes.bool,
  error: PropTypes.bool,
};

RecapItem.defaultProps = {
  accountLabel: '',
  balance: 0,
  transferAmount: 0,
  loading: true,
  totalNegative: false,
  error: false,
};

function InternalTransferForm() {
  const { t } = useTranslation();

  const internalTransferMutation = useMutation({
    mutationFn: postInternalTransfer,
    onSuccess: ({ response, status }) => {
      const s = status || response?.status;
      switch (s) {
        case 204:
          utils.toast.success(t('internalTransfer.crud.success'));
          formik.resetForm();
          break;
        default:
          break;
      }
    },
    onError: () => {
      utils.toast.error(t('global.form.errors.generic'));
    },
    onSettled: () => {
      formik.setSubmitting(false);
    },
  });

  const formik = useFormik({
    initialValues: internalTransferInitialValues,
    validationSchema: internalTransferSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      internalTransferMutation.mutate({
        sourceAccount: values.sourceAccount.value,
        destinationAccount: values.destinationAccount.value,
        amount: utils.euroToCents(values.amount),
      });
    },
  });

  const {
    data: sourceBankAccountData,
    isFetching: sourceBankAccountIsFetching,
    isError: sourceBankAccountIsError,
  } = useQuery({
    queryKey: ['sourceBankAccount', formik.values.sourceAccount?.value],
    queryFn: () => getBankAccountBalance(formik.values.sourceAccount?.value),
    enabled: !!formik.values.sourceAccount?.value,
  });

  const {
    data: destinationBankAccountData,
    isFetching: destinationBankAccountIsFetching,
    isError: destinationBankAccountIsError,
  } = useQuery({
    queryKey: ['destinationBankAccount', formik.values.destinationAccount?.value],
    queryFn: () => getBankAccountBalance(formik.values.destinationAccount?.value),
    enabled: !!formik.values.destinationAccount?.value,
  });

  const amountInCents = useMemo(() => utils.euroToCents(formik.values.amount), [formik.values.amount]);

  const sourceBalance = useMemo(() => sourceBankAccountData?.data?.balance, [sourceBankAccountData]);

  const sourceBalanceWillBeNegative = useMemo(() => {
    const total = sourceBalance - amountInCents;
    return total < 0;
  }, [sourceBalance, amountInCents]);

  const handleCancel = useCallback(() => {
    formik.resetForm();
  }, [formik]);

  const error = useMemo(() => {
    if (sourceBankAccountIsFetching || destinationBankAccountIsFetching) {
      return null;
    }
    const sourceUid = formik.values.sourceAccount?.value;
    const destinationUid = formik.values.destinationAccount?.value;

    if (sourceUid && destinationUid && sourceUid === destinationUid) {
      return t('internalTransfer.crud.errors.sameAccount');
    }
    if (sourceBankAccountIsError) {
      return t('internalTransfer.crud.errors.sourceAccount');
    }
    if (destinationBankAccountIsError) {
      return t('internalTransfer.crud.errors.destinationAccount');
    }
    if (sourceBalanceWillBeNegative) {
      return t('internalTransfer.crud.errors.sourceBalanceNegative');
    }
    return null;
  }, [
    sourceBankAccountIsError,
    sourceBankAccountIsFetching,
    destinationBankAccountIsError,
    destinationBankAccountIsFetching,
    sourceBalanceWillBeNegative,
    formik,
  ]);

  return (
    <>
      <div className={styles.container}>
        <form className={styles.form} onSubmit={errorFocusSubmit(formik.handleSubmit)}>
          <SearchInput
            value={formik.values.sourceAccount}
            id="sourceAccount"
            name="sourceAccount"
            className="m-b-25"
            label={t('internalTransfer.crud.form.sourceAccount')}
            scope={SEARCH_SCOPE}
            error={displayError(t, formik, 'sourceAccount')}
            onSelect={(value) => formik.setFieldValue('sourceAccount', value)}
            onBlur={formik.handleBlur}
          />
          <SearchInput
            value={formik.values.destinationAccount}
            id="destinationAccount"
            name="destinationAccount"
            className="m-b-25"
            label={t('internalTransfer.crud.form.destinationAccount')}
            scope={SEARCH_SCOPE}
            error={displayError(t, formik, 'destinationAccount')}
            onSelect={(value) => formik.setFieldValue('destinationAccount', value)}
            onBlur={formik.handleBlur}
          />
          <TextInput
            min="0"
            type="number"
            id="amount"
            name="amount"
            className={utils.cn(['m-b-25', styles.mediumInput])}
            label={t('internalTransfer.crud.form.amount')}
            value={formik.values.amount}
            icon="euro"
            iconSize={20}
            iconColor="var(--color-primary-300)"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={displayError(t, formik, 'amount')}
          />
        </form>
        <div className={styles.recap}>
          <RecapItem
            accountLabel={formik.values.sourceAccount?.label}
            balance={sourceBalance}
            transferAmount={-amountInCents}
            loading={sourceBankAccountIsFetching}
            totalNegative={sourceBalanceWillBeNegative}
            error={sourceBankAccountIsError}
          />
          <Picto
            icon="arrow-right-bulk"
            width={50}
            color="var(--color-primary-400)"
            className={styles.arrowIcon}
          />
          <RecapItem
            accountLabel={formik.values.destinationAccount?.label}
            balance={destinationBankAccountData?.data?.balance}
            transferAmount={amountInCents}
            loading={destinationBankAccountIsFetching}
            error={destinationBankAccountIsError}
          />
        </div>
      </div>
      {error && (
        <Message variant="error" className="m-t-30" content={error} />
      )}
      <div className={styles.submit}>
        <Button
          onClick={formik.handleSubmit}
          size="large"
          loading={formik.isSubmitting}
          label={t('internalTransfer.crud.submit')}
          disabled={!!error}
        />
        <p
          tabIndex={0}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
          role="button"
          onKeyDown={null}
          className={styles.cancel}
          onClick={handleCancel}
        >
          {t('global.cancel')}
        </p>
      </div>
    </>
  );
}

InternalTransferForm.propTypes = {};

export default InternalTransferForm;
