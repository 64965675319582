/* eslint-disable max-len */
import { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

// Components
import {
  WysiwygInput,
  utils,
  Button,
} from 'ui-library-unlocker';
import ConfirmationModal from '../../ConfirmationModal/ConfirmationModal';

// Hooks
import useRoles from '../../../../hooks/useRoles';
import useScroll from '../../../../hooks/useScroll';

// Utils
import { displayError, isFieldValid } from '../../../../utils/forms/form';
import { showModal, hideModal } from '../../../../utils/modal';

// Styles
import styles from './LeaseForm.module.scss';

const CONFIRM_EDIT_MODAL_ID = 'confirm-edit-modal-id';

function LeasePreview({
  formik,
  leaseMutation,
}) {
  const { t } = useTranslation();
  const { isUserLessor } = useRoles();
  const { scrollContentToTop } = useScroll();

  const [canEditPreview, setCanEditPreview] = useState(false);

  if (!isUserLessor) return <Navigate to="/" />;

  useEffect(() => {
    scrollContentToTop();
  }, []);

  const handleSubmit = useCallback(() => {
    leaseMutation.mutate({
      ...formik.values,
      rent: utils.euroToCents(formik.values.rent),
      charges: utils.euroToCents(formik.values.charges),
      depositAmount: utils.euroToCents(formik.values.depositAmount),
      lastRentAmount: formik.values.lastRentAmount ? utils.euroToCents(formik.values.lastRentAmount) : null,
      new: true,
      isHtmlModified: true,
    });
  }, [formik.values, leaseMutation]);

  const handleEditClick = useCallback(() => showModal(CONFIRM_EDIT_MODAL_ID), []);

  const cancelModal = useCallback(() => hideModal(CONFIRM_EDIT_MODAL_ID), []);

  const handleConfirm = useCallback(() => {
    hideModal(CONFIRM_EDIT_MODAL_ID);
    setCanEditPreview(true);
  }, [setCanEditPreview]);

  return (
    <div className={styles.previewSection}>
      <h2 className="m-b-25">{t('lease.add.preview.title')}</h2>
      {!canEditPreview && (
        <Button
          type="button"
          size="medium"
          variant="grey"
          label={t('global.edit')}
          onClick={handleEditClick}
          className="m-b-10"
        />
      )}
      <WysiwygInput
        className={styles.wysiwygPreview}
        label={t('lease.add.preview.inputLabel')}
        onChange={(value) => formik.setFieldValue('html', value)}
        error={displayError(t, formik, 'html', 'wysiwyg')}
        valid={isFieldValid(formik, 'html')}
        defaultValue={formik.values.html}
        disabled={!canEditPreview}
      />
      <div className={styles.submit}>
        <Button
          type="button"
          size="large"
          loading={leaseMutation.isLoading}
          label={t('lease.add.form.submit')}
          onClick={handleSubmit}
        />
      </div>
      <ConfirmationModal
        id={CONFIRM_EDIT_MODAL_ID}
        onSubmit={handleConfirm}
        onCancel={cancelModal}
        title={t('lease.add.preview.warning')}
        size="small"
      />
    </div>
  );
}

LeasePreview.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({
      rent: PropTypes.number,
      charges: PropTypes.number,
      depositAmount: PropTypes.number,
      lastRentAmount: PropTypes.number,
      html: PropTypes.string,
    }).isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  leaseMutation: PropTypes.shape({
    mutate: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
};

export default LeasePreview;
