import { useState } from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function Image({
  className,
  src,
  alt,
  width,
  height,
}) {
  const [loaded, setLoaded] = useState(false);

  return (
    <>
      {!loaded && (
        <Skeleton className={className} />
      )}
      <img
        className={className}
        src={src ?? '/images/imgNotFound_w250.png'}
        alt={alt ?? ''}
        width={width}
        height={height}
        onLoad={() => setLoaded(true)}
        style={{ display: loaded ? 'block' : 'none' }}
      />
    </>
  );
}

Image.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  src: PropTypes.string,
  alt: PropTypes.string,
};

Image.defaultProps = {
  className: '',
  width: 0,
  height: 0,
  src: '/images/imgNotFound_w250.png',
  alt: '',
};

export default Image;
