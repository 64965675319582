/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  useState, useMemo, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';

// Components
import {
  Picto,
  Tag,
  Button,
  utils,
} from 'ui-library-unlocker';
import Tooltip from '../../components/atoms/Tooltip/Tooltip';
import TableOptions from '../../components/atoms/TableOptions/TableOptions';
import DataTable from '../../components/organisms/DataTable/DataTable';
import ConfirmationModal from '../../components/organisms/ConfirmationModal/ConfirmationModal';

// Hooks
import useFilters from '../../hooks/useFilters';
import useRoles from '../../hooks/useRoles';
import { useAppContext } from '../../store/context';
import useDocumentTitle from '../../hooks/useDocumentTitle';

// Services
import { getProperties, deleteProperty } from '../../services/property';
import { getPropertiesFromAdmin } from '../../services/admin';

// Utils
import { getStatusStyles } from '../../utils/properties';
import { showModal, hideModal } from '../../utils/modal';

// Styles
import styles from './PropertyList.module.scss';

// Constants
import { SEARCH_SCOPE_LIST, PROPERTY_STATUS, PROPERTY_VISIBILITY } from '../../utils/constants';

const SEARCH_SCOPE = {
  [SEARCH_SCOPE_LIST.PROPERTIES]: 'uid',
  [SEARCH_SCOPE_LIST.PROPERTY_OWNERS]: 'ownerUid',
  [SEARCH_SCOPE_LIST.MANAGERS]: 'managerUid',
};
const DELETE_CONFIRM_MODAL_ID = 'property-delete-confirm';

function PropertyListView() {
  const { t } = useTranslation();
  const { isUserAdmin } = useRoles();
  const { context: { uiBuilders }, dispatch } = useAppContext();

  const [tooltipOpen, setTooltipOpen] = useState(null);
  const [itemSelected, setItemSelected] = useState(null);
  const navigate = useNavigate();
  const { filters } = useFilters();

  useDocumentTitle(t('property.browserTitle'));

  const propertyBuilders = useMemo(() => (uiBuilders ? uiBuilders['/property/ui'] : null), [uiBuilders]);

  const propertyDeleteMutation = useMutation({
    mutationFn: () => deleteProperty(itemSelected),
    onSuccess: () => {
      dispatch({ type: 'SET_NEED_DATA_TABLE_REFETCH', payload: 'property-list' });
      hideModal(DELETE_CONFIRM_MODAL_ID);
      utils.toast.success(t('property.crud.deleteSuccess'));
    },
    onError: (err) => {
      if (err?.response) {
        switch (err?.response?.status) {
          case 400: {
            if (err?.response?.data?.message === 'PROPERTY_PROPERTY_DELETE_UNDER_ACTIVE_LEASE_ERROR') {
              utils.toast.error(t(`property.crud.formErrors.${err.response.data.message}`));
            } else {
              utils.toast.error(t('global.form.errors.generic'));
            }
            break;
          }
          default: {
            utils.toast.error(t('global.form.errors.global'));
            break;
          }
        }
      }
    },
  });

  const typeOptions = useMemo(() => {
    if (!propertyBuilders?.propertyTypes) return [];
    return Object.keys(propertyBuilders.propertyTypes).map((type) => ({
      value: type,
      label: propertyBuilders.propertyTypes[type],
    }));
  }, [propertyBuilders]);

  const showDeleteConfirmModal = useCallback((uid) => {
    setItemSelected(uid);
    showModal(DELETE_CONFIRM_MODAL_ID);
  }, []);

  const handleDelete = useCallback(() => {
    propertyDeleteMutation.mutate();
  }, [propertyDeleteMutation]);

  const cancelDelete = useCallback(() => {
    setItemSelected(null);
    hideModal(DELETE_CONFIRM_MODAL_ID);
  }, []);

  const columns = useMemo(() => ([
    {
      header: t('property.table.columns.createdAt'),
      accessorKey: 'createdAt',
      size: 100,
      cell: ({ row: { original: { createdAt } } }) => (
        <div>
          <span>{createdAt ? format(new Date(createdAt), 'dd MMM yyyy', { locale: fr }) : '-'}</span>
        </div>
      ),
    },
    {
      header: t('property.table.columns.id'),
      accessorKey: 'unlockerID',
      size: 125,
    },
    {
      header: t('property.table.columns.name'),
      accessorKey: 'name',
      minSize: 200,
      cell: ({ row }) => {
        const { address, name } = row?.original || {};
        return (
          <div className={styles.nameAddress}>
            <Link to={`/property/${row.original.uid}`} className={styles.name}>
              <p className="p-2-700">{name}</p>
              <Picto icon="export-link" width={9} color="var(--color-secondary)" className={styles.externalLink} />
            </Link>
            <div className={styles.address}>
              <span>
                {`${address?.streetNumber || ''} ${address?.street || ''}`}
              </span>
              <span>
                {`, ${address?.zipCode || ''} ${address?.city || ''}`}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      header: t('property.table.columns.type'),
      accessorKey: 'type',
      size: 150,
      enableSorting: false,
      cell: ({ row: { original: { type } } }) => (
        <div>
          {t(`property.table.type.${type}`)}
        </div>
      ),
    },
    {
      header: t('property.table.columns.rent'),
      accessorKey: 'rentExcludingCharges',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { rentExcludingCharges } } }) => (
        <div className={styles.price}>
          {`${utils.centsToEuro(rentExcludingCharges)} €`}
        </div>
      ),
    },
    {
      header: t('property.table.columns.status'),
      accessorKey: 'status',
      size: 50,
      enableSorting: false,
      cell: ({ row: { original: { status } } }) => {
        const { variant, icon } = getStatusStyles(status);

        return (
          <div className={styles.tag}>
            <Tag
              label={t(`property.table.status.${status}`)}
              variant={variant}
              icon={icon}
              size="medium"
            />
          </div>
        );
      },
    },
    {
      header: t('property.table.columns.visibility'),
      accessorKey: 'visibility',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { visibility } } }) => {
        const { variant, icon } = getStatusStyles(visibility);

        return (
          <div className={styles.tag}>
            <Tag
              label={t(`property.table.visibility.${visibility}`)}
              variant={variant}
              icon={icon}
              size="medium"
            />
          </div>
        );
      },
    },
    {
      header: '',
      accessorKey: 'additionalOptions',
      size: 1,
      enableSorting: false,
      cell: ({ row }) => (
        <div className={styles.seeMore}>
          <Picto
            id={`more-option-property-${row?.original?.uid}`}
            icon="more"
            width={24}
            onClick={(e) => {
              e.stopPropagation();
              if (tooltipOpen === row?.original?.uid) setTooltipOpen(null);
              else setTooltipOpen(row?.original?.uid);
            }}
            color="var(--color-secondary)"
          />
          <Tooltip
            isOpen={tooltipOpen === row?.original?.uid}
            anchorId={`more-option-property-${row?.original?.uid}`}
            place="bottom"
            type="dark"
            effect="solid"
          >
            <TableOptions
              options={[
                {
                  id: 'edit',
                  label: t('global.listOptions.manage'),
                  icon: <Picto icon="edit" width={24} color="var(--color-primary)" />,
                  onHoverIcon: <Picto icon="edit" width={24} color="var(--color-white)" />,
                  onClick: (e) => {
                    e.stopPropagation();
                    setTooltipOpen(null);
                    navigate(`/property/${row?.original?.uid}`);
                  },
                },
                {
                  id: 'delete',
                  label: t('global.listOptions.delete'),
                  icon: <Picto icon="trash" width={24} color="var(--color-primary)" />,
                  onHoverIcon: <Picto icon="trash" width={24} color="var(--color-white)" />,
                  onClick: () => {
                    showDeleteConfirmModal(row?.original?.uid);
                  },
                },
              ]}
            />
          </Tooltip>
        </div>
      ),
    },
  ]), [t, tooltipOpen, navigate, showDeleteConfirmModal]);

  const allFiltersOptions = useMemo(() => [
    {
      name: 'status',
      type: 'checkbox',
      title: t('property.table.columns.status'),
      options: Object.values(PROPERTY_STATUS).map((status) => ({
        value: status,
        label: t(`property.table.status.${status}`),
      })),
    },
    {
      name: 'type',
      type: 'checkbox',
      title: t('property.table.columns.type'),
      options: typeOptions,
    },
    {
      name: 'visibility',
      type: 'checkbox',
      title: t('property.table.columns.visibility'),
      options: [
        {
          value: `${PROPERTY_VISIBILITY.VISIBLE},${PROPERTY_VISIBILITY.LISTING_VISIBLE}`,
          label: t(`property.table.visibility.${PROPERTY_VISIBILITY.VISIBLE}`),
        },
        {
          // eslint-disable-next-line max-len
          value: ` ${PROPERTY_VISIBILITY.HIDDEN},${PROPERTY_VISIBILITY.DEACTIVATED},${PROPERTY_VISIBILITY.LISTING_DELETED}`,
          label: t(`property.table.visibility.${PROPERTY_VISIBILITY.HIDDEN}`),
        },
        {
          value: PROPERTY_VISIBILITY.WAITING_LISTING_VALIDATION,
          label: t(`property.table.visibility.${PROPERTY_VISIBILITY.WAITING_LISTING_VALIDATION}`),
        },
        {
          value: PROPERTY_VISIBILITY.WAITING_KYC,
          label: t(`property.table.visibility.${PROPERTY_VISIBILITY.WAITING_KYC}`),
        },
      ],
    },
    {
      name: 'rent',
      multiName: ['rentPriceMin', 'rentPriceMax'],
      type: 'price',
      title: t('property.table.columns.rent'),
      options: [
        {
          value: filters?.rentPriceMin ? filters?.rentPriceMin[0] : null,
          label: `${t('property.filter.min-price')} (${filters?.rentPriceMin ? filters?.rentPriceMin[0] : null}€)`,
        },
        {
          value: filters?.rentPriceMax ? filters?.rentPriceMax[0] : null,
          label: `${t('property.filter.max-price')} (${filters?.rentPriceMax ? filters?.rentPriceMax[0] : null}€)`,
        },
      ],
    },
    {
      name: 'createdAt',
      multiName: ['createdAtDate', 'createdAtStartDate', 'createdAtEndDate'],
      type: 'date',
      title: t('property.table.columns.createdAt'),
      options: [
        {
          value: filters?.createdAtDate?.[0],
          label: `${t('global.filterDate.exactSelectedFilterLabel')} ${filters?.createdAtDate?.[0]
            ? format(new Date(filters?.createdAtDate?.[0]), 'dd/MM/yyyy', { locale: fr })
            : ''
          }`,
        },
        {
          value: filters?.createdAtStartDate?.[0],
          label: `${t('global.filterDate.after')} ${filters?.createdAtStartDate?.[0]
            ? format(new Date(filters?.createdAtStartDate?.[0]), 'dd/MM/yyyy', { locale: fr })
            : ''
          }`,
        },
        {
          value: filters?.createdAtEndDate?.[0],
          label: `${t('global.filterDate.before')} ${filters?.createdAtEndDate?.[0]
            ? format(new Date(filters?.createdAtEndDate?.[0]), 'dd/MM/yyyy', { locale: fr })
            : ''
          }`,
        },
      ],
    },
  ], [t, filters]);

  return (
    <div onClick={() => setTooltipOpen(null)}>
      <div className={styles.heading}>
        <h1>{t('property.title')}</h1>
      </div>
      <DataTable
        columns={columns}
        searchLabel={t('property.search')}
        omniSearch
        omniSearchScope={SEARCH_SCOPE}
        getData={isUserAdmin ? getPropertiesFromAdmin : getProperties}
        queryKey="property-list"
        allFiltersOptions={allFiltersOptions}
        headerBtn={(
          <Link to="/property/add" className={styles.headerBtn}>
            <Button
              variant="primary"
              size="large"
              icon="plus"
            >
              {t('property.addProperty')}
            </Button>
          </Link>
        )}
      />
      <ConfirmationModal
        id={DELETE_CONFIRM_MODAL_ID}
        onSubmit={handleDelete}
        onCancel={cancelDelete}
        loading={propertyDeleteMutation.isLoading}
      />
    </div>
  );
}

export default PropertyListView;
