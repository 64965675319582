import * as yup from 'yup';

export const newMessageInitialValues = {
  recipientUid: '',
  propertyUid: null,
  subject: '',
  message: '',
};

const newMessageSchema = yup.object().shape({
  recipientUid: yup.string().required('global.form.errors.required'),
  propertyUid: yup.string().nullable(),
  subject: yup.string().required('global.form.errors.required'),
  message: yup.string().required('global.form.errors.required'),
});

export default newMessageSchema;
