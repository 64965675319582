import * as yup from 'yup';

export const resendCodeInitialValues = {
  email: '',
};

const resendCodeSchema = yup.object().shape({
  email: yup.string().email('global.form.errors.email').required('global.form.errors.required'),
});

export default resendCodeSchema;
